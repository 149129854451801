import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { SalvarIcon, VoltarIcon } from "../../../../components/icons";
import { AddIcon } from "../../../../components/icons/add-icon";
import { useStyles } from "../dispensacao-avulsa-cadastro-style";
import { DispensacaoAvulsaFormMedicamento, EDispensacaoAvulsaPassos } from "../models/dispensacao-avulsa-models";
import { DispensacaoAvulsaStepsProp } from "../models/dispensacao-avulsa-props";
import { DispensacaoAvulsaMedicamentosCard } from "./dispensacao-avulsa-medicamentos-card";

export const DispensacaoAvulsaMedicamentoForm = (({
  dispensacao,
  setDispensacao,
  salvarDispensacao,
  avancarStep,
  editarMedicamentos
}: DispensacaoAvulsaStepsProp) => {
  const styles = useStyles();
  const [meds, setMeds] = useState<Array<DispensacaoAvulsaFormMedicamento[]>>([]);

  useEffect(() => {
    let codigosDistintos = dispensacao.medicamentos?.map(m => m.codigoAnvisa)
      .filter(selecionarUnicos);
    let matrizMedicamentos: Array<DispensacaoAvulsaFormMedicamento[]> = [];

    if (!codigosDistintos?.length)
      return;

    codigosDistintos.forEach((c) => {
      let medicamentosIguas = dispensacao.medicamentos.filter(m => m.codigoAnvisa === c);
      matrizMedicamentos[codigosDistintos.indexOf(c)] = medicamentosIguas;
    });

    setMeds(matrizMedicamentos);
  }, [dispensacao.medicamentos]);

  const selecionarUnicos = (value: string, index: number, array: string[]) =>
    array.indexOf(value) === index;

  const excluirMedicamento = (codigoAnvisa: string) => {
    let medicamentosAtualizados = dispensacao.medicamentos
      .filter(m => m.codigoAnvisa !== codigoAnvisa);

    setDispensacao((dispensacaoAtual) => ({
      ...dispensacaoAtual,
      medicamentos: medicamentosAtualizados
    }));

    let codigosDistintosAtualizados = medicamentosAtualizados.map(m => m.codigoAnvisa).filter(selecionarUnicos);
    let matrizMedicamentosAtualizada: Array<DispensacaoAvulsaFormMedicamento[]> = [];

    codigosDistintosAtualizados.forEach((c) => {
      let medicamentosIguas = medicamentosAtualizados.filter(m => m.codigoAnvisa === c);
      matrizMedicamentosAtualizada[codigosDistintosAtualizados.indexOf(c)] = medicamentosIguas;
    });

    setMeds(matrizMedicamentosAtualizada);
  }

  const voltarStep = () => avancarStep(EDispensacaoAvulsaPassos.Comprador);

  return (
    <div className={`flex-column w-100 h-100`}>
      <div className={styles.containerMedInfoTitle}>
        <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
        <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
      </div>

      {editarMedicamentos &&
        <>
          <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
            <div className={`flex-column w-100 ${styles.medicamentosCardsContainer}`}>
              {meds?.map((medicamentos, index) => (
                <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                  <DispensacaoAvulsaMedicamentosCard
                    medicamentos={medicamentos}
                    editar={() => editarMedicamentos(medicamentos)}
                    excluir={() => excluirMedicamento(medicamentos[0].codigoAnvisa)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={classNames('h-100', styles.addButtonMedicamentoStyle, styles.whiteButton)}>
            <Button variant="text" className="h-100 w-100" onClick={() => editarMedicamentos([])} >
              <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                <AddIcon class={styles.iconSize} />
                Adicionar Produto
              </div>
            </Button>
          </div>
        </>
      }

      <div className={`flex-row w-100 ${styles.buttonContainer}`}>
        <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
          <Button variant="text" className="h-100 w-100" onClick={() => voltarStep()} >
            <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
              <VoltarIcon class={styles.iconSize} />
              Voltar
            </div>
          </Button>
        </div>

        <div className={classNames(styles.buttonStyle, styles.darkButton)}>
          <Button onClick={() => salvarDispensacao!()} variant="text" className="h-100 w-100">
            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
              <SalvarIcon class={styles.iconSize} />
              Salvar
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
});
