import { useCallback, useState } from "react";
import { useAutocompleteMedicamento } from "../../../../data/api/gestao/medicamento/autocomplete-medicamento";
import { AutocompleteSaurus, retornoAutoComplete } from '../autocomplete-saurus';
import { AutocompleteMedicamentoProps, MedicamentoAtucompleteResponse } from "./autocomplete-medicamento-model";

interface MedDadoAdicional {
  estendido: string;
}

export const AutocompleteMedicamento = ({ onChange, ...props }: AutocompleteMedicamentoProps) => {
  const { getListMedicamento, carregando } = useAutocompleteMedicamento();
  const [medicamentos, setMedicamentos] = useState<Array<MedDadoAdicional>>([]);

  const getMedicamentosWrapper = useCallback(
    async (termo: string) => {
      try {
        const res = await getListMedicamento(termo);

        if (res.erro) throw res.erro;

        let responseList: Array<MedDadoAdicional> = [];
        if (res.resultado?.data?.list?.length > 0) {
          responseList = (res.resultado?.data?.list)
            .map((medicamento: MedicamentoAtucompleteResponse) => ({
              id: medicamento.id,
              descricao: medicamento.nome,
              descritivo: medicamento.descritivo,
              composicao: medicamento.composicao,
              ms: medicamento.codigoAnvisa,
              estendido: (`${medicamento.nome} - ${medicamento.codigoAnvisa}`),
              value: medicamento,
              vendaControlada: medicamento.tarja
            }));
        };

        setMedicamentos(responseList);
      } catch (e: any) { }
    }, [getListMedicamento]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );

  const onPesquisa = useCallback((termo: string) => {
    getMedicamentosWrapper(termo)
  }, [getMedicamentosWrapper]);

  return (
    <>
      <AutocompleteSaurus
        disabled={props.disabled}
        name={props.name}
        loading={(carregando)}
        onPesquisa={onPesquisa}
        fullWidth
        onChange={onChangeWrapper}
        opcoes={medicamentos}
        optionLabelKey={"estendido"}
        onBlur={props.onBlur}
        value={props.value}
        allowSubmit={props.allowSubmit}
        noOptionsText={"Medicamento não Localizado"}
        label={props.label}
        helperText={props.helperText}
        error={props.error}
        placeholder={props.placeholder}
      />
    </>
  );
}