import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { ETypeDomain } from "../../../../config/types";
import { OperacaoPendenteAvulsa } from "../../../../views/pages/private/dispensacoes-avulsas/models/dispensacao-avulsa-models";
import { OperacaoDispensacaoRequest } from "../../../../views/pages/private/dispensacoes/models/dispensacao-models";
import { useApiBase } from "../../base";

export function useGetDispensacoesPaginado() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getDispensacoesPaginado = useCallback(
    (filter: string, pageIndex: number, pageSize: number) => {
      return invocarApi({
        url: `Dispensacao/dispensacao-paginado?filter=${filter}&page=${pageIndex}&pageSize=${pageSize}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getDispensacoesPaginado,
  };
}

export function useCadastrarDispensacao() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const cadastrarDispensacao = useCallback(
    (novaDispensacao: OperacaoPendenteAvulsa) => {
      return invocarApi({
        url: `OperacaoPendente`,
        method: "POST",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        data: novaDispensacao,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    cadastrarDispensacao,
  };
}

export function useEditarOperacaoDispensacao() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const editarOperacaoDispensacao = useCallback(
    (request: OperacaoDispensacaoRequest) => {
      return invocarApi({
        url: `OperacaoPendente`,
        method: "PUT",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(request),
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    editarOperacaoDispensacao,
  };
}

export const useGetDispensacaoById = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getDispensacaoById = useCallback(
    (dispensasaoId: string, dataCompetencia: Date) => {     
      return invocarApi(
        {
          url: `Dispensacao/${dispensasaoId}/${dataCompetencia}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getDispensacaoById,
  };
}

export const useGetDispensacaoMedicamentoLote = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getDispensacaoMedicamentoLote = useCallback(
    (dispensasaoId?: string, dataCompetencia?: Date, codigoAnvisa?: string,) => {     
      return invocarApi(
        {
          url: `Dispensacao/${dispensasaoId}/${dataCompetencia}/${codigoAnvisa}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getDispensacaoMedicamentoLote,
  };
}