import { Button, MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { KeyValueModel } from "../../../../../model";
import { ETipoReceituario, ETipoReceituarioMap } from "../../../../../model/enums/enum-tipo-receiturario";
import { UFMock } from "../../../../../utils/uf-mock";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { useStyles } from "../dispensacao-cadastro-style";
import { DispensacaoStepsProp } from "../models/dispensacao-props";
import { DispensacaoMedicamentoReceita } from "../models/dispensacao-models";

export const DispensacaoReceitaForm = (({ visualizacao, formState, register, dispensacao, setDispensacao }: DispensacaoStepsProp) => {
  const styles = useStyles();
  const { errors } = formState;
  const fileTypes = ".pdf,.png,.jpg";
  const maxBytes = 1000000;
  const [receitaArquivoSalvo, setReceitaArquivoSalvo] = useState('');


  const listaTipoReceituario = Object.values(ETipoReceituario).filter(e => !isNaN(Number(e)))
    .map((tipo: any) => new KeyValueModel(tipo, ETipoReceituarioMap.get(tipo)));

  useEffect(() => {

    if (dispensacao.receitaArquivo)
      setReceitaArquivoSalvo(dispensacao.receitaArquivo)

    dispensacao.medicamentos?.map(medicamento => {

      if (!medicamento.receita)
        medicamento.receita = new DispensacaoMedicamentoReceita();

      return medicamento;
    });


  }, []);

  const setDataReceita = useCallback((dataReceita) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.dataReceita = dataReceita;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setDataLancamento = useCallback((data) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.dataLancamento = data;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setHoraLancamento = useCallback((hora) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.horaLancamento = hora;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setNumeroReceita = useCallback((numeroReceita: string) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.numeroReceita = numeroReceita;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setUf = useCallback((uf: string) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.uf = uf;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setCid = useCallback((cid: string) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.cid = cid;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setReceitaTipo = useCallback((receitaTipo) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.receitaTipo = receitaTipo;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  const setReceitaArquivo = useCallback((receitaArquivo, receitaArquivoNome) => {
    setDispensacao({ ...dispensacao, receitaArquivoNome, receitaArquivo });
  }, [dispensacao, setDispensacao]);

  const handleFile = async (event: any) => {

    if (!event.target.files.length) return;

    var fr = new FileReader();
    var receitaSelecionada = event.target.files[0];

    fr.onload = (fileLoadedEvent) =>
      setReceitaArquivo(fileLoadedEvent?.target?.result, receitaSelecionada.name)

    fr.readAsDataURL(receitaSelecionada);
  };

  const downloadFile = async () => {

    if (!receitaArquivoSalvo) return;

    const link = document.createElement('a');
    link.href = receitaArquivoSalvo;
    link.download = dispensacao.receitaArquivoNome ? dispensacao.receitaArquivoNome : "Receita Médica";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };

  return (
    <>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Receita</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados da receita</Typography>
        </div>

        <div className={`flex-column h-100 ${styles.innerFormContainer}`}>
          <SelectSaurus label="Tipo de Receita"
            value={dispensacao?.medicamentos[0]?.receita?.receitaTipo ?? ""}
            className={`w-100 ${styles.inputStyle}`}
            conteudo={listaTipoReceituario}
            disabled={visualizacao}
            variant="outlined"
            size="small"
            allowSubmit
            required
            error={Boolean(errors.receitaTipo && !dispensacao?.medicamentos[0]?.receita?.receitaTipo)}
            helperText={
              errors.receitaTipo && !dispensacao?.medicamentos[0]?.receita?.receitaTipo
                ? errors.receitaTipo?.message
                : undefined
            }
            {...register('receitaTipo', {
              onChange: (e: any) => setReceitaTipo(e.target.value)
            })}
          />
          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className="w-50">
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Data de Lançamento"
                value={dispensacao?.medicamentos[0]?.receita?.dataLancamento ?
                  new Date(dispensacao?.medicamentos[0]?.receita?.dataLancamento)?.toISOString()?.slice(0, 10) : ""}
                tipo="DATA"
                size="small"
                variant="outlined"
                disabled={visualizacao}
                error={Boolean(errors.dataLancamento && !dispensacao?.medicamentos[0]?.receita?.dataLancamento)}
                helperText={
                  errors.dataLancamento && !dispensacao?.medicamentos[0]?.receita?.dataLancamento
                    ? errors.dataLancamento?.message
                    : undefined
                }
                {...register('dataLancamento', {
                  onChange: (e: any) => setDataLancamento(e.target.value)
                })}
              />
            </div>
            <div className="w-50">
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Hora de Lançamento"
                value={dispensacao?.medicamentos[0]?.receita?.horaLancamento ?? ""}
                tipo="HORA"
                size="small"
                variant="outlined"
                disabled={visualizacao}
                error={Boolean(errors.horaLancamento && !dispensacao?.medicamentos[0]?.receita?.horaLancamento)}
                helperText={
                  errors.horaLancamento && !dispensacao?.medicamentos[0]?.receita?.horaLancamento
                    ? errors.horaLancamento?.message
                    : undefined
                }
                {...register('horaLancamento', {
                  onChange: (e: any) => setHoraLancamento(e.target.value)
                })}
              />
            </div>
          </div>
          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className="w-50">
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Data da Receita"
                value={dispensacao?.medicamentos[0]?.receita?.dataReceita ?
                  new Date(dispensacao?.medicamentos[0]?.receita?.dataReceita)?.toISOString()?.slice(0, 10) : ""}
                tipo="DATA"
                size="small"
                variant="outlined"
                disabled={visualizacao}
                maxDate={moment(new Date().setDate(new Date().getDate())).format("YYYY-MM-DD")}
                error={Boolean(errors.dataReceita && !dispensacao.medicamentos[0].receita?.dataReceita)}
                helperText={
                  errors.dataReceita && !dispensacao?.medicamentos[0]?.receita?.dataReceita
                    ? errors.dataReceita?.message
                    : undefined
                }
                {...register('dataReceita', {
                  onChange: (e: any) => setDataReceita(e.target.value)
                })}
              />
            </div>
            <div className="w-50">
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Número da Receita"
                value={dispensacao?.medicamentos[0]?.receita?.numeroReceita ?? ""}
                tipo="TEXTO"
                size="small"
                variant="outlined"
                disabled={visualizacao}
                error={Boolean(errors.numeroReceita && !dispensacao?.medicamentos[0]?.receita?.numeroReceita)}
                helperText={
                  errors.numeroReceita && !dispensacao?.medicamentos[0]?.receita?.numeroReceita
                    ? errors.numeroReceita?.message
                    : undefined
                }
                {...register('numeroReceita', {
                  onChange: (e: any) => setNumeroReceita(e.target.value)
                })}
              />
            </div>
          </div>
          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className="w-50">
              <TextFieldSaurus className={`w-100 h-100 ${styles.inputStyle}`}
                labelCustom="UF"
                value={dispensacao?.medicamentos[0]?.receita?.uf ?? ""}
                variant="outlined"
                size="small"
                select
                disabled={visualizacao}
                defaultValue={dispensacao?.medicamentos[0]?.receita?.uf ?? ""}
                error={Boolean(errors.uf && !dispensacao?.medicamentos[0]?.receita?.uf)}
                helperText={
                  errors.uf && !dispensacao?.medicamentos[0]?.receita?.uf
                    ? errors.uf?.message
                    : undefined
                }
                {...register('uf', {
                  onChange: (e: any) => setUf(e.target.value)
                })}
              >
                {
                  UFMock.map(uf => (
                    <MenuItem className={styles.menuItemStyle} key={uf.Key} value={uf.Value}>
                      {uf.Value}
                    </MenuItem>
                  ))
                }
              </TextFieldSaurus>
            </div>
            <div className="w-50">
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="CID"
                value={dispensacao?.medicamentos[0]?.receita?.cid}
                defaultValue={dispensacao?.medicamentos[0]?.receita?.cid ?? ""}
                size="small"
                variant="outlined"
                disabled={visualizacao}
                error={Boolean(errors.cid && !dispensacao?.medicamentos[0].receita?.cid)}
                helperText={
                  errors.cid && !dispensacao?.medicamentos[0]?.receita?.cid
                    ? errors.cid?.message
                    : undefined
                }
                {...register('cid', {
                  onChange: (e: any) => {
                    setCid(e.target.value)
                  }
                })}
              />
            </div>
          </div>
          <input id="receitaFile" type="file" size={maxBytes} accept={fileTypes} name="files" className={styles.fileInput} onChange={(e: any) => handleFile(e)} />
          <div className={`flex-column w-100 ${styles.uploadReceitaContainer}`}>
            <div className={`w-100 ${styles.uploadButtonContainer}`}>
              <Button className="w-100" variant="text" onClick={() => {
                {
                  receitaArquivoSalvo ?
                    downloadFile() :
                    document.getElementById('receitaFile')?.click()
                }
              }

              }>
                <div className={styles.uploadButtonLabel}>{dispensacao.receitaArquivoNome ?? "Selecione sua Receita"}</div>
              </Button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
});