import { Button, Fade, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useToastSaurus } from "../../../../../services/app";
import { ButtonModalHeader } from "../../../../components/controles/buttons/button-modal-header";
import { VoltarIcon } from "../../../../components/icons";
import { ModalHeader } from "../../../../components/modal-header";
import { InventarioLote, InventarioMedicamento, MedicamentoInfo } from "../models/inventario.models";
import { InventarioLoteAdicionar } from "./componentes/inventario-lote-adicionar";
import { InventarioLoteCard } from "./componentes/inventario-lote-card";
import { InventarioLoteForm } from "./componentes/inventario-lote-form";
import { useStyles } from "./inventario-lote.styles";


interface InventarioLotePageProps {
  medicamentoInfo: MedicamentoInfo;
  inventarioMedicamentosAtual: InventarioMedicamento[];
  fecharModal: (state: any) => void;
}

export const InventarioLotePage = ((props: InventarioLotePageProps) => {

  const [medicamentoInfo] = useState<MedicamentoInfo>(props.medicamentoInfo);
  const [inventarioMedicamentosAtual] = useState<Array<InventarioMedicamento>>(props.inventarioMedicamentosAtual);
  const [lotesMedicamento, setLotesMedicamento] = useState<Array<InventarioLote>>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loteEdicao, setLoteEdicao] = useState<InventarioLote>({});

  const { showToast } = useToastSaurus();
  const styles = useStyles();

  useEffect(() => {
    if (!medicamentoInfo?.lotes?.length)
      return;
    setLotesMedicamento(medicamentoInfo?.lotes);
  }, []);

  const adicionarLote = (lote: InventarioLote) => {

    const medicamentoJaExiste = lotesMedicamento.some(x => x.lote === lote.lote);

    if (medicamentoJaExiste === true) {
      showToast("error", "Já existe um lote com esta descrição", 5000, 'top-center');
      return;
    }

    setLotesMedicamento(prevLoteMedicamento => [...prevLoteMedicamento, lote]);
  };

  const editarLote = (lote?: string) => {

    const loteEditar = lotesMedicamento.find(x => x.lote === lote);

    if (loteEditar) {
      setIsEdit(true);
      setLoteEdicao(loteEditar);
    }
  };

  const salvarEdicaoLote = (lote: InventarioLote, loteEditado?: string) => {

    const indexLoteAtualizar = lotesMedicamento?.findIndex(x => x.lote === loteEditado);

    const indexLoteAtualizado = lotesMedicamento?.findIndex(x => x.lote === lote.lote);

    if (indexLoteAtualizado >= 0 && indexLoteAtualizar !== indexLoteAtualizado) {
      showToast("error", "Já existe um lote com esta descrição", 5000, 'top-center');
      setIsEdit(false);
      return;
    }

    if (indexLoteAtualizar !== -1) {
      setLotesMedicamento(prevLotesMedicamento => {
        const updatedLotes = [...prevLotesMedicamento];
        updatedLotes[indexLoteAtualizar] = lote;
        return updatedLotes;
      });
    }

    setIsEdit(false);

  };

  const removerLote = (lote?: string) => {
    const listaLoteResult = lotesMedicamento?.filter(x => x.lote !== lote);
    setLotesMedicamento(listaLoteResult);
  };

  const fecharInventarioMedicamentoLote = () => {

    if (isEdit) {
      setIsEdit(false)
      return;
    }

    props.fecharModal({ state: { inventarioMedicamentosAtual: inventarioMedicamentosAtual } });
  };

  const salvarLote = () => {

    const medicamentoIndex = inventarioMedicamentosAtual.findIndex(x => x.medicamentoId === medicamentoInfo.medicamentoId);

    if (medicamentoIndex !== -1) {

      const medicamentoAtualizado = inventarioMedicamentosAtual[medicamentoIndex];

      medicamentoAtualizado.lotes = lotesMedicamento;

      let quantidadeLote: number = 0;

      medicamentoAtualizado?.lotes.forEach(x => {
        const quantidadeLoteAtual: number = x.quantidade !== undefined ? parseInt(x.quantidade.toString(), 10) : 0;
        quantidadeLote += quantidadeLoteAtual;
      })

      medicamentoAtualizado.quantidade = quantidadeLote;
      const novoInventarioMedicamentos = [...inventarioMedicamentosAtual];
      novoInventarioMedicamentos[medicamentoIndex] = medicamentoAtualizado;


      props.fecharModal({
        state: {
          medicamentoInfo: medicamentoInfo,
          inventarioMedicamentosAtual: novoInventarioMedicamentos
        }
      });
    }

  };

  const {
    control,
    formState
  } = useForm<InventarioLote>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  return (
    <>
      <Fade in mountOnEnter unmountOnExit >
        <div className={styles.container}>

          <ModalHeader
            title={'Inventário - Lote'}
            leftArea={
              <ButtonModalHeader
                tooltip="Voltar"
                icon={<VoltarIcon tipo="MODAL_HEADER" />}
                onClick={() => { fecharInventarioMedicamentoLote() }}
              />
            }
          />
          <div className={`flex-column w-100 ${styles.contentContainer}`}>

            <div className={`flex-column h-100 ${styles.listPadding}`}>
              <Typography className={`w-100 ${styles.titleStyle}`}>
                Lote
              </Typography>
              <div className={`w-100 ${styles.breakLine}`} />

              {isEdit
                ? (
                  <div className={`flex-column h-100 w-100 ${styles.loteContainer}`}>
                    <InventarioLoteForm
                      control={control}
                      formState={formState}
                      salvarEdicaoLote={salvarEdicaoLote}
                      loteEdicao={loteEdicao}
                    />
                  </div>
                )
                : (
                  <>
                    <div className={`flex-column h-100 w-100 ${styles.loteContainer}`}>

                      <div className={`flex-column w-100 ${styles.containerLoteAdicionar}`} >
                        <InventarioLoteAdicionar
                          control={control}
                          formState={formState}
                          medicamentonome={medicamentoInfo.medicamentoNome ?? ''}
                          adicionarLote={adicionarLote}
                        />
                      </div>

                      {!lotesMedicamento?.length && (
                        <Typography variant="h3" className={styles.semRegistroStyle}>
                          Nenhum lote foi encontrado.
                        </Typography>
                      )}

                      <div className={styles.listContainer}>
                        {lotesMedicamento?.map((inventarioLote, index) => (
                          <div className={`flex-column w-100 ${styles.cardLoteItem}`}>
                            <InventarioLoteCard
                              inventarioLote={inventarioLote}
                              removerLote={removerLote}
                              editarLote={editarLote}
                              key={index}
                            />
                          </div>
                        ))}
                      </div>

                    </div>

                    <div className={`flex-row w-100 ${styles.containerButton}`}>

                      <div className={
                        classNames(
                          styles.buttonStyle,
                          styles.darkButton)}>

                        <Button
                          className="h-50 w-100"
                          variant="text"
                          onClick={() => salvarLote()}
                        >
                          <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                            Concluir
                          </div>
                        </Button>

                      </div>

                    </div>
                  </>
                )
              }

            </div >
          </div >

        </div >
      </Fade >
    </>
  );

});