import { Button, Fade, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useGetConfiguracoes } from '../../../../data/api/gestao/configuracoes/get-configuracoes';
import { usePostConfiguracoes } from '../../../../data/api/gestao/configuracoes/post-configuracoes';
import { usePutConfiguracoes } from '../../../../data/api/gestao/configuracoes/put-configuracoes';
import { ConfiguracoesModel } from '../../../../model/api/configuracoes/configuracoes-models';
import { useToastSaurus } from '../../../../services/app';
import { CircularLoading } from '../../../components';
import { DefaultCard } from '../../../components/cards/components';
import { TextFieldSaurus } from '../../../components/controles/inputs';
import { PageHeader } from '../../../components/headers/header-page/header-page';
import { useStyles } from "./configuracoes-page-styles";

export class ValidacaoConfiguracoes {

  constructor(
    emailError: boolean,
    senhaError: boolean,
    confirmarSenhaError: boolean,
    isValidEmail: boolean
  ) {
    this.emailError = emailError;
    this.senhaError = senhaError;
    this.confirmarSenhaError = confirmarSenhaError;
    this.isValidEmail = isValidEmail;
  }

  emailError: boolean;
  senhaError: boolean;
  confirmarSenhaError: boolean;
  isValidEmail: boolean;
}

export class PropsConfiguracoes {

  constructor(
    id: string,
    email: string,
    senha: string,
    confirmarSenha: string
  ) {

    this.id = id;
    this.email = email;
    this.senha = senha
    this.confirmarSenha = confirmarSenha
  }
  id: string;
  email: string;
  senha: string;
  confirmarSenha: string;
}

export const ConfiguracoesPage = () => {

  const [propsConfiguracoes, setPropsConfiguracoes] = useState(new PropsConfiguracoes('', '', '', ''))
  const [validacaoConfiguracoes, setValidacaoConfiguracoes] = useState(new ValidacaoConfiguracoes(false, false, false, false));

  const { postConfiguracoes, carregando: salvandoConfiguracao } = usePostConfiguracoes();
  const { putConfiguracoes, carregando: alterandoConfiguracao } = usePutConfiguracoes();
  const { getConfiguracoes, carregando: carregandoConfiguracao } = useGetConfiguracoes();
  const { showToast } = useToastSaurus();
  const classes = useStyles();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validatePasswordMatch = (password: string, confirmPassword: string): boolean => {
    return password === confirmPassword;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPropsConfiguracoes(prev => ({ ...prev, email: inputValue }));

    if (inputValue.length < 3) {
      setValidacaoConfiguracoes(prev => ({
        ...prev,
        emailError: true,
        isValidEmail: false
      }));
    } else {
      const emailValid = emailRegex.test(inputValue?.trim());
      setValidacaoConfiguracoes(prev => ({
        ...prev,
        emailError: !emailValid,
        isValidEmail: emailValid
      }));
    }
  };

  const handleSenhaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPropsConfiguracoes(prev => ({ ...prev, senha: inputValue }));
    setValidacaoConfiguracoes(prev => ({
      ...prev,
      senhaError: inputValue.length < 6
    }));
  };

  const handleConfirmarSenhaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPropsConfiguracoes(prev => ({ ...prev, confirmarSenha: inputValue }));
    setValidacaoConfiguracoes(prev => ({
      ...prev,
      confirmarSenhaError: !validatePasswordMatch(propsConfiguracoes.senha, inputValue)
    }));
  };

  const carregarConfiguracoes = useCallback(async () => {

    try {
      const response = await getConfiguracoes();

      if (response.erro) {
        showToast("error", `${response.erro}`, 5000, 'top-center');
        return;
      }

      if (response.resultado?.data) {
        setPropsConfiguracoes(prev => ({
          ...prev,
          email: response.resultado?.data.emailSNGPC,
          senha: response.resultado?.data.senhaSNGPC,
          confirmarSenha: response.resultado?.data.confirmarSenha,
          id: response.resultado?.data.id,
        }));
      }
    } catch (error) {
      console.error("Erro ao obter configurações:", error);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [setPropsConfiguracoes]);

  useEffect(() => {
    carregarConfiguracoes();
  }, []);

  const salvarEditarConfiguracoes = useCallback(async () => {

    try {
      const trimmedEmail = propsConfiguracoes.email?.trim();
      const trimmedSenha = propsConfiguracoes.senha?.trim();
      const trimmedConfirmarSenha = propsConfiguracoes.confirmarSenha?.trim();

      const eMailValido = emailRegex.test(trimmedEmail);
      const isEmailVazio = !trimmedEmail;
      const isSenhaValida = trimmedSenha.length >= 6;
      const isSenhaVazia = !trimmedSenha;
      const isConfirmarSenhaVazia = !trimmedConfirmarSenha;

      setValidacaoConfiguracoes(prev => ({
        ...prev,
        emailError: isEmailVazio || !eMailValido,
        senhaError: isSenhaVazia || !isSenhaValida,
        confirmarSenhaError: isConfirmarSenhaVazia || trimmedSenha !== trimmedConfirmarSenha
      }));

      if (isEmailVazio || isSenhaVazia || isConfirmarSenhaVazia) {
        showToast("error", "Preencha todos os campos!", 5000, 'top-center');
        return;
      }

      if (!eMailValido) {
        showToast("error", "E-Mail inválido!", 5000, 'top-center');
        return;
      }

      if (!isSenhaValida) {
        showToast("error", "Senha Inválida!", 5000, 'top-center');
        return;
      }

      if (trimmedSenha !== trimmedConfirmarSenha) {
        showToast("error", "As Senhas digitadas não coincidem!", 5000, 'top-center');
        return;
      }

      var configuracao = new ConfiguracoesModel(
        trimmedEmail,
        trimmedSenha,
        trimmedConfirmarSenha
      )

      if (propsConfiguracoes.id) {

        configuracao.id = propsConfiguracoes.id;

        const result = await putConfiguracoes(configuracao);

        if (result.erro) {
          showToast("error", `${result.erro}`, 5000, 'top-center');
          return;
        }

        showToast('success', 'Configuração Salva com Sucesso!', 5000, 'top-center');
      }
      else {
        await postConfiguracoes(configuracao);
        showToast('success', 'Configuração Salva com Sucesso!', 5000, 'top-center');
        await carregarConfiguracoes();
      }

    } catch (error: any) {
      showToast("error", 'Erro ao salvar Configuração', 5000, 'top-center');
    }
  }, [propsConfiguracoes.email, propsConfiguracoes.senha, propsConfiguracoes.confirmarSenha]);

  const isLoading = () =>
    salvandoConfiguracao || alterandoConfiguracao || carregandoConfiguracao;

  return (
    <>

      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      <Fade in mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${classes.color}`}>
          <PageHeader
            titulo='Configurações - SNGPC'
            showMenu
          />
          <div className={`flex-column h-90 overflow-auto ${classes.contentContainer}`}>
            <div className={`flex-column h-100 m-1 ${classes.listPadding}`} >

              <DefaultCard className={classes.card}>
                <Grid container className={classes.root}>
                  <div className={classes.containerEmailSenha}>
                    <div className={classes.containerEmail}>
                      <TextFieldSaurus
                        className={`w-100 ${classes.inputEmail}`}
                        tipo='EMAIL'
                        variant="outlined"
                        labelCustom='E-Mail do SNGPC'
                        size="medium"
                        name='email'
                        allowSubmit
                        fullWidth
                        onChange={handleEmailChange}
                        value={propsConfiguracoes.email ? propsConfiguracoes.email : ''}
                        error={!propsConfiguracoes.email && validacaoConfiguracoes.emailError}
                        helperText={
                          validacaoConfiguracoes.emailError ? (validacaoConfiguracoes.isValidEmail ? 'E-Mail Inválido!' : 'O E-Mail é obrigatório!') : ''
                        }
                        FormHelperTextProps={{ className: classes.customHelperText }}
                      />
                    </div>
                    <div className={classes.containerSenha}>
                      <TextFieldSaurus
                        className={classes.inputSenha}
                        tipo='PASSWORD'
                        variant="outlined"
                        labelCustom='Senha do SNGPC'
                        size="medium"
                        name='senha'
                        allowSubmit
                        onChange={handleSenhaChange}
                        value={propsConfiguracoes.senha ? propsConfiguracoes.senha : ''}
                        error={validacaoConfiguracoes.senhaError}
                        helperText={
                          validacaoConfiguracoes.senhaError ? (propsConfiguracoes.senha?.trim().length === 0 ? 'A Senha é obrigatória!' : 'Você deve inserir no mínimo seis caracteres.') : ''
                        }
                        FormHelperTextProps={{ className: classes.customHelperText }}
                      />
                    </div>
                    <div className={classes.containerSenha}>
                      <TextFieldSaurus
                        className={classes.inputSenha}
                        tipo='PASSWORD'
                        variant="outlined"
                        labelCustom='Confirmar Senha'
                        size="medium"
                        name="confirmarSenha"
                        allowSubmit
                        onChange={handleConfirmarSenhaChange}
                        value={propsConfiguracoes.confirmarSenha ? propsConfiguracoes.confirmarSenha : ''}
                        error={validacaoConfiguracoes.confirmarSenhaError || (validacaoConfiguracoes.confirmarSenhaError && !propsConfiguracoes.confirmarSenha)}
                        helperText={
                          validacaoConfiguracoes.confirmarSenhaError ? (propsConfiguracoes.senha?.trim().length === 0 ? 'A Confirmação da Senha é obrigatória!' : 'As Senhas digitadas não coincidem!') : ''
                        }
                        FormHelperTextProps={{ className: classes.customHelperText }}
                      />
                    </div>
                  </div>
                  <div className={classes.containerButtonSave}>
                    <Button
                      onClick={salvarEditarConfiguracoes}
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.btnSalvarEditar}
                      fullWidth={true}
                    >
                      <span >Salvar</span>
                    </Button>
                  </div>
                </Grid>
              </DefaultCard>

            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}