import { Typography } from "@material-ui/core";
import { KeyValueModel } from "../../../../../../model";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../../components/controles/selects/select-saurus/select-saurus";
import { ETipoOperacaoTransferencia, ETipoOperacaoTransferenciaMap, TransferenciaFilial, TransferenciaStepsProp } from "../../models/transferencias.model";
import { useStyles } from "./transferencias-cadastro-styles";


export const TransferenciaLancamentoForm = (({
  visualizacao,
  register,
  formState,
  empresasFiliais,
  transferencia,
  setTransferencia
}: TransferenciaStepsProp) => {
  const styles = useStyles();
  const { errors } = formState;

  const listaTipoOperacao = Object.values(ETipoOperacaoTransferencia)
    .filter(t => !isNaN(Number(t))).map(i => i as ETipoOperacaoTransferencia)
    .map(tipo => new KeyValueModel(tipo, ETipoOperacaoTransferenciaMap.get(tipo)));

  const listaFiliais = empresasFiliais?.map(empresa =>
    new KeyValueModel(empresa.Documento, `${empresa.Documento} - ${empresa.Descricao}`));

  return (
    <div className={`flex-column ${styles.formContainer}`}>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Dados da Transferência</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados da Transferência</Typography>
        </div>

        <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <SelectSaurus label="Tipo da Operação"
              disabled={visualizacao}
              conteudo={listaTipoOperacao}
              className={`h-100 w-100 ${styles.inputStyle}`}
              variant="outlined"
              allowSubmit={true}
              size="small"
              required
              value={transferencia.tipoOperacaoTransferencia}
              error={Boolean(errors.tipoOperacaoTransferencia && !transferencia.tipoOperacaoTransferencia)}
              helperText={
                errors.tipoOperacaoTransferencia && !transferencia.tipoOperacaoTransferencia
                  ? errors.tipoOperacaoTransferencia?.message
                  : undefined
              }
              {...register("tipoOperacaoTransferencia", {
                onChange: (e: any) => setTransferencia({
                  ...transferencia,
                  origemCNPJ: "",
                  origem: new TransferenciaFilial(),
                  destinoCNPJ: "",
                  destino: new TransferenciaFilial(),
                  tipoOperacaoTransferencia: e.target.value
                })
              })}
            />
          </div>

          <div className={`w-100 ${styles.inputContainer}`}>
            {transferencia.tipoOperacaoTransferencia === ETipoOperacaoTransferencia.entrada
              ? <SelectSaurus label="Filial de Origem"
                disabled={visualizacao}
                conteudo={listaFiliais ?? []}
                className={`h-100 w-100 ${styles.inputStyle}`}
                variant="outlined"
                allowSubmit={true}
                size="small"
                required
                value={transferencia.origemCNPJ}
                error={Boolean(errors.origemCNPJ && !transferencia.origemCNPJ)}
                helperText={
                  errors.origemCNPJ && !transferencia.origemCNPJ
                    ? errors.origemCNPJ?.message
                    : undefined
                }
                {...register("origemCNPJ", {
                  onChange: (e: any) => setTransferencia({
                    ...transferencia,
                    origemCNPJ: e.target.value
                  })
                })}
              />
              : <SelectSaurus label="Filial de Destino"
                disabled={visualizacao}
                conteudo={listaFiliais ?? []}
                className={`h-100 w-100 ${styles.inputStyle}`}
                variant="outlined"
                allowSubmit={true}
                size="small"
                required
                value={transferencia.destinoCNPJ}
                error={Boolean(errors.destinoCNPJ && !transferencia.destinoCNPJ)}
                helperText={
                  errors.destinoCNPJ && !transferencia.destinoCNPJ
                    ? errors.destinoCNPJ?.message
                    : undefined
                }
                {...register("destinoCNPJ", {
                  onChange: (e: any) => setTransferencia({
                    ...transferencia,
                    destinoCNPJ: e.target.value
                  })
                })}
              />
            }
          </div>

          <div className="w-100">
            <TextFieldSaurus
              className={`h-100 w-100 ${styles.inputStyle}`}
              labelCustom="N. da Nota Fiscal"
              value={transferencia.numeroNotaFiscal}
              required
              fullWidth
              tipo="NUMERO"
              size="small"
              variant="outlined"
              showStartAdornment
              error={Boolean(errors.numeroNotaFiscal && !transferencia.numeroNotaFiscal)}
              helperText={
                errors.numeroNotaFiscal && !transferencia.numeroNotaFiscal
                  ? errors.numeroNotaFiscal?.message
                  : undefined
              }
              {...register("numeroNotaFiscal", {
                onChange: (e: any) => setTransferencia({ ...transferencia, numeroNotaFiscal: e.target.value })
              })}
              disabled={visualizacao}
            />
          </div>

          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className="w-50">
              <TextFieldSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Data de Lançamento"
                value={transferencia.data}
                required
                tipo="DATA"
                size="small"
                variant="outlined"
                showStartAdornment
                error={Boolean(errors.data && !transferencia.data)}
                helperText={
                  errors.data && !transferencia.data
                    ? errors.data?.message
                    : undefined
                }
                {...register("data", {
                  onChange: (e: any) => setTransferencia({ ...transferencia, data: e.target.value })
                })}
                disabled={visualizacao}
              />
            </div>
            <div className="w-50">
              <TextFieldSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Hora de Lançamento"
                value={transferencia.hora}
                required
                tipo="HORA"
                size="small"
                variant="outlined"
                showStartAdornment
                error={Boolean(errors.hora && !transferencia.hora)}
                helperText={
                  errors.hora && !transferencia.hora
                    ? errors.hora?.message
                    : undefined
                }
                {...register("hora", {
                  onChange: (e: any) => setTransferencia({ ...transferencia, hora: e.target.value })
                })}
                disabled={visualizacao}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
});