import { Button, Fade, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useGetMovimentacoes } from "../../../../../data/api/gestao/movimentacao/movimentacao";
import { useToastSaurus } from "../../../../../services/app";
import { CircularLoading } from "../../../../components";
import { DialogConflitoPeriodo } from "../../../../components/dialog/dialog-conflito-periodo/dialog-conflito-periodo";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import {
  ETipoOperacaoMovimentacao,
  MovimentacaoCompraResponse,
  MovimentacaoDispensacaoResponse,
  MovimentacaoModel,
  MovimentacaoPerdaResponse,
  MovimentacaoTransferenciaResponse
} from "../models/envio-movimentacao.model";
import { EnvioMovimentacaoListFiltro } from "./componentes/envio-movimentacao-listagem-filtro";
import { MovimentacaoCompraLista } from "./componentes/movimentacao-compra-lista";
import { MovimentacaoDispensacaoLista } from "./componentes/movimentacao-dispensacao-lista";
import { MovimentacaoPerdaLista } from "./componentes/movimentacao-perda-lista";
import { MovimentacaoTransferenciaLista } from "./componentes/movimentacao-transferencia-lista";
import { useStyles } from "./envio-movimentacao-listagem-styles";

export const EnvioMovimentacaoList = () => {

  const styles = useStyles();
  const [formMovimentacao, setFormMovimentacao] = useState({} as MovimentacaoModel);
  const [existeMovimentacao, setExisteMovimentacao] = useState(false);
  const [compras, setCompras] = useState<MovimentacaoCompraResponse[]>([]);
  const [dispensacoes, setDispensacoes] = useState<MovimentacaoDispensacaoResponse[]>([]);
  const [transferencias, setTransferencias] = useState<MovimentacaoTransferenciaResponse[]>([]);
  const [perdas, setPerdas] = useState<MovimentacaoPerdaResponse[]>([]);
  const [exibirSemRegistro, setExibirSemRegistro] = useState(false);

  const [resetarFiltro, setResetarFiltro] = useState(false);

  const [conflitoPeriodo, setConflitoPeriodo] = useState(false);
  const [openModalConflitoPeriodo, setOpenModalConflitoPeriodo] = useState(false);

  const { getMovimentacoes, carregando: carregandoLista } = useGetMovimentacoes();
  const { getDownloadXML, carregando: gerandoXML } = useGetMovimentacoes();
  const { postXMLSNGPCAnvisa, carregando: enviandoXMLSNGPC } = useGetMovimentacoes();
  const { showToast } = useToastSaurus();

  const getMovimentacao = useCallback(async (movimentacaoFiltro: MovimentacaoModel) => {

    try {

      setExisteMovimentacao(false);
      setCompras([]);
      setDispensacoes([]);
      setTransferencias([]);
      setPerdas([]);

      const res = await getMovimentacoes(movimentacaoFiltro.dataInicial, movimentacaoFiltro.dataFinal, movimentacaoFiltro.tipoOperacao);

      if (res.erro)
        throw res.erro;

      setFormMovimentacao(movimentacaoFiltro);

      switch (movimentacaoFiltro.tipoOperacao) {

        case ETipoOperacaoMovimentacao.Compra:

          if (!res.resultado?.data?.listaMovimentacaoCompra?.length) {
            setExibirSemRegistro(true);
            setExisteMovimentacao(false);
            return;
          }

          const comprasResult = res.resultado?.data?.listaMovimentacaoCompra?.map((compra: any) => ({
            dataLancamento: compra.dataLancamento,
            dataNotaFiscal: compra.dataNotaFiscal,
            numeroNotaFiscal: compra.numeroNotaFiscal,
            cnpjFornecedor: compra.cnpj,
            nomeFornecedor: compra.nome
          } as MovimentacaoCompraResponse));

          setCompras(comprasResult);
          setExisteMovimentacao(true);
          setExibirSemRegistro(false);
          break;

        case ETipoOperacaoMovimentacao.Dispensacao:

          if (!res.resultado?.data?.listaMovimentacaoDispensacao?.length) {
            setExibirSemRegistro(true);
            setExisteMovimentacao(false)
            return;
          }

          const dispensacoesResult = res.resultado?.data?.listaMovimentacaoDispensacao?.map((dispensacao: any) => ({
            dataVenda: dispensacao.dataHoraLancamento,
            dataReceita: dispensacao.dataHoraLancamento,
            numeroReceita: dispensacao.numeroReceita,
            pacienteComprador: dispensacao.nomePaciente,
            prescritor: dispensacao.nomePrescritor
          } as MovimentacaoDispensacaoResponse));

          setDispensacoes(dispensacoesResult);
          setExisteMovimentacao(true);
          setExibirSemRegistro(false);
          break;

        case ETipoOperacaoMovimentacao.Perda:

          if (!res.resultado?.data?.listaMovimentacaoPerda?.length) {
            setExibirSemRegistro(true);
            setExisteMovimentacao(false)
            return;
          }

          const perdasResult = res.resultado?.data?.listaMovimentacaoPerda?.map((perda: any) => ({
            data: perda.data,
            medicamento: perda.medicamentoNome,
            quantidade: perda.quantidade,
            motivo: perda.motivo
          } as MovimentacaoPerdaResponse));

          setPerdas(perdasResult);
          setExisteMovimentacao(true);
          setExibirSemRegistro(false);
          break;

        case ETipoOperacaoMovimentacao.Transferencia:

          if (!res.resultado?.data?.listaMovimentacaoTransferencia?.length) {
            setExibirSemRegistro(true);
            setExisteMovimentacao(false)
            return;
          }

          const transferenciasResult = res.resultado?.data?.listaMovimentacaoTransferencia?.map((transferencia: any) => ({
            data: transferencia.data,
            operacao: transferencia.tipoOperacaoTransferencia,
            numeroNotaFiscal: transferencia.numeroNotaFiscal,
            origem: transferencia.origem,
            destino: transferencia.destino
          } as MovimentacaoTransferenciaResponse));

          setTransferencias(transferenciasResult);
          setExisteMovimentacao(true);
          setExibirSemRegistro(false);
          break;

        default:
          break;
      }


    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getMovimentacoes]);

  function limparCardMovimentacoes() {
    setExisteMovimentacao(false);
    setCompras([]);
    setDispensacoes([]);
    setTransferencias([]);
    setPerdas([]);
  }

  function download() {
    downloadXML(formMovimentacao)
  }

  const downloadXML = useCallback(async (movimentacao: MovimentacaoModel) => {

    try {

      const res = await getDownloadXML(movimentacao.dataInicial, movimentacao.dataFinal, movimentacao.tipoOperacao);

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      const blob = new Blob([res.resultado?.data], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = getNomeArquivo(movimentacao.tipoOperacao);
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);


    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }, [getDownloadXML]);

  function enviarMovimentacao() {
    if (conflitoPeriodo)
      return setOpenModalConflitoPeriodo(true);

    enviarXMLSNGPCAnvisa(formMovimentacao);
  }

  const enviarXMLSNGPCAnvisa = useCallback(async (movimentacao: MovimentacaoModel) => {

    try {

      const res = await postXMLSNGPCAnvisa(movimentacao.dataInicial, movimentacao.dataFinal, movimentacao.tipoOperacao);

      if (res.erro)
        throw res.erro;

      if (res.resultado?.data)
        showToast("info", res.resultado?.data)

      setExisteMovimentacao(false);
      setCompras([]);
      setDispensacoes([]);
      setTransferencias([]);
      setPerdas([]);
      setResetarFiltro(true);

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }, [postXMLSNGPCAnvisa, showToast]);

  function getNomeArquivo(tipoOperacao: ETipoOperacaoMovimentacao): string {

    var nomeArquivo = `SNGPC_` + moment().format('DDMMYYYYHHmmss')

    switch (tipoOperacao) {

      case ETipoOperacaoMovimentacao.Compra:
        return "Compra_" + nomeArquivo;

      case ETipoOperacaoMovimentacao.Dispensacao:
        return "Dispensação_" + nomeArquivo;

      case ETipoOperacaoMovimentacao.Perda:
        return "Perda_" + nomeArquivo;


      case ETipoOperacaoMovimentacao.Transferencia:
        return "Transferência_" + nomeArquivo;

      default:
        return "";

    }
  }

  const {
    control,
    formState
  } = useForm<MovimentacaoModel>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const tipoMovimentacaoSelecionada = () => {

    switch (formMovimentacao.tipoOperacao) {

      case ETipoOperacaoMovimentacao.Compra:

        const compraComponent =
          compras?.length > 0 ? (
            <MovimentacaoCompraLista
              listaCompra={compras}
            />
          ) : null;

        return compraComponent;

      case ETipoOperacaoMovimentacao.Dispensacao:

        const dispensacaoComponent =
          dispensacoes?.length > 0 ? (
            <MovimentacaoDispensacaoLista
              listaDispensacao={dispensacoes}
            />
          ) : null;

        return dispensacaoComponent;

      case ETipoOperacaoMovimentacao.Perda:

        const perdaComponent =
          perdas?.length > 0 ? (
            <MovimentacaoPerdaLista
              listaPerda={perdas}
            />
          ) : null;

        return perdaComponent;


      case ETipoOperacaoMovimentacao.Transferencia:

        const transferenciaComponent =
          transferencias?.length > 0 ? (
            <MovimentacaoTransferenciaLista
              listaTransferencia={transferencias}
            />
          ) : null;

        return transferenciaComponent;

      default:
        break;
    }
  }

  const buscarMovimentacoes = (movimentacaoFiltro: MovimentacaoModel) => {
    getMovimentacao(movimentacaoFiltro);
  }

  const isLoading = () =>
    carregandoLista || gerandoXML || enviandoXMLSNGPC

  const semRegistro = (
    <Typography variant="h3" className={styles.semRegistroStyle}>
      Nenhuma movimentação foi encontrada para a Operação e o Período informado.
    </Typography>
  )

  return (
    <>
      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      <Fade in mountOnEnter unmountOnExit >
        <div className={`flex-column w-100 h-100 ${styles.color}`}>
          <PageHeader
            showBotaoFiltrar
            showMenu
            titulo='Envio de Movimentação' />

          <div className={`flex-column h-75 w-100 ${styles.contentContainer}`}>

            <div className={`flex-column h-100 ${styles.listPadding}`}>

              <div className={`flex-column h-100 w-100 ${styles.listContainer}`}>

                <div className={`flex-column w-100 ${styles.containerButtonBuscar}`} >
                  <EnvioMovimentacaoListFiltro
                    control={control}
                    formState={formState}
                    buscarMovimentacoes={buscarMovimentacoes}
                    limparCardMovimentacoes={limparCardMovimentacoes}
                    validarConflitoPeriodo={res => setConflitoPeriodo(res)}
                    resetarFiltro={resetarFiltro}
                    setResetarFiltro={res => setResetarFiltro(res)}
                  />
                </div>

                {exibirSemRegistro && semRegistro}

                <div className={`flex-column w-100 overflow-auto`}>
                  {tipoMovimentacaoSelecionada()}
                </div>

              </div>
            </div >
          </div >

          {existeMovimentacao &&

            <div className={`flex-row h-10 w-100 ${styles.buttonContainer}`}>
              <div className={classNames(styles.buttonStyleXml, styles.whiteButton)}>
                <Button onClick={() => enviarMovimentacao()} variant="text" className="h-100 w-100">
                  <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                    Enviar Movimentação
                  </div>
                </Button>
              </div>
              <div className={classNames(styles.buttonStyleXml, styles.darkButton)}>
                <Button onClick={() => download()} variant="text" className="h-100 w-100">
                  <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                    Download XML
                  </div>
                </Button>
              </div>
            </div>

          }

          {openModalConflitoPeriodo && (
            <div className={styles.modal}>
              <DialogConflitoPeriodo
                fecharModal={() => { }}
                sim={() => {
                  enviarXMLSNGPCAnvisa(formMovimentacao);
                  setOpenModalConflitoPeriodo(false);
                }}
                nao={() => setOpenModalConflitoPeriodo(false)}
              />
            </div>
          )}
        </div >
      </Fade >

    </>
  );

};