import classNames from "classnames";
import { ButtonIcon } from "../../../../../../components/controles/buttons";
import { VoltarGrandeIcon } from "../../../../../../components/icons";

import { MenuPrincipalTopProps } from "./menu-principal-top-props";
import { useStyles } from "./menu-principal-top-styles";
import { useThemeQueries } from "../../../../../../theme";

export const MenuPrincipalTop = (
  props: MenuPrincipalTopProps
) => {
  const classes = useStyles();
  const { theme } = useThemeQueries()
  return (
    <>
      <div className={classes.drawerDefaultTopButtonsContainer}>
        <div className={classes.pinButton}>
          <ButtonIcon
            onClick={() => { props.pinClicked() }}
            icon={
              <VoltarGrandeIcon
                class={classNames(classes.pinButtonIcon, (props.modeloAtual === "Mini" ? classes.pinButtonMini : undefined))}
                tipo="GERAL"
                fill={theme.palette.text.secondary}
              />
            } />
        </div>
      </div>
    </>
  );
};
