import { useCallback, useEffect, useState } from 'react';
import { AppEventEnum } from '../../../../model/enums/enum-app-event';
import { useEventTools } from '../../../../services/app/use-cases/events/event-tools';
import { DialogInventarioMedicamentoOpcoesConteudo } from './dialog-inventario-medicamento-opcoes-conteudo';

class DialogInventarioMedicamentoOpcoesControleProps {
  constructor(
    public aberto: boolean = false,
    public navegar = () => { },
    public excluir = () => { },
  ) { }
}

export const DialogInventarioMedicamentoOpcoesControle = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogInventarioMedicamentoOpcoesControleProps>(
    new DialogInventarioMedicamentoOpcoesControleProps()
  );

  const modalAlterado = useCallback(({ aberto, navegar, excluir }: any) => {
    setModalState({ aberto, navegar, excluir });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.InventarioMedicamentoOpcoes, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.InventarioMedicamentoOpcoes, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogInventarioMedicamentoOpcoesConteudo
          aberto={modalState.aberto}
          navegar={modalState.navegar}
          excluir={modalState.excluir}
        />
      )}
    </>
  );
};