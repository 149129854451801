import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { ETypeDomain } from "../../../../config/types";
import { OperacaoMenuEnum } from "../../../../views/components/headers/header-page/header-page";
import { PerdaAvulsaCadastroModel } from "../../../../views/pages/private/perdas-avulsas/models/perda-avulsa-models";
import { OperacaoPerdaRequest, PerdaRequest } from "../../../../views/pages/private/perdas/models/perdas-models";
import { useApiBase } from "../../base/api-base";

export class OperacaoPendenteCadastroRequest {
  public constructor(
    public operacaoTipo: OperacaoMenuEnum,
    public dataHoraLancamento: Date,
    public perda: PerdaAvulsaCadastroModel
  ) { }
}

export function useGetPerdasPaginado() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getPerdasPaginado = useCallback(
    (pageIndex: number, pageSize: number, filter: string) => {
      return invocarApi({
        url: `Perda/perdas-paginado?page=${pageIndex}&pageSize=${pageSize}&filter=${filter}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getPerdasPaginado,
  };
}

export function useCadastrarPerda() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const cadastrarPerda = useCallback(
    (novaPerda: PerdaRequest) => {
      return invocarApi({
        url: `Perda`,
        method: "POST",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        data: novaPerda,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    cadastrarPerda,
  };
}

export function useEditarOperacaoPerda() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const editarOperacaoPerda = useCallback(
    (request: OperacaoPerdaRequest) => {
      return invocarApi({
        url: `OperacaoPendente`,
        method: "PUT",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(request),
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    editarOperacaoPerda,
  };
}

export const useGetPerdaById = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getPerdaById = useCallback(
    (perdaId: string, dataHoraLancamento: string) => {
      return invocarApi(
        {
          url: `Perda/${perdaId}/${dataHoraLancamento}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getPerdaById,
  };
}

export const useCadastrarOperacaoPendentePerda = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const cadastrarOperacaoPendentePerda = useCallback(
    (perda: PerdaAvulsaCadastroModel) => {
      var operacaoPendente = new OperacaoPendenteCadastroRequest(OperacaoMenuEnum.Perda, new Date, perda);
      return invocarApi({
        url: `OperacaoPendente`,
        method: "POST",
        data: operacaoPendente,
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    cadastrarOperacaoPendentePerda,
  };
}
