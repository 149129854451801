import { Divider, Grid } from '@material-ui/core';
import classNames from 'classnames';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { VariaveisAmbiente } from '../../../../../config';
import { useGetMenu } from '../../../../../data/app';
import { EnumMenu } from '../../../../../model';
import { MenuGroup } from '../../../../../model/app';
import { useGlobalStates } from '../../../../../services/app/providers/global-states-provider/global-states-provider';
import { useMenuPrincipal } from '../../../../../services/app/providers/menu-principal-provider';
import { useGlobalStateTools } from '../../../../../services/app/use-cases/global-states/global-state-tools';
import { useMenuStorage } from '../../../../../services/app/use-cases/menu-storage';
import { useThemeQueries } from '../../../../theme';
import { ListModuloFilho } from './components/list-modulo-filho/list-modulo-filho';
import { ListModuloPai } from './components/list-modulo-pai/list-modulo-pai';
import { MenuPrincipalPerfil } from './components/menu-principal-perfil/menu-principal-perfil';
import { MenuPrincipalTop } from './components/menu-principal-top/menu-principal-top';
import { useStyles } from './menu-principal-styles';

export const MenuPrincipal = () => {
  const { fecharMenu, abrirMenu, alterarModelo } = useMenuPrincipal();
  const { get } = useMenuStorage();
  const { isMobile } = useThemeQueries();
  const { menuGlobalState } = useGlobalStates();
  const { getValue } = useGlobalStateTools();
  const menuState = getValue(menuGlobalState!);
  const modeloAtual = menuState.modelo;
  const situacaoAtual = menuState.situacao;
  const CorrecaoModeloAtual = isMobile ? 'Completo' : modeloAtual;

  const classes = useStyles();
  const { getMenu } = useGetMenu();
  const [listaModulos, setListaModulos] = useState(null as ReactNode);
  const [modulosRetornados, setModulosRetornados] = useState({} as MenuGroup);

  const refDrawer = useRef<HTMLDivElement>(null);

  const pinClicked = useCallback(async () => {
    const { modelo } = get();

    if (modelo === 'Mini') {
      await alterarModelo("Completo");
      await abrirMenu();
      return;
    }

    await alterarModelo("Mini");
    await fecharMenu();
  }, [fecharMenu]);

  useEffect(() => {
    if (Object.values(modulosRetornados)) {
      setListaModulos(
        Object.keys(modulosRetornados).map(tituloPai => {
          return (
            <>
              <Divider classes={{ root: classes.divider }} />
              {
                (modeloAtual !== 'Mini' && tituloPai !== '') &&
                <span className={classes.titulo}>{tituloPai}</span>
              }
              {
                modulosRetornados[tituloPai].map((item) => {
                  return (
                    <ListModuloPai
                      key={item.codigo}
                      modeloAtual={CorrecaoModeloAtual}
                      codigoModulo={item.codigo}
                      nomeModulo={item.nome}
                      modulos={modulosRetornados[tituloPai]}
                      rota={item.rota}
                      isMobile={isMobile}
                    />
                  )
                })
              }
            </>
          )
        }));
    }
  }, [CorrecaoModeloAtual, modulosRetornados, modeloAtual, isMobile]);

  const leftAtual = situacaoAtual === 'Aberto'
    ? 0
    : modeloAtual === 'Completo'
      ? -350
      : -75;


  const itensMenu = React.useMemo(() => {
    return (
      <Grid className={classes.drawerOverflowMenu}>
        <Divider classes={{ root: classes.divider }} />
        {listaModulos}
        <Grid className={classes.drawerVersion}>
          <div className={classes.publicPageHeaderVersionControl}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end'
              }}
            >
              {menuState.modelo === 'Completo' && (
                <div>
                  <b>Ambiente: </b>
                  <i>{VariaveisAmbiente.environment}</i>
                </div>
              )}
              <div>
                <b>Versão: </b>
                <i>{VariaveisAmbiente.version}</i>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }, [classes.divider, classes.drawerOverflowMenu, classes.drawerVersion, classes.publicPageHeaderVersionControl, listaModulos, menuState.modelo]);

  useEffect(() => {
    const ret = getMenu();

    setModulosRetornados(ret);
  }, [getMenu]);

  const component = React.useMemo(() => {
    return (
      <>
        <div
          className={classNames(classes.drawerBackground)}
          onClick={fecharMenu}
        />
        <div
          className={classNames(
            classes.drawer,
            classes.drawerBox,
            modeloAtual === 'Mini' ? classes.drawerReduzido : undefined,
          )}
          style={{ left: leftAtual }}
        >
          <Grid ref={refDrawer} className={classes.drawerContainer}>
            <Grid className={classes.drawerTop}>
              <MenuPrincipalTop
                isMobile={isMobile}
                modeloAtual={CorrecaoModeloAtual}
                pinClicked={async () => await pinClicked()}
              />
              <MenuPrincipalPerfil
                isMobile={isMobile}
                modeloAtual={CorrecaoModeloAtual}
              />
            </Grid>
            {itensMenu}
            <Grid className={classes.drawerBottom}>
              <Divider classes={{ root: classes.divider }} />
              <ListModuloFilho
                key={EnumMenu.MASTER_SAIR}
                isFavorite={false}
                modeloAtual={CorrecaoModeloAtual}
                isMobile={isMobile}
                codigoModulo={EnumMenu.MASTER_SAIR}
                nomeModulo="Sair"
                rota="/logout"
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }, [CorrecaoModeloAtual, classes.divider, classes.drawer, classes.drawerBackground, classes.drawerBackgroundAberto, classes.drawerBottom, classes.drawerContainer, classes.drawerReduzido, classes.drawerTop, classes.drawerBox, fecharMenu, isMobile, itensMenu, leftAtual, modeloAtual, pinClicked, situacaoAtual]);

  return component;
};
