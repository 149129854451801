import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useToastSaurus } from '../../../../../../services/app';
import { ConfirmarIcon } from "../../../../../components/icons/confirmar-icon";
import { ComprasMedicamentoLote, ComprasMedicamentoLotesProps, NovoLote } from "../models/compras-cadastro.model";
import { ComprasMedicamentoLotesCard } from "./compras-medicamento-lotes-card";
import { useStyles } from "./compras-medicamento-lotes-style";
import { ComprasMedicamentoNovoLoteCard } from "./compras-medicamento-novo-lote-card";

export const ComprasMedicamentoLotes = (({ medicamentos, salvarLotesCompras, visualizacao }: ComprasMedicamentoLotesProps) => {
  const styles = useStyles();
  const { showToast } = useToastSaurus();

  const [lotes, setLotes] = useState<ComprasMedicamentoLote[]>([]);
  const [quantidadeRestante, SetQuantidadeRestante] = useState<number>(medicamentos.length);

  useEffect(() => {
    let medicamentosComLotes = medicamentos
      .filter(m => m.lote);

    if (!medicamentos.length || !medicamentosComLotes.length) return;

    let listaLotes: ComprasMedicamentoLote[] = [];
    let lotesRestantes = quantidadeRestante;

    let lotesUnicos: string[] = medicamentosComLotes
      .map(l => String(l.lote))
      .filter(selecionarUnicos);

    lotesUnicos.forEach((c) => {
      let medicamentosIguas = medicamentosComLotes.filter(m => m.lote === c);
      lotesRestantes -= medicamentosIguas.length;

      listaLotes.push({
        lote: medicamentosIguas[0].lote,
        quantidade: medicamentosIguas.length,
        quantidadeReservada: 0,
        dataValidade: medicamentosIguas[0].validade,
        dataFabricacao: medicamentosIguas[0].fabricacao,
        codigoAgregador: medicamentosIguas[0].codigoAgregador,
      } as ComprasMedicamentoLote);
    })

    setLotes(listaLotes);
    SetQuantidadeRestante(lotesRestantes);
  }, [medicamentos]);

  const selecionarUnicos = (value: string, index: number, array: string[]) =>
    array.indexOf(value) === index;

  const adicionarLote = (novoLote: NovoLote) => {
    let lotesAtuais = [...lotes];

    let loteDuplicado = lotesAtuais.filter(l => l.lote === novoLote.lote).length;
    if (loteDuplicado) return showToast('error', "Lote informado já existe");

    lotesAtuais.push({
      lote: novoLote.lote,
      quantidade: novoLote.quantidade,
      quantidadeReservada: 0,
      dataValidade: novoLote.dataValidade,
      dataFabricacao: novoLote.dataFabricacao,
      codigoAgregador: undefined
    } as ComprasMedicamentoLote)

    setLotes(lotesAtuais);
    SetQuantidadeRestante(quantidadeRestante - novoLote.quantidade);
  };

  const removerLote = (lote: string) => {
    if (!lote)
      return;

    let lotesAtuais = [...lotes];
    let lotesEditados = lotesAtuais.filter(l => l.lote !== lote);
    let novaQtdRestante = lotesEditados.map(l => l.quantidade)
      .reduce((accumulator, currentValue) =>
        accumulator + currentValue, 0);

    setLotes(lotesEditados);
    SetQuantidadeRestante(medicamentos.length - novaQtdRestante);
  };

  const quantidadeRestanteCor = () => !quantidadeRestante
    ? styles.quantidadeRestanteCompleto
    : styles.quantidadeRestantePendente;

  return (
    <>
      {medicamentos !== undefined &&
        <div className={`flex-row w-100 ${styles.medicamentoInfoContainer}`}>
          <div className={`flex-column ${styles.medicamentoInfoStyle}`}>
            <Typography className={styles.medicamentoCodigoStyle}>
              Cód: {medicamentos[0].codigoAnvisa}
            </Typography>
            <Typography className={styles.medicamentoNomeStyle}>
              {medicamentos[0].medicamentoNome}
            </Typography>
          </div>
          <div className={`flex-column ${styles.quantidadeTotalCores} ${styles.quantidadeContainer}`}>
            <Typography className={styles.quantidadeLabel}>
              Quantidade Total
            </Typography>
            <Typography className={styles.quantidadeValor}>
              {medicamentos.length}
            </Typography>
          </div>
          <div className={`flex-column ${styles.quantidadeContainer} ${quantidadeRestanteCor()}`}>
            <Typography className={styles.quantidadeLabel}>
              Quantidade Restante
            </Typography>
            <Typography className={styles.quantidadeValor}>
              {quantidadeRestante}
            </Typography>
          </div>
        </div>
      }

      <div className={`flex-column w-100 ${styles.formPadding}`}>

        <div className={`flex-column w-100 ${styles.titleContainer}`}>
          <Typography className={`w-100 ${styles.titleStyle}`}>
            Lote e Validade
          </Typography>
          <div className={`w-100 ${styles.breakLine}`} />
        </div>

        <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
          {
            lotes.map((lote, index) => (
              <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                <ComprasMedicamentoLotesCard lote={lote} removerLote={removerLote} visualizacao={visualizacao} />
              </div>
            ))
          }
          {(quantidadeRestante !== 0 && !visualizacao) &&
            <div className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
              <ComprasMedicamentoNovoLoteCard
                quantidadeRestante={quantidadeRestante}
                adicionarLote={adicionarLote} />
            </div>
          }
        </div>

        {
          !visualizacao &&
          <div className={`flex-row w-100 ${styles.buttonContainer}`}>
            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
              <Button variant="text" className="h-100 w-100" onClick={
                () => salvarLotesCompras(lotes, medicamentos[0].codigoAnvisa)
              }>
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  <ConfirmarIcon class={styles.iconSize} />
                  Adicionar
                </div>
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  );
});