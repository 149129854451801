import { useCallback } from 'react';
import { EnumMenu } from '../../../model';
import { OperacaoMenuEnum } from '../headers/header-page/header-page';
import {
  LogoutIcon,
  MonitoramentoIcon,
  RelatorioIcon,
  SemDinheiroIcon,
  TransferenciaBancariaIcon
} from '../icons';
import { ConfiguracaoIcon } from '../icons/configuracao-icon';
import { EnvioInventarioIcon } from '../icons/envio-inventario-icon';
import { EnvioMovimentacaoIcon } from '../icons/envio-movimentacao-icon';
import { InventarioIcon } from '../icons/inventario-icon';
import { TransferenciaIcon } from '../icons/transferencia-icon';
import { VendaIcon } from '../icons/venda-icon';
import { IconsHelperProps } from './icons-helper-props';

export const IconsHelper = (props: IconsHelperProps) => {
  const selectIcon = useCallback(() => {
    switch (props.codigoModulo) {
      case OperacaoMenuEnum.Autorizacao:
        return <MonitoramentoIcon {...props} />;
      case OperacaoMenuEnum.Venda:
        return <VendaIcon {...props} />;
      case OperacaoMenuEnum.Compra:
        return <TransferenciaBancariaIcon {...props} />;
      case OperacaoMenuEnum.Perda:
        return <SemDinheiroIcon {...props} />;
      case OperacaoMenuEnum.Transferencia:
        return <TransferenciaIcon {...props} />;
      case OperacaoMenuEnum.Inventario:
        return <InventarioIcon {...props} />;
      case OperacaoMenuEnum.Relatorio:
        return <RelatorioIcon {...props} />;
      case OperacaoMenuEnum.SNGPCEnvioMovimentacao:
        return <EnvioMovimentacaoIcon {...props} />;
      case OperacaoMenuEnum.SNGPCEnvioInventario:
        return <EnvioInventarioIcon {...props} />;
      case OperacaoMenuEnum.SNGPCInventarioEnviado:
          return <EnvioInventarioIcon {...props} />;
      case OperacaoMenuEnum.Configuracao:
        return <ConfiguracaoIcon {...props} />;
      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;
    }
  }, [props]);

  return <>{selectIcon()}</>;
};
