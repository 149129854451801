import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { AddIcon } from "../../../../../components/icons/add-icon";
import { ETipoOperacaoTransferencia, MedicamentoTransferencia, TransferenciaStepsProp } from "../../models/transferencias.model";
import { useStyles } from "../cadastro/transferencias-cadastro-styles";
import { TransferenciaMedicamentosCard } from "./transferencias-medicamentos-card";

export const TransferenciaMedicamentosForm = (({ visualizacao, transferencia, editarMedicamentoLotes }: TransferenciaStepsProp) => {
  const styles = useStyles();
  const [meds, setMeds] = useState<Array<MedicamentoTransferencia[]>>([]);

  useEffect(() => {
    let codigosDistintos = transferencia.medicamentos
      .map(m => m.codigoAnvisa)
      .filter(selecionarUnicos);
    let matrizMedicamentos: Array<MedicamentoTransferencia[]> = [];

    codigosDistintos.forEach((c) => {
      let medicamentosIguas = transferencia.medicamentos.filter(m => m.codigoAnvisa === c);
      matrizMedicamentos[codigosDistintos.indexOf(c)] = medicamentosIguas;
    })

    setMeds(matrizMedicamentos);
  }, [transferencia.medicamentos]);

  const selecionarUnicos = (value: string, index: number, array: string[]) =>
    array.indexOf(value) === index;

  const isEntrada = () =>
    transferencia.tipoOperacaoTransferencia === ETipoOperacaoTransferencia.entrada;

  return (
    <>
      {transferencia.medicamentos && editarMedicamentoLotes !== undefined &&
        <div className={`flex-column w-100 h-100`}>
          <div className={`flex-column ${styles.titleContainer}`}>
            <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
            <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
          </div>

          <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
            <div className={`flex-column w-100 ${styles.medicamentosCardsContainer}`}>
              {meds.map((medicamentos, index) => (
                <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                  <TransferenciaMedicamentosCard visualizacao={visualizacao} medicamentos={medicamentos} editar={editarMedicamentoLotes} />
                </div>
              ))}
              {!visualizacao && isEntrada() && (
                <div className={classNames(styles.addButtonStyle, styles.whiteButton)}>
                  <Button onClick={() => editarMedicamentoLotes([])} variant="text" className="h-100 w-100">
                    <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                      <AddIcon class={styles.iconSize} />
                      Adicionar Produto
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>

        </div>
      }
    </>
  );
});