import { Dispatch, SetStateAction } from "react";
import { DefaultModal } from "../../../../../components/default-modal";
import { DispensacaoAvulsaCadastroPage } from "../../dispensacao-avulsa-cadastro";
import { DispensacaoCadastroPage } from "../../../dispensacoes/dispensacao-cadastro";

interface DispensacaoModalProps {
    openned?: boolean;
    operacaoId?: string;
    dispensacaoId?: string;
    dataCompetencia?: Date;

    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const DispensacaoModal = (props: DispensacaoModalProps) => {

    const fecharModal = () => props.setModalOpen(false);

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned}
            variant={"temporary"}
            anchor="right"
        >
            {
                (props.operacaoId || props.dispensacaoId) ?
                    <DispensacaoCadastroPage
                        dispensacaoId={props.dispensacaoId}
                        operacaoId={props.operacaoId}
                        dataCompetencia={props.dataCompetencia}
                        fecharModal={() => fecharModal()}
                    /> : <DispensacaoAvulsaCadastroPage fecharModal={() => fecharModal()} />
            }
        </DefaultModal>
    );
};
