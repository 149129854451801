import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
        height: '100%'
    },
    containerMain: {
        maxWidth: '100%'
    }
}));
