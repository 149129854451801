import { Typography } from "@material-ui/core";
import { TransferenciaSaidaMedicamentoLoteProp } from "../../../models/transferencias.model";
import { TransferenciaMedicamentoLotesCard } from "../../cadastro/transferencias-medicamentos-lote/transferencias-medicamentos-lote-card";
import { useStyles } from "../../cadastro/transferencias-medicamentos-lote/transferencias-medicamentos-lote-styles";

export const TransferenciaAvulsaSaidaMedicamentoLotes = (({ lotes, medicamento, alterarLotes, visualizacao }: TransferenciaSaidaMedicamentoLoteProp) => {
  const styles = useStyles();

  return (
    <>
      <div className={`flex-column w-100 h-100`}>
        {!lotes.length &&
          <div className='flex-column w-100 h-100'>
            <Typography className={styles.noInfoStyle}>BUSQUE O PRODUTO TRANSFERIDO!</Typography>
          </div>
        }

        {medicamento?.medicamentoId?.length && lotes.length &&
          <div className={`flex-column w-100 ${styles.titleContainer}`}>
            <Typography className={`w-100 ${styles.titleStyle}`}>
              Lote e Validade
            </Typography>
            <div className={`w-100 ${styles.breakLine}`} />
          </div>
        }
        <div className={`flex-column ${styles.innerFormContainerMedicamento}`}>
          {
            lotes?.map((lote, index) => (
              <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                <TransferenciaMedicamentoLotesCard lote={lote}
                  alteraLoteQtd={alterarLotes}
                  isEntrada={false}
                  visualizacao={visualizacao}
                />
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
});