import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";
import { RequestAutorizacaoAprovacaoReprovacao } from "../../../../views/pages/private/autorizacao-page/model/autorizacao.model";

export const useReprovarAutorizacoes = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const reprovarAutorizacoes = useCallback(
    (listaAutorizacaoId: Array<string>) => {
      var listaRequest = new Array<RequestAutorizacaoAprovacaoReprovacao>();
      listaAutorizacaoId.forEach(autorizacaoId => {
        listaRequest.push(new RequestAutorizacaoAprovacaoReprovacao(
          autorizacaoId,
          false
        ))
      })
      return invocarApi({
        url: `OperacaoPendente/autorizar-reprovar-lista`,
        method: "PUT",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(listaRequest),
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    reprovarAutorizacoes,
  };
}

export const useReprovarAutorizacao = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const reprovarAutorizacao = useCallback(
    (operacaoPendenteId: string, autorizado: boolean = false) => {
      return invocarApi({
        url: `OperacaoPendente/autorizar-reprovar/${operacaoPendenteId}/${autorizado}`,
        method: "PUT",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    reprovarAutorizacao,
  };
}