import { FormState } from "react-hook-form";
import { EMotivoPerda } from "../../perdas/models/perdas-models";

export class PerdaAvulsaCadastroModel {
    constructor(
    ) {
        this.dataHoraLancamento = new Date();
        this.data = "";
        this.hora = "";
        this.motivoPerda = EMotivoPerda.FurtoRoubo;
        this.medicamentos = new Array<MedicamentoPerda>();
    }
    dataHoraLancamento: Date;
    data: string;
    hora: string;
    motivoPerda: EMotivoPerda;
    medicamentos: MedicamentoPerda[];
}

export class MedicamentoPerda {
    constructor(
    ) {
        this.medicamentoNome = "";
        this.medicamentoId = "";
        this.codigoAnvisa = "";
        this.validade = new Date
        this.fabricacao = new Date;
        this.lote = "";
        this.vendaControlada = 0;
        this.codigoAgregador = 0;
        this.motivoIsencao = "";
        this.vpmc = 0;
        this.quantidade = 0;
    }

    medicamentoNome: string;
    medicamentoId: string;
    codigoAnvisa: string;
    validade?: Date;
    fabricacao?: Date;
    lote?: string;
    vendaControlada: number;
    codigoAgregador?: number;
    motivoIsencao?: string;
    vpmc: 0;
    quantidade: number;
}

export interface PerdaAvulsaStepsProp {
    perda: PerdaAvulsaCadastroModel;
    formState: FormState<PerdaAvulsaCadastroModel>;
    setPerda: React.Dispatch<React.SetStateAction<PerdaAvulsaCadastroModel>>;
    register: any;
}

export interface PerdaAvulsaMedicamentoStepsProp {
    medicamento: MedicamentoPerda;
    excluirMedicamento: () => void;
    setEditando: (editando: boolean) => void;
}

export interface PerdaAvulsaStepsNotaFiscalProp {
    perda: PerdaAvulsaCadastroModel;
    formState: FormState<PerdaAvulsaCadastroModel>;
    setPerda: React.Dispatch<React.SetStateAction<PerdaAvulsaCadastroModel>>;
    register: any;
}

export interface PerdaAvulsaMedicamentoLotesProp {
    adicionarNovoMedicamento: (perdasMedicamentoAvulsa: MedicamentoPerda) => void;
} 

export enum EPerdaPassos {
    Movimentacao = 0,
    Medicamento = 1,
}