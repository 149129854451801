import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Fade } from '@material-ui/core';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { CircularLoading } from '../../../..';
import MedicamentoIcon from "../../../../../assets/img/icon-medicamento.svg";
import NotaFiscalIcon from "../../../../../assets/img/icon-nota-fiscal.svg";
import { useCadastrarOperacaoPendenteCompra } from '../../../../../data/api/gestao/operacao-pendente/cadastrar-operacao-pendente';
import { useToastSaurus } from '../../../../../services/app';
import { ButtonModalHeader } from '../../../../components/controles/buttons/button-modal-header';
import { AvancarIcon, SalvarIcon, VoltarIcon } from '../../../../components/icons';
import { ModalHeader } from '../../../../components/modal-header';
import { Wizard } from '../../../../components/wizard/wizard-component';
import { WizardStep } from '../../../../components/wizard/wizard-model';
import { CompraAvulsaMedicamentosForm } from './compra-avulsa-medicamentos-form';
import { CompraAvulsaNotaFiscalForm } from './compra-avulsa-nota-fiscal-form';
import { useStyles } from './compras-styles';
import {
    CompraMedicamentoItem,
    ComprasAvulsaCadastroModel,
    ECompraPassos
} from './models/compras-cadastro.model';

export const CompraCadastroAvulsaPage = (props: any) => {
    const styles = useStyles();
    const { showToastPersonalizado } = useToastSaurus();
    const [passoCompra, setPassoCompra] = useState(0);
    const [compra, setCompra] = useState(new ComprasAvulsaCadastroModel());
    const [carregando, setCarregando] = useState(false);
    const [errorValidationFornecedor, setErrorValidationFornecedor] = useState(false);

    const { cadastrarOperacaoPendenteCompra } = useCadastrarOperacaoPendenteCompra();

    const schema = yup
        .object()
        .shape({
            dataAtual: yup.string().required("Data é obrigatória!"),
            horaAtual: yup.string().required("Hora é obrigatória!"),
            dataNotaFiscal: yup.string().required("Data é obrigatória!"),
            numeroNotaFiscal: yup.string().required("Número da N.F é obrigatório!")
        });

    const {
        control,
        formState,
        register,
        setError
    } = useForm<ComprasAvulsaCadastroModel>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const steps: WizardStep[] = [
        {
            label: 'Dados da Nota',
            icon: <img src={NotaFiscalIcon} alt="" />
        },
        {
            label: 'Medicamentos',
            icon: <img src={MedicamentoIcon} alt="" />
        }
    ];

    const avancarStep = async (step: number) => {

        if (await validarNota()) {
            setPassoCompra(step);
            return;
        }

        return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
    }

    const validarNota = async () => {

        let formIsValid = true;

        try {

            await schema.validate({
                dataAtual: compra.dataAtual,
                horaAtual: compra.horaAtual,
                dataNotaFiscal: compra.dataNotaFiscal,
                numeroNotaFiscal: compra.numeroNotaFiscal
            }, { abortEarly: false });

            formIsValid = true;

        } catch (error) {

            const validationError = error as yup.ValidationError;

            validationError?.inner?.forEach(err => {
                const path = err.path?.toString();

                if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
                    setErrorField(path, err.message);
                }

            });

            formIsValid = false;
        }


        const resultErroValidationFornecedor = !compra.fornecedor.nome || compra.fornecedor.nome === undefined;
        setErrorValidationFornecedor(resultErroValidationFornecedor);
        return (!formIsValid || resultErroValidationFornecedor) ? false : true;

    }

    function setErrorField(path: string, errorMensage: string) {

        switch (path) {

            case 'dataAtual':
                setError('dataAtual', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'horaAtual':
                setError('horaAtual', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'dataNotaFiscal':
                setError('dataNotaFiscal', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'numeroNotaFiscal':
                setError('numeroNotaFiscal', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            default:
                break;
        }

    }

    const passoFormRender = () => {
        return (passoCompra === ECompraPassos.NotaFiscal && compra) ? (
            <CompraAvulsaNotaFiscalForm
                control={control}
                formState={formState}
                register={register}
                compra={compra}
                setCompra={setCompra}
                errorValidation={errorValidationFornecedor}
            />
        ) : (
            <div className={styles.compraAvulsaMedicamentosForm}>
                <CompraAvulsaMedicamentosForm
                    control={control}
                    formState={formState}
                    compra={compra}
                    setCompra={setCompra}
                />
            </div>
        )
    };

    const salvar = () => {
        if (!compra.medicamentos?.length)
            return showToastPersonalizado('error', 'Nenhum Medicamento foi informado!', 3000, 'top-center');

        var novaListaMedicamentos = Array<CompraMedicamentoItem>();
        compra.medicamentos.forEach(medicamento => {
            for (let index = 0; index < medicamento.quantidade; index++) {
                novaListaMedicamentos.push(medicamento);
            }
        })
        setCarregando(true);
        cadastrarOperacaoPendenteCompra(compra, novaListaMedicamentos)
            .then(() => {
                setCarregando(false);
                showToastPersonalizado('success', 'Compra cadastrada com sucesso, dado salvo na listagem de Operação Pendente', 3000, 'top-center');
                props.fecharModal();
            });
    }
    return (
        <>
            <Fade in mountOnEnter unmountOnExit>
                <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>

                    <ModalHeader
                        title={'Compras'}
                        leftArea={
                            <ButtonModalHeader
                                tooltip="Voltar"
                                icon={<VoltarIcon tipo="MODAL_HEADER" />}
                                onClick={() => { props.fecharModal() }}
                            />
                        }
                    />

                    {carregando ? <CircularLoading tipo="FULLSIZED" /> :
                        <>
                            <div className={`flex-column w-100 ${styles.formPadding, styles.formHeightCadastroAvulso}`}>
                                <div className={`${styles.wizardHeight}`}>
                                    <Wizard steps={steps} activeStep={passoCompra} setActiveStep={step => avancarStep(Number(step))} />
                                </div>

                                <div className={`flex-column ${styles.buttonCadastroAvulsoContainer, styles.medsHeight}`}>
                                    {passoFormRender()}
                                </div>

                                <div className={`flex-row w-100 ${styles.buttonCadastroAvulsoContainer}`}>
                                    {passoCompra === ECompraPassos.NotaFiscal ?
                                        <div className={styles.buttonCadastroAlign}>
                                            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                                <Button onClick={() => avancarStep(passoCompra + 1)} variant="text" className="h-100 w-100">
                                                    <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                                        <AvancarIcon class={styles.iconSize} />
                                                        Avançar
                                                    </div>
                                                </Button>
                                            </div>
                                        </div> :
                                        <div className={styles.buttonCadastroAlign}>
                                            <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                                                <Button onClick={() => salvar()} variant="text" className="h-100 w-100">
                                                    <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                                                        <SalvarIcon class={styles.iconSize} />
                                                        Salvar
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Fade>
        </>
    );
};
