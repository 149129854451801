import { Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback } from "react";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components";
import { useInventarioMedicamentoOpcoes } from "../../../../../components/dialog/dialog-inventario-medicamento-opcoes/use-dialog-inventario-medicamento-opcoes";
import { EditarIcon } from "../../../../../components/icons";
import { InventarioMedicamento } from "../../models/inventario.models";
import { useStyles } from "../inventario-listagem.styles";

interface InventarioMedicamentoListaProps {
  inventarioMedicamento: InventarioMedicamento;
  excluirRegistro: (medicamentoId?: string) => void;
  navegarLotes: (medicamento: InventarioMedicamento) => void;
}

export const InventarioMedicamentoCard = ({
  inventarioMedicamento,
  excluirRegistro,
  navegarLotes
}: InventarioMedicamentoListaProps) => {
  const styles = useStyles();
  const classesCard = useDefaultCardStyles();
  const { abrirInventarioMedicamentoOpcoes, fecharInventarioMedicamentoOpcoes } = useInventarioMedicamentoOpcoes();

  const excluir = useCallback(() => {
    excluirRegistro(inventarioMedicamento.medicamentoId)
    fecharInventarioMedicamentoOpcoes();
  }, [excluirRegistro, fecharInventarioMedicamentoOpcoes, inventarioMedicamento.medicamentoId])

  const navegar = useCallback(() => {
    navegarLotes(inventarioMedicamento)
    fecharInventarioMedicamentoOpcoes();
  }, [fecharInventarioMedicamentoOpcoes, inventarioMedicamento, navegarLotes]);

  const openModalMedicamentoOpcoes = useCallback(() => {
    abrirInventarioMedicamentoOpcoes(navegar, excluir);
  }, [abrirInventarioMedicamentoOpcoes, excluir, navegar]);

  return (
    <DefaultCard className='w-100'>
      <div className={`flex-row w-100`}>
        <div className={classNames(classesCard.tagStatus, styles.statusColor)} />

        <div className={`flex-row w-100 ${styles.infoContainer}`} onClick={navegar}>
          <div className={`${styles.medicamentoInfoContainer}`}>
            <Typography className={classesCard.labelFont}>
              Medicamento:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {inventarioMedicamento.medicamento ?? '--'}
            </Typography>
          </div>

          <div className={`${styles.codAnvisaInfoContainer}`}>
            <Typography className={classesCard.labelFont}>
              Código Anvisa:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {inventarioMedicamento.codigoAnvisa ?? 0}
            </Typography>
          </div>

          <div className={`${styles.quantidadeInfoContainer}`}>
            <Typography className={classesCard.labelFont}>
              Quantidade:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {inventarioMedicamento.quantidade ?? 0}
            </Typography>
          </div>
        </div>

        <Tooltip arrow title="Opções">
          <div className={classesCard.cardRightButton}>
            <EditarIcon tipo="BUTTON" onClick={openModalMedicamentoOpcoes} />
          </div>
        </Tooltip>
      </div>
    </DefaultCard>
  )
}