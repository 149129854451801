import { Button, Fade, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import BalancoMedicamentoIcon from "../../../../assets/img/icon-balanco-medicamento.svg";
import LivroRegistroEspecificoIcon from "../../../../assets/img/icon-livro-registro-especifico.svg";
import RelacaoMensalVendaIcon from "../../../../assets/img/icon-relacao-mensal-venda.svg";
import RelacaoReceitaAIcon from "../../../../assets/img/icon-relacao-receita-a.svg";
import RelacaoReceitaB2Icon from "../../../../assets/img/icon-relacao-receita-b2.svg";
import { DefaultCard } from "../../../components/cards/components";
import { PageHeader } from "../../../components/headers/header-page/header-page";
import { ETipoRelatorio } from "./models/relatorio-models";
import { useStyles } from "./relatorio-home.styles";


export const RelatorioHome = () => {
    const styles = useStyles();
    const history = useHistory();

    const abrirRelatorio = (tipoRelatorio: ETipoRelatorio) => {
        switch (tipoRelatorio) {
            case ETipoRelatorio.BalancoMedicamento:
                history.push({
                    pathname: '/relatorio-balanco-medicamento',
                })
                break;
            case ETipoRelatorio.RelacaoReceitaA:
                history.push({
                    pathname: '/relatorio-relacao-mensal-receita-a',
                })
                break;
            case ETipoRelatorio.RelacaoReceitaB2:
                history.push({
                    pathname: '/relatorio-relacao-mensal-receita-b2',
                })
                break;
            case ETipoRelatorio.RelacaoMensalVendas:
                history.push({
                    pathname: '/relatorio-mensal-venda',
                })
                break;
            case ETipoRelatorio.LivroRegistroEspecifico:
                history.push({
                    pathname: 'relatorio-livro-registro-especifico',
                })
                break;
            default: return;
        }
    }

    return (
        <>
            <Fade in mountOnEnter unmountOnExit>
                <div className={`flex-column w-100 h-100 ${styles.color}`}>
                    <PageHeader showMenu titulo='Relatórios SNGPC' />

                    <div className={`flex-row ${styles.cardContainer}`}>
                        <DefaultCard className={`flex-column ${styles.cardMedicamentoItem}`}>
                            <Button onClick={() => abrirRelatorio(ETipoRelatorio.BalancoMedicamento)}
                                className={styles.iconeContainer}>
                                <div className={styles.icone}>
                                    <img src={BalancoMedicamentoIcon} alt="" />
                                </div>

                                <Typography className={styles.tituloRelatorio}>
                                    BMPO - Balanço de Medicamento
                                </Typography>
                            </Button>
                        </DefaultCard>

                        <DefaultCard className={`flex-column ${styles.cardMedicamentoItem}`}>
                            <Button onClick={() => abrirRelatorio(ETipoRelatorio.RelacaoReceitaA)}
                                className={styles.iconeContainer}>
                                <div className={styles.icone}>
                                    <img src={RelacaoReceitaAIcon} alt="" />
                                </div>

                                <Typography className={styles.tituloRelatorio}>
                                    RMNA - Relação de Receitas A
                                </Typography>
                            </Button>
                        </DefaultCard>

                        <DefaultCard className={`flex-column ${styles.cardMedicamentoItem}`}>
                            <Button onClick={() => abrirRelatorio(ETipoRelatorio.RelacaoReceitaB2)}
                                className={styles.iconeContainer}>
                                <div className={styles.icone}>
                                    <img src={RelacaoReceitaB2Icon} alt="" />
                                </div>

                                <Typography className={styles.tituloRelatorio}>
                                    RMNRB2 - Relação de Receitas B2
                                </Typography>
                            </Button>
                        </DefaultCard>

                        <DefaultCard className={`flex-column ${styles.cardMedicamentoItem}`}>
                            <Button onClick={() => abrirRelatorio(ETipoRelatorio.RelacaoMensalVendas)}
                                className={styles.iconeContainer}>
                                <div className={styles.icone}>
                                    <img src={RelacaoMensalVendaIcon} alt="" />
                                </div>

                                <Typography className={styles.tituloRelatorio}>
                                    RMV - Relação Mensal de Vendas
                                </Typography>
                            </Button>
                        </DefaultCard>

                        <DefaultCard className={`flex-column ${styles.cardMedicamentoItem}`}>
                            <Button onClick={() => abrirRelatorio(ETipoRelatorio.LivroRegistroEspecifico)}
                                className={styles.iconeContainer}>
                                <div className={styles.icone}>
                                    <img src={LivroRegistroEspecificoIcon} alt="" />
                                </div>

                                <Typography className={styles.tituloRelatorio}>
                                    Livro de Registro Específico
                                </Typography>
                            </Button>
                        </DefaultCard>
                    </div>
                </div>
            </Fade>
        </>
    );
}