import { useCallback } from 'react';
import { useApiBase } from '../../base';
import { ETypeDomain } from '../../../../config/types';

export function usePostIdentidadeRefreshToken() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeRefreshToken = useCallback(
        (tokenAtual: string) =>
            invocarApi({
                url: `identidade/refresh-token`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: `"${tokenAtual}"`,
                enviarTokenUsuario: false,
                typeDomain: ETypeDomain.Pedidos,
                apiVersion: 'v1'
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeRefreshToken,
    };
}
