import { Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CircularLoading, useThemeQueries } from '../../..';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useFinalizacaoOperacaoOpcoes } from './use-dialog-finalizacao-operacao-opcoes';
import { useStyles } from './dialog-finalizacao-operacao-opcoes-style';

interface DialogFinalizacaoOperacaoOpcoesConteudoProps {
  carregando?: boolean;
  aberto: boolean;
  operacaoId: string;
  aprovar: (operacaoId: string) => any;
  reprovar: (operacaoId: string) => any;
  salvar: () => void;
}

export const DialogFinalizacaoOperacaoOpcoesConteudo = ({
  aberto,
  carregando,
  operacaoId,
  reprovar,
  aprovar,
  salvar,
}: DialogFinalizacaoOperacaoOpcoesConteudoProps) => {
  const { fecharFinalizacaoOperacaoOpcoes } = useFinalizacaoOperacaoOpcoes();
  const theme = useThemeQueries();
  const styles = useStyles();
  const [cardWidth, setCardWidth] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    setarTamanhoModal();
  }, [theme])

  const setarTamanhoModal = () => {
    if (theme.isMobile && theme.lg && !theme.xs) {
      setCardWidth(40);
      setCardHeight(35);
      return;
    };
    if (theme.isMobile && theme.xs) {
      setCardWidth(65);
      setCardHeight(35);
      return;
    };
    if (theme.isMobile && theme.lg) {
      setCardWidth(40);
      setCardHeight(28);
      return;
    };
    if (theme.md) {
      setCardWidth(35);
      setCardHeight(35);
      return;
    };
    if (theme.lg) {
      setCardWidth(20);
      setCardHeight(38);
      return;
    };
    if (theme.sm) {
      setCardWidth(20);
      setCardHeight(38);
      return;
    };
    if (theme.xl) {
      setCardWidth(15);
      setCardHeight(30);
      return;
    };
  }

  const salvarEdicao = () => {
    salvar();
    fecharFinalizacaoOperacaoOpcoes();
  }

  const aprovarOperacao = () => {
    aprovar(operacaoId);
    fecharFinalizacaoOperacaoOpcoes();
  }

  const reprovarOperacao = () => {
    reprovar(operacaoId);
    fecharFinalizacaoOperacaoOpcoes();
  }

  return (
    <DialogSaurus
      isPopUp
      tamanho="xs"
      titulo="Operações"
      cardHeight={cardHeight}
      cardWidth={cardWidth}
      isButtonTitleClose
      aberto={aberto || false}
      handleClickClose={fecharFinalizacaoOperacaoOpcoes}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <div className={`flex-column h-85 w-100 ${styles.dialogContainer}`}>
        <Button onClick={salvarEdicao} className={`w-100 ${styles.buttonContainer}`}>
          <Typography className={styles.buttonFont}>
            Salvar e Fechar
          </Typography>
        </Button>
        <Button onClick={aprovarOperacao} className={`w-100 ${styles.buttonContainer}`}>
          <Typography className={styles.buttonFont}>
            Autorizar
          </Typography>
        </Button>
        <Button onClick={reprovarOperacao} className={`w-100 ${styles.buttonContainer}`}>
          <Typography className={styles.buttonFont} color='error'>
            Reprovar
          </Typography>
        </Button>
      </div>
    </DialogSaurus>
  );
};