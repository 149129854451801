// Interface 

import { createContext, useCallback, useContext, useState } from "react"

interface NavegacaoContextValues {
    fecharMenu: () => void,
    abrirMenu: () => void
    aberto: boolean
}

const NavegacaoContext = createContext<NavegacaoContextValues>({
    fecharMenu: () => true,
    abrirMenu: () => true,
    aberto: false
})
export const useNavegacao = () => useContext(NavegacaoContext)

export const NavegacaoProvider = (props: { children: JSX.Element[] }) => {
    const [aberto, setAberto] = useState(false)
    console.log('render navegacao provider')

    const fecharMenu = useCallback(() => {
        setAberto(false);
    }, [setAberto])

    const abrirMenu = useCallback(() => {
        setAberto(true);
    }, [setAberto])

    return (
        <NavegacaoContext.Provider value={{
            aberto,
            fecharMenu,
            abrirMenu
        }}>
            <>
                {props.children}
            </>
        </NavegacaoContext.Provider>
    )

}


// Criar um Contexto

// Consumo para o contexto

// Provedor para prover o contexto 