import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";
import { VariaveisAmbiente } from "../../../../config";
import { Inventario } from "../../../../views/pages/private/inventarios/models/inventario.models";

export function useInventario() {

  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getOperacaoPendenteAprovacao = useCallback(() => {
    return invocarApi({
      url: `OperacaoPendente/existe-operacao-pendente-aprovacao`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const getInventarioAndamento = useCallback(() => {
    return invocarApi({
      url: `Inventario/inventario-andamento`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const getInventarioPendenteEnvioSNGPC = useCallback(() => {
    return invocarApi({
      url: `Inventario/inventario-pendente-envio-sngpc`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const getInventarioPaginado = useCallback(
    (pageIndex: number, pageSize: number) => {
      return invocarApi({
        url: `Inventario/inventario-paginado?page=${pageIndex}&pageSize=${pageSize}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );

  const getInventarioLotePaginado = useCallback(
    (pageIndex: number, pageSize: number) => {
      return invocarApi({
        url: `Inventario/inventario-lote-paginado?page=${pageIndex}&pageSize=${pageSize}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );

  const getInventario = useCallback(() => {
    return invocarApi({
      url: `Inventario/buscar-inventario`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const saveInventario = useCallback((inventario: Inventario) => {
    return invocarApi({
      url: `Inventario/salvar-inventario`,
      method: "POST",
      data: inventario,
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      headers: { "Content-Type": "application/json" },
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  ); 

  const authorizeInventario = useCallback((inventario: Inventario) => {
    return invocarApi({
      url: `Inventario/autorizar-inventario`,
      method: "POST",
      data: inventario,
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      headers: { "Content-Type": "application/json" },
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  ); 

  return {
    ...outrasPropriedades,
    carregando,
    getOperacaoPendenteAprovacao,
    getInventarioAndamento,
    getInventarioPendenteEnvioSNGPC,
    getInventarioPaginado,
    getInventarioLotePaginado,
    getInventario,
    saveInventario,
    authorizeInventario
  };
}