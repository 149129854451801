
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    color: {
        backgroundColor: theme.palette.grey[100],
    },
    root: {
        margin: 0,
        position: "relative",
        paddingTop: theme.spacing(3),
        background: theme.palette.common.white,
        height: '100%'
    },
    containerSave: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
    },
    containerSenha: {
        width: '100%',
        paddingBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    inputSenha: {
        height: '100%',
        fontSize: "1.5rem",
    },
    containerEmail: {
        width: '100%',
        paddingBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    inputEmail: {
        fontSize: "1.5rem",
    },
    containerEmailSenha: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "80%",
    },

    btnSalvarEditar: {
        backgroundColor: theme.palette.primary.main,
        fontSize: "1.5rem",
        padding: theme.spacing(5),
        width: "100%",
        borderRadius: "10px"
    },
    customHelperText: {
        fontSize: "0.9rem",
    },
    contentContainer: {
        flexGrow: 1,
        alignItems: "center"
    },
    listPadding: {
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            width: '60%'
        }
    },
    card: {
        height: '100%',
    },

    containerButtonSave: {
        width: "100%",
        padding: theme.spacing(5),
        height: '20%',
        display: 'flex',
        alignItems: 'center'
    }

}));

