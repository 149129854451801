import { Dispatch, SetStateAction } from "react";
import { DefaultModal } from "../../../../../components/default-modal";
import { InventarioMedicamento, MedicamentoInfo } from "../../models/inventario.models";
import { InventarioLotePage } from "../inventario-lote";

interface InventarioLoteModalProps {
    openned?: boolean;
    medicamentoInfo: MedicamentoInfo;
    inventarioMedicamentosAtual: InventarioMedicamento[];

    setModalOpen: Dispatch<SetStateAction<boolean>>;
    setInventarioAtual: Dispatch<SetStateAction<Array<InventarioMedicamento>>>;
    setMedicamentoInfo: Dispatch<SetStateAction<MedicamentoInfo>>;
}

export const InventarioLoteModal = (props: InventarioLoteModalProps) => {

    const fecharModal = (res: any) => {
        props.setInventarioAtual(res.state.inventarioMedicamentosAtual);
        props.setMedicamentoInfo(res.state.medicamentoInfo);
        props.setModalOpen(false);
    };

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned}
            variant={"temporary"}
            anchor="right"
        >
            <InventarioLotePage
                medicamentoInfo={props.medicamentoInfo}
                inventarioMedicamentosAtual={props.inventarioMedicamentosAtual}
                fecharModal={(res: any) => fecharModal(res)}
            />
        </DefaultModal>
    );
};
