import { Button, Fade, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { ReactNode, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetPerdasPaginado } from "../../../../../../data/api/gestao/perdas/perdas";
import { CircularLoading } from "../../../../../components";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components/default-card";
import { PageHeader } from "../../../../../components/headers/header-page/header-page";
import { ProcurarIcon } from "../../../../../components/icons";
import { NovoIcon } from "../../../../../components/icons/novo-icon";
import { Paginacao } from "../../../../../components/paginacao";
import { PerdaModal } from "../../../perdas-avulsas/modals/modal-perda/perda-modal";
import { EMotivoPerdaMap, PerdaResponse } from "../../models/perdas-models";
import { useStyles } from "./perdas-listagem-styles";

export const PerdaListPage = (() => {
	const classes = useStyles();
	const classesCard = useDefaultCardStyles();

	const pageItems = 10;
	const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<PerdaResponse>() });

	const { getPerdasPaginado, carregando } = useGetPerdasPaginado();

	const [modalOpen, setModalOpen] = useState(false);
	const [perdaModal, setPerdaModal] = useState(null as ReactNode);

	const [timer, setTimer] = useState({} as NodeJS.Timeout);

	const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<PerdaResponse>) => {
		setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
	}, []);

	const search = useCallback(
		async (newPage: number, filter: string = "") => {
			try {
				const res = await getPerdasPaginado(newPage, pageItems, filter);

				if (res.erro)
					throw res.erro;

				const list = res.resultado?.data.list;
				const pageIndex = res.resultado?.data.pageIndex;
				const totalPages = res.resultado?.data.totalPages;
				const totalResults = res.resultado?.data.totalResults;

				fillResult(
					pageIndex,
					totalPages,
					totalResults,
					list
				);

			} catch (e: any) {
				if (axios.isCancel(e))
					return;
				
				console.error("error", e.message);
				toast.error('O processo resultou em um erro interno da API');
			}
		},
		[fillResult]
	);

	const pageChanged = useCallback(
		(newPage: number) => {
			search(newPage);
		},
		[search]
	);

	useEffect(() => {
		setTimeout(() => {
			search(1)
		}, 100)
	}, []);

	const visualizarPerda = (perdaId: string, dataHoraLancamento: string) => {
		perdaRender(perdaId, dataHoraLancamento);
	}

	const perdaRender = (id?: string, data?: string) => {
		setModalOpen(true);

		return (
			setPerdaModal(
				<PerdaModal
					openned={true}
					setModalOpen={setModalOpen}
					perdaId={id}
					dataHoraLancamento={data}
				/>
			)
		);
	}

	const filtroListagem = (res: string) => {
		clearTimeout(timer!);
		const newTimer = setTimeout(() => {
			search(1, res);
		}, 500);
		setTimer(newTimer);
	}

	return (
		<>
			{carregando && <CircularLoading tipo="FULLSIZED" />}

			<Fade in timeout={900} mountOnEnter unmountOnExit>
				<div className={`flex-column w-100 h-100 ${classes.color}`}>
					<PageHeader
						showSearchableBar
						showBotaoFiltrar
						showMenu
						titulo='Perdas'
						filtroListagem={res => filtroListagem(res)} />

					<>
						<Paginacao
							pageChanged={pageChanged}
							totalPages={queryStatus.totalPages}
							totalRegisters={queryStatus.totalResults}
							currentPage={queryStatus.page}
						/>

						<div className={`flex-column h-100 mb-20 overflow-auto ${classes.listPadding}`}>

							{!queryStatus.list.length &&
								<Typography variant="h3" className={classes.semRegistroStyle}>
									Nenhuma perda foi encontrada.
								</Typography>
							}

							<div className={`flex-column h-100 w-100 ${classes.listContainer}`}>
								{queryStatus.list.length > 0 && queryStatus.list.map((item, index) => {
									return (
										<DefaultCard key={index} className={classes.cardContainer}
											onClick={() => visualizarPerda(item.id, item.dataHoraLancamento)}>
											<div className={`flex-row w-100 h-100`}>
												<div className={classNames(classesCard.tagStatus, classes.statusColor)} />

												<div className={classesCard.infoTagContainer}>
													<Typography className={classesCard.infoTagStyle}>
														P
													</Typography>
												</div>

												<div className={`flex-row w-100 ${classesCard.infoContainer}`}>
													<div className={`flex-column ${classesCard.inputMinWidth}`}>
														<Typography className={classesCard.labelFont}>
															Medicamento:
														</Typography>
														<Typography className={classesCard.contentFont}>
															{item.medicamentos[0]?.medicamentoNome}
														</Typography>
													</div>
													<div className={`flex-column ${classesCard.inputMinWidth}`}>
														<Typography className={classesCard.labelFont}>
															MS:
														</Typography>
														<Typography className={classesCard.contentFont}>
															{item.medicamentos[0]?.codigoAnvisa}
														</Typography>
													</div>
													<div className={`flex-column ${classesCard.inputMinWidth}`}>
														<Typography className={classesCard.labelFont}>
															Qtd:
														</Typography>
														<Typography className={classesCard.contentFont}>
															{item.medicamentos?.length}
														</Typography>
													</div>
													<div className={`flex-column ${classesCard.inputMinWidth}`}>
														<Typography className={classesCard.labelFont}>
															Data:
														</Typography>
														<Typography className={classesCard.contentFont}>
															{item.dataLancamento}
														</Typography>
													</div>
													<div className={`flex-column ${classesCard.inputMinWidth}`}>
														<Typography className={classesCard.labelFont}>
															Motivo:
														</Typography>
														<Typography className={classesCard.contentFont}>
															{EMotivoPerdaMap.get(item.motivoPerda)}
														</Typography>
													</div>
												</div>

												<Tooltip arrow title="Vizualizar Perda">
													<div className={classesCard.cardRightButton}>
														<ProcurarIcon tipo="BUTTON" onClick={() => visualizarPerda(item.id, item.dataHoraLancamento)} />
													</div>
												</Tooltip>
											</div>
										</DefaultCard>
									);
								})}
							</div>

							{modalOpen && (perdaModal)}

							<div className={`flex-row w-100 ${classNames(classes.buttonContainer, classes.buttonPosition)}`}>
								<div className={classNames(classes.buttonStyle, classes.darkButton, classes.buttonAlign)}>
									<Button onClick={() => perdaRender()} variant="text" className="h-100 w-100">
										<div className={`flex-row ${classes.buttonLabel} ${classes.whiteLabel}`}>
											<NovoIcon class={classes.iconSize} />
										</div>
									</Button>
								</div>
							</div>
						</div>

					</>
				</div>
			</Fade>
		</>
	);
});