import { FormState } from "react-hook-form";
import { EnumCadastroTipo, KeyValueModel } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { DefaultCard } from '../../../../components/cards/components';
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { RelatorioMensalReceitaAModel } from "../models/relatorio-models";
import { useStyles } from "./relatorio-relacao-mensal-receita-a-styles";

interface RMNRAFormProps {
  listaMeses: KeyValueModel[];
  formState: FormState<RelatorioMensalReceitaAModel>;
  register: any;
  formRMNRA: RelatorioMensalReceitaAModel;
  errorValidationfarmaceutico: boolean;
  setErrorValidationfarmaceutico: React.Dispatch<React.SetStateAction<boolean>>;
  setFormRMNRA: React.Dispatch<React.SetStateAction<RelatorioMensalReceitaAModel>>;
}

export const RelatorioRelacaoMensalReceitaAForm = ({ listaMeses, formState, register, formRMNRA, errorValidationfarmaceutico, setErrorValidationfarmaceutico, setFormRMNRA }: RMNRAFormProps) => {
  const styles = useStyles();
  const { errors } = formState;

  return (
    <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
      <DefaultCard className={styles.card}>
        <div className={`w-100 ${styles.inputContainer}`}>
          <AutocompletePessoas label="Farmacêutico"
            name="farmaceutico"
            size="medium"
            tipo={EnumCadastroTipo.TODOS}
            onChange={(e: any) => setFormRMNRA({ ...formRMNRA, farmaceutico: e?.value?.id })}
            error={Boolean(errorValidationfarmaceutico && !formRMNRA.farmaceutico)}
            helperText={
              errorValidationfarmaceutico && !formRMNRA.farmaceutico
                ? "O Farmacêutico é obrigatório!"
                : undefined
            }
            onBlur={() => {
              if (!formRMNRA.farmaceutico)
                setErrorValidationfarmaceutico(true);
            }}
          />
        </div>
        <div className={`flex-row w-100 ${styles.inputContainer}`}>
          <div className={styles.dataPeriodoContainer}>
            <div className="w-50">
              <SelectSaurus label="Mês"
                conteudo={listaMeses}
                className={`h-100 w-100 ${styles.inputStyle}`}
                variant="outlined"
                size="medium"
                allowSubmit
                required
                error={Boolean(errors.mes && !formRMNRA.mes)}
                helperText={
                  errors.mes && !formRMNRA.mes
                    ? errors.mes?.message
                    : undefined
                }
                {...register('mes', {
                  onChange: (e: any) => setFormRMNRA({ ...formRMNRA, mes: e.target.value })
                })}
              />
            </div>
            <div className="w-50">
              <TextFieldSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Ano"
                value={formRMNRA.ano ?? undefined}
                name="ano"
                required
                tipo="NUMERO"
                size="medium"
                variant="outlined"
                inputProps={{ maxLength: 4 }}
                showStartAdornment
                error={Boolean(errors.ano && !formRMNRA.ano)}
                helperText={
                  errors.ano && !formRMNRA.ano
                    ? errors.ano?.message
                    : undefined
                }
                {...register('ano', {
                  onChange: (e: any) => setFormRMNRA({ ...formRMNRA, ano: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 })
                })}
              />
            </div>
          </div>
        </div>
      </DefaultCard>

    </div>
  );
} 