import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmaIcon from "../../../../../assets/img/icon-confirma.svg";
import { useToastSaurus } from '../../../../../services/app';
import { AutocompleteMedicamento } from "../../../../components/autocompletes/autocomplete-medicamentos";
import { DefaultCard } from "../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { MedicamentoPerda, PerdaAvulsaMedicamentoLotesProp } from "../models/perda-avulsa-models";
import { useStyles } from "../perda-avulsa-cadastro-style";

export const NovaPerdaAvulsaMedicamentosCard = (({ adicionarNovoMedicamento }: PerdaAvulsaMedicamentoLotesProp) => {
    const styles = useStyles();
    const [novoMedicamento, setNovoMedicamento] = useState(new MedicamentoPerda());
    const { showToastPersonalizado } = useToastSaurus();

    const schema = yup
        .object()
        .shape({
            quantidade: yup.number()
                .required("Item é obrigatório!")
        });

    const {
        formState,
        register,
        clearErrors,
    } = useForm<MedicamentoPerda>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    const adicionar = async () => {
        var formIsValid = await schema.isValid(novoMedicamento);

        if (!formIsValid || !novoMedicamento.medicamentoNome)
            return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

        adicionarNovoMedicamento(novoMedicamento);
        clearErrors("quantidade");
        setNovoMedicamento(new MedicamentoPerda());
    }

    const handleMedicamentoAutocomplete = (medicamento: any) => {
        if (!medicamento.ms && medicamento)
            return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

        clearErrors("quantidade");

        setNovoMedicamento({
            medicamentoId: medicamento.id,
            medicamentoNome: medicamento.descricao,
            codigoAnvisa: medicamento.ms,
            vendaControlada: medicamento.vendaControlada
        } as MedicamentoPerda);
    };

    const handleMedicamentoLabel = (): string =>
        novoMedicamento.codigoAnvisa ? `${novoMedicamento.medicamentoNome} - ${novoMedicamento.codigoAnvisa}` : '';

    return (
        <>
            <DefaultCard>
                <div className={`flex-row h-100 w-100 ${styles.infoContainerPerdaAvulsa, styles.divCardMed}`}>

                    <div className={`flex-column w-100 ${styles.infoContainer}`}>
                        <div className="flex-column w-100">
                            <AutocompleteMedicamento
                                value={handleMedicamentoLabel()}
                                label="Buscar Medicamento"
                                className={`h-50 w-100 ${styles.inputStyle}`}
                                onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
                            />
                        </div>
                        <div className={`flex-row w-100 ${styles.itensDiv}`}>
                            <div className="flex-column w-50">
                                <Typography className={styles.labelFont}>
                                    Código
                                </Typography>
                                <Typography className={styles.contentFont}>
                                    {novoMedicamento.codigoAnvisa}
                                </Typography>
                            </div>

                            <div className="flex-column w-50">
                                <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                                    value={novoMedicamento.quantidade}
                                    labelCustom="Itens"
                                    variant="outlined"
                                    size="small"
                                    tipo='NUMERO'
                                    error={Boolean(errors.quantidade && !novoMedicamento.quantidade)}
                                    helperText={
                                        errors.quantidade && !novoMedicamento.quantidade
                                            ? errors.quantidade?.message
                                            : undefined
                                    }
                                    {...register('quantidade', {
                                        onChange: (e: any) => setNovoMedicamento({ ...novoMedicamento, quantidade: e.target.value })
                                    })}
                                    min={undefined}
                                    max={undefined}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
                        <Button onClick={adicionar} variant="text" className="h-100 w-100">
                            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
                            </div>
                        </Button>
                    </div>
                </div>
            </DefaultCard>
        </>
    );
});