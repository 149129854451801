export enum ETipoReceituario {
  ReceitaControleEspecial2Vias = 1,
  NotificacaoReceitaB = 2,
  NotificacaoReceitaEspecial = 3,
  NotificacaoReceitaA = 4,
  ReceitaAntimicrobiano2Vias = 5
}

export const ETipoReceituarioMap = new Map<number, string>([
  [ETipoReceituario.ReceitaControleEspecial2Vias, 'Receita de Controle Especial - 2 Vias'],
  [ETipoReceituario.NotificacaoReceitaB, 'Notificação de Receita B'],
  [ETipoReceituario.NotificacaoReceitaEspecial, 'Notificação de Receita Especial'],
  [ETipoReceituario.NotificacaoReceitaA, 'Notificação de Receita A'],
  [ETipoReceituario.ReceitaAntimicrobiano2Vias, 'Receita de Anti-microbiano - 2 Vias']
]);