import { useCallback, FocusEvent, useState, useRef, useEffect } from "react";

import { useStyles } from "./autocomplete-saurus-styles";
import React from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField, useTheme } from "@material-ui/core";
import { isArray, isEmpty, isNaN } from "lodash";
import classNames from "classnames";
import { DefaultIconProps } from "../icons/default-icon/default-icon-props";
import { ProcurarIcon } from "../icons";
import { CircularLoading } from "../utils/circular-loading/circular-loading";
import { mask } from "../controles/inputs/text-field-saurus-masks";
import { TipoField } from "../controles/inputs/text-field-saurus-types";
import { KeyValueModel } from "../../../model";

export interface retornoAutoComplete<T = any> {
  value: T;
  isString: boolean;
  isNewVal: boolean | {
    isNew: boolean,
    identificador: any
  };
}

export interface NovoItemModel {
  texto: string;
  identificador: any;
}
export interface AutocompleteSaurusProps {
  name?: string;
  value?: any;
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string | undefined;
  textoNovoItem?: string | NovoItemModel[];
  opcoes?: Array<any>;
  optionLabelKey?: string;
  optionValueKey?: string;
  optionCategoryKey?: string;
  categoryMock?: KeyValueModel[];
  disabledValues?: Array<any>;
  disabledValueKey?: string;
  tipoTextField?: TipoField;
  loading?: boolean;
  permiteNovo?: boolean;
  noOptionsText?: string;
  inputRef?: React.Ref<any>;
  allowSubmit?: boolean;
  disabled?: boolean;
  numerico?: boolean;
  externalInput?: boolean;
  readOnly?: boolean;
  CustomIcon?: (props: DefaultIconProps) => JSX.Element;
  onPesquisa?: (termo: string) => any;
  onChange?: (value: retornoAutoComplete) => any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onClickIcon?: () => any;
  fullWidth?: boolean;
  size?: "medium" | "small";
}

const filter = createFilterOptions<{
  nomeDisplay: string;
  inputValue: string | undefined;
  aditionalValue?: any;
}>();

export const AutocompleteSaurus = ({
  label,
  placeholder,
  error,
  helperText,
  onPesquisa,
  onChange,
  name,
  value,
  onBlur,
  opcoes,
  loading,
  optionLabelKey,
  optionValueKey,
  disabledValues,
  disabledValueKey,
  textoNovoItem,
  permiteNovo,
  tipoTextField,
  noOptionsText,
  inputRef,
  allowSubmit,
  disabled,
  numerico,
  CustomIcon,
  onClickIcon,
  externalInput,
  optionCategoryKey,
  categoryMock,
  readOnly,
  fullWidth,
  size
}: AutocompleteSaurusProps) => {
  const [inputValue, setInputValue] = useState("");
  const timer = useRef({} as NodeJS.Timeout);
  const [carregando, setCarregando] = useState(false);
  const [open, setOpen] = useState(false);
  const ultimoTermoPesquisado = useRef("");
  const classes = useStyles();
  const theme = useTheme();

  const showloading = carregando || loading;

  useEffect(() => {
    setCarregando(loading || false);
  }, [loading]);

  useEffect(() => {
    if (onPesquisa) {
      clearTimeout(timer.current);
      if (open && (ultimoTermoPesquisado.current !== value || inputValue.length === 0)) {
        setCarregando(true);
        timer.current = setTimeout(() => {
          setCarregando(false)
          onPesquisa(inputValue);
        }, 1000);
      } else {
        setCarregando(false)
      }
      return () => {
        clearTimeout(timer.current);
      };
    }
  }, [inputValue, onPesquisa, open, value]);

  const onChangeWrap = useCallback(
    (event: any, value: any) => {
      if (!onChange) {
        return;
      }
      let valor = value != null ? (value.inputValue ? value.inputValue : value) : "";
      let isString = typeof valor === "string";
      let isNewVal: boolean | {
        isNew: boolean,
        identificador: any
      } = false;

      if (value?.aditionalValue) {
        isNewVal = {
          isNew: true,
          identificador: value.aditionalValue
        }
      } else if (value?.inputValue) {
        isNewVal = true;
      }

      onChange({ value: valor, isString: isString, isNewVal: isNewVal });
    },
    [onChange]
  );

  const onInputChange = useCallback(
    (event, value) => {
      if (numerico && isNaN(Number(value))) {
        return
      }
      let valor = mask(value as string, tipoTextField);
      ultimoTermoPesquisado.current = valor;
      setInputValue(valor);

      try {
        const ret = opcoes?.find((x) => x[optionValueKey || ""].toString() === valor);
        if (!isEmpty(ret)) {
          if (onChange) {
            onChange({ value: ret, isString: false, isNewVal: false });
            setOpen(false);
          }
        }
      } catch { }
    },
    [numerico, tipoTextField, opcoes, optionValueKey, onChange]
  );

  //ISSO AQUI É PRA CASO EXISTA UM INPUT EXTERNO (EXEMPLO SCANNER DE BARCODE) E PRECISE ATUALIZAR O VALOR DO INPUT DO AUTOCOMPLETE
  useEffect(() => {
    if (externalInput && !isEmpty(opcoes) && !isEmpty(optionValueKey) && !isEmpty(value) && !isEmpty(optionLabelKey)) {
      const opcao = opcoes?.find((item) => item[optionValueKey!] === value)
      setInputValue(opcao[optionLabelKey!])
    }
  }, [externalInput, onInputChange, opcoes, optionLabelKey, optionValueKey, tipoTextField, value])

  const getOptionLbl = useCallback(
    (valor) => {
      if (typeof valor === "string") {
        return valor;
      }

      if (valor.nomeDisplay) {
        return valor.nomeDisplay;
      }
      if (valor.inputValue) {
        return valor.inputValue;
      }
      if (valor[optionLabelKey || ""]) {
        if (optionCategoryKey && categoryMock) {
          return `${valor[optionLabelKey || ''] === 'Sem Produto' ? ''
            : valor[optionLabelKey || ''] === 'Sem Insumo' ? ''
              : valor[optionLabelKey || ''] === 'Sem Combo' ? ''
                : `${categoryMock.find(x => x.Key === valor[optionCategoryKey])?.Value}:`} ${valor[optionLabelKey || '']}`
        }
        return valor[optionLabelKey || ""];
      }
      return "";
    },
    [categoryMock, optionCategoryKey, optionLabelKey]
  );

  const onKeyDownWrapper = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        if (!allowSubmit) {
          const form = event.target.form;
          if (form) {
            const focusableElements =
              'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
            const index = Array.prototype.indexOf.call(
              form?.querySelectorAll(focusableElements),
              event.target
            );
            if (index === form?.querySelectorAll(focusableElements)) {
              form.querySelectorAll(focusableElements)[0].focus();
            } else {
              form.querySelectorAll(focusableElements)[index + 1].focus();
            }
          }
          if (!allowSubmit) {
            event.preventDefault();
          }
        }
      }
    },
    [allowSubmit]
  );

  return (
    <Autocomplete
      disabled={disabled || readOnly}
      classes={{
        noOptions: noOptionsText && !showloading ? classes.display : classes.displayNone,
        loading: classes.displayNone,
        endAdornment: classes.displayNone,
        listbox: classes.list,
        inputRoot: classNames(classes.input, permiteNovo ? classes.inputComNovo : undefined, disabled ? classes.disabled : undefined),
        paper: classes.paper,
      }}
      autoComplete={false}
      options={opcoes || []}
      getOptionSelected={(option: any, value: any) => {
        return option[optionValueKey || ""] === value;
      }}
      loading={showloading}
      loadingText=""
      filterOptions={(options) => {
        try {
          const valor = inputValue || "";
          const filtered = filter(options, { inputValue: valor, getOptionLabel: getOptionLbl });

          if (valor !== "" && permiteNovo && !carregando) {
            if (typeof textoNovoItem === 'string') {
              filtered.push({
                nomeDisplay: `${textoNovoItem} "${valor}"`,
                inputValue: valor,
              });
            } else if (textoNovoItem && isArray(textoNovoItem)) {
              textoNovoItem.forEach(item => {
                filtered.push({
                  nomeDisplay: `${item.texto} "${valor}"`,
                  inputValue: valor,
                  aditionalValue: item.identificador
                });
              })
            }
          }

          return filtered;
        } catch {
          return options;
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChangeWrap}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLbl}
      inputValue={inputValue}
      value={value}
      noOptionsText={noOptionsText ? noOptionsText : ""}
      clearOnBlur

      selectOnFocus
      freeSolo={permiteNovo}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          name={name}
          size={size}
          disabled={disabled}
          variant="outlined"
          onBlur={onBlur}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          onKeyDown={onKeyDownWrapper}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (!readOnly &&
              <>
                {showloading ? <CircularLoading tipo="ONAUTOCOMPLETE" /> : null}
                {CustomIcon ?
                  <CustomIcon
                    tipo='INPUT'
                    style={{
                      color: theme.palette.text.primary,
                      cursor: "pointer",
                    }}
                    onClick={onClickIcon ? onClickIcon : undefined}
                  /> : !disabled ? <ProcurarIcon
                    tipo="INPUT"
                    style={{
                      color: theme.palette.text.primary,
                      cursor: "pointer",
                    }}
                  /> : null}
              </>
            ),
          }}
        />
      )}
    />
  );
};
