import { Button, Tooltip, Typography } from "@material-ui/core";
import Edit from '../../../../../assets/img/edit.svg';
import { DefaultCard } from "../../../../components/cards/components";
import { useStyles } from "./compras-styles";
import { CompraMedicamentoLotesProp, EnumUnidadeComercial } from "./models/compras-cadastro.model";
import { ProcurarIcon } from "../../../../components/icons";


export const CompraMedicamentosCard = (({ visualizacao, medicamentos, editar }: CompraMedicamentoLotesProp) => {
  const styles = useStyles();

  const getDescricaoUnidadeComercial = (valor?: EnumUnidadeComercial) => {
    switch (valor) {
      case EnumUnidadeComercial.Caixas:
        return 'Caixas';
      case EnumUnidadeComercial.Frascos:
        return 'Frascos';
      default:
        return '';
    }
  };

  return (
    <>
      <DefaultCard>
        <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}
          onClick={() => editar(medicamentos)}>

          <div className={`flex-column w-100 ${styles.infoContainer}`}>
            <div className="flex-column w-100">
              <Typography className={styles.labelFont}>
                Medicamento
              </Typography>
              <Typography className={styles.contentFont}>
                {medicamentos[0]?.medicamentoNome}
              </Typography>
            </div>
            <div className="flex-row w-100">
              <div className="flex-column w-50">
                <Typography className={styles.labelFont}>
                  Código
                </Typography>
                <Typography className={styles.contentFont}>
                  {medicamentos[0]?.codigoAnvisa}
                </Typography>
              </div>
              <div className="flex-column w-50">
                <Typography className={styles.labelFont}>
                  Unidade
                </Typography>
                <Typography className={styles.contentFont}>
                  {getDescricaoUnidadeComercial(medicamentos[0]?.unidadeComercialId)}
                </Typography>
              </div>
              <div className="flex-column w-50">
                <Typography className={styles.labelFont}>
                  Itens
                </Typography>
                <Typography className={styles.contentFont}>
                  {medicamentos?.length}
                </Typography>
              </div>
            </div>
          </div>

          <div className={`h-100 ${styles.optionsButtonContainer}`}>
            {
              !visualizacao ?
                <Button disabled={visualizacao} className={styles.optionsButtonStyle} onClick={() => editar(medicamentos)}>
                  <img src={Edit} alt="" />
                </Button>
                :
                <Tooltip arrow title="Vizualizar Medicamento">
                  <div className={styles.cardRightButton} onClick={() => editar(medicamentos)}>
                    <ProcurarIcon tipo="BUTTON" />
                  </div>
                </Tooltip>
            }
          </div>
        </div>
      </DefaultCard>
    </>
  );
});