import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Fade } from '@material-ui/core';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { CircularLoading } from '../../../../..';
import MedicamentoIcon from "../../../../../../assets/img/icon-medicamento.svg";
import MovimentacaoIcon from "../../../../../../assets/img/icon-movimentacao.svg";
import { useCadastrarTransferencia } from '../../../../../../data/api/gestao/transferencias/transferencias';
import { TokenSngpcEmpresaModel } from '../../../../../../model/api/gestao/master/token-sngpc-empresa';
import { useSessaoAtual, useToastSaurus } from '../../../../../../services/app';
import { ButtonModalHeader } from '../../../../../components/controles/buttons/button-modal-header';
import { AvancarIcon, SalvarIcon, VoltarIcon } from '../../../../../components/icons';
import { ModalHeader } from '../../../../../components/modal-header';
import { Wizard } from "../../../../../components/wizard/wizard-component";
import { WizardStep } from '../../../../../components/wizard/wizard-model';
import { ETipoOperacaoMovimentacao } from '../../../envio-movimentacao/models/envio-movimentacao.model';
import {
  ControleMedicamentoLotes,
  ETipoOperacaoTransferencia,
  ETransferenciaPassos,
  MedicamentoTransferencia,
  OperacaoTransferenciaRequest,
  TransferenciaFilial,
  TransferenciaMedicamentoLote,
  TransferenciaModel
} from '../../models/transferencias.model';
import { TransferenciaAvulsaLancamentoForm } from './transferencia-avulsa-lancamento-form';
import { TransferenciaAvulsaMedicamentosForm } from './transferencia-avulsa-medicamentos-form';
import { TransferenciaAvulsaMedicamentoLotes } from './transferencia-avulsa-medicamentos-lote/transferencia-avulsa-medicamentos-lote';
import { useStyles } from './transferencia-avulsa-styles';

export const TransferenciaAvulsaCadastroPage = (props: any) => {
  const [passoTransferencia, setPassoTransferencia] = useState(0);
  const [formTransferencia, setFormTransferencia] = useState({} as TransferenciaModel);
  const [controleMedicamentos, setControleMedicamentos] = useState<ControleMedicamentoLotes>(new ControleMedicamentoLotes());
  const [empresasFiliais, setEmpresasFiliais] = useState<TokenSngpcEmpresaModel[]>([]);
  const { getEmpresaSelecionada, getEmpresasVinculadas } = useSessaoAtual();
  const [isLoading, setIsLoading] = useState(true);

  const styles = useStyles();

  const { cadastrarTransferencia } = useCadastrarTransferencia();
  const { showToast, showToastPersonalizado } = useToastSaurus();

  const schema = yup
    .object()
    .shape({
      numeroNotaFiscal: yup.string().required("Nº da Nota Físcal é obrigatória!"),
      tipoOperacaoTransferencia: yup.string().required("Tipo da Operação é obrigatória!"),
      origemCNPJ: yup.string().when("tipoOperacaoTransferencia", {
        is: ETipoOperacaoTransferencia.entrada,
        then: yup.string().required("Origem é obrigatória!")
      }),
      destinoCNPJ: yup.string().when("tipoOperacaoTransferencia", {
        is: ETipoOperacaoTransferencia.saida,
        then: yup.string().required("Destino é obrigatório!")
      }),
      data: yup.string().required("Data é obrigatória!"),
      hora: yup.string().required("Hora é obrigatória!"),
    });

  const schemaSaida = yup
    .object()
    .shape({
      numeroNotaFiscal: yup.string().required("Nº da Nota Físcal é obrigatória!"),
      destinoCNPJ: yup.string().when("tipoOperacaoTransferencia", {
        is: ETipoOperacaoTransferencia.entrada,
        then: yup.string().notRequired(),
        otherwise: yup.string().required("Destino é obrigatório!")
      }),
      data: yup.string().required("Data é obrigatória!"),
      hora: yup.string().required("Hora é obrigatória!"),
    });

  const schemaEntrada = yup
    .object()
    .shape({
      numeroNotaFiscal: yup.string().required("Nº da Nota Físcal é obrigatória!"),
      origemCNPJ: yup.string().when("tipoOperacaoTransferencia", {
        is: ETipoOperacaoTransferencia.saida,
        then: yup.string().notRequired(),
        otherwise: yup.string().required("Origem é obrigatória!")
      }),
      data: yup.string().required("Data é obrigatória!"),
      hora: yup.string().required("Hora é obrigatória!"),
    });

  const {
    register,
    formState,
    setError
  } = useForm<TransferenciaModel>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (
      getEmpresaSelecionada === undefined ||
      getEmpresasVinculadas === undefined ||
      empresasFiliais.length > 0
    ) return;

    let empresas = getEmpresasVinculadas();

    if (empresas !== undefined && empresas?.length > 0) {
      setEmpresasFiliais(empresas.filter(e =>
        e.Descricao !== getEmpresaSelecionada()?.Descricao
      ));
      setIsLoading(false);
    }
  }, [getEmpresaSelecionada, getEmpresasVinculadas])

  const editarMedicamentos = useCallback((medicamentos: MedicamentoTransferencia[]) => {
    setControleMedicamentos({
      exibir: true,
      medicamentoLotes: medicamentos,
    });
  }, []);

  const salvarLotes = (lotesSelecionados: TransferenciaMedicamentoLote[], medicamento: MedicamentoTransferencia) => {
    if (!medicamento.codigoAnvisa.length)
      return setControleMedicamentos(new ControleMedicamentoLotes());

    let medicamentosSalvos = formTransferencia.medicamentos?.filter(m => m.codigoAnvisa !== medicamento.codigoAnvisa);

    if (!medicamentosSalvos) {
      medicamentosSalvos = [];
    }

    while (lotesSelecionados?.length) {
      const loteAtual = lotesSelecionados[0];

      medicamentosSalvos.push({
        medicamentoId: medicamento.medicamentoId,
        medicamentoNome: medicamento.medicamentoNome,
        medicamentoDescritivo: medicamento.medicamentoDescritivo,
        medicamentoComposicao: medicamento.medicamentoComposicao,
        codigoAnvisa: medicamento.codigoAnvisa,
        vendaControlada: medicamento.vendaControlada,
        vpmc: medicamento.vpmc,
        motivoIsencao: medicamento.motivoIsencao,
        codigoAgregador: loteAtual.codigoAgregador,
        lote: loteAtual.lote,
        validade: loteAtual.dataValidade,
        fabricacao: loteAtual.dataFabricacao
          ? new Date(loteAtual.dataFabricacao)
          : undefined
      } as MedicamentoTransferencia);

      lotesSelecionados[0].quantidadeTransferida -= 1;
      if (loteAtual.quantidadeTransferida === 0)
        lotesSelecionados = lotesSelecionados.slice(1);
    }

    setFormTransferencia({ ...formTransferencia, medicamentos: medicamentosSalvos });
    setControleMedicamentos(new ControleMedicamentoLotes());
  }

  const steps: WizardStep[] = [
    {
      label: 'Dados da Transferência',
      icon: <img src={MovimentacaoIcon} alt="" />
    },
    {
      label: 'Medicamentos',
      icon: <img src={MedicamentoIcon} alt="" />
    }
  ];

  const salvarTransferencia = async (deveAutorizar: boolean) => {
    var formIsValid = await schema.isValid(formTransferencia);

    if (!formIsValid)
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

    if (!formTransferencia?.medicamentos?.length)
      return showToast("error", "Nenhum medicamento adicionado!", 5000, 'top-center');

    try {

      const empresaSelecionada = getEmpresaSelecionada();
      const empresas = getEmpresasVinculadas();

      setIsLoading(true);

      let origemSngpc = isEntrada()
        ? empresas?.find(x => x.Documento === formTransferencia.origemCNPJ)
        : empresaSelecionada;

      let destinoSngpc = !isEntrada()
        ? empresas?.find(x => x.Documento === formTransferencia.destinoCNPJ)
        : empresaSelecionada;

      let transferenciaRequest = {
        ...formTransferencia,
        dataHoraLancamento: new Date(`${formTransferencia.data}T${formTransferencia.hora}:00.000Z`),
        origem: {
          nome: origemSngpc?.Descricao ?? "",
          cnpj: origemSngpc?.Documento ?? ""
        } as TransferenciaFilial,
        destino: {
          nome: destinoSngpc?.Descricao ?? "",
          cnpj: destinoSngpc?.Documento ?? ""
        } as TransferenciaFilial,
      }

      const transferencia = new OperacaoTransferenciaRequest(
        transferenciaRequest.id,
        transferenciaRequest.operacaoAutorizacaoId,
        ETipoOperacaoMovimentacao.Transferencia,
        transferenciaRequest.dataHoraLancamento,
        deveAutorizar,
        transferenciaRequest
      );

      const operacaoSalva = await cadastrarTransferencia(transferencia);

      if (operacaoSalva.erro) {
        setIsLoading(false);
        return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
      }

      showToastPersonalizado('success', 'Transferência cadastrada com sucesso, dado salvo na listagem de Operação Pendente', 5000, 'top-center');

      setIsLoading(false);
      props.fecharModal();

    } catch (e: any) {

      showToast("error", e.message, 500, 'top-center');
      setIsLoading(false);

    }

  };

  const isEntrada = () =>
    formTransferencia.tipoOperacaoTransferencia === ETipoOperacaoTransferencia.entrada;

  const goBack = () =>
    controleMedicamentos.exibir
      ? setControleMedicamentos(new ControleMedicamentoLotes())
      : props.fecharModal();

  const avancarStep = async (passo: number) => {
    if (passoTransferencia === passo) return;

    return await validarTransferencia()
      ? setPassoTransferencia(passo)
      : showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
  }

  const validarTransferencia = async () => {

    try {

      if (formTransferencia.tipoOperacaoTransferencia === undefined ||
        formTransferencia.tipoOperacaoTransferencia == ETipoOperacaoTransferencia.saida) {

        await schemaSaida.validate({
          numeroNotaFiscal: formTransferencia.numeroNotaFiscal,
          destinoCNPJ: formTransferencia.destinoCNPJ,
          data: formTransferencia.data,
          hora: formTransferencia.hora
        }, { abortEarly: false });

        return true;

      } else {
        await schemaEntrada.validate({
          numeroNotaFiscal: formTransferencia.numeroNotaFiscal,
          origemCNPJ: formTransferencia.origemCNPJ,
          data: formTransferencia.data,
          hora: formTransferencia.hora
        }, { abortEarly: false });

        return true;
      }

    } catch (error) {

      const validationError = error as yup.ValidationError;

      validationError?.inner?.forEach(err => {
        const path = err.path?.toString();

        if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
          setErrorField(path, err.message);
        }

      });

      return false;
    }

  }

  function setErrorField(path: string, errorMensage: string) {

    switch (path) {

      case 'numeroNotaFiscal':
        setError('numeroNotaFiscal', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'origemCNPJ':
        setError('origemCNPJ', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'destinoCNPJ':
        setError('destinoCNPJ', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'data':
        setError('data', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'hora':
        setError('hora', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      default:
        break;
    }

  }

  return (
    <Fade in mountOnEnter unmountOnExit>
      <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>

        <ModalHeader
          title={'Transferências'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={goBack}
            />
          }
        />

        {isLoading ? <CircularLoading tipo="FULLSIZED" /> :
          <>
            {controleMedicamentos.exibir
              ? <TransferenciaAvulsaMedicamentoLotes
                medicamentos={controleMedicamentos.medicamentoLotes}
                salvarLotesTransferencia={salvarLotes}
                isEntrada={isEntrada()}
                visualizacao={false}
              />
              : (
                <div className={`flex-column w-100 ${styles.formPadding, styles.formHeight}`}>
                  <Wizard steps={steps} activeStep={passoTransferencia} currentStepEmmiter={avancarStep} />

                  <div className={`flex-column ${styles.formContainer}`}>
                    {
                      passoTransferencia === ETransferenciaPassos.Movimentacao ? (
                        <TransferenciaAvulsaLancamentoForm
                          register={register}
                          formState={formState}
                          visualizacao={false}
                          transferencia={formTransferencia}
                          empresasFiliais={empresasFiliais}
                          setTransferencia={setFormTransferencia}
                        />
                      ) : (
                        <TransferenciaAvulsaMedicamentosForm
                          register={register}
                          formState={formState}
                          visualizacao={false}
                          transferencia={formTransferencia}
                          setTransferencia={setFormTransferencia}
                          editarMedicamentoLotes={editarMedicamentos}
                        />
                      )
                    }
                  </div>

                  <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                    {passoTransferencia === ETransferenciaPassos.Movimentacao ?
                      <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                        <Button onClick={() => avancarStep(passoTransferencia + 1)} variant="text" className="h-100 w-100">
                          <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                            <AvancarIcon class={styles.iconSize} />
                            Avançar
                          </div>
                        </Button>
                      </div> :

                      <>
                        <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                          <Button variant="text" className="h-100 w-100" onClick={() => setPassoTransferencia(0)} >
                            <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                              <VoltarIcon class={styles.iconSize} />
                              Voltar
                            </div>
                          </Button>
                        </div>
                        <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                          <Button onClick={() => salvarTransferencia(false)} variant="text" className="h-100 w-100">
                            <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                              <SalvarIcon class={styles.iconSize} />
                              Salvar
                            </div>
                          </Button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              )
            }
          </>
        }
      </div>
    </Fade>
  );
};
