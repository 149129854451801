import { Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useMenuPrincipal } from "../../../../../../../services/app/providers/menu-principal-provider";
import { IconsHelper } from "../../../../../../components/icons-helper";
import { useAcoesMenu } from "./acoes-menu/acoes-menu";
import { ListModuloFilhoProps } from "./list-modulo-filho-props";
import { useStyles } from "./list-modulo-filho-styles";

export const ListModuloFilho = (props: ListModuloFilhoProps) => {
  const { ...listItemProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);
  const classes = useStyles();
  const { selectAction } = useAcoesMenu();
  const { fecharMenu, alterarModelo } = useMenuPrincipal();

  const onClickItem = useCallback(() => {
    selectAction(listItemProps.codigoModulo, listItemProps.rota);

    if (props.isMobile) {
      alterarModelo("Mini");
      fecharMenu();
    };
  }, [selectAction, listItemProps.codigoModulo, listItemProps.rota, props.isMobile, alterarModelo, fecharMenu]);

  const component = React.useMemo(() => {
    return (<>
      <ListItem className={props.isFavorite ? classes.favoriteRoot : classes.root} button onClick={onClickItem}>
        <Tooltip arrow title={listItemProps.nomeModulo} placement="right">
          <ListItemIcon>
            <IconsHelper
              codigoModulo={listItemProps.codigoModulo} class={props.isFavorite ? classes.favoriteIcon : classes.icon} tipo="GERAL"
            />
          </ListItemIcon>
        </Tooltip>
        {props.modeloAtual === "Completo" && (
          <ListItemText
            classes={{
              primary: classNames(
                classes.text,
                props.isFavorite &&
                classes.favoriteText
              ),
            }}
            primary={listItemProps.nomeModulo}
          />
        )}
      </ListItem>
    </>)
  }, [
    classes.favoriteIcon,
    classes.favoriteRoot,
    classes.favoriteText,
    classes.icon,
    classes.root,
    classes.text,
    listItemProps.codigoModulo,
    listItemProps.nomeModulo,
    onClickItem,
    props.isFavorite,
    props.modeloAtual
  ]);

  return component;
};
