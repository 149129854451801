import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { ETypeDomain } from "../../../../config/types";
import { OperacaoPendenteAvulsa, OperacaoTransferenciaRequest } from "../../../../views/pages/private/transferencias/models/transferencias.model";
import { useApiBase } from "../../base/api-base";

export const useGetListTransferencias = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getListTransferencias = useCallback(
    (pageIndex: number, pageSize: number, filter: string) => {
      return invocarApi(
        {
          url: `Transferencia/transferencias-paginado?page=${pageIndex}&pageSize=${pageSize}&filter=${filter}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getListTransferencias,
  };
}

export const useGetTransferenciaById = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getTransferenciaById = useCallback(
    (transferenciaId: string, dataCompetencia: Date) => {
      return invocarApi(
        {
          url: `Transferencia/${transferenciaId}/${dataCompetencia}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getTransferenciaById,
  };
}

export function useCadastrarTransferencia() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const cadastrarTransferencia = useCallback(
    (novaTransferencia: OperacaoPendenteAvulsa) => {
      return invocarApi({
        url: `OperacaoPendente`,
        method: "POST",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        data: novaTransferencia,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    cadastrarTransferencia,
  };
}

export function useEditarOperacaoTransferencia() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const editarOperacaoTransferencia = useCallback(
    (request: OperacaoTransferenciaRequest) => {
      return invocarApi({
        url: `OperacaoPendente`,
        method: "PUT",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(request),
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    editarOperacaoTransferencia,
  };
}
