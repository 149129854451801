import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import LixeiraIcon from "../../../../../../assets/img/icon-lixeira.svg";
import { DefaultCard } from "../../../../../components/cards/components";
import { ComprasMedicamentoLoteCardProp } from "../models/compras-cadastro.model";
import { useStyles } from "./compras-medicamento-lotes-style";


export const ComprasMedicamentoLotesCard = (({ lote, removerLote, visualizacao }: ComprasMedicamentoLoteCardProp) => {
  const styles = useStyles();

  return (
    <DefaultCard>
      <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

        <div className={`flex-row w-100 ${styles.infoContainer}`}>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Lote:
            </Typography>
            <Typography className={styles.contentFont}>
              {lote.lote}
            </Typography>
          </div>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Fabricação:
            </Typography>
            <Typography className={styles.contentFont}>
              {moment(lote.dataFabricacao).format("DD/MM/YYYY")}
            </Typography>
          </div>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Validade:
            </Typography>
            <Typography className={styles.contentFont}>
              {moment(lote.dataValidade).format("DD/MM/YYYY")}
            </Typography>
          </div>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Qtd:
            </Typography>
            <Typography className={styles.contentFont}>
              {lote.quantidade}
            </Typography>
          </div>

          {
            !visualizacao &&
            <div className={styles.removeButtonStyle}>
              <Button onClick={() => removerLote(lote.lote)} variant="text" className="h-100 w-100">
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  <img className={styles.buttonIcon} src={LixeiraIcon} alt="" />
                </div>
              </Button>
            </div>
          }
        </div>
      </div>
    </DefaultCard>
  );
});