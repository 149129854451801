import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { ETypeDomain } from "../../../../config/types";
import { OperacaoCompraRequest } from "../../../../views/pages/private/compras/componentes/models/compras-cadastro.model";
import { useApiBase } from "../../base/api-base";

export function useGetComprasPaginado() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getComprasPaginado = useCallback(
    (filter: string, pageIndex: number, pageSize: number) => {
      return invocarApi({
        url: `Compra/compras-paginado?filter=${filter}&page=${pageIndex}&pageSize=${pageSize}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getComprasPaginado,
  };
}

export function useEditarOperacaoCompra() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const editarOperacaoCompra = useCallback(
    (request: OperacaoCompraRequest) => {
      return invocarApi({
        url: `OperacaoPendente`,
        method: "PUT",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(request),
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    editarOperacaoCompra,
  };
}

export const useGetCompraById = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getCompraById = useCallback(
    (compraId: string, dataCompetencia: Date) => {
      return invocarApi(
        {
          url: `Compra/${compraId}/${dataCompetencia}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getCompraById,
  };
}