
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToastSaurus } from "../../../../../../services/app";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { useThemeQueries } from "../../../../../theme";
import { InventarioAdicionarLoteMedicamentoProp, InventarioLote } from "../../models/inventario.models";
import { useStyles } from "../inventario-lote.styles";

export const ErroInitialValue = {
  erroLote: false,
  erroDataValidade: false,
  erroQuantidade: false
}

export const InventarioLoteAdicionar = (({
  control,
  medicamentonome,
  adicionarLote }: InventarioAdicionarLoteMedicamentoProp) => {

  const [lote, setLote] = useState<InventarioLote>({} as InventarioLote);
  const { showToast } = useToastSaurus();
  const styles = useStyles();
  const [erros, setErros] = useState(ErroInitialValue);

  const schema = yup
    .object()
    .shape({
      lote: yup.string()
        .required("Lote é obrigatório!"),
      dataValidade: yup.string()
        .required("Data de Validade é obrigatória!"),
      quantidade: yup.number()
        .required("Quantidade é obrigatória!")
    });

  const {
    formState,
    register,
    clearErrors
  } = useForm<InventarioLote>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  function verificarPeriodoPermitido(): boolean {

    if (lote?.dataValidade) {

      if (moment(lote?.dataFabricacao).isAfter(moment(lote?.dataValidade), 'day')) {
        showToast('error', 'A Data de Validade não pode ser menor que a Data de Fabricação!', 5000, 'top-center')
        return false;
      }

    }

    return true;

  }

  const adicionar = async () => {

    var formIsValid = await schema.isValid(lote);

    if (!formIsValid)
      return showToast("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

    if (!verificarPeriodoPermitido())
      return;

    let validadeFormatada = moment(lote.dataValidade).format('DD/MM/YYYY');
    let fabricacaoFormatada = lote.dataFabricacao ? moment(lote.dataFabricacao).format('DD/MM/YYYY') : "";

    adicionarLote({
      ...lote,
      dataValidade: lote.dataValidade,
      dataFabricacao: lote.dataFabricacao,
      dataValidadeFormatada: validadeFormatada,
      dataFabricacaoFormatada: fabricacaoFormatada
    });

    setLote(new InventarioLote());
    clearErrors("lote");
    clearErrors("quantidade");
    clearErrors("dataValidade");
    setErros(ErroInitialValue);

  }

  const { md } = useThemeQueries();



  return (
    <>

      <div className={styles.containerMedicamento} >

        <div className='flex-row w-100'>
          <Typography className={styles.labelMedicamentoFont}>
            Medicamento:
          </Typography>
          <Typography className={styles.contentFontMedicamento}>
            {medicamentonome}
          </Typography>
        </div>

      </div>

      <div className={styles.containerInput} >

        <div className={`flex-column w-100 ${styles.loteFormContainer}`}>

          <div className={`flex-row w-100 ${styles.formContainer}`}>
            <TextFieldSaurus
              required
              className={`w-100 ${styles.inputStyle}`}
              tipo='TEXTO'
              value={lote.lote}
              labelCustom='Lote'
              variant='outlined'
              size='medium'
              error={Boolean(errors.lote && !lote.lote)}
              helperText={!md &&
                (errors.lote && !lote.lote)
                ? errors.lote?.message
                : undefined
              }
              {...register('lote', {
                onChange: (e: any) => setLote({ ...lote, lote: e.target.value })
              })}
              min={undefined}
              max={undefined}
            />

            <TextFieldSaurus
              required
              className={`h-100 w-100 ${styles.inputStyle}`}
              tipo='NUMERO'
              value={lote.quantidade ?? 0}
              labelCustom='Quantidade'
              variant="outlined"
              size="medium"
              error={Boolean(errors.quantidade && !lote.quantidade)}
              helperText={!md &&
                (errors.quantidade && !lote.quantidade)
                ? errors.quantidade?.message
                : undefined
              }
              {...register('quantidade', {
                onChange: (e: any) => setLote({ ...lote, quantidade: e.target.value })
              })}
              min={undefined}
              max={undefined}
            />

          </div>

          <div className={`flex-row w-100 ${styles.formContainer}`}>
            <Controller
              name="dataFabricacao"
              control={control}
              render={() => (
                <TextFieldSaurus
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='DATA'
                  variant='outlined'
                  labelCustom='Data de Fabricação'
                  showStartAdornment={true}
                  allowSubmit={true}
                  size="medium"
                  fullWidth={true}
                  value={lote.dataFabricacao}
                  onChange={(e: any) => setLote({ ...lote, dataFabricacao: e.target.value })}
                  onBlur={(e: any) => {
                    verificarPeriodoPermitido();
                  }}
                />
              )}
            />

            <TextFieldSaurus
              required
              className={`w-100 ${styles.inputStyle}`}
              tipo='DATA'
              value={lote.dataValidade}
              labelCustom='Data de Validade'
              variant='outlined'
              size="medium"
              showStartAdornment={true}
              allowSubmit={true}
              fullWidth={true}
              error={Boolean(errors.dataValidade && !lote.dataValidade)}
              helperText={!md &&
                (errors.dataValidade && !lote.dataValidade)
                ? errors.dataValidade?.message
                : undefined
              }
              {...register('dataValidade', {
                onChange: (e: any) => setLote({ ...lote, dataValidade: e.target.value })
              })}
              min={undefined}
              max={undefined}
            />
          </div>

          <div className={`flex-row w-100 ${styles.containerAdicionar}`}>

            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
              <Button onClick={() => { adicionar() }} variant="text" className="h-100 w-100">
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  Adicionar
                </div>
              </Button>

            </div>

          </div>

        </div>
      </div>

    </>
  );
});