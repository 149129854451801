import { useCallback } from "react";
import { ETypeDomain } from "../../../../config/types";
import { useApiBase } from "../../base/api-base";

export const useGetListAutorizacao = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getListAutorizacao = useCallback(
    (pageIndex: number, pageSize: number, filter: string) => {
      return invocarApi(
        {
          url: `OperacaoPendente?page=${pageIndex}&pageSize=${pageSize}&filter=${filter}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getListAutorizacao,
  };
}