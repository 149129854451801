import { Grid, Typography } from '@material-ui/core';
import { CircularLoading } from '../../..';
import { MedicamentoPerda } from '../../../pages/private/perdas/models/perdas-models';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-perda-lote-view-style';
import { usePerdaLoteView } from './use-dialog-perda-lote-view';

interface DialoPerdaLoteViewConteudoProps {
  carregando?: boolean;
  aberto: boolean;
  lotes: Array<MedicamentoPerda>;
}

export const DialogPerdaLoteViewConteudo = ({
  carregando,
  aberto,
  lotes
}: DialoPerdaLoteViewConteudoProps) => {
  const { fecharPerdaLoteView } = usePerdaLoteView();
  const styles = useStyles();

  return (
    <DialogSaurus
      tamanho="xs"
      titulo="Lotes"
      cardHeight={80}
      isButtonTitleClose
      aberto={aberto || false}
      handleClickClose={fecharPerdaLoteView}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <div className={`h-100 ${styles.containerGrid}`}>
        <Grid container className={`w-100 ${styles.estiloGridContainer}`}>
          <div className={`flex-row w-100 ${styles.containerRow}`}>
            <Grid item xs={4} className={styles.containerTxtGridColuna}>
              <Typography className={styles.estiloTxtGridColuna}>Lote</Typography>
            </Grid>
            <Grid item xs={4} className={styles.containerTxtGridColuna}>
              <Typography className={styles.estiloTxtGridColuna}>Validade</Typography>
            </Grid>
            <Grid item xs={4} className={styles.containerTxtGridColuna}>
              <Typography className={styles.estiloTxtGridColuna}>Quantidade</Typography>
            </Grid>
          </div>
          <div className={`w-100 ${styles.tableItemsContainer}`}>
            {lotes.map((lote, id) => (
              <div key={id} className={`flex-row w-100 ${styles.containerRow}`}>
                <Grid item xs={4} className={`flex-row w-100 ${styles.containerTxtGrid}`}>
                  <Typography className={styles.estiloTxtGrid}>{lote.lote}</Typography>
                </Grid>
                <Grid item xs={4} className={`flex-row w-100 ${styles.containerTxtGrid}`}>
                  <Typography className={styles.estiloTxtGrid}>{lote.validade}</Typography>
                </Grid>
                <Grid item xs={4} className={`flex-row w-100 ${styles.containerTxtGrid}`}>
                  <Typography className={styles.estiloTxtGrid}>0</Typography>
                </Grid>
              </div>
            ))}
          </div>
        </Grid>
      </div>
    </DialogSaurus>
  );
};