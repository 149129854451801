import { Button, Typography } from '@material-ui/core';
import { useThemeQueries } from '../../..';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-inventario-pendente-envio-style';

interface DialogInventarioPendenteEnvioSNGPCProps {
  acessarEnvioInventarioSNGPC: () => any;
  fecharModal: () => any;
}

export const DialogInventarioPendenteEnvioSNGPC = ({
  acessarEnvioInventarioSNGPC,
  fecharModal
}: DialogInventarioPendenteEnvioSNGPCProps) => {
  const { isMobile, lg, xl } = useThemeQueries();
  const styles = useStyles();

  return (
    <DialogSaurus
      isPopUp={true}
      tamanho="xs"
      titulo="Envio Pendente!"
      subTitulo="Inventário pendente de envio ao SNGPC."
      cardHeight={isMobile ? 30 : lg ? 35 : xl ? 20 : 50}
      cardWidth={isMobile ? 70 : 25}
      isButtonTitleClose={false}
      aberto={true}
      handleClickClose={fecharModal}
      carregando={false}
      fullScreen={false}
    >
      <div className={`flex-column h-100 w-100 ${styles.dialogContainer}`}>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={() => acessarEnvioInventarioSNGPC()} className='w-100'>
            <Typography className={styles.buttonFont}>
              Acessar
            </Typography>
          </Button>
        </div>
      </div>
    </DialogSaurus >
  );
};