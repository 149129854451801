import { Button } from "@material-ui/core";
import classNames from "classnames";
import ConfirmaIcon from "../../../../../../../assets/img/icon-confirma.svg";
import { DefaultCard } from "../../../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../../../components/controles/inputs";
import { TransferenciasMedicamentoNovoLoteCardProp } from "../../../models/transferencias.model";
import { useStyles } from "./transferencias-medicamentos-lote-styles";


export const TransferenciaMedicamentoNovoLoteCard = (({
  novoLote,
  setNovoLote,
  register,
  formState,
  adicionar
}: TransferenciasMedicamentoNovoLoteCardProp) => {
  const styles = useStyles();
  const { errors } = formState;

  return (
    <DefaultCard>
      <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

        <div className={`flex-row w-100 ${styles.infoContainer}`}>
          <div className={styles.inputNovoLote}>
            <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
              value={novoLote.lote}
              labelCustom="Lote"
              variant="outlined"
              size="medium"
              required
              showStartAdornment
              error={Boolean(errors.lote && !novoLote.lote)}
              {...register("lote", {
                onChange: (e: any) => setNovoLote({ ...novoLote, lote: e.target.value })
              })}
            />
          </div>
          <div className={styles.inputValidadeLote}>
            <TextFieldSaurus className={styles.inputStyle}
              value={novoLote.dataValidade}
              labelCustom="Validade"
              variant="outlined"
              size="medium"
              tipo="DATA"
              required
              error={Boolean(errors.dataValidade && !novoLote.dataValidade)}
              {...register("dataValidade", {
                onChange: (e: any) => setNovoLote({ ...novoLote, dataValidade: e.target.value })
              })}
            />
          </div>
          <div className={styles.inputQtdLote}>
            <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
              value={novoLote.quantidade}
              labelCustom="Qtd"
              variant="outlined"
              size="medium"
              tipo="NUMERO"
              required
              error={Boolean(errors.quantidade && !novoLote.quantidade)}
              {...register("quantidade", {
                onChange: (e: any) => setNovoLote({ ...novoLote, quantidade: Number(e.target.value) })
              })}
            />
          </div>

          <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
            <Button onClick={adicionar} variant="text" className={`h-100 w-100 ${styles.espacamentoIconeAdicionar}`}>
              <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </DefaultCard>
  );
});
