import { merge } from "lodash";
import { EnumDeviceType } from "../model/enums/enum-device-type";
import { IAmbienteUnico, IVariaveisAmbiente } from "./types";

const AmbienteEnviado = (process.env.REACT_APP_ENV || "mpProd") as keyof IVariaveisAmbiente;
const AmbientePadrao: keyof IVariaveisAmbiente = AmbienteEnviado;
const LabelEnviado = (process.env.REACT_APP_LABEL || "Touchone") as keyof IVariaveisAmbiente;

const apiUrl = 'https://api-sngpc-staging.touchone.com.br';
const apiLoginUrl = 'https://api-gateway-staging.touchone.com.br';
const apiKdsUrl = "https://api-setores.touchone.com.br"
const apiPedidosUrl = "https://api-pedidos-local.touchone.com.br"
const apiProdutosUrl = "https://api-produtos-staging.touchone.com.br"
const apiPessoasUrl = "https://api-pessoas-staging.touchone.com.br"
const apiMedicamentosUrl = "https://api-medicamentos-staging.touchone.com.br/"

// variáveis de ambiente(óbvio)

const _variaveisAmbiente: IVariaveisAmbiente = {
  all: {
    version: "1.0.5",
    isDev: (["prodWeb"].find(x => x === AmbientePadrao.toString())?.length || 0) === 0,
    isSlowDevice: false,
    environment: AmbienteEnviado,
    googleAppId: "27279191635-5kdincvmbm92lihatr0k4tm1o4kon8ue.apps.googleusercontent.com",
    saurusUtilsWebService: "https://wsmaster.saurus.net.br/v003/servicemaster.asmx",
    label: LabelEnviado,
  },
  devWeb: {
    apiUrl: apiUrl,
    apiLoginUrl: apiLoginUrl,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiKdsUrl: apiKdsUrl,
    apiPedidosUrl: apiPedidosUrl,
    apiProdutosUrl: apiProdutosUrl,
    apiMedicamentosUrl: apiMedicamentosUrl,
    facebookAppId: "661218711269424",
    frontUrl: "https://app.touchone.com.br",
    apiPessoasUrl: apiPessoasUrl

  },
  prodWeb: {
    apiUrl: apiUrl,
    apiLoginUrl: apiLoginUrl,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiKdsUrl: apiKdsUrl,
    apiPedidosUrl: apiPedidosUrl,
    apiProdutosUrl: apiProdutosUrl,
    apiMedicamentosUrl: apiMedicamentosUrl,
    facebookAppId: "1880946222051906",
    frontUrl: "https://app.touchone.com.br",
    apiPessoasUrl: apiPessoasUrl
  },
};

const _ambienteUnico =
  (_variaveisAmbiente[AmbientePadrao] as IAmbienteUnico) || _variaveisAmbiente.devWeb;
export const VariaveisAmbiente = merge(_variaveisAmbiente.all, _ambienteUnico);
