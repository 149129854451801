import { ETipoReceituario } from "../../../../../model/enums/enum-tipo-receiturario";

export enum EDispensacaoPassos {
  Receita = 0,
  Prescritor = 1,
  Comprador = 2,
  Medicamento = 3,
}

export const EDispensacaoPassosMap = new Map<number, string>([
  [EDispensacaoPassos.Receita, 'Receita'],
  [EDispensacaoPassos.Prescritor, 'Prescritor'],
  [EDispensacaoPassos.Comprador, 'Comprador'],
  [EDispensacaoPassos.Medicamento, 'Medicamento'],
]);

export class OperacaoDispensacaoRequest {
  constructor(
    public id: string,
    public operacaoAutorizacaoId: string,
    public operacaoTipo: number,
    public dataHoraLancamento: Date,
    public deveAutorizar: boolean,
    public dispensacao: Dispensacao
  ) { }
}

export class Dispensacao {
  constructor(
    public id: string,
    public operacaoAutorizacaoId: string,
    public dataHoraLancamento: Date = new Date(),
    public dataLancamento: string = "",
    public horaLancamento: string = "",
    public medicamentos: DispensacaoMedicamento[] = [],
    public receitaArquivoNome?: string,
    public receitaArquivo?: string,
  ) { }
};

export type DispensacaoMedicamento = {
  medicamentoId: string;
  medicamentoNome: string;
  medicamentoDescritivo: string;
  medicamentoComposicao: string;
  codigoAnvisa: string;
  vendaControlada: boolean;
  vpmc: number;
  motivoIsencao: string;
  lote: string;
  validade?: Date;
  fabricacao?: Date;
  codigoAgregador?: number;
  usoProlongado: boolean;
  receita: DispensacaoMedicamentoReceita;
}

export type DispensacaoMedicamentoLote = {
  lote: string;
  quantidade: number;
  quantidadeReservada: number;
  dataValidade: Date;
  dataFabricacao: Date;
  codigoAgregador: number;
}

export class DispensacaoMedicamentoReceita {
  numeroReceita?: string;
  receitaTipo?: ETipoReceituario;
  dataLancamento?: string;
  horaLancamento?: string;
  dataHoraLancamento?: Date;
  dataReceita?: Date;
  uf?: string;
  cid?: string;
  compradorPacienteMesmaPessoa: boolean = true;
  prescritor?: DispensacaoPrescritor;
  comprador?: DispensacaoCompradorPaciente;
  paciente?: DispensacaoCompradorPaciente;
  isAutorizado?: boolean = false;
}

export class DispensacaoPrescritor {
  id?: string;
  nome?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  municipio?: string;
  codMunicipio?: number;
  uf?: string;
  especialidade?: string;
  tipoDocumento?: number;
  documento?: string;
  orgaoExpedidor?: number;
  ufOrgaoExpedidor?: string;
};

export class DispensacaoCompradorPaciente {
  id?: string;
  nome?: string;
  sexo?: number;
  telefone?: string;
  dataNascimento?: Date;
  tipoDocumento?: number;
  numeroDocumento?: string;
  orgaoExpedidor?: number;
  ufOrgaoExpedidorId?: number;

  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  uf?: string;
  municipio?: string;
};

export interface DispensacaoResponse {
  receita: ReceitasResposa;
}

export interface ReceitasResposa {
  id: string;
  comprador?: string;
  dataReceita?: string;
  dataReceitaFormatada?: string;
  dataFormatada: string;
  numero?: number;
  data?: Date;
}