import { isEmpty } from 'lodash';
import { useCallback } from "react";
import { decodeToken } from "react-jwt";
import { PerfilModel } from '../../../model/api/gestao/master/perfil';
import { UsuarioConectadoModel } from "../../../model/app";

import { TokenSngpcModel } from "../../../model/api/gestao/master/token-sngpc";
import { PlanoAtualModel } from "../../../model/api/gestao/plano/plano-atual-model";
import { SngpcStorageKeys, useSngpcStorage } from "./sngpc-storage";

export function useSngpcToken() {
    const { setRegistro, delRegistro, getRegistro } = useSngpcStorage();
    const tokenFromSession: boolean = false;

    const isTokenExpired = useCallback((token: TokenSngpcModel | undefined): boolean => {
        if (!token?.exp) {
            return true;
        }
        return token?.exp * 1000 <= new Date().getTime();
    }, []);

    const convertToken = useCallback((token?: string): TokenSngpcModel | undefined => {
        let gtoken: TokenSngpcModel | undefined;
        try {
            const utf8 = require('utf8');
            gtoken = decodeToken(token!) as TokenSngpcModel;
            gtoken.saudacao = utf8.decode(gtoken.saudacao);
            gtoken.originalToken = token || '';
            gtoken.empresa = JSON.parse(JSON.parse(JSON.stringify(gtoken.empresa)))
            gtoken.usuarioId = gtoken.sub || gtoken.jti;
        } catch (e) {
            gtoken = undefined;
        }
        return gtoken;
    }, []);

    const getTokenFromStorage = useCallback((): TokenSngpcModel | undefined => {
        const token = getRegistro(SngpcStorageKeys.TokenTouchPharma, tokenFromSession);
        if (isEmpty(token))
            return undefined;

        return (convertToken(token));
    }, [getRegistro, convertToken, tokenFromSession]);

    const isTokenValid = useCallback((token: TokenSngpcModel | undefined): boolean => {
        return !isEmpty(token) && !isTokenExpired(token);
    }, [isTokenExpired]);

    const persistToken = useCallback((token: TokenSngpcModel | undefined): void => {
        if (isEmpty(token)) {
            return delRegistro(SngpcStorageKeys.TokenTouchPharma, tokenFromSession);
        } else {
            return setRegistro(SngpcStorageKeys.TokenTouchPharma, token?.originalToken || "", tokenFromSession);
        }
    }, [delRegistro, setRegistro, tokenFromSession]);

    const persistPerfilPermissoes = useCallback((perfis: Array<PerfilModel> | undefined) => {
        if (perfis)
            setRegistro(SngpcStorageKeys.PerfisDoUsuario, perfis, tokenFromSession);
        else
            delRegistro(SngpcStorageKeys.PerfisDoUsuario, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getPerfilPermissoes = useCallback((): Array<PerfilModel> => {
        const ret = getRegistro(SngpcStorageKeys.PerfisDoUsuario, tokenFromSession);
        if (isEmpty(ret))
            return new Array<PerfilModel>();

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistTermosDeUso = useCallback((valid: boolean | undefined, statusApi: number | undefined) => {
        if (valid === undefined)
            delRegistro(SngpcStorageKeys.TermosDeUso, tokenFromSession)
        else {
            const termo = {
                termoAceito: valid,
                statusApi
            }
            setRegistro(SngpcStorageKeys.TermosDeUso, termo, tokenFromSession);
        }
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getTermosDeUso = useCallback((): boolean => {
        const ret = getRegistro(SngpcStorageKeys.TermosDeUso, tokenFromSession);
        if (isEmpty(ret)) {
            return true;
        } if (ret.termoAceito === false && ret.statusApi === 400) {
            return false
        } else {
            return true;
        }
    }, [getRegistro, tokenFromSession])


    const getEmpresaAtual = useCallback((): string => {
        const ret = getRegistro(SngpcStorageKeys.EmpresaAtual, tokenFromSession);
        if (isEmpty(ret))
            return '';

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistEmpresaSelecionada = useCallback((empresaId: string | undefined) => {
        if (empresaId === undefined)
            delRegistro(SngpcStorageKeys.EmpresaAtual, tokenFromSession)
        else
            setRegistro(SngpcStorageKeys.EmpresaAtual, empresaId.toString(), tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getEmpresaAnterior = useCallback((empresaId: string | undefined) => {
        if (empresaId === undefined)
            delRegistro(SngpcStorageKeys.EmpresaAtual, tokenFromSession)
        else
            setRegistro(SngpcStorageKeys.EmpresaAtual, empresaId.toString(), tokenFromSession);
    }, [delRegistro, setRegistro, tokenFromSession])


    const getPlanoUsuario = useCallback((): PlanoAtualModel | undefined => {
        const ret = getRegistro(SngpcStorageKeys.Plano, tokenFromSession);
        if (isEmpty(ret))
            return undefined;

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistPlanoUsuario = useCallback((plano: PlanoAtualModel | undefined) => {
        if (plano)
            setRegistro(SngpcStorageKeys.Plano, plano, tokenFromSession);
        else
            delRegistro(SngpcStorageKeys.Plano, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const removeConnectedUser = useCallback((usuarioId: string) => {
        let conectados: UsuarioConectadoModel[] = getRegistro(SngpcStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }
        conectados = conectados.filter(x => x.usuarioId !== usuarioId);
        setRegistro(SngpcStorageKeys.UsuariosConectados, conectados, false);
    }, [setRegistro, getRegistro]);

    const getConnectedUsers = useCallback((): UsuarioConectadoModel[] => {
        let conectados: UsuarioConectadoModel[] = getRegistro(SngpcStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }
        return conectados;
    }, [getRegistro]);

    const addConnectedUser = useCallback((token: TokenSngpcModel) => {
        let conectados: UsuarioConectadoModel[] = getRegistro(SngpcStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }

        conectados = conectados.filter(x => x.usuarioId !== token.usuarioId);

        let conUser = new UsuarioConectadoModel(token.usuarioId, token.empresa[0].Descricao, token.saudacao, token.originalToken);
        conectados.push(conUser);

        setRegistro(SngpcStorageKeys.UsuariosConectados, conectados, false);
    }, [setRegistro, getRegistro]);

    return {
        isTokenExpired,
        isTokenValid,
        persistToken,
        getTokenFromStorage,
        convertToken,
        removeConnectedUser,
        addConnectedUser,
        getConnectedUsers,
        persistTermosDeUso,
        getTermosDeUso,
        persistPerfilPermissoes,
        getPerfilPermissoes,
        getEmpresaAtual,
        getEmpresaAnterior,
        persistEmpresaSelecionada,
        getPlanoUsuario,
        persistPlanoUsuario,
    };
}