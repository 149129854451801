import { useCallback } from "react";
import { ETypeDomain } from "../../../../config/types";
import { OperacaoMenuEnum } from "../../../../views/components/headers/header-page/header-page";
import { CompraMedicamentoItem, ComprasAvulsaCadastroModel } from "../../../../views/pages/private/compras/componentes/models/compras-cadastro.model";
import { useApiBase } from "../../base/api-base";

export class OperacaoPendenteCadastroRequest {
  public constructor(
    public operacaoTipo: OperacaoMenuEnum,
    public dataHoraLancamento: Date,
    public compra: ComprasAvulsaCadastroModel
  ) { }
}

export const useCadastrarOperacaoPendenteCompra = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const cadastrarOperacaoPendenteCompra = useCallback(
    (compra: ComprasAvulsaCadastroModel, novaListaMedicamentos: Array<CompraMedicamentoItem>) => {
      var novaCompra = criarNovaCompra(compra, novaListaMedicamentos);
      var operacaoPendente = new OperacaoPendenteCadastroRequest(OperacaoMenuEnum.Compra, new Date, novaCompra);
      return invocarApi({
        url: `OperacaoPendente`,
        method: "POST",
        data: operacaoPendente,
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    cadastrarOperacaoPendenteCompra,
  };
}

const criarNovaCompra = (compra: ComprasAvulsaCadastroModel, novaListaMedicamentos: Array<CompraMedicamentoItem>) => {
  var novaCompra = new ComprasAvulsaCadastroModel();
  novaCompra.dataAtual = compra.dataAtual;
  novaCompra.horaAtual = compra.horaAtual;
  novaCompra.dataHoraLancamento = compra.dataAtual + ' ' + compra.horaAtual;
  novaCompra.dataNotaFiscal = compra.dataNotaFiscal;
  novaCompra.numeroNotaFiscal = compra.numeroNotaFiscal;
  novaCompra.fornecedor = compra.fornecedor;
  novaCompra.medicamentos = novaListaMedicamentos;
  return novaCompra;
}