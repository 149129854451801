import { MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
import { DefaultCard } from "../../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { DispensacaoMedicamentoLoteCardProp } from "../../models/dispensacao-props";
import { useStyles } from "./dispensacao-medicamento-lotes-style";


export const DispensacaoMedicamentoLotesCard = (({ lote, quantidadeRestante, quantidadeTotal, alteraLoteQtd, visualizacao }: DispensacaoMedicamentoLoteCardProp) => {
  const styles = useStyles();

  const buscarValoresDisponiveis = () => {
    let valoresDisponiveis = [];

    for (let index = 0; index <= lote.quantidade; index++)
      valoresDisponiveis.push(index);

    return valoresDisponiveis;
  }

  const verificaDisponibilidade = (valor: number, reservados: number): boolean => {
    if (valor > quantidadeTotal)
      return true;

    if (!reservados && valor > quantidadeRestante)
      return true;

    if (!quantidadeRestante && valor > reservados)
      return true;

    return false;
  }

  return (
    <>
      <DefaultCard>
        <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

          <div className={`h-100 ${styles.statusBarContainer}`}>
            <div className={`${styles.statusBarStyle}`} style={{
              backgroundColor: lote.quantidadeReservada ? 'var(--sistema-sucesso)' : 'var(--sistema-sem-acao)'
            }} />
          </div>

          <div className={`flex-row w-100 ${styles.infoContainer}`}>
            <div className="flex-column">
              <Typography className={styles.labelFont}>
                Lote
              </Typography>
              <Typography className={styles.contentFont}>
                {lote.lote}
              </Typography>
            </div>
            <div className="flex-column">
              <Typography className={styles.labelFont}>
                Validade
              </Typography>
              <Typography className={styles.contentFont}>
                {moment(lote.dataValidade).format("DD/MM/YYYY")}
              </Typography>
            </div>
            {!visualizacao &&
              <div className="flex-column">
                <Typography className={styles.labelFont}>
                  Disponível
                </Typography>
                <Typography className={styles.contentFont}>
                  {lote.quantidade}
                </Typography>
              </div>
            }

            {!visualizacao ?
              < div >
                <TextFieldSaurus className={`h-100 ${styles.inputStyle}`}
                  value={lote.quantidadeReservada}
                  labelCustom="Qtd"
                  variant="outlined"
                  size="small"
                  select
                  defaultValue={lote.quantidadeReservada}
                  onChange={(e: any) => alteraLoteQtd(lote.lote, e.target.value)}
                  disabled={visualizacao || !lote.quantidade}
                >
                  {buscarValoresDisponiveis().map(value => (
                    <MenuItem disabled={verificaDisponibilidade(value, lote.quantidadeReservada)} className={styles.menuItemStyle} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextFieldSaurus>
              </div>
              :
              <div className="flex-column">
                <Typography className={styles.labelFont}>
                  Quantidade
                </Typography>
                <Typography className={styles.contentFont}>
                  {lote.quantidadeReservada}
                </Typography>
              </div>
            }

          </div>

        </div>
      </DefaultCard >
    </>
  );
});