import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { AddIcon } from "../../../../../components/icons/add-icon";
import { MedicamentoPerda, PerdaStepsProp } from "../../models/perdas-models";
import { PerdaMedicamentosCard } from "./perda-medicamentos-card";
import { useStyles } from "./perdas-cadastro-styles";


export const PerdaMedicamentosForm = (({ visualizacao, perda, editarMedicamentoLotes }: PerdaStepsProp) => {
  const styles = useStyles();
  const [meds, setMeds] = useState<Array<MedicamentoPerda[]>>([]);

  useEffect(() => {
    let codigosDistintos = perda.medicamentos
      ?.map(m => m.codigoAnvisa)
      ?.filter(selecionarUnicos);
    let matrizMedicamentos: Array<MedicamentoPerda[]> = [];

    codigosDistintos.forEach((c) => {
      let medicamentosIguas = perda.medicamentos?.filter(m => m.codigoAnvisa === c);
      matrizMedicamentos[codigosDistintos.indexOf(c)] = medicamentosIguas;
    })

    setMeds(matrizMedicamentos);
  }, [perda.medicamentos]);

  const selecionarUnicos = (value: string, index: number, array: string[]) =>
    array.indexOf(value) === index;

  return (
    <>
      {perda.medicamentos && editarMedicamentoLotes !== undefined &&
        <div className={`flex-column w-100 h-100`}>
          <div className={`flex-column ${styles.titleContainer}`}>
            <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
            <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
          </div>

          <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
            {
              meds?.map((medicamentos, index) => (
                <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                  <PerdaMedicamentosCard visualizacao={visualizacao} medicamentos={medicamentos} editar={editarMedicamentoLotes} />
                </div>
              ))
            }
          </div>

          {
            !visualizacao && (
              <div className={classNames(styles.addButtonMedicamentoStyle, styles.whiteButton)}>
                <Button onClick={() => editarMedicamentoLotes([])} variant="text" className="h-100 w-100">
                  <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                    <AddIcon class={styles.iconSize} />
                    Adicionar Produto
                  </div>
                </Button>
              </div>
            )
          }
        </div>
      }
    </>
  );
});