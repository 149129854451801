import { Redirect, Route, Switch } from "react-router-dom"
import { MenuPrincipalProvider } from "../../../services/app/providers/menu-principal-provider"
import { NavegacaoProvider } from "../../../services/app/providers/navegacao-provider"
import { AutorizacaoListPage } from "../../../views/pages/private/autorizacao-page/autorizacao-list/autorizacao-list"
import { CompraCadastroPage } from "../../../views/pages/private/compras/componentes/compras-cadastro"
import { CompraCadastroAvulsaPage } from "../../../views/pages/private/compras/componentes/compras-cadastro-avulso"
import { CompraListPage } from "../../../views/pages/private/compras/componentes/compras-listagem"
import { ConfiguracoesPage } from "../../../views/pages/private/configuracoes-page"
import { DispensacaoAvulsaCadastroPage } from "../../../views/pages/private/dispensacoes-avulsas/dispensacao-avulsa-cadastro"
import { DispensacaoListPage } from "../../../views/pages/private/dispensacoes/componentes/listagem/dispensacoes-listagem"
import { DispensacaoCadastroPage } from "../../../views/pages/private/dispensacoes/dispensacao-cadastro"
import { EnvioInventarioListPage } from "../../../views/pages/private/envio-inventario-page/components/envio-inventario-list/envio-inventario-list"
import { EnvioMovimentacaoList } from "../../../views/pages/private/envio-movimentacao/listagem/envio-movimentacao-listagem"
import { InventarioListPage } from "../../../views/pages/private/inventarios/listagem/inventario-listagem"
import { InventarioLotePage } from "../../../views/pages/private/inventarios/lote/inventario-lote"
import { MainPage } from "../../../views/pages/private/main/main-page"
import { PerdaAvulsaCadastroPage } from "../../../views/pages/private/perdas-avulsas/perda-avulsa-cadastro"
import { PerdaCadastroPage } from "../../../views/pages/private/perdas/componentes/cadastro/perdas-cadastro"
import { PerdaListPage } from "../../../views/pages/private/perdas/componentes/listagem/perdas-listagem"
import { RelatorioBalancoMedicamento } from "../../../views/pages/private/relatorios/relatorio-balanco-medicamentos/relatorio-balanco-medicamento"
import { RelatorioHome } from "../../../views/pages/private/relatorios/relatorio-home"
import { RelatorioLivroRegistroEspecifico } from "../../../views/pages/private/relatorios/relatorio-livro-registro-especificos/relatorio-livro-registro-especifico"
import { RelatorioRelacaoMensalReceitaA } from "../../../views/pages/private/relatorios/relatorio-mensal-receita-a/relatorio-relacao-mensal-receita-a"
import { RelatorioRelacaoMensalReceitaB2 } from "../../../views/pages/private/relatorios/relatorio-mensal-receita-b2/relatorio-relacao-mensal-receita-b2"
import { RelatorioMensalVenda } from "../../../views/pages/private/relatorios/relatorio-mensal-vendas/relatorio-mensal-venda"
import { TransferenciaAvulsaCadastroPage } from "../../../views/pages/private/transferencias/componentes/cadastro-avulso/transferencia-avulsa-cadastro"
import { TransferenciaCadastroPage } from "../../../views/pages/private/transferencias/componentes/cadastro/transferencias-cadastro"
import { TransferenciaListPage } from "../../../views/pages/private/transferencias/componentes/listagem/transferencias-listagem"
import { WhiteLabelProvider } from "../../../views/theme/whitelabel-provider"
import { InventarioEnviadoList } from "../../../views/pages/private/inventarios-enviados/listagem/inventario-enviado-list"

const RouterMain = () => {
    return (
        <WhiteLabelProvider>
            <Switch>
                <MenuPrincipalProvider>
                    <MainPage>
                        <NavegacaoProvider>

                            <Route exact path="/autorizacao-listagem" component={AutorizacaoListPage} />                            
                            <Route exact path="/compra-listagem" component={CompraListPage} />
                            <Route exact path="/perda-listagem" component={PerdaListPage} />
                            <Route exact path="/transferencia-listagem" component={TransferenciaListPage} />
                            <Route exact path="/dispensacao-listagem" component={DispensacaoListPage} />
                            <Route exact path="/inventario-listagem" component={InventarioListPage} />
                            <Route exact path="/inventario-medicamento-lote" component={InventarioLotePage} />

                            <Route exact path="/compra-cadastro" component={CompraCadastroPage} />
                            <Route exact path="/perda-cadastro" component={PerdaCadastroPage} />
                            <Route exact path="/dispensacao-cadastro" component={DispensacaoCadastroPage} />
                            <Route exact path="/transferencia-cadastro" component={TransferenciaCadastroPage} />

                            <Route exact path="/compra-avulsa-cadastro" component={CompraCadastroAvulsaPage} />
                            <Route exact path="/dispensacao-avulsa-cadastro" component={DispensacaoAvulsaCadastroPage} />
                            <Route exact path="/perda-avulsa-cadastro" component={PerdaAvulsaCadastroPage} />
                            <Route exact path="/transferencia-avulsa-cadastro" component={TransferenciaAvulsaCadastroPage} />

                            <Route exact path="/relatorio-home" component={RelatorioHome} />
                            <Route exact path="/relatorio-balanco-medicamento" component={RelatorioBalancoMedicamento} />
                            <Route exact path="/relatorio-relacao-mensal-receita-a" component={RelatorioRelacaoMensalReceitaA} />
                            <Route exact path="/relatorio-relacao-mensal-receita-b2" component={RelatorioRelacaoMensalReceitaB2} />
                            <Route exact path="/relatorio-mensal-venda" component={RelatorioMensalVenda} />
                            <Route exact path="/relatorio-livro-registro-especifico" component={RelatorioLivroRegistroEspecifico} />

                            <Route exact path="/envio-movimentacao-listagem" component={EnvioMovimentacaoList} />
                            <Route exact path="/envio-inventario-listagem" component={EnvioInventarioListPage} />
                            <Route exact path="/inventarios-enviados" component={InventarioEnviadoList} />
                            <Route exact path="/configuracoes" component={ConfiguracoesPage} />

                            <Route exact path="/">
                                <Redirect to="/autorizacao-listagem" />
                            </Route>
                        </NavegacaoProvider>
                    </MainPage>
                </MenuPrincipalProvider>
            </Switch>
        </WhiteLabelProvider>
    );
}

export default RouterMain