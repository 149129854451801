import { useCallback } from "react";
import { useApiBase } from "../../base";
import { ETypeDomain } from "../../../../config/types";

export function useGetPerfilById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPerfilById = useCallback(
        (id: string) =>
            invocarApi({
                url: `Perfil/${id}`,
                method: "GET",
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.Login,
                apiVersion: 'v4'
            }),
        [invocarApi]
    );


    return {
        ...outrasPropriedades,
        getPerfilById,
    };


}
