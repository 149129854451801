import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CompraMedicamentosCard } from "./compra-medicamentos-card";
import { useStyles } from "./compras-styles";
import { CompraMedicamentoResponse, CompraStepsProp } from "./models/compras-cadastro.model";


export const CompraMedicamentosForm = (({ visualizacao, compra, editarMedicamentoLotes }: CompraStepsProp) => {
  const styles = useStyles();
  const [meds, setMeds] = useState<Array<CompraMedicamentoResponse[]>>([]);

  useEffect(() => {
    let codigosDistintos = compra.medicamentos
      ?.map(m => m.codigoAnvisa)
      ?.filter(selecionarUnicos);
    let matrizMedicamentos: Array<CompraMedicamentoResponse[]> = [];

    codigosDistintos?.forEach((c) => {
      let medicamentosIguas = compra.medicamentos?.filter(m => m.codigoAnvisa === c);
      matrizMedicamentos[codigosDistintos.indexOf(c)] = medicamentosIguas;
    })

    setMeds(matrizMedicamentos);
  }, [compra.medicamentos]);

  const selecionarUnicos = (value: string, index: number, array: string[]) =>
    array.indexOf(value) === index;

  return (
    <>
      {compra.medicamentos && editarMedicamentoLotes !== undefined &&
        <div className={`flex-column w-100 h-100`}>
          <div className={`flex-column ${styles.titleContainer}`}>
            <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
            <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
          </div>

          <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
            {
              meds?.map((medicamentos, index) => (
                <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                  <CompraMedicamentosCard visualizacao={visualizacao} medicamentos={medicamentos} editar={editarMedicamentoLotes} />
                </div>
              ))
            }
          </div>
        </div>
      }
    </>
  );
});