import { PlanoModel } from "../model/api/gestao/plano/plano-model";
import { EnumPlanoOpcoes } from "../model/enums/enum-plano-opcoes";

export const getPlanoLimite = (plano: PlanoModel | undefined, opcao: EnumPlanoOpcoes): number => {
    if (!plano)
        return 0;

    var finded = plano.opcoes.find(x => x.codigo === opcao);
    if (!finded)
        return 0;
    return parseInt(finded.valor);
}

export const SNGPC = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.SNGPC);
    return limite === 1;
};
