export class ConfiguracoesModel {
    constructor(
        emailSNGPC: string,
        senhaSNGPC: string,
        confirmarSenha: string
    ) {
        this.emailSNGPC = emailSNGPC;
        this.senhaSNGPC = senhaSNGPC;
        this.confirmarSenha = confirmarSenha;
    }

    public emailSNGPC: string;
    public senhaSNGPC: string;
    public confirmarSenha: string;
    public id?: string;
}