import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: 'relative',
    overflowY: 'hidden',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },

  formPadding: {
    flexGrow: 1,
    padding: '0.5rem'
  },

  medicamentoInfoContainer: {
    height: "12vh",
    boxShadow: "0 0 0.5rem 0"
  },

  medicamentoInfoStyle: {
    paddingLeft: "0.8rem",
    width: '80vw',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '1.5rem'
    }
  },

  medicamentoCodigoStyle: {
    fontSize: '0.85rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },

  medicamentoNomeStyle: {
    fontSize: '0.85rem',
    lineHeight: '1.1rem',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },

  quantidadeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '25vw',
    [theme.breakpoints.up('sm')]: {
      width: '15vw',
    }
  },

  quantidadeLabel: {
    padding: '0.3rem 0.4rem 0',
    textAlign: 'center',
    fontSize: '0.75rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.65rem'
    }
  },

  quantidadeValor: {
    fontSize: '1.8rem',
    fontWeight: 700,
    marginTop: '-0.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.1rem',
    }
  },

  quantidadeTotalCores: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100],
  },

  quantidadeRestanteCompleto: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  },
  quantidadeRestantePendente: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
  },

  innerFormContainerMedicamento: {
    padding: '0 0.2rem',
    margin: '0.5rem 0',
    overflow: 'auto',
    flexGrow: 1,
    height: 0,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      alignSelf: 'center'
    }
  },

  buttonContainer: {
    height: '4rem',
    gap: '5%',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
      alignSelf: 'center',
      marginBottom: '2%'
    }
  },

  buttonStyle: {
    flexGrow: 1,
    minWidth: '47%',
    borderRadius: '10px',
    alignContent: 'center'
  },

  darkButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  buttonLabel: {
    gap: '0.4rem',
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem'
    },
  },

  whiteLabel: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },

  iconSize: {
    width: '1.3rem',
    [theme.breakpoints.up('md')]: {
      width: '2rem'
    }
  },

  titleContainer: {
    alignItems: 'center'
  },

  titleMessage: {
    textAlign: 'center'
  },

  titleStyle: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
      width: '95%',
    }
  },

  titleMessageStyle: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
    },
    width: '100%',
  },

  breakLine: {
    height: '2px',
    marginBottom: '1vh',
    backgroundColor: theme.palette.divider,
    [theme.breakpoints.up('sm')]: {
      width: '95%',
    }
  },

  inputStyle: {
    width: '15vw',
    fontSize: '0.9rem',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      width: '6vw',
    },
    [theme.breakpoints.between('lg', 'sm')]: {
      width: '10vw',
    }
  },

  cardMedicamentoItem: {
    justifyContent: 'center',
  },

  cardContainer: {
    padding: '0 0.25rem'
  },

  infoContainer: {
    margin: '0.3rem 0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  labelFont: {
    fontSize: '0.6rem',
    color: theme.palette.grey[400],
    marginBottom: '-0.25rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    }
  },

  contentFont: {
    fontSize: '0.75rem',
    color: theme.palette.common.black,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
    }
  },

  statusBarContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0'
  },

  statusBarStyle: {
    width: '0.4rem',
    height: '90%',
    margin: '0 0.5rem',
    borderRadius: '12px',
  },

  menuItemStyle: {
    fontSize: '0.8rem',
  },
}));