import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerTable: {
    height: '100%',
    padding: '2%',
    width: '100%'
  },

  tableRowDisplay: {
    display: 'ruby-text'
  },

  backgroundModal: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    position: "absolute",
    height: "100vh",
    zIndex: 2,
  },

  dialogContainer: {
    justifyContent: 'space-between',
    paddingBottom: '0.8rem'
  },

  buttonContainer: {
    display: 'flex',
    padding: '0.2rem',
    justifyContent: 'center'
  },

  cardContainer: {
    position: "absolute",
    pointerEvents: "none",
    height: "100vh",
    padding: 0,
    zIndex: 3,
  },

  cardStyle: {
    position: "sticky",
    padding: 60,
    top: "100vh",
    width: "100%",
    pointerEvents: "all",
    borderTopLeftRadius: 75,
    borderTopRightRadius: 75,
  },

  tableTitle: {
    marginBottom: "1.2rem"
  },

  tableHeaderStyle: {
    width: "30%",
    fontSize: "1.8rem"
  },

  tableCellStyle: {
    width: "30%",
    fontSize: "1.2rem"
  },

  tableScroll: {
    padding: 0,
    maxHeight: "50vh",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "0.6rem"
  },

  closeButtonContainer: {
    display: "flex",
    flexDirection: "row-reverse"
  },

  defaultContainer: {
    position: 'relative',
    overflowY: 'hidden',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },

  formPadding: {
    flexGrow: 1,
    padding: '0.5rem'
  },

  medicamentoInfoContainer: {
    height: "5rem",
    boxShadow: "0 0 0.5rem 0"
  },

  medicamentoInfoStyle: {
    width: '100%',
    padding: '0.8rem'
  },

  medicamentoCodigoStyle: {
    fontSize: '0.85rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },

  medicamentoNomeStyle: {
    fontSize: '0.85rem',
    lineHeight: '1.1rem',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },

  quantidadeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '25vw',
    [theme.breakpoints.up('sm')]: {
      width: '15vw',
    }
  },

  quantidadeLabel: {
    padding: '0.3rem 0.4rem 0',
    textAlign: 'center',
    fontSize: '0.75rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.65rem'
    }
  },

  quantidadeValor: {
    fontSize: '1.8rem',
    fontWeight: 700,
    marginTop: '-0.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.1rem',
    }
  },

  quantidadeTotalCores: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100],
  },

  quantidadeRestanteCompleto: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  },
  quantidadeRestantePendente: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
  },

  innerFormContainerMedicamento: {
    gap: '2%',
    paddingBottom: '1rem',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      alignSelf: 'center'
    }
  },

  titleContainer: {
    alignItems: 'center'
  },

  titleMessage: {
    textAlign: 'center'
  },

  titleStyle: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
      width: '95%',
    }
  },

  titleMessageStyle: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
    },
    width: '100%',
  },

  breakLine: {
    height: '2px',
    marginBottom: '1vh',
    backgroundColor: theme.palette.divider,
    [theme.breakpoints.up('sm')]: {
      width: '95%',
    }
  },

  inputStyle: {
    fontSize: '0.9rem',
    borderRadius: '10px',
    width: '100%',
  },

  cardMedicamentoItem: {
    justifyContent: 'center',
  },

  cardContainerLote: {
    padding: '0 0.25rem'
  },

  infoContainer: {
    margin: '0.3rem 0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  labelFont: {
    fontSize: '0.6rem',
    color: theme.palette.grey[400],
    marginBottom: '-0.25rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    }
  },

  contentFont: {
    fontSize: '0.75rem',
    color: theme.palette.common.black,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
    }
  },

  statusBarContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0'
  },

  statusBarStyle: {
    width: '0.4rem',
    height: '90%',
    margin: '0 0.5rem',
    borderRadius: '12px',
  },

  menuItemStyle: {
    fontSize: '0.8rem',
  },
}));

export const closeButtonStyle = {
  width: "120px",
  height: "120px",
  color: "var(--secundaria-03)",

};
