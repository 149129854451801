import { Button, Fade, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { ReactNode, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetDispensacoesPaginado } from "../../../../../../data/api/gestao/dispensacao/dispensacao";
import { CircularLoading } from "../../../../../components";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components";
import { PageHeader } from "../../../../../components/headers/header-page/header-page";
import { ProcurarIcon } from "../../../../../components/icons";
import { NovoIcon } from "../../../../../components/icons/novo-icon";
import { Paginacao } from "../../../../../components/paginacao";
import { DispensacaoModal } from "../../../dispensacoes-avulsas/modals/modal-dispensacao/dispensacao-modal";
import { DispensacaoResponse } from "../../models/dispensacao-models";
import { useStyles } from "./dispensacoes-listagem-styles";

export const DispensacaoListPage = (() => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();
  const pageItems = 10;
  const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<DispensacaoResponse>() });
  const { getDispensacoesPaginado, carregando } = useGetDispensacoesPaginado();
  const [modalOpen, setModalOpen] = useState(false);
  const [dispensacaoModal, setDispensacaoModal] = useState(null as ReactNode);
  const [timer, setTimer] = useState({} as NodeJS.Timeout);

  const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<DispensacaoResponse>) => {
    setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
  }, []);

  const search = useCallback(
    async (newPage: number, filter: string = "") => {
      try {

        const res = await getDispensacoesPaginado(filter, newPage, pageItems);

        if (res.erro)
          throw res.erro;

        const list = res.resultado?.data.list;
        const pageIndex = res.resultado?.data.pageIndex;
        const totalPages = res.resultado?.data.totalPages;
        const totalResults = res.resultado?.data.totalResults;

        fillResult(
          pageIndex,
          totalPages,
          totalResults,
          list
        );



      } catch (e: any) {
        if (axios.isCancel(e))
					return;
				
        console.error("error", e.message);
        toast.error('O processo resultou em um erro interno da API');
      }
    },
    [fillResult]
  );

  const pageChanged = useCallback(
    (newPage: number) => {
      search(newPage);
    },
    [search]
  );

  useEffect(() => {
    setTimeout(() => {
      search(1)
    }, 100)
  }, []);

  const visualizarDispensacao = (dispensacaoId: string, dataCompetencia?: Date) => {
    dispensacaoRender(dispensacaoId, dataCompetencia);
  }

  const dispensacaoRender = (id?: string, data?: Date) => {
    setModalOpen(true);

    return (
      setDispensacaoModal(
        <DispensacaoModal
          openned={true}
          setModalOpen={setModalOpen}
          dispensacaoId={id}
          dataCompetencia={data}
        />
      )
    );
  }

  const filtroListagem = (res: string) => {
    clearTimeout(timer!);
    const newTimer = setTimeout(() => {
      search(1, res);
    }, 500);
    setTimer(newTimer);
  }

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <Fade in timeout={900} mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${classes.color}`}>
          <PageHeader
            showSearchableBar
            showBotaoFiltrar
            showMenu
            titulo='Dispensações'
            filtroListagem={res => filtroListagem(res)} />
          <>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />

            <div className={`flex-column h-100 mb-20 overflow-auto ${classes.listPadding}`}>

              {!queryStatus.list.length &&
                <Typography variant="h3" className={classes.semRegistroStyle}>
                  Nenhuma dispensação foi encontrada.
                </Typography>
              }

              <div className={`flex-column h-100 w-100 ${classes.listContainer}`}>
                {queryStatus.list.length > 0 && queryStatus.list.map((item, index) => (
                  <DefaultCard key={index} className={classesCard.cardContainer} onClick={() => visualizarDispensacao(item?.receita?.id, item?.receita?.data)}>
                    <div className={`flex-row w-100 h-100`}>
                      <div className={classNames(classesCard.tagStatus, classes.statusColor)} />

                      <div className={classesCard.infoTagContainer}>
                        <Typography className={classesCard.infoTagStyle}>
                          D
                        </Typography>
                      </div>

                      <div className={`flex-row w-100 ${classesCard.infoContainer}`}>
                        <div className={`flex-column ${classesCard.inputMinWidth}`}>
                          <Typography className={classesCard.labelFont}>
                            Comprador:
                          </Typography>
                          <Typography className={classesCard.contentFont}>
                            {item?.receita?.comprador ?? "Comprador não informado"}
                          </Typography>
                        </div>
                        <div className={`flex-column ${classesCard.inputMinWidth}`}>
                          <Typography className={classesCard.labelFont}>
                            Receita:
                          </Typography>
                          <Typography className={classesCard.contentFont}>
                            {item?.receita?.dataReceitaFormatada}
                          </Typography>
                        </div>
                        <div className={`flex-column ${classesCard.inputMinWidth}`}>
                          <Typography className={classesCard.labelFont}>
                            N°:
                          </Typography>
                          <Typography className={classesCard.contentFont}>
                            {item?.receita?.numero}
                          </Typography>
                        </div>
                        <div className={`flex-column ${classesCard.inputMinWidth}`}>
                          <Typography className={classesCard.labelFont}>
                            Data:
                          </Typography>
                          <Typography className={classesCard.contentFont}>
                            {item?.receita?.dataFormatada}
                          </Typography>
                        </div>
                      </div>

                      <Tooltip arrow title="Vizualizar Dispensação">
                        <div className={classesCard.cardRightButton}>
                          <ProcurarIcon tipo="BUTTON" onClick={() => visualizarDispensacao(item?.receita?.id, item?.receita?.data)} />
                        </div>
                      </Tooltip>
                    </div>
                  </DefaultCard>
                ))}
              </div>

              {modalOpen && (dispensacaoModal)}

              <div className={`flex-row w-100 ${classNames(classes.buttonContainer, classes.buttonPosition)}`}>
                <div className={classNames(classes.buttonStyle, classes.darkButton, classes.buttonAlign)}>
                  <Button onClick={() => dispensacaoRender()} variant="text" className="h-100 w-100">
                    <div className={`flex-row ${classes.buttonLabel} ${classes.whiteLabel}`}>
                      <NovoIcon class={classes.iconSize} />
                    </div>
                  </Button>
                </div>
              </div>
            </div>

          </>
        </div>
      </Fade>
    </>
  );

});