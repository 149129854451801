import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    formContainer: {
        flexGrow: 1
    },

    card: {
        width: '98%',
        alignSelf: 'center',
        padding: '2%',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
            alignItens: 'center',
        }
    },

    formPadding: {
        flexGrow: 1,
        padding: '1rem 0.5rem'
    },

    defaultContainer: {
        position: "relative",
        overflowY: "hidden",
        overflowX: "hidden",
        backgroundColor: theme.palette.grey[100],
    },

    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },

    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },

    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },

    inputContainer: {
        padding: '1.2rem 0.5rem',
    },

    dataPeriodoContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        width: '100%', 
        gap: '5%'
    },

    inputStyle: {
        borderRadius: '10px',
    },

    innerFormContainer: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            alignSelf: 'center'
        }
    },

    buttonContainer: {
        gap: '5%',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            alignSelf: 'center'
        }
    },

    buttonStyle: {
        flexGrow: 1,
        minWidth: '47%',
        borderRadius: '10px',
        alignContent: 'center',
    },
}))

export const relatorioDisplay = {
    display: "none"
}