import { Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components";
import { MovimentacaoDispensacaoResponse } from "../../models/envio-movimentacao.model";
import { useStyles } from "../envio-movimentacao-listagem-styles";

interface MovimentacaoDispensacaoListaProps {
  listaDispensacao: MovimentacaoDispensacaoResponse[];
}

export const MovimentacaoDispensacaoLista: React.FC<MovimentacaoDispensacaoListaProps> = ({ listaDispensacao }) => {

  const styles = useStyles();
  const classesCard = useDefaultCardStyles();

  return (
    <div>
      {
        listaDispensacao?.map((row, index) => (

          <div className={`flex-column w-100 ${styles.cardMovimentacaoItem}`} key={index}>

            <DefaultCard className={styles.cardContainer}>

              <div className={`flex-row w-100 h-100`}>
                <div className={classNames(classesCard.tagStatus, styles.statusColor)} />

                <div className={`flex-row w-100 ${classesCard.infoContainer}`}>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Data da Venda:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {moment(row.dataVenda).format("DD/MM/YYYY")}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Data da Receita:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {moment(row.dataReceita).format("DD/MM/YYYY")}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      N° da Receita:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {row.numeroReceita ?? 0}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Paciente/Comprador:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {row.pacienteComprador ?? '--'}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Prescritor:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {row.prescritor ?? '--'}
                    </Typography>
                  </div>

                </div>

              </div>

            </DefaultCard>

          </div>
        ))
      }
    </div>
  )
}