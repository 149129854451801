import moment from "moment";
import { useState } from "react";
import { FormState } from "react-hook-form";
import { KeyValueModel } from "../../../../../model";
import { DefaultCard } from '../../../../components/cards/components';
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { RelatorioLivroRegistroEspecificoModel } from "../models/relatorio-models";
import { useStyles } from "./relatorio-livro-registro-especifico-styles";

interface RelatorioLivroRegistroEspecificoFormProps {
  listaGrupoLista: KeyValueModel[];
  formState: FormState<RelatorioLivroRegistroEspecificoModel>;
  register: any;
  formRelatorioLivroRegistroEspecifico: RelatorioLivroRegistroEspecificoModel;
  setFormRelatorioLivroRegistroEspecifico: React.Dispatch<React.SetStateAction<RelatorioLivroRegistroEspecificoModel>>;
}

export const RelatorioLivroRegistroEspecificoForm = (({
  listaGrupoLista,
  formState,
  register,
  formRelatorioLivroRegistroEspecifico,
  setFormRelatorioLivroRegistroEspecifico
}: RelatorioLivroRegistroEspecificoFormProps) => {
  const styles = useStyles();
  const { errors } = formState;

  const [dataMaximaInicial, setDataMaximaInicial] = useState(moment(new Date()).format("YYYY-MM-DD"));

  return (
    <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
      <DefaultCard className={styles.card}>
        <div className={`flex-row w-100 ${styles.inputContainer}`}>
          <div className={styles.dataPeriodoContainer}>
            <div className="w-50">
              <TextFieldSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Data Inicial"
                value={formRelatorioLivroRegistroEspecifico.dataInicial}
                name="dataInicial"
                required
                maxDate={dataMaximaInicial}
                variant="outlined"
                tipo="DATA"
                size="medium"
                showStartAdornment
                error={Boolean(errors.dataInicial && !formRelatorioLivroRegistroEspecifico.dataInicial)}
                helperText={
                  errors.dataInicial && !formRelatorioLivroRegistroEspecifico.dataInicial
                    ? errors.dataInicial?.message
                    : undefined
                }
                {...register('dataInicial', {
                  onChange: (e: any) => {

                    if (!formRelatorioLivroRegistroEspecifico.dataFinal) {

                      setFormRelatorioLivroRegistroEspecifico({
                        ...formRelatorioLivroRegistroEspecifico,
                        dataInicial: e.target.value,
                        dataFinal: moment(dataMaximaInicial).toDate()
                      })
                      return;
                    }

                    setFormRelatorioLivroRegistroEspecifico({
                      ...formRelatorioLivroRegistroEspecifico, dataInicial: e.target.value
                    })
                  }
                })}
              />
            </div>
            <div className="w-50">
              <TextFieldSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Data Final"
                value={formRelatorioLivroRegistroEspecifico.dataFinal ? new Date(formRelatorioLivroRegistroEspecifico.dataFinal).toISOString().slice(0, 10) : ''}
                name="dataFinal"
                required
                maxDate={moment(new Date()).format("YYYY-MM-DD")}
                minDate={formRelatorioLivroRegistroEspecifico.dataInicial}
                disabled={formRelatorioLivroRegistroEspecifico.dataInicial == null}
                variant="outlined"
                tipo="DATA"
                size="medium"
                showStartAdornment
                error={Boolean(errors.dataFinal && !formRelatorioLivroRegistroEspecifico.dataFinal)}
                helperText={
                  errors.dataFinal && !formRelatorioLivroRegistroEspecifico.dataFinal
                    ? errors.dataFinal?.message
                    : undefined
                }
                {...register('dataFinal', {
                  onChange: (e: any) => {
                    setDataMaximaInicial(moment(e.target.value).format("YYYY-MM-DD"));
                    setFormRelatorioLivroRegistroEspecifico({
                      ...formRelatorioLivroRegistroEspecifico, dataFinal: e.target.value
                    })
                  }
                })}
              />
            </div>
          </div>
        </div>
        <div className={`w-100 ${styles.inputContainer}`}>
          <SelectSaurus label="Lista"
            conteudo={listaGrupoLista}
            className={`h-100 w-100 ${styles.inputStyle}`}
            variant="outlined"
            allowSubmit={true}
            size="medium"
            required
            error={Boolean(errors.tipoRegistro && !formRelatorioLivroRegistroEspecifico.tipoRegistro)}
            helperText={
              errors.tipoRegistro && !formRelatorioLivroRegistroEspecifico.tipoRegistro
                ? errors.tipoRegistro?.message
                : undefined
            }
            {...register('tipoRegistro', {
              onChange: (e: any) => setFormRelatorioLivroRegistroEspecifico({
                ...formRelatorioLivroRegistroEspecifico, tipoRegistro: e.target.value
              })
            })}
          />
        </div>
      </DefaultCard >
    </div >
  );
});