import { EnumDeviceType } from "../model/enums/enum-device-type";

export interface IAmbienteUnico {
  apiUrl: string;
  apiLoginUrl: string;
  apiKdsUrl: string;
  apiPedidosUrl: string;
  apiProdutosUrl: string;
  apiPessoasUrl: string;
  apiMedicamentosUrl: string;
  paymentDevice: EnumDeviceType;
  frontUrl: string;
  facebookAppId: string;
}

export interface IAmbienteCompartilhado {
  isDev: boolean;
  isSlowDevice: boolean;
  label: string;
  version: string;
  environment: string;
  saurusUtilsWebService: string;
  googleAppId: string;
}

export interface IVariaveisAmbiente {
  all: IAmbienteCompartilhado;
  prodWeb: IAmbienteUnico;
  devWeb: IAmbienteUnico;
}

export enum ETypeDomain {
  TouchPharma = 1,
  Login = 2,
  Produtos = 3,
  Pedidos = 4,
  Kds = 5,
  Pessoa = 6,
  Medicamentos = 7
}