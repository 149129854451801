import { FormState } from "react-hook-form";
import { EnumCadastroTipo, KeyValueModel } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { DefaultCard } from '../../../../components/cards/components';
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { RelatorioMensalReceitaB2Model } from "../models/relatorio-models";
import { useStyles } from "./relatorio-relacao-mensal-receita-b2-styles";

interface RMNRB2FormProps {
  listaMeses: KeyValueModel[];
  formState: FormState<RelatorioMensalReceitaB2Model>;
  register: any;
  formRMNRB2: RelatorioMensalReceitaB2Model;
  errorValidationfarmaceutico: boolean;
  setErrorValidationfarmaceutico: React.Dispatch<React.SetStateAction<boolean>>;
  setFormRMNRB2: React.Dispatch<React.SetStateAction<RelatorioMensalReceitaB2Model>>;
}

export const RelatorioRelacaoMensalReceitaB2Form = ({ listaMeses, formState, register, formRMNRB2, setFormRMNRB2, errorValidationfarmaceutico, setErrorValidationfarmaceutico }: RMNRB2FormProps) => {
  const styles = useStyles();
  const { errors } = formState;

  return (
    <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
      <DefaultCard className={styles.card}>
        <div className={`w-100 ${styles.inputContainer}`}>
          <AutocompletePessoas label="Farmacêutico"
            name="farmaceutico"
            size="medium"
            tipo={EnumCadastroTipo.TODOS}
            onChange={(e: any) => setFormRMNRB2({ ...formRMNRB2, farmaceutico: e?.value?.id })}
            error={Boolean(errorValidationfarmaceutico && !formRMNRB2.farmaceutico)}
            helperText={
              errorValidationfarmaceutico && !formRMNRB2.farmaceutico
                ? "O Farmacêutico é obrigatório!"
                : undefined
            }
            onBlur={() => {
              if (!formRMNRB2.farmaceutico)
                setErrorValidationfarmaceutico(true);
            }}
          />
        </div>

        <div className={`flex-row w-100 ${styles.inputContainer}`}>
          <div className={styles.dataPeriodoContainer}>
            <div className="w-50">
              <SelectSaurus label="Mês"
                conteudo={listaMeses}
                className={`h-100 w-100 ${styles.inputStyle}`}
                variant="outlined"
                size="medium"
                allowSubmit
                required
                error={Boolean(errors.mes && !formRMNRB2.mes)}
                helperText={
                  errors.mes && !formRMNRB2.mes
                    ? errors.mes?.message
                    : undefined
                }
                {...register('mes', {
                  onChange: (e: any) => setFormRMNRB2({ ...formRMNRB2, mes: e.target.value })
                })}
              />
            </div>

            <div className="w-50">
              <TextFieldSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                labelCustom="Ano"
                value={formRMNRB2.ano}
                name="ano"
                required
                tipo="NUMERO"
                size="medium"
                variant="outlined"
                inputProps={{ maxLength: 4 }}
                error={Boolean(errors.ano && !formRMNRB2.ano)}
                helperText={
                  errors.ano && !formRMNRB2.ano
                    ? errors.ano?.message
                    : undefined
                }
                {...register('ano', {
                  onChange: (e: any) => setFormRMNRB2({ ...formRMNRB2, ano: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 })
                })}
              />
            </div>
          </div>
        </div>
      </DefaultCard>
    </div>
  );
} 