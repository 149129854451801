import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";
import { EnumCadastroTipo } from "../../../../model";

export function useAutocompletePessoas() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getPessoas = useCallback((tipoCadastro: EnumCadastroTipo, filtro: string) => {
    return invocarApi({
      url: `Pessoa`,
      method: "GET",
      enviarTokenUsuario: true,
      apiVersion: "v4",
      typeDomain: ETypeDomain.Pessoa,
      params: {
        "TpCadastro": tipoCadastro,
        "Generico": filtro,
      }
    });
  },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getPessoas,
  };
}