import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";

export const useGetAutorizacaoById = () => {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getAutorizacaoById = useCallback(
    (operacaoPendenteId: string) => {
      return invocarApi(
        {
          url: `OperacaoPendente/${operacaoPendenteId}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );

  const getMedicamentoSemEstoque = useCallback(
    (operacaoPendenteId: string) => {
      return invocarApi(
        {
          url: `OperacaoPendente/medicamento-lote-sem-estoque/${operacaoPendenteId}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    carregando,
    getAutorizacaoById,
    getMedicamentoSemEstoque
  };
}