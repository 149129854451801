import { DialogAutorizacaoOpcoesControle } from "../views/components/dialog/dialog-autorizacao-opcoes/dialog-autorizacao-opcoes-controle";
import { DialogGerenciarMedicamentoControle } from "../views/components/dialog/dialog-dispensacao/dialog-gerenciar-medicamento-controle";
import { DialogFinalizacaoOperacaoOpcoesControle } from "../views/components/dialog/dialog-finalizar-operacao/dialog-finalizacao-operacao-opcoes-controle";
import { DialogInventarioMedicamentoOpcoesControle } from "../views/components/dialog/dialog-inventario-medicamento-opcoes/dialog-inventario-medicamento-opcoes-controle";
import { DialogPerdaLoteViewControle } from "../views/components/dialog/dialog-perda-lote-view/dialog-perda-lote-view-controle";

export const RenderModais = () => {

  return (
    <>
      <DialogPerdaLoteViewControle />
      <DialogAutorizacaoOpcoesControle />
      <DialogGerenciarMedicamentoControle />
      <DialogFinalizacaoOperacaoOpcoesControle />
      <DialogInventarioMedicamentoOpcoesControle />
    </>
  );
};