import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { ETypeDomain } from "../../../../config/types";
import { RelatorioLivroRegistroEspecificoModel } from "../../../../views/pages/private/relatorios/models/relatorio-models";
import { useApiBase } from "../../base/api-base";

export function useGerarRelatorioLivroRegistro() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const gerarRelatorioLivroRegistro = useCallback(
    (filtros: RelatorioLivroRegistroEspecificoModel) => {
      return invocarApi({
        url: `Relatorio/relatorio-livro-registro`,
        method: "POST",
        enviarTokenUsuario: true,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(filtros),
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    gerarRelatorioLivroRegistro,
  };

}