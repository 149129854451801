import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Fade } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CircularLoading } from "../../../..";
import NotaFiscalIcon from "../../../../../assets/img/icon-nota-fiscal.svg";
import { useGerarRelatorioLivroRegistro } from "../../../../../data/api/gestao/relatorios/gerar-relatorio-livro-registro";
import { KeyValueModel } from "../../../../../model";
import { useToastSaurus } from "../../../../../services/app";
import { useEmpresaAtualHandler } from "../../../../../services/app/use-cases/empresa-atual-handler";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import { EGrupoLista, EGrupoListaMap, RelatorioLivroRegistroEspecificoModel } from "../models/relatorio-models";
import { RelatorioLivroRegistroEspecificoForm } from "./relatorio-livro-registro-especifico-form";
import { relatorioDisplay, useStyles } from "./relatorio-livro-registro-especifico-styles";

export const RelatorioLivroRegistroEspecifico = () => {
    const [formRelatorioLivroRegistroEspecifico, setFormRelatorioLivroRegistroEspecifico] = useState({} as RelatorioLivroRegistroEspecificoModel);
    const [relatorioLivroRegistroGerado, setRelatorioLivroRegistroGerado] = useState("");
    const styles = useStyles();

    const { gerarRelatorioLivroRegistro, carregando } = useGerarRelatorioLivroRegistro();
    const { getEmpresaAtualEndereco } = useEmpresaAtualHandler();
    const { showToast } = useToastSaurus();

    const listaGrupoLista = Object.values(EGrupoLista)
        .filter(e => !isNaN(Number(e))).map(i => i as EGrupoLista)
        .map((tipo: EGrupoLista) => new KeyValueModel(tipo, EGrupoListaMap.get(tipo)));

    const schema = yup
        .object()
        .shape({
            dataInicial: yup.date().required("A Data Inicial é obrigatória!"),
            dataFinal: yup.date().required("A Data Final é obrigatória!"),
            tipoRegistro: yup.string().required("O Tipo de Lista é obrigatório!"),
        });

    const { register, formState, setError } = useForm<RelatorioLivroRegistroEspecificoModel>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const handleEmpresaEndereco = useCallback(async (form: RelatorioLivroRegistroEspecificoModel) => {
        let enderecoEmpresa = await getEmpresaAtualEndereco();

        if (enderecoEmpresa === undefined)
            return;

        setFormRelatorioLivroRegistroEspecifico({ ...form, enderecoEmpresa });
    }, [getEmpresaAtualEndereco]);

    useEffect(() => {
        if (
            formRelatorioLivroRegistroEspecifico === undefined ||
            formRelatorioLivroRegistroEspecifico?.enderecoEmpresa !== undefined ||
            getEmpresaAtualEndereco === undefined
        ) return;

        handleEmpresaEndereco(formRelatorioLivroRegistroEspecifico);
    }, [formRelatorioLivroRegistroEspecifico, getEmpresaAtualEndereco, handleEmpresaEndereco]);

    const gerarRelatorio = async () => {
        validarCampos();

        if (!await schema.isValid(formRelatorioLivroRegistroEspecifico))
            return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

        try {
            let res = await gerarRelatorioLivroRegistro(formRelatorioLivroRegistroEspecifico);

            if (res.erro)
                throw res.erro;

            if (!res.resultado?.data)
                return;

            setRelatorioLivroRegistroGerado(res.resultado?.data);
        } catch (e: any) {
            if (axios.isCancel(e))
                return;

            showToast('error', e.message);
        }
    };

    const validarCampos = async () => {
        try {
            await schema.validate({
                dataInicial: formRelatorioLivroRegistroEspecifico.dataInicial,
                dataFinal: formRelatorioLivroRegistroEspecifico.dataFinal,
                tipoRegistro: formRelatorioLivroRegistroEspecifico.tipoRegistro
            }, { abortEarly: false });
        } catch (error) {

            const validationError = error as yup.ValidationError;

            validationError?.inner?.forEach(err => {
                const path = err.path?.toString();

                if (path && Object.prototype.hasOwnProperty.call(schema.fields, path))
                    setErrorField(path, err.message);

            });

            return false;
        }
    }

    function setErrorField(path: string, errorMensage: string) {

        switch (path) {

            case 'dataInicial':
                setError('dataInicial', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'dataFinal':
                setError('dataFinal', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'tipoRegistro':
                setError('tipoRegistro', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            default:
                break;
        }

    }

    useEffect(() => {
        if (relatorioLivroRegistroGerado.length)
            baixarRelatorio();
    }, [relatorioLivroRegistroGerado]);

    const baixarRelatorio = () => {
        let link = document.getElementById('relatorioLink');
        link?.click();
    };

    return (
        <>
            <Fade in mountOnEnter unmountOnExit>
                <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>
                    <PageHeader showBotaoVoltar titulo='Livro de Registro Específico' />

                    <div className={`flex-column w-100 ${styles.formPadding}`}>

                        <div className={`flex-column ${styles.formContainer}`}>
                            <div className={`flex-column w-100 h-100`}>

                                <RelatorioLivroRegistroEspecificoForm
                                    register={register}
                                    formState={formState}
                                    listaGrupoLista={listaGrupoLista}
                                    formRelatorioLivroRegistroEspecifico={formRelatorioLivroRegistroEspecifico}
                                    setFormRelatorioLivroRegistroEspecifico={setFormRelatorioLivroRegistroEspecifico}
                                />
                            </div>
                        </div>

                        <a id="relatorioLink" href={relatorioLivroRegistroGerado} target="_blank" style={relatorioDisplay} />
                        <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                <Button onClick={() => !carregando ? gerarRelatorio() : {}} variant="text" className="h-100 w-100">
                                    {carregando ? <CircularLoading tipo="FULLSIZED" /> :
                                        <>
                                            <img src={NotaFiscalIcon} alt="" style={{ marginRight: '2%' }} />
                                            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                                Gerar Relatório
                                            </div>
                                        </>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </>

    );
}