import { Dispatch, SetStateAction } from "react";
import { DefaultModal } from "../../../../../components/default-modal";
import { TransferenciaAvulsaCadastroPage } from "../../componentes/cadastro-avulso/transferencia-avulsa-cadastro";
import { TransferenciaCadastroPage } from "../../componentes/cadastro/transferencias-cadastro";

interface TransferenciaModalProps {
    openned?: boolean;
    operacaoId?: string;
    transferenciaId?: string;
    dataCompetencia?: Date;

    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const TransferenciaModal = (props: TransferenciaModalProps) => {

    const fecharModal = () => props.setModalOpen(false);

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned}
            variant={"temporary"}
            anchor="right"
        >
            {
                (props.operacaoId || props.transferenciaId) ?
                    <TransferenciaCadastroPage
                        transferenciaId={props.transferenciaId}
                        operacaoId={props.operacaoId}
                        dataCompetencia={props.dataCompetencia}
                        fecharModal={() => fecharModal()}
                    /> : <TransferenciaAvulsaCadastroPage fecharModal={() => fecharModal()} />
            }
        </DefaultModal>
    );
};
