import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { ConfiguracoesModel } from '../../../../model/api/configuracoes/configuracoes-models';
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from '../../../../config/types';

export function usePutConfiguracoes() {
    const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

    const putConfiguracoes = useCallback(
        (model: ConfiguracoesModel) => {
            return invocarApi({
                url: `Configuracoes`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(model),
                baseURL: VariaveisAmbiente.apiUrl,
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.TouchPharma,
                apiVersion: 'v1'
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        carregando,
        putConfiguracoes,
    };
}
