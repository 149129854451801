
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    color: {
        backgroundColor: theme.palette.grey[100],
    },
    root: {
        width: "100%",
        height: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        background: theme.palette.common.white,
        [theme.breakpoints.up("md")]: {
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: theme.palette.grey[300],
            background: theme.palette.grey[50],
        }
    },
    cardAutorizacaoItem: {
        height: '10%',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            height: '20%',
        }
    },
    statusColor: {
        backgroundColor: theme.palette.info.main
    },
    cardComprasFornecedor: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between"
    },
    cardPadding: {
        padding: '0.25rem 0.5rem',
    },
    labelFont: {
        fontWeight: 700,
        fontSize: "0.6rem",
        color: theme.palette.common.black,
        paddingRight: '0.25rem',
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },
    contentFont: {
        fontSize: "0.6rem",
        color: theme.palette.secondary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },
    operacaoTipoContainer: {
        justifyContent: 'center'
    },

    operacaoTipoCard: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
        width: '1.5rem',
        height: '1.5rem',
        padding: '0.3rem',
        paddingTop: '0.4rem',
        borderRadius: '3px',
        alignItems: 'center',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main,
    },

    titleStyle: {
        paddingLeft: '1rem',
        fontSize: '1.3rem',
        fontWeight: 500,
    },

    breakLine: {
        height: '2px',
        marginBottom: '1vh',
        backgroundColor: theme.palette.divider,
    },

    semRegistroStyle: {
        textAlign: 'center',
        margin: '1rem 0',
        fontSize: '1rem'
    },

    contentContainer: {
        flexGrow: 1,
        alignItems: "center",
        marginBottom: "3vh",
    },

    listPadding: {
        padding: '0 4%',
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            padding: '0 1%'
        }
    },

    listContainer: {
        justifyContent: 'start',
        gap: '1%'
    },

    statusBarContainer: {
        display: 'flex',
        alignItems: 'center'
    },

    statusBarStyle: {
        width: '0.3rem',
        height: '90%',
        margin: '0 0.5rem',
        borderRadius: '12px',
        backgroundColor: theme.palette.info.main,
    },

    infoContainer: {
        margin: '0.3rem 0.5rem',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexGrow: 1,
    },

    optionsButtonContainer: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        paddingRight: '0.5rem',
        alignSelf: 'center'
    },

    optionsButtonStyle: {
        padding: '0.15rem',
        color: theme.palette.common.black
    },

    iconMoreOptions: {
        height: '1.63rem',
        width: '1.63rem',
        color: theme.palette.common.black,
        cursor: 'pointer',
    },

    buttonStyle: {
        flexGrow: 1,
        minWidth: '47%',
        borderRadius: '50%',
    },

    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },

    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },
    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },

    iconSize: {
        width: '2rem',
        [theme.breakpoints.up('md')]: {
            width: '2rem'
        }
    },

    buttonContainer: {
        display: 'flex',
        padding: '0.2rem',
        justifyContent: 'center',
        width: '4rem',
        height: '3.8rem',
        alignSelf: 'self-end'
    },

    buttonPosition: {
        position: 'absolute',
        bottom: '10px'
    },

    buttonAlign: {
        display: 'flex',
        placeContent: 'center'
    },

    formPadding: {
        flexGrow: 1,
        padding: '1rem 0.5rem'
    },
}));