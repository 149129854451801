import { Button, Typography } from '@material-ui/core';
import { useThemeQueries } from '../../..';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-operacao-pendente-autorizacao-style';

interface DialogOperacaoPendenteAutorizacaoProps {
  nao: () => any;
  sim: () => any;
  fecharModal: () => any;
}

export const DialogOperacaoPendenteAutorizacao = ({
  nao,
  sim,
  fecharModal
}: DialogOperacaoPendenteAutorizacaoProps) => {
  const { md, isMobile, lg, xl } = useThemeQueries();
  const styles = useStyles();

  return (
    <DialogSaurus
      isPopUp={true}
      tamanho="xs"
      titulo="Operação Pendente de Autorização "
      subTitulo="Existe Operação Pendente de Autorização. Deseja realmente iniciar o inventário?"
      cardHeight={isMobile ? 46 : lg ? 43 : xl ? 30 : 50}
      cardWidth={isMobile ? 70 : 25}
      isButtonTitleClose={false}
      aberto={true}
      handleClickClose={fecharModal}
      carregando={false}
      fullScreen={false}
    >
      <div className={`flex-column h-100 w-100 ${styles.dialogContainer}`}>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={() => sim()} className='w-100'>
            <Typography className={styles.buttonFont}>
              Sim
            </Typography>
          </Button>
        </div>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={() => nao()} className='w-100'>
            <Typography className={styles.buttonFont} color='error'>
              Não
            </Typography>
          </Button>
        </div>
      </div>
    </DialogSaurus >
  );
};