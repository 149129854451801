import { createStyles, makeStyles, StepConnector, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[300],
      zIndex: 1,
      color: theme.palette.common.white,
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    labelConainer: {
      '& .MuiStepLabel-label': {
        margin: 0,
      },
    },
    labelStyle: {
      fontSize: '0.65rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.9rem',
      },
      marginTop: '0.3rem',
      marginBottom: '0.3rem',
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundColor: theme.palette.primary.main,
    },
    wizardContainer: {
      padding: 0,
    },
  }),
);

export const ConnectorStyle = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
  },
}))(StepConnector);