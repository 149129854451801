import { Button } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useThemeQueries } from "../../../../..";
import ConfirmaIcon from "../../../../../../assets/img/icon-confirma.svg";
import { useToastSaurus } from "../../../../../../services/app";
import { DefaultCard } from "../../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { ComprasMedicamentoNovoLoteCardProp, NovoLote } from "../models/compras-cadastro.model";
import { useStyles } from "./compras-medicamento-lotes-style";


export const ComprasMedicamentoNovoLoteCard = (({ quantidadeRestante, adicionarLote }: ComprasMedicamentoNovoLoteCardProp) => {
  const styles = useStyles();
  const theme = useThemeQueries();
  const { showToast } = useToastSaurus();
  const [novoLote, setNovoLote] = useState<NovoLote>(new NovoLote());

  const adicionar = () => {
    if (!novoLote.lote)
      return showToast('error', 'Lote inválido!');
    if (!novoLote.dataValidade)
      return showToast('error', 'Data de Validade inválida!');
    if (!novoLote.quantidade)
      return showToast('error', 'Quantidade inválida!');

    adicionarLote(novoLote);
    setNovoLote(new NovoLote());
  }

  return (
    <div className={`w-100 ${styles.cardLimit}`}>
      <DefaultCard>
        <div className={classNames(styles.cardStyle, theme.sm ? 'flex-column' : 'flex-row')}>

          <div className={`flex-row w-100 ${styles.infoContainer}`}>
            <div className={styles.inputLote}>
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                value={novoLote.lote}
                labelCustom="Lote"
                variant="outlined"
                size="small"
                onChange={(e: any) =>
                  setNovoLote({ ...novoLote, lote: e.target.value })
                }
              />
            </div>
            <div className={styles.inputDatasLote}>
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                value={novoLote.dataFabricacao}
                labelCustom="Fabricação"
                variant="outlined"
                size="small"
                tipo="DATA"
                onChange={(e: any) =>
                  setNovoLote({ ...novoLote, dataFabricacao: e.target.value })
                }
              />
            </div>
            <div className={styles.inputDatasLote}>
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                value={novoLote.dataValidade}
                labelCustom="Validade"
                variant="outlined"
                size="small"
                tipo="DATA"
                onChange={(e: any) =>
                  setNovoLote({ ...novoLote, dataValidade: e.target.value })
                }
              />
            </div>
            <div className={styles.inputQtdLote}>
              <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                value={novoLote.quantidade ?? 0}
                labelCustom="Qtd"
                variant="outlined"
                size="small"
                tipo="NUMERO"
                max={quantidadeRestante}
                onChange={(e: any) =>
                  setNovoLote({
                    ...novoLote,
                    quantidade: e.target.value > quantidadeRestante
                      ? quantidadeRestante
                      : e.target.value
                  })
                }
              />
            </div>
          </div>

          <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
            <Button onClick={adicionar} variant="text" className="w-100">
              <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                <img className={styles.buttonIcon} src={ConfirmaIcon} alt="" />
              </div>
            </Button>
          </div>
        </div>
      </DefaultCard>
    </div>
  );
});