import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    color: {
        backgroundColor: theme.palette.grey[100],
    },

    cardContainer: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '2%',
        gap: '3%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'start',
        }
    },

    cardMedicamentoItem: {
        marginTop: '1rem',
        width: '10vw',
        height: '8vw',
        [theme.breakpoints.down('sm')]: {
            width: '30vw',
            height: '30vw'
        }
    },

    iconeContainer: {
        display: 'grid',
        width: '100%',
        height: '100%',
        alignItems: 'normal'
    },

    icone: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
        paddingTop: '5%',
        alignSelf: 'center',
    },

    tituloRelatorio: {
        fontSize: '10px',
        fontWeight: 'bolder',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));