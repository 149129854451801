import { MenuItem, Typography } from "@material-ui/core";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { EMotivoPerda, EMotivoPerdaMap } from "../../perdas/models/perdas-models";
import { PerdaAvulsaStepsProp } from "../models/perda-avulsa-models";
import { useStyles } from "../perda-avulsa-cadastro-style";

export const PerdaAvulsaMovimentacaoForm = (({ formState, register, perda, setPerda }: PerdaAvulsaStepsProp) => {
    const styles = useStyles();
    const { errors } = formState;

    const listaMotivoPerda = Object.values(EMotivoPerda)
        .filter(e => !isNaN(Number(e))).map(i => i as EMotivoPerda);

    return (
        <>
            <div className={`flex-column ${styles.formContainer}`}>
                <div className={`flex-column w-100 h-100`}>
                    <div className={`flex-column ${styles.titleContainer}`}>
                        <Typography className={styles.titleStyle} variant="h6">Dados da Movimentação</Typography>
                        <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados da Perda</Typography>
                    </div>

                    <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
                        <div className={`w-100 ${styles.inputContainer}`}>
                            <TextFieldSaurus
                                className={`h-100 w-100 ${styles.inputStyle}`}
                                labelCustom="Motivo da Perda"
                                key={perda.motivoPerda}
                                defaultValue={perda.motivoPerda}
                                value={perda.motivoPerda}
                                variant="outlined"
                                size="small"
                                select
                                required={true}
                                error={Boolean(errors.motivoPerda && !perda.motivoPerda)}
                                helperText={
                                    errors.motivoPerda && !perda.motivoPerda
                                        ? errors.motivoPerda?.message
                                        : undefined
                                }
                                {...register('motivoPerda', {
                                    onChange: (e: any) => setPerda({ ...perda, motivoPerda: e.target.value })
                                })}
                            >
                                {
                                    listaMotivoPerda?.map(value => (
                                        <MenuItem className={styles.inputStyle} key={value} value={value}>
                                            {EMotivoPerdaMap.get(value)}
                                        </MenuItem>
                                    ))
                                }
                            </TextFieldSaurus>
                        </div>

                        <div className={`flex-row w-100 ${styles.inputContainer}`}>
                            <div className="w-50">
                                <TextFieldSaurus
                                    className={`h-100 w-100 ${styles.inputStyle}`}
                                    labelCustom="Data de Lançamento"
                                    value={perda.data}
                                    tipo="DATA"
                                    size="small"
                                    variant="outlined"
                                    showStartAdornment={true}
                                    required={true}
                                    error={Boolean(errors.data && !perda.data)}
                                    helperText={
                                        errors.data && !perda.data
                                            ? errors.data?.message
                                            : undefined
                                    }
                                    {...register('data', {
                                        onChange: (e: any) => setPerda({ ...perda, data: e.target.value })
                                    })}
                                />
                            </div>
                            <div className="w-50">
                                <TextFieldSaurus
                                    className={`h-100 w-100 ${styles.inputStyle}`}
                                    labelCustom="Hora de Lançamento"
                                    value={perda.hora}
                                    tipo="HORA"
                                    size="small"
                                    variant="outlined"
                                    error={Boolean(errors.hora && !perda.hora)}
                                    required={true}
                                    helperText={
                                        errors.hora && !perda.hora
                                            ? errors.hora?.message
                                            : undefined
                                    }
                                    {...register('hora', {
                                        onChange: (e: any) => setPerda({ ...perda, hora: e.target.value })
                                    })}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
});