import { TextField, TextFieldProps } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useCallback, useState } from "react";
import { KeyValueModel } from "../../../../../model";
import { useStyles } from "./select-saurus-styles";
export type SelectSaurusProps = TextFieldProps & {
  conteudo: KeyValueModel[];
  allowSubmit?: boolean;
  labelCustom?: string;
};

export const SelectSaurus = ({ conteudo, allowSubmit, labelCustom, ...props }: SelectSaurusProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const onKeyDownWrapper = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        if (open) {
          const input = event.currentTarget.parentElement.getElementsByTagName("input")[0];

          const form = input.form;
          if (form) {
            const index = Array.prototype.indexOf.call(form?.querySelectorAll("input,select,button"), input);

            const focusableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

            event.currentTarget.className = event.currentTarget.className.replace("Mui-focused", "");

            if (index === form?.querySelectorAll(focusableElements)) {
              setTimeout(() => {
                form.querySelectorAll(focusableElements)[0].focus();
              }, 10);
            } else {
              setTimeout(() => {
                let inputTarget = form.querySelectorAll(focusableElements)[index + 1];
                if (inputTarget === undefined || inputTarget === null) {
                  inputTarget = form.querySelectorAll(focusableElements)[index];
                }

                if (inputTarget?.type === "submit" && allowSubmit) {
                  inputTarget?.click();
                } else {
                  inputTarget?.focus();
                }
              }, 10);
            }
          }
        }
      }
    },
    [open, allowSubmit]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const selecionado = conteudo.find(x => x.Key === props.value);
  return (
    <TextField
      select
      SelectProps={{
        value: props.value,
        open: open,
        onClose: handleClose,
        onOpen: handleOpen,
        className: props.className,
        style: props.style
      }}
      onKeyDown={onKeyDownWrapper}
      label={
        <span style={{
          fontSize: '2rem'
        }}>
          {labelCustom}
        </span>
      }
      inputProps={{ MenuProps: { disableScrollLock: true } }}
      {...props}
      value={selecionado?.Value || ''}
      style={{ position: 'relative' }}
    >
      {conteudo.map((item) => {
        return (
          <MenuItem className={classes.menuItem} key={item.Key} value={item.Key}>
            {item.Value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
