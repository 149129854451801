import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { makeUseAxios, Options } from "axios-hooks";
import { merge } from "lodash";
import LRU from "lru-cache";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { VariaveisAmbiente } from "../../../config";
import { ETypeDomain } from "../../../config/types";
import { RetornoApiModel } from "../../../model";
import { SngpcStorageKeys, useSngpcStorage } from "../../../services/app";
import { useSessaoAtual } from '../../../services/app/providers/sessao-atual-provider/sessao-atual-provider';
import { consoleDev } from "../../../utils/console-dev";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
  tokenUsuario?: string | null;
  typeDomain: ETypeDomain;
  apiVersion: string;
};

const defaultTimeout = 30 * 1000; /* 30 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O tempo da requisição foi excedido. Verifique sua conexão com internet e tente novamente.",
  }),
  cache: new LRU({ max: 10 }),
});


export function useApiBase(props?: ApiBaseProps) {

  const {
    getEmpresaSelecionada,
    refreshUser,
    deslogar,
    converterToken,
    validaUsuarioConectado } = useSessaoAtual();
  const history = useHistory();

  const { getRegistro } = useSngpcStorage();

  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes)
  );

  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const getUrlEndpointBase = (typeDomain: ETypeDomain, apiVersion: string) => {

    switch (typeDomain) {
      case ETypeDomain.TouchPharma:
        return `${VariaveisAmbiente.apiUrl}/api/${apiVersion}/empresa/${getEmpresaSelecionada()?.Id}`;
      case ETypeDomain.Login:
        return `${VariaveisAmbiente.apiLoginUrl}/api/${apiVersion}`;
      case ETypeDomain.Produtos:
        return `${VariaveisAmbiente.apiProdutosUrl}/api/${apiVersion}/empresa/${getEmpresaSelecionada()?.Id}`;
      case ETypeDomain.Pedidos:
        return `${VariaveisAmbiente.apiPedidosUrl}/api`;
      case ETypeDomain.Kds:
        return `${VariaveisAmbiente.apiKdsUrl}/api`;
      case ETypeDomain.Pessoa:
        return `${VariaveisAmbiente.apiPessoasUrl}/api/${apiVersion}`;
      case ETypeDomain.Medicamentos:
        return `${VariaveisAmbiente.apiMedicamentosUrl}/api/${apiVersion}`;
      default:
        return `${VariaveisAmbiente.apiUrl}/api/${apiVersion}/empresa/${getEmpresaSelecionada()?.Id}`;
    }
  }

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel> => {
    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;

    resultado = undefined;
    erro = undefined;

    const tokenStorage = getRegistro(SngpcStorageKeys.TokenTouchPharma, false);
    const userToken = converterToken(tokenStorage);
    try {
      var headers = {
        'FrontUrl': VariaveisAmbiente.frontUrl,
        ...cfg?.headers,
        'Domain': 'app.touchone.com.br'
      }

      if (tokenStorage) {
        headers['Authorization'] = `Bearer ${tokenStorage}`;
      }

      resultado = await invocarAxios(
        {
          method: cfg?.method,
          headers: headers,
          data: cfg?.data,
          baseURL: `${getUrlEndpointBase(cfg?.typeDomain!, cfg?.apiVersion!)}/${cfg?.url}`,
          params: cfg?.params
        },
        options
      );
      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

    } catch (e: Error | any) {
      if (VariaveisAmbiente.isDev) consoleDev(e);
      erro = e;

      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (!e.response?.data?.errors?.Mensagens) {
            if (e.response?.data?.errors?.Mensagens?.length > 1) {
              const erroFormatado = e.response?.data?.errors?.Mensagens.join('. ');
              erro = new Error(erroFormatado);
            }
          } else {
            erro = new Error(e.response?.data?.errors?.Mensagens[0]);
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }

      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";
          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          else if (retStatus === 401) {
            if (cfg?.enviarTokenUsuario) {
              // TRATAMENTO DO REFRESHTOKEN AUTOMÁTICO
              if (validaUsuarioConectado() && tentarNovamente) {
                const retRefresh = await refreshUser(userToken!.originalToken);
                if (retRefresh) {
                  return await invocarApi(cfg, options);
                }
                else {
                  erro = new Error(
                    `Ocorreu um problema ao executar novamente. Realize o login novamente.`
                  );
                  deslogar(userToken?.usuarioId || '');
                  history.push('/login/expirou');
                }
              } else {
                erro = new Error(
                  `Sua sessão expirou. Realize o login novamente para continuar. (401)`
                );
                deslogar(userToken?.usuarioId || '');
                history.push('/login/expirou');
              }

            }
          }
          else if (msg.toLowerCase().indexOf("network error") > -1) {
            erro = new Error(
              `Não foi possível conectar ao servidor. Verifique sua conexão com internet e tente novamente.` +
              (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            );
          }
          break;
        case EnumRetornoApiBase.Api:

          if (retStatus === 400) {

            if (e.response?.data?.erro) {
              erro = new Error(
                e.response?.data?.erro
              );
            }
          }

          if (retStatus === 401) {
            if (cfg?.enviarTokenUsuario) {
              // TRATAMENTO DO REFRESHTOKEN AUTOMÁTICO
              if (validaUsuarioConectado() && tentarNovamente) {
                const retRefresh = await refreshUser(userToken!.originalToken);
                if (retRefresh) {
                  return await invocarApi(cfg, options);
                }
                else {
                  erro = new Error(
                    `Ocorreu um problema ao executar novamente. Realize o login novamente.`
                  );
                  deslogar(userToken?.usuarioId || '');
                  history.push('/login/expirou');
                }
              } else {
                erro = new Error(
                  `Sua sessão expirou. Realize o login novamente para continuar. (401)`
                );
                deslogar(userToken?.usuarioId || '');
                history.push('/login/expirou');
              }

            }
          }
          if (retStatus === 402) {
            resultado = e.response
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }

      resultado = e.response
    }

    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
    };
  },
    [getRegistro, converterToken, invocarAxios, validaUsuarioConectado, refreshUser, deslogar, history]
  );

  return {
    invocarApi,
    carregando: carregandoInterno
  };
}
