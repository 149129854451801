import { useCallback } from "react";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools";

export const useAutorizacaoOpcoes = () => {
  const { callEvent } = useEventTools();

  const abrirAutorizacaoOpcoes = useCallback((
    operacaoId: string,
    operacaoAptaAutorizar: boolean,
    aprovar: (operacaoId: string) => any,
    reprovar: (operacaoId: string) => any,
    navegar: () => void
  ) => {
    callEvent(AppEventEnum.AutorizacaoOpcoes, { aberto: true, operacaoId, operacaoAptaAutorizar, aprovar, reprovar, navegar });
  },
    [callEvent]
  );

  const fecharAutorizacaoOpcoes = useCallback(
    () => {
      callEvent(AppEventEnum.AutorizacaoOpcoes, { aberto: false });
    },
    [callEvent]
  );

  return {
    abrirAutorizacaoOpcoes,
    fecharAutorizacaoOpcoes,
  }
};