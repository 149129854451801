import { Theme, ThemeProvider } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { VariaveisAmbiente } from '../../config';
import { SngpcStorageKeys, useSngpcStorage, useSessaoAtual } from "../../services/app";
import {
  ChangeColorsProps,
  useWhitelabelTheme
} from './whitelabel-theme';

interface WhiteLabelProviderProps {
  children: React.ReactNode;
}

export const WhiteLabelProvider = ({ children }: WhiteLabelProviderProps) => {
  const { getTemaAtual, mudarTema, defaultTheme } = useWhitelabelTheme();
  const { getUsuarioAtual } = useSessaoAtual();
  const { getRegistro } = useSngpcStorage();

  const [tema, setTema] = useState<Theme>(defaultTheme);
  const [usuario, setUsuario] = useState(getUsuarioAtual());

  React.useEffect(() => {
    if (getUsuarioAtual !== undefined) {
      setUsuario(getUsuarioAtual());
    }
  }, [getUsuarioAtual]);

  //Se for Whitelabel do Safra não deve trocar o whitelabel
  const changeTheme =
    getRegistro(SngpcStorageKeys.Whitelabel, false) &&
    getRegistro(SngpcStorageKeys.Whitelabel, false).contratoId ===
    usuario?.empresa[0].ContratoId;

  useEffect(() => {
    const whitelabel = getRegistro(SngpcStorageKeys.Whitelabel, false);

    if (changeTheme) {
      const colors = new ChangeColorsProps();
      colors.mudarCor('primary', whitelabel.corPrincipal);
      if (whitelabel.corSecundaria) {
        colors.mudarCor('secondary', whitelabel.corSecundaria);
      }
      if (whitelabel.urlIcone) {
        let link = document.querySelector("link[rel~='icon']") as any;
        if (!link) {
          link = document.createElement('link');
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = whitelabel.urlIcone;
      }
      document.title = !isEmpty(whitelabel.nome) ? whitelabel.nome : 'PDV NET';
      mudarTema(colors);

      const temaAtual = getTemaAtual();
      setTema(temaAtual);
    }

    return () => {
      let link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      console.log(VariaveisAmbiente);
      link.href =
        VariaveisAmbiente.label === 'SafraPDV' ? './safra/favicon.ico' : '';
      document.title =
        VariaveisAmbiente.label === 'SafraPDV' ? 'Safra PDV' : 'PDV';
    };
  }, [changeTheme, getRegistro, getTemaAtual, mudarTema, usuario]);

  return (
    <>
      {changeTheme
        ? (<ThemeProvider theme={tema}>
          {children}
        </ThemeProvider>)
        : (<>{children}</>)}
    </>
  );
};