
export enum EnumPlanoOpcoes {
    Fiscal = 600100,
    Modificador = 600900,
    CadastroProdutos = 100200,
    Vendas = 100100,
    IntegraoSaurusPDV = 600400,
    ControleDeVendedor = 500100,
    DiasExpiracao = 400200,
    Consumacao = 600300,
    ControleEstoque = 500300,
    SuporteViaWhatsApp = 400100,
    PagamentoIntegrado = 300200,
    RelatoriosBasicos = 300400,
    ControleProducao = 300600,
    SNGPC = 600700
}