import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";

export function useAutocompleteMedicamento() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const autocompleteMedicamento = useCallback((filtro: string) => {
    return invocarApi({
      url: `Medicamento`,
      method: "GET",
      enviarTokenUsuario: true,
      apiVersion: "v1",
      typeDomain: ETypeDomain.Medicamentos,
      params: {
        "Query": filtro.toLowerCase(),
        "PageSize": 10,
        "Page": 1,
      }
    });
  },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getListMedicamento: autocompleteMedicamento,
  };
}