import { useCallback, useEffect, useState } from 'react';
import { AppEventEnum } from '../../../../model/enums/enum-app-event';
import { useEventTools } from '../../../../services/app/use-cases/events/event-tools';
import { DialogGerenciarMedicamentoConteudo } from './dialog-gerenciar-medicamento-conteudo';

class DialogGerenciarMedicamentoControleProps {
  constructor(
    public aberto: boolean = false,
    public operacaoId: string = "",
    public excluir = () => { },
    public editar = () => { }
  ) { }
}

export const DialogGerenciarMedicamentoControle = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogGerenciarMedicamentoControleProps>(
    new DialogGerenciarMedicamentoControleProps()
  );

  const modalAlterado = useCallback(({ aberto, operacaoId, excluir, editar }: any) => {
    setModalState({ aberto, operacaoId, excluir, editar });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.GerenciarMedicamentos, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.GerenciarMedicamentos, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogGerenciarMedicamentoConteudo
          aberto={modalState.aberto}
          editar={modalState.editar}
          excluir={modalState.excluir}
        />
      )}
    </>
  );
};