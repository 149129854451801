import { useCallback } from 'react';
import { ETypeDomain } from '../../../../config/types';
import { useApiBase } from '../../base';

export class IdentidadeAutenticarEmailProps {
    public constructor(
        public email: string = '',
        public senha: string = '',
        public contratoId: string = ''
    ) { }
}

export function usePostIdentidadeAutenticarEmail() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarEmail = useCallback(
        (autenticacaoProps: IdentidadeAutenticarEmailProps) =>
            invocarApi({
                method: 'POST',
                url: `Auth/token-email`,
                data: autenticacaoProps,
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.Login,
                apiVersion: 'v4'
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarEmail,
    };
}
