import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import LixeiraIcon from "../../../../../../../assets/img/icon-lixeira.svg";
import { DefaultCard } from "../../../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../../../components/controles/inputs";
import { TransferenciasMedicamentoLoteCardProp } from "../../../models/transferencias.model";
import { useStyles } from "./transferencias-medicamentos-lote-styles";


export const TransferenciaMedicamentoLotesCard = (({ lote, isEntrada, alteraLoteQtd, remover, visualizacao }: TransferenciasMedicamentoLoteCardProp) => {
  const styles = useStyles();

  const alterar = (quantidade: number) => {
    if (!isEntrada && quantidade > lote.quantidadeDisponivel)
      quantidade = lote.quantidadeDisponivel;
    alteraLoteQtd(lote.lote, quantidade);
  }

  return (
    <DefaultCard>
      <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

        <div className={`flex-row w-100 ${styles.infoContainer}`}>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Lote
            </Typography>
            <Typography className={styles.contentFont}>
              {lote.lote}
            </Typography>
          </div>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Validade
            </Typography>
            <Typography className={styles.contentFont}>
              {moment(lote.dataValidade).format("DD/MM/YYYY")}
            </Typography>
          </div>
          {!lote.novoLote &&
            <div className="flex-column">
              <Typography className={styles.labelFont}>
                Disponível
              </Typography>
              <Typography className={styles.contentFont}>
                {lote.quantidadeDisponivel}
              </Typography>
            </div>
          }
          <div>
            <TextFieldSaurus className={`h-100 w-100 ${styles.inputLote}`}
              value={lote.quantidadeTransferida}
              labelCustom="Qtd"
              variant="outlined"
              size="small"
              tipo="NUMERO"
              onChange={(e: any) => alterar(Number(e.target.value))}
              disabled={visualizacao}
            />
          </div>
          {lote.novoLote && remover !== undefined &&
            <div className={classNames(styles.addButtonStyle, styles.redButton)}>
              <Button onClick={() => remover(lote.lote)} variant="text" className={`h-100 w-100 ${styles.espacamentoIcone}`}>
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  <img className={styles.addButtonIcon} src={LixeiraIcon} alt="" />
                </div>
              </Button>
            </div>
          }
        </div>
      </div>
    </DefaultCard>
  );
});