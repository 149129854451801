import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";
import { ETipoOperacaoMovimentacao } from "../../../../views/pages/private/envio-movimentacao/models/envio-movimentacao.model";

export function useGetMovimentacoes() {

  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getMovimentacoes = useCallback((dataInicial: Date, dataFinal: Date | null, tipoOperacao: ETipoOperacaoMovimentacao) => {
    return invocarApi(
      {
        url: `Movimentacao/buscar-operacoes-autorizadas-envio-sngpc?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoOperacao=${tipoOperacao}`,
        method: "GET",
        enviarTokenUsuario: true,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      }
    );
  },
    [invocarApi]
  );

  const getDownloadXML = useCallback((dataInicial: Date, dataFinal: Date | null, tipoOperacao: ETipoOperacaoMovimentacao) => {
    return invocarApi(
      {
        url: `Movimentacao/download-xml-sngpc?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoOperacao=${tipoOperacao}`,
        method: "GET",
        enviarTokenUsuario: true,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      }
    );
  },
    [invocarApi]
  );

  const postXMLSNGPCAnvisa = useCallback((dataInicial: Date, dataFinal: Date | null, tipoOperacao: ETipoOperacaoMovimentacao) => {
    return invocarApi(
      {
        url: `Movimentacao/enviar-xml-sngpc-anvisa?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoOperacao=${tipoOperacao}`,
        method: "POST",
        enviarTokenUsuario: true,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      }
    );
  },
    [invocarApi]
  );

  const getPeriodoInicial = useCallback((tipoOperacao?: ETipoOperacaoMovimentacao | null) => {

    return invocarApi(
      {
        url: `Movimentacao/buscar-periodo-inicial-envio-xml-sngpc?tipoOperacao=${tipoOperacao ?? ''}`,
        method: "GET",
        enviarTokenUsuario: true,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      }
    );
  },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    carregando,
    getMovimentacoes,
    getDownloadXML,
    postXMLSNGPCAnvisa,
    getPeriodoInicial
  };
}