import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    justifyContent: 'space-between',
    paddingBottom: '0.8rem'
  },

  buttonContainer: {
    display: 'flex',
    padding: '0.2rem',
    justifyContent: 'center'
  },

  buttonFont: {
    fontSize: '1.25rem',
  }
}));