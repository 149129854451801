import { FC } from 'react';
import { MenuPrincipal } from './menu-principal';
import { useStyles } from './main-page-styles';
import React from 'react';

export const MainPage: FC = ({ children }) => {

    const classes = useStyles();
    const menu = React.useMemo(() => {
        return (<MenuPrincipal></MenuPrincipal>);
    }, []);
    return (
        <>
            <div className={classes.containerBody}>
                {menu}
                {children}
            </div>
        </>
    );
};
