import * as Yup from 'yup';
import { useValidationYupEmail } from '../../../form-validations/yup/email';
import { useValidationYupSenha } from '../../../form-validations/yup/senha';
export const useFormLoginValidation = () => {
    const { emailYup } = useValidationYupEmail()
    const { senhaYup } = useValidationYupSenha()
    const FormLoginYupValidation = Yup.object().shape({
        email: emailYup(),
        senha: senhaYup(),
    });

    return {
        FormLoginYupValidation
    }
}

