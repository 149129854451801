import { useCallback } from 'react';
import { ETypeDomain } from '../../../../config/types';
import { useApiBase } from '../../base';

export class IdentidadeAutenticarTelefoneProps {
    public constructor(
        public telefone: string = '',
        public senha: string = '',
        public contratoId: string = ''
    ) { }
}

export function usePostIdentidadeAutenticarTelefone() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarTelefone = useCallback(
        (autenticacaoProps: IdentidadeAutenticarTelefoneProps) =>
            invocarApi({
                method: 'POST',
                url: `Auth/token-telefone`,
                data: autenticacaoProps,
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.Login,
                apiVersion: 'v4'
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarTelefone,
    };
}
