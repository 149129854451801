import { Typography } from "@material-ui/core";
import moment from "moment";
import { DefaultCard } from "../../../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../../../components/controles/inputs";
import { PerdasMedicamentoLoteCardProp } from "../../../models/perdas-models";
import { useStyles } from "./perda-medicamento-lotes-styles";


export const PerdaMedicamentoLotesCard = (({ lote, alteraLoteQtd, visualizacao }: PerdasMedicamentoLoteCardProp) => {
  const styles = useStyles();

  return (
    <DefaultCard>
      <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

        <div className={`h-100 ${styles.statusBarContainer}`}>
          <div className={`${styles.statusBarStyle}`} style={{
            backgroundColor: lote.quantidadePerdida ? 'var(--sistema-sucesso)' : 'var(--sistema-sem-acao)'
          }} />
        </div>

        <div className={`flex-row w-100 ${styles.infoContainer}`}>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Lote
            </Typography>
            <Typography className={styles.contentFont}>
              {lote.lote}
            </Typography>
          </div>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Validade
            </Typography>
            <Typography className={styles.contentFont}>
              {moment(lote.dataValidade).format("DD/MM/YYYY")}
            </Typography>
          </div>
          <div className="flex-column">
            <Typography className={styles.labelFont}>
              Disponível
            </Typography>
            <Typography className={styles.contentFont}>
              {lote.quantidadeDisponivel}
            </Typography>
          </div>
          <div>
            <TextFieldSaurus labelCustom="Qtd"
              value={lote.quantidadePerdida ?? 0}
              className={`h-100 w-100 ${styles.inputStyle}`}
              variant="outlined"
              size="small"
              tipo="NUMERO"
              disabled={visualizacao}
              onChange={(e: any) =>
                alteraLoteQtd(lote.lote, e.target.value > lote.quantidadeDisponivel
                  ? lote.quantidadeDisponivel
                  : Number(e.target.value))
              }
            />
          </div>
        </div>
      </div>
    </DefaultCard>
  );
});