import { yupResolver } from "@hookform/resolvers/yup";
import { Fade } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CompradorIcon from "../../../../assets/img/icon-comprador.svg";
import MedicamentoIcon from "../../../../assets/img/icon-medicamento.svg";
import PrescritorIcon from "../../../../assets/img/icon-prescritor.svg";
import ReceitaIcon from "../../../../assets/img/icon-receita.svg";
import { useCadastrarDispensacao } from "../../../../data/api/gestao/dispensacao/dispensacao";
import { useToastSaurus } from "../../../../services/app";
import { CircularLoading } from "../../../components";
import { ButtonModalHeader } from "../../../components/controles/buttons/button-modal-header";
import { VoltarIcon } from "../../../components/icons";
import { ModalHeader } from "../../../components/modal-header";
import { Wizard } from "../../../components/wizard/wizard-component";
import { WizardStep } from "../../../components/wizard/wizard-model";
import { DispensacaoAvulsaCompradorForm } from "./componentes/dispensacao-avulsa-comprador-form";
import { DispensacaoAvulsaMedicamentoForm } from "./componentes/dispensacao-avulsa-medicamento-form";
import { DispensacaoAvulsaMedicamentoLotes } from "./componentes/dispensacao-avulsa-medicamento-lotes/dispensacao-avulsa-medicamento-lotes";
import { DispensacaoAvulsaPrescritorForm } from "./componentes/dispensacao-avulsa-prescritor-form";
import { DispensacaoAvulsaReceitaForm } from "./componentes/dispensacao-avulsa-receita-form";
import { useStyles } from "./dispensacao-avulsa-cadastro-style";
import {
  ControleMedicamentoLotes,
  DispensacaoAvulsaForm,
  DispensacaoAvulsaFormMedicamento,
  DispensacaoMedicamentoLote,
  EDispensacaoAvulsaPassos,
  OperacaoPendenteAvulsa,
  OperacaoPendenteDispensacaoAvulsa,
  OperacaoPendenteDispensacaoCompradorPaciente,
  OperacaoPendenteDispensacaoMedicamento,
  OperacaoPendenteDispensacaoPrescritor,
  OperacaoPendenteDispensacaoReceita
} from "./models/dispensacao-avulsa-models";

export const DispensacaoAvulsaCadastroPage = ((props: any) => {
  const styles = useStyles();

  const { cadastrarDispensacao } = useCadastrarDispensacao();

  const [passoDispensacao, setPassoDispensacao] = useState(0);
  const [dispensacao, setDispensacao] = useState(new DispensacaoAvulsaForm());
  const [controleMedicamentos, setControleMedicamentos] = useState<ControleMedicamentoLotes>(new ControleMedicamentoLotes());
  const { showToast, showToastPersonalizado } = useToastSaurus();
  const [isLoading, setIsLoading] = useState(false);
  const [errorValidationPrescritor, setErrorValidationPrescritor] = useState(false);
  const [errorValidationComprador, setErrorValidationComprador] = useState(false);
  const [errorValidationPaciente, setErrorValidationPaciente] = useState(false);

  const schema = yup
    .object()
    .shape({
      receitaTipo: yup.number().required('Tipo da Receita é obrigatório!'),
      dataLancamento: yup.string().required('Data é obrigatória!'),
      horaLancamento: yup.string().required('Hora é obrigatória!'),
      dataReceita: yup.string().required('Data é obrigatória!'),
      numeroReceita: yup.string().required('Número é obrigatório!'),
      uf: yup.string().required('UF é obrigatória!'),
      cid: yup.string().required('CID é obrigatório!'),
      compradorPacienteMesmaPessoa: yup.boolean(),
      prescritor: yup.object().shape({
        nome: yup.string().required('Nome é obrigatório!'),
      }).required("Prescritor é obrigatório"),
      comprador: yup.object().shape({
        nome: yup.string().required('Nome é obrigatório!'),
      }).required("Comprador é obrigatório")
    });

  const receitaSchema = yup
    .object()
    .shape({
      dataLancamento: yup.string().required('Data é obrigatória!'),
      horaLancamento: yup.string().required('Hora é obrigatória!'),
      dataReceita: yup.string().required('Data é obrigatória!'),
      numeroReceita: yup.string().required('Número é obrigatório!'),
      uf: yup.string().required('UF é obrigatória!'),
      cid: yup.string().required('CID é obrigatório!')
    });

  const {
    formState,
    register,
    setError
  } = useForm<DispensacaoAvulsaForm>({
    criteriaMode: 'all',
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const controlarStep = async (step: EDispensacaoAvulsaPassos) => {

    if (await validarStep(step))
      return setPassoDispensacao(step);

    return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
  }

  const validarStep = async (step: EDispensacaoAvulsaPassos) => {
    if (step == EDispensacaoAvulsaPassos.Receita)
      return true;

    if (step == EDispensacaoAvulsaPassos.Prescritor)
      return await validarReceita();

    if (step == EDispensacaoAvulsaPassos.Comprador) {
      return await validarPrescritor();
    }

    if (step == EDispensacaoAvulsaPassos.Medicamento) {
      return await validarComprador();
    }
  }

  const validarReceita = async () => {

    try {

      await receitaSchema.validate({
        dataLancamento: dispensacao.dataLancamento,
        dataReceita: dispensacao.dataReceita,
        horaLancamento: dispensacao.horaLancamento,
        numeroReceita: dispensacao.numeroReceita,
        cid: dispensacao.cid,
        uf: dispensacao.uf,
      }, { abortEarly: false });

      return true;

    } catch (error) {

      const validationError = error as yup.ValidationError;

      validationError?.inner?.forEach(err => {
        const path = err.path?.toString();

        if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
          setErrorField(path, err.message);
        }

      });

      return false;
    }

  }

  const validarPrescritor = async () => {
    const result = dispensacao?.prescritor?.nome === undefined;
    setErrorValidationPrescritor(result);
    return !result;
  }

  const validarComprador = async () => {

    const resultComprador = dispensacao.comprador.nome === undefined;  
    setErrorValidationComprador(resultComprador);

    const resultPaciente = dispensacao.compradorPacienteMesmaPessoa !== true &&
      dispensacao?.paciente?.nome === undefined;

    setErrorValidationPaciente(resultPaciente);

    return (resultComprador || resultPaciente) ? false : true;

  }

  function setErrorField(path: string, errorMensage: string) {

    switch (path) {

      case 'dataLancamento':
        setError('dataLancamento', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'horaLancamento':
        setError('horaLancamento', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'dataReceita':
        setError('dataReceita', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'numeroReceita':
        setError('numeroReceita', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'uf':
        setError('uf', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'cid':
        setError('cid', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      default:
        break;
    }

  }

  const editarMedicamentos = useCallback((medicamentos: DispensacaoAvulsaFormMedicamento[]) => {
    setControleMedicamentos({
      exibir: true,
      medicamentoLotes: medicamentos,
    });
  }, []);

  const salvarLotes = (lotesSelecionados: DispensacaoMedicamentoLote[], medicamento: DispensacaoAvulsaFormMedicamento) => {
    if (!medicamento.codigoAnvisa.length)
      return setControleMedicamentos(new ControleMedicamentoLotes());

    let medicamentosSalvos = dispensacao.medicamentos.filter(m => m.codigoAnvisa !== medicamento.codigoAnvisa);

    lotesSelecionados.forEach(l => {
      for (let index = 0; index < l.quantidadeReservada; index++) {
        medicamentosSalvos.push({
          medicamentoId: medicamento.medicamentoId,
          medicamentoNome: medicamento.medicamentoNome,
          medicamentoDescritivo: medicamento.medicamentoDescritivo,
          medicamentoComposicao: medicamento.medicamentoComposicao,
          codigoAnvisa: medicamento.codigoAnvisa,
          vendaControlada: medicamento.vendaControlada,
          vpmc: medicamento.vpmc,
          motivoIsencao: medicamento.motivoIsencao,
          codigoAgregador: l.codigoAgregador,
          usoProlongado: medicamento.usoProlongado,
          lote: l.lote,
          validade: l.dataValidade,
          fabricacao: l.dataFabricacao
        } as DispensacaoAvulsaFormMedicamento);
      }
    });

    setDispensacao((dispensacao) => ({
      ...dispensacao,
      medicamentos: medicamentosSalvos
    }));
    setControleMedicamentos(new ControleMedicamentoLotes());
  }

  const salvarDispensacao = async () => {
    var formIsValid = await schema.isValid(dispensacao);

    if (!formIsValid)
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

    if (!dispensacao?.medicamentos?.length)
      return showToast("error", "É necessário vincular ao menos um medicamento!", 5000, 'top-center');

    setIsLoading(true);

    var dataLancamento = new Date(`${dispensacao.dataLancamento} ${dispensacao.horaLancamento}`);
    var listaMedicamentos = Array<OperacaoPendenteDispensacaoMedicamento>();

    dispensacao.medicamentos.forEach((medicamento) => {
      listaMedicamentos.push(
        new OperacaoPendenteDispensacaoMedicamento(
          medicamento.medicamentoId,
          medicamento.medicamentoNome,
          medicamento.medicamentoDescritivo,
          medicamento.medicamentoComposicao,
          medicamento.codigoAnvisa,
          medicamento.vendaControlada,
          medicamento.vpmc,
          medicamento.motivoIsencao,
          medicamento.lote,
          medicamento.validade,
          medicamento.fabricacao,
          medicamento.codigoAgregador,
          medicamento.usoProlongado,
          new OperacaoPendenteDispensacaoReceita(
            dispensacao.numeroReceita,
            dispensacao.receitaTipo,
            dataLancamento,
            new Date(dispensacao.dataReceita),
            dispensacao.uf,
            dispensacao.cid,
            dispensacao.compradorPacienteMesmaPessoa !== undefined ? dispensacao.compradorPacienteMesmaPessoa : false,
            new OperacaoPendenteDispensacaoPrescritor(
              dispensacao.prescritor?.nome,
              dispensacao.prescritor?.cep,
              dispensacao.prescritor?.logradouro,
              dispensacao.prescritor?.numero,
              dispensacao.prescritor?.complemento,
              dispensacao.prescritor?.municipio,
              dispensacao.prescritor?.codMunicipio,
              dispensacao.prescritor?.uf,
              dispensacao.prescritor?.especialidade,
              dispensacao.prescritor?.tipoDocumento,
              dispensacao.prescritor?.documento,
              dispensacao.prescritor?.orgaoExpedidor,
              dispensacao.prescritor?.ufOrgaoExpedidor
            ),
            new OperacaoPendenteDispensacaoCompradorPaciente(
              dispensacao.comprador.nome,
              dispensacao.comprador.sexo,
              dispensacao.comprador.telefone,
              dispensacao.comprador.dataNascimento,
              dispensacao.comprador.tipoDocumento,
              dispensacao.comprador.documento,
              dispensacao.comprador.orgaoExpedidor,
              dispensacao.comprador.ufOrgaoExpedidorId,
              dispensacao.comprador.cep,
              dispensacao.comprador.logradouro,
              dispensacao.comprador.numero,
              dispensacao.comprador.complemento,
              dispensacao.comprador.uf,
              dispensacao.comprador.codMunicipio,
              dispensacao.comprador.municipio,
            ),
            new OperacaoPendenteDispensacaoCompradorPaciente(
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.nome : dispensacao.paciente.nome,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.sexo : dispensacao.paciente.sexo,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.telefone : dispensacao.paciente.telefone,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.dataNascimento : dispensacao.paciente.dataNascimento,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.tipoDocumento : dispensacao.paciente.tipoDocumento,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.documento : dispensacao.paciente.documento,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.orgaoExpedidor : dispensacao.paciente.orgaoExpedidor,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.ufOrgaoExpedidorId : dispensacao.paciente.ufOrgaoExpedidorId,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.cep : dispensacao.paciente.cep,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.logradouro : dispensacao.paciente.logradouro,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.numero : dispensacao.paciente.numero,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.complemento : dispensacao.paciente.complemento,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.uf : dispensacao.paciente.uf,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.codMunicipio : dispensacao.paciente.codMunicipio,
              dispensacao.compradorPacienteMesmaPessoa ? dispensacao.comprador.municipio : dispensacao.paciente.municipio,
            ),
          )
        )
      )
    })

    var operacaoPendente = new OperacaoPendenteAvulsa(
      dataLancamento,
      new OperacaoPendenteDispensacaoAvulsa(
        listaMedicamentos,
        dataLancamento,
        dispensacao.receitaArquivoNome,
        dispensacao.receitaArquivo
      )
    );

    const operacaoSalva = await cadastrarDispensacao(operacaoPendente);

    if (operacaoSalva.erro) {
      setIsLoading(false);
      return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
    }

    showToastPersonalizado('success', 'Dispensação cadastrada com sucesso, dado salvo na listagem de Operação Pendente', 5000, 'top-center');

    setIsLoading(false);
    props.fecharModal();
  };

  const steps: WizardStep[] = [
    { label: 'Receita', icon: <img src={ReceitaIcon} alt="" /> },
    { label: 'Prescritor', icon: <img src={PrescritorIcon} alt="" /> },
    { label: 'Comprador', icon: <img src={CompradorIcon} alt="" /> },
    { label: 'Medicamento', icon: <img src={MedicamentoIcon} alt="" /> }
  ];

  const passoFormRender = () => {
    switch (passoDispensacao) {
      case EDispensacaoAvulsaPassos.Receita:
        return <DispensacaoAvulsaReceitaForm
          formState={formState}
          dispensacao={dispensacao}
          setDispensacao={setDispensacao}
          avancarStep={controlarStep}
          register={register}
        />
      case EDispensacaoAvulsaPassos.Prescritor:
        return <DispensacaoAvulsaPrescritorForm
          formState={formState}
          dispensacao={dispensacao}
          setDispensacao={setDispensacao}
          avancarStep={controlarStep}
          register={register}
          errorValidation={errorValidationPrescritor}
        />
      case EDispensacaoAvulsaPassos.Comprador:
        return <DispensacaoAvulsaCompradorForm
          formState={formState}
          dispensacao={dispensacao}
          setDispensacao={setDispensacao}
          avancarStep={controlarStep}
          register={register}
          errorValidation={errorValidationComprador}
          errorValidationCompradorPacienteMesmaPessoa={errorValidationPaciente}
        />
      case EDispensacaoAvulsaPassos.Medicamento:
        return <DispensacaoAvulsaMedicamentoForm
          formState={formState}
          dispensacao={dispensacao}
          setDispensacao={setDispensacao}
          avancarStep={controlarStep}
          register={register}
          salvarDispensacao={salvarDispensacao}
          editarMedicamentos={editarMedicamentos}
        />
      default:
        return <>
        </>
    }
  };

  const goBack = () =>
    controleMedicamentos.exibir
      ? setControleMedicamentos(new ControleMedicamentoLotes())
      : props.fecharModal();

  return (
    <>
      <Fade in timeout={900} mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>

          <ModalHeader
            title={'Dispensação'}
            leftArea={
              <ButtonModalHeader
                tooltip="Voltar"
                icon={<VoltarIcon tipo="MODAL_HEADER" />}
                onClick={goBack}
              />
            }
          />

          {isLoading ? <CircularLoading tipo="FULLSIZED" /> :
            <>
              {controleMedicamentos.exibir
                ? <DispensacaoAvulsaMedicamentoLotes medicamentos={controleMedicamentos.medicamentoLotes} salvarLotesDispensacao={salvarLotes} />
                : (
                  <div className={`flex-column w-100 ${styles.formPadding, styles.formHeight}`}>
                    <div className={styles.containerSteps} >
                      <Wizard
                        steps={steps}
                        activeStep={passoDispensacao}
                        currentStepEmmiter={controlarStep}
                      />
                    </div>

                    <div className={`flex-column ${styles.formContainer} ${styles.containerStepsForm}`}>
                      {passoFormRender()}
                    </div>
                  </div>
                )
              }
            </>
          }
        </div>
      </Fade>
    </>
  );
});