export function toDecimal(value: any, casas: number = 2): number {
  const ret = parseFloat(parseFloat(value.toString().replace(',', '.')).toFixed(casas).replace(",", "."))
  if (isNaN(ret))
    return 0;
  return ret;
}

export function toDecimalString(value: any, casas: number = 2): string {
  if (value === '') return '';
  return parseFloat(value.toString().replace(",", ".")).toFixed(casas).toString().replace(".", ",");
}


export function toDecimalStringGeneralizated(value: any, casas: number = 2): string {
  if (value === '') return '';
  return parseFloat(parseFloat(value.toString().replace(",", ".")).toFixed(casas)).toString().replace(".", ",");
}
