import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetEstoqueByCodigoAnvisa } from "../../../../../../../data/api/gestao/estoque/get-estoque";
import { useToastSaurus } from "../../../../../../../services/app";
import { AutocompleteMedicamento } from "../../../../../../components/autocompletes/autocomplete-medicamentos";
import { ConfirmarIcon } from "../../../../../../components/icons/confirmar-icon";
import { MedicamentoTransferencia, NovoLote, TransferenciaMedicamentoLote, TransferenciaMedicamentoLoteProp } from "../../../models/transferencias.model";
import { TransferenciaEntradaMedicamentoLotes } from "./transferencias-entrada-medicamentos-lote";
import { useStyles } from "./transferencias-medicamentos-lote-styles";
import { TransferenciaSaidaMedicamentoLotes } from "./transferencias-saida-medicamentos-lote";

export const TransferenciaMedicamentoLotes = (({ medicamentos, isEntrada, salvarLotesTransferencia, visualizacao }: TransferenciaMedicamentoLoteProp) => {
  const [medicamento, setMedicamento] = useState<MedicamentoTransferencia>({} as MedicamentoTransferencia);
  const [lotes, setLotes] = useState<TransferenciaMedicamentoLote[]>([]);
  const [totalTransferido, SetTotalTransferidos] = useState<number>(0)

  const { getEstoqueByCodigoAnvisa } = useGetEstoqueByCodigoAnvisa();
  const styles = useStyles();
  const { showToastPersonalizado } = useToastSaurus();

  useEffect(() => {
    if (medicamentos?.length)
      setMedicamento({ ...medicamentos[0] } as MedicamentoTransferencia);
  }, [medicamentos]);

  useEffect(() => {
    if (medicamento?.codigoAnvisa)
      getLotes(medicamento.codigoAnvisa);
  }, [medicamento]);

  useEffect(() => {
    if (!lotes?.length) return;

    let transferidos = lotes.map(l => l.quantidadeTransferida ?? 0)
      .reduce((accumulator, currentValue) =>
        accumulator + currentValue, 0);

    SetTotalTransferidos(transferidos);
  }, [lotes]);

  const getLotes = useCallback(async (codigoAnvisa: string) => {
    try {
      const res = await getEstoqueByCodigoAnvisa(codigoAnvisa);

      if (res.erro)
        throw res.erro;

      let lotes = res.resultado?.data?.lotes;
      let lotesFormatados = new Array<TransferenciaMedicamentoLote>();

      if (!lotes?.length && !isEntrada) {
        setMedicamento({} as MedicamentoTransferencia);
        return showToastPersonalizado('error', 'O Produto selecionado não existe no Estoque!', 3000, 'top-center');
      }

      res.resultado?.data?.lotes?.map((lote: any) =>
        lotesFormatados.push({
          lote: lote.lote,
          quantidadeDisponivel: lote.quantidade,
          quantidadeTransferida: medicamentos.filter(m => m.lote === lote.lote).length,
          dataValidade: lote.dataValidade,
          dataFabricacao: lote.dataFabricacao,
          codigoAgregador: lote.codigoAgregador,
          novoLote: false
        } as TransferenciaMedicamentoLote)
      );

      setLotes(lotesFormatados);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getEstoqueByCodigoAnvisa, medicamentos]);

  const adicionarLote = (novoLote: NovoLote) => {
    let lotesLista = [...lotes];

    lotesLista.push({
      lote: novoLote.lote,
      quantidadeDisponivel: novoLote.quantidade,
      quantidadeTransferida: novoLote.quantidade,
      dataValidade: novoLote.dataValidade,
      dataFabricacao: undefined,
      codigoAgregador: undefined,
      novoLote: true
    } as TransferenciaMedicamentoLote);

    setLotes(lotesLista);
  };

  const alterarLotes = (loteNome: string, quantidade: number) => {
    if (!loteNome)
      return;

    let lotesAlteracao = [...lotes];
    let loteIndice = lotesAlteracao.findIndex(l => l.lote === loteNome);
    if (loteIndice === undefined)
      return;

    lotesAlteracao[loteIndice].quantidadeTransferida = quantidade;

    setLotes(lotesAlteracao);
  };

  const removerLote = (loteNome: string) => {
    if (!loteNome)
      return;

    let lotesAlteracao = [...lotes];
    let loteIndice = lotesAlteracao.findIndex(l => l.lote === loteNome);
    if (loteIndice === undefined)
      return;

    lotesAlteracao.splice(loteIndice);

    setLotes(lotesAlteracao);
  };

  const salvarLotes = () => {
    let lotesSelecionados = lotes?.filter(l => l.quantidadeTransferida);
    salvarLotesTransferencia(lotesSelecionados, medicamento);
  };

  const handleMedicamentoAutocomplete = useCallback((medicamento: any) => {
    if (!medicamento.ms && medicamento)
      return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

    setLotes([]);

    setMedicamento({
      medicamentoId: medicamento.id,
      medicamentoNome: medicamento.descricao,
      medicamentoDescritivo: medicamento.descritivo,
      medicamentoComposicao: medicamento.composicao,
      codigoAnvisa: medicamento.ms
    } as MedicamentoTransferencia);
  }, [showToastPersonalizado]);

  const handleMedicamentoLabel = (): string =>
    medicamento.codigoAnvisa ? `${medicamento.medicamentoNome} - ${medicamento.codigoAnvisa}` : '';

  return (
    <>
      {medicamentos !== undefined &&
        <div className={`flex-row w-100 ${styles.medicamentoInfoContainer}`}>
          <div className={`flex-column h-100 ${styles.medicamentoInfoStyle}`}>
            <AutocompleteMedicamento
              value={handleMedicamentoLabel()}
              label="Buscar Medicamento"
              className={styles.inputStyle}
              disabled={(!isEntrada && lotes?.length > 0) && visualizacao}
              onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
            />
          </div>
          <div className={`flex-column ${styles.quantidadeContainer}`}>
            <Typography className={styles.quantidadeLabel}>
              Quantidade Transferida
            </Typography>
            <Typography className={styles.quantidadeValor}>
              {totalTransferido}
            </Typography>
          </div>
        </div>
      }

      <div className={`flex-column w-100 ${styles.formPadding}`}>
        {isEntrada
          ? <TransferenciaEntradaMedicamentoLotes
            lotes={lotes}
            medicamento={medicamento}
            alterarLotes={alterarLotes}
            adicionarLote={adicionarLote}
            removerLote={removerLote}
            visualizacao={visualizacao}
          />
          : <TransferenciaSaidaMedicamentoLotes
            lotes={lotes}
            medicamento={medicamento}
            alterarLotes={alterarLotes}
            visualizacao={visualizacao}
          />
        }
        {
          !visualizacao &&
          <div className={`flex-row w-100 ${styles.buttonContainer}`}>
            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
              <Button onClick={() => salvarLotes()} variant="text" className="h-100 w-100">
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  <ConfirmarIcon class={styles.iconSize} />
                  Salvar
                </div>
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  );
});