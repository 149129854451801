import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { AddIcon } from "../../../../../components/icons/add-icon";
import { MedicamentoTransferencia, TransferenciaStepsProp } from "../../models/transferencias.model";
import { useStyles } from "./transferencia-avulsa-styles";
import { TransferenciaAvulsaMedicamentosCard } from "./transferencia-avulsa-medicamentos-card";

export const TransferenciaAvulsaMedicamentosForm = (({ transferencia, editarMedicamentoLotes }: TransferenciaStepsProp) => {
  const styles = useStyles();
  const [meds, setMeds] = useState<Array<MedicamentoTransferencia[]>>([]);

  useEffect(() => {
    let codigosDistintos = transferencia.medicamentos?.map(m => m.codigoAnvisa)
      .filter(selecionarUnicos);
    let matrizMedicamentos: Array<MedicamentoTransferencia[]> = [];

    if (!codigosDistintos?.length)
      return;

    codigosDistintos.forEach((c) => {
      let medicamentosIguas = transferencia.medicamentos.filter(m => m.codigoAnvisa === c);
      matrizMedicamentos[codigosDistintos.indexOf(c)] = medicamentosIguas;
    })

    setMeds(matrizMedicamentos);
  }, [transferencia.medicamentos]);

  const selecionarUnicos = (value: string, index: number, array: string[]) =>
    array.indexOf(value) === index;

  return (
    <>
      {editarMedicamentoLotes !== undefined &&
        <div className={`flex-column w-100 h-100`}>
          <div className={`flex-column ${styles.titleContainer}`}>
            <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
            <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
          </div>

          <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
            <div className={`flex-column w-100 ${styles.medicamentosCardsContainer}`}>
              {meds?.map((medicamentos, index) => (
                <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                  <TransferenciaAvulsaMedicamentosCard visualizacao={false} medicamentos={medicamentos} editar={editarMedicamentoLotes} />
                </div>
              ))}
            </div>

          </div>
          <div className={classNames(styles.addButtonMedicamentoStyle, styles.whiteButton)}>
            <Button onClick={() => editarMedicamentoLotes([])} variant="text" className="h-100 w-100">
              <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                <AddIcon class={styles.iconSize} />
                Adicionar Produto
              </div>
            </Button>
          </div>

        </div>
      }
    </>
  );
});