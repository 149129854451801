import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  color: {
    backgroundColor: theme.palette.grey[100],
  },
  listContainer: {
    justifyContent: 'start',
    gap: '1%',
    height: 0,
    flexGrow: 1
  },
  contentContainer: {
    padding: '0 4%',
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: '0 1%'
    }
  },
  semRegistroStyle: {
    fontSize: "1rem",
    fontWeight: 600,
    textAlign: 'center',
    margin: '1rem 0'
  },
  inventarioListStyle: {
    overflow: 'auto',
    paddingRight: '0.5rem'
  },
}));