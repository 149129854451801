import { guidEmpty } from "../../../../utils/guid-empty";
import { EnumTpTermo } from "../../../enums/enum-tptermo";

export interface PropOpcoes {
  id: string;
  nome: string;
  descricao: string;
  liberado: boolean;
  grupo: string;
  planoId: string;
  exibir: number;
  codigo: number;
  valor: string;
}

export class PlanoModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public dValidade: string = "",
    public opcoes: PropOpcoes[] = [],
    public tpTermo: EnumTpTermo = EnumTpTermo.SIMPLES,
    public valor: number = 0,
    public valorpromo: number = 0,
  ) { }
}
