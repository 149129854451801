export class ControlePaginacao<T> {

  constructor(
    pageIndex: number,
    pageSize: number,
    totalPages: number,
    totalResults: number,
    data: Array<T>
  ) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.totalPages = totalPages;
    this.totalRegisters = totalResults;
    this.data = data;
  }

  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRegisters: number;
  data: Array<T>;
}