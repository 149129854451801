import { StepIconProps } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import clsx from 'clsx';
import { WizardProps } from './wizard-model';
import { ConnectorStyle, useStyles } from './wizard-styles';

export const Wizard = ({ steps, activeStep, setActiveStep, currentStepEmmiter }: WizardProps) => {
  const classes = useStyles();

  const handleStep = (step: number) => {
    if (setActiveStep)
      setActiveStep(step);
    if (currentStepEmmiter)
      currentStepEmmiter(step);
  };

  const handleStepIcon = (props: StepIconProps, stepIcon: JSX.Element) => {
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {stepIcon}
      </div>
    );
  }

  return (
    <div className="w-100">
      <Stepper className={classes.wizardContainer} alternativeLabel activeStep={activeStep} connector={<ConnectorStyle />}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel className={classes.labelConainer}
              StepIconComponent={(props) => handleStepIcon(props, step.icon)}
              onClick={() => handleStep(index)}
            >
              <div className={classes.labelStyle}>
                {step.label}
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
