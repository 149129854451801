import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const TransferenciaIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M37.8438 0.0937291C37.4688 0.160135 37.168 0.433573 37.0625 0.796854C36.957 1.16404 37.0664 1.55467 37.3438 1.81248L46.5312 11H1C0.96875 11 0.9375 11 0.90625 11C0.355469 11.0273 -0.0742188 11.4961 -0.046875 12.0469C-0.0195313 12.5976 0.449219 13.0273 1 13H46.5312L37.3438 22.1875C37.0469 22.4297 36.9102 22.8164 36.9961 23.1914C37.082 23.5625 37.375 23.8554 37.7461 23.9414C38.1211 24.0273 38.5078 23.8906 38.75 23.5937L49.6562 12.7187L50.3438 12L49.6562 11.2812L38.75 0.406229C38.543 0.183573 38.2422 0.0702916 37.9375 0.0937291C37.9062 0.0937291 37.875 0.0937291 37.8438 0.0937291ZM11.8125 26.0937C11.5938 26.1328 11.3984 26.2422 11.25 26.4062L0.34375 37.2812L-0.34375 38L0.34375 38.7187L11.25 49.5937C11.4922 49.8906 11.8789 50.0273 12.2539 49.9414C12.625 49.8554 12.918 49.5625 13.0039 49.1914C13.0898 48.8164 12.9531 48.4297 12.6562 48.1875L3.46875 39H49C49.3594 39.0039 49.6953 38.8164 49.8789 38.5039C50.0586 38.1914 50.0586 37.8086 49.8789 37.4961C49.6953 37.1836 49.3594 36.9961 49 37H3.46875L12.6562 27.8125C12.9805 27.5117 13.0664 27.0351 12.8711 26.6406C12.6797 26.2422 12.2461 26.0234 11.8125 26.0937Z" />
            </DefaultIcon>
        </>
    );
};
