import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Fade } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CircularLoading } from "../../../..";
import NotaFiscalIcon from "../../../../../assets/img/icon-nota-fiscal.svg";
import { useGerarRelatorioBMPO } from "../../../../../data/api/gestao/relatorios/gerar-relatorio-bmpo";
import { KeyValueModel } from "../../../../../model";
import { useToastSaurus } from "../../../../../services/app";
import { useEmpresaAtualHandler } from "../../../../../services/app/use-cases/empresa-atual-handler";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import {
  EPeriodoTipo,
  EPeriodoTipoMap,
  ETrimestre,
  ETrimestreMap,
  RelatorioBalancoMedicamentoModel
} from "../models/relatorio-models";
import { RelatorioBalancoMedicamentoForm } from "./relatorio-balanco-medicamento-form";
import { relatorioDisplay, useStyles } from "./relatorio-balanco-medicamento-styles";

export const RelatorioBalancoMedicamento = () => {
  const [formBMPO, setFormBMPO] = useState({ ano: new Date().getFullYear() } as RelatorioBalancoMedicamentoModel);
  const [relatorioBMPOGerado, setRelatorioBMPOGerado] = useState("");
  const styles = useStyles();

  const [errorValidationfarmaceutico, setErrorValidationfarmaceutico] = useState(false);

  const { gerarRelatorioBMPO, carregando } = useGerarRelatorioBMPO();
  const { getEmpresaAtualEndereco } = useEmpresaAtualHandler();
  const { showToast } = useToastSaurus();

  const listaDataTipo = Object.values(EPeriodoTipo)
    .filter(e => !isNaN(Number(e))).map(i => i as EPeriodoTipo)
    .map((tipo: EPeriodoTipo) => new KeyValueModel(tipo, EPeriodoTipoMap.get(tipo)));

  const listaTrimestre = Object.values(ETrimestre)
    .filter(e => !isNaN(Number(e))).map(i => i as ETrimestre)
    .map((tipo: ETrimestre) => new KeyValueModel(tipo, ETrimestreMap.get(tipo)));

  const schema = yup
    .object()
    .shape({
      farmaceutico: yup.string().required("O Farmacêutico é obrigatório!"),
      periodoTipoId: yup.number().required("O Tipo de Data é obrigatório!"),
      periodoTrimestralId: yup.number().when("periodoTipoId", {
        is: EPeriodoTipo.Trimestral,
        then: yup.number().required("O Trimestre é obrigatório!")
      }
      ),
      ano: yup.number().when("periodoTipoId", {
        is: EPeriodoTipo.Personalizado,
        then: yup.number().notRequired(),
        otherwise: yup.number().required("O Ano é obrigatório!")
      }
      ),
      dataInicial: yup.date().when("periodoTipoId", {
        is: EPeriodoTipo.Personalizado,
        then: yup.date().required("A Data Inicial é obrigatória!")
      }
      ),
      dataFinal: yup.date().notRequired().when("periodoTipoId", {
        is: EPeriodoTipo.Personalizado,
        then: yup.date().required("A Data Final é obrigatória!")
      }
      ),
    });

  const { register, formState, setError } = useForm<RelatorioBalancoMedicamentoModel>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  const handleEmpresaEndereco = useCallback(async (form: RelatorioBalancoMedicamentoModel) => {
    let enderecoEmpresa = await getEmpresaAtualEndereco();

    if (enderecoEmpresa === undefined)
      return;

    setFormBMPO({ ...form, enderecoEmpresa });
  }, [getEmpresaAtualEndereco]);

  useEffect(() => {
    if (
      formBMPO === undefined ||
      formBMPO?.enderecoEmpresa !== undefined ||
      getEmpresaAtualEndereco === undefined
    ) return;

    handleEmpresaEndereco(formBMPO);
  }, [formBMPO, getEmpresaAtualEndereco, handleEmpresaEndereco]);

  const gerarRelatorio = async () => {
    validarCampos();

    if (!await schema.isValid(formBMPO))
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

    try {
      let res = await gerarRelatorioBMPO(formBMPO);

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      setRelatorioBMPOGerado(res.resultado?.data);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      showToast('error', e.message);
    }
  };

  const validarCampos = async () => {
    setErrorValidationfarmaceutico(true);

    try {
      await schema.validate({
        periodoTipoId: formBMPO.periodoTipoId,
        periodoTrimestralId: formBMPO.periodoTrimestralId,
        ano: formBMPO.ano,
        dataInicial: formBMPO.dataInicial,
        dataFinal: formBMPO.dataFinal
      }, { abortEarly: false });
    } catch (error) {

      const validationError = error as yup.ValidationError;

      validationError?.inner?.forEach(err => {
        const path = err.path?.toString();

        if (path && Object.prototype.hasOwnProperty.call(schema.fields, path))
          setErrorField(path, err.message);

      });

      return false;
    }
  }

  function setErrorField(path: string, errorMensage: string) {

    switch (path) {

      case 'periodoTipoId':
        setError('periodoTipoId', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'periodoTrimestralId':
        setError('periodoTrimestralId', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'ano':
        setError('ano', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'dataInicial':
        setError('dataInicial', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'dataFinal':
        setError('dataFinal', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      default:
        break;
    }

  }

  useEffect(() => {
    if (relatorioBMPOGerado.length)
      baixarRelatorio();
  }, [relatorioBMPOGerado]);

  const baixarRelatorio = () => {
    let link = document.getElementById('relatorioLink');
    link?.click();
  };

  return (
    <>
      <Fade in mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>
          <PageHeader showBotaoVoltar titulo='BMPO - Balanço de Medicamento' />

          <div className={`flex-column w-100 ${styles.formPadding}`}>

            <div className={`flex-column ${styles.formContainer}`}>
              <div className={`flex-column w-100 h-100`}>

                <RelatorioBalancoMedicamentoForm
                  listaDataTipo={listaDataTipo}
                  listaTrimestre={listaTrimestre}
                  formState={formState}
                  register={register}
                  formBMPO={formBMPO}
                  setFormBMPO={setFormBMPO}
                  errorValidationfarmaceutico={errorValidationfarmaceutico}
                  setErrorValidationfarmaceutico={res => setErrorValidationfarmaceutico(res)}
                />
              </div>
            </div>
            <a id="relatorioLink" href={relatorioBMPOGerado} target="_blank" style={relatorioDisplay} />
            <div className={`flex-row w-100 ${styles.buttonContainer}`}>
              <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                <Button onClick={() => !carregando ? gerarRelatorio() : {}} variant="text" className="h-100 w-100">
                  {carregando ? <CircularLoading tipo="FULLSIZED" /> :
                    <>
                      <img src={NotaFiscalIcon} alt="" style={{ marginRight: '2%' }} />
                      <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                        Gerar Relatório
                      </div>
                    </>
                  }
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}