export class AutorizacaoOperacaoPendenteListagem {
  id?: string;
  dataHoraLancamento?: Date;
  operacaoAutorizacaoId?: string;
  status?: StatusAutorizacaoEnum;
  operacaoTipo?: OperacaoAutorizacaoEnum;
  usuarioAptoAutorizar?: boolean;
  operacaoAptaAutorizar?: boolean;

  compra?: OperacaoPendenteCompra;
  dispensacao?: OperacaoPendenteDispensacao;
  inventario?: OperacaoPendenteInventario;
  perda?: OperacaoPendentePerda;
  transferencia?: OperacaoPendenteTransferencia;
}

export class OperacaoPendenteCompra {
  dataHoraLancamento?: Date;
  dataNotaFiscal?: Date;
  numeroNotaFiscal?: string;
  fornecedor?: OperacaoPendenteCompraFornecedorListagem;
  medicamentos?: AutorizacaoOperacaoPendenteMedicamentoListagem[];
};

export class OperacaoPendenteDispensacao {
  dataHoraLancamento?: Date;
  numeroNotaFiscal?: string;
  medicamentos?: AutorizacaoOperacaoPendenteMedicamentoListagem[];
};

export class OperacaoPendenteInventario {
  data?: Date;
  quantidadeTotalMedicamentos?: number;
  salvarAutomaticamente?: boolean;
  inventarioContinuado?: boolean;
  medicamentos?: AutorizacaoOperacaoPendenteMedicamentoListagem[];
};

export class OperacaoPendentePerda {
  dataHoraLancamento?: Date;
  motivoPerda?: number;
  medicamentos?: AutorizacaoOperacaoPendenteMedicamentoListagem[];
};

export class OperacaoPendenteTransferencia {
  tipoOperacaoTransferencia?: ETipoOperacaoTransferencia;
  numeroNotaFiscal?: string;
  dataHoraLancamento?: Date;

  destino?: OperacaoPendenteTransferenciaFilialListagem;
  origem?: OperacaoPendenteTransferenciaFilialListagem;

  medicamentos?: AutorizacaoOperacaoPendenteMedicamentoListagem[];
};

export class OperacaoPendenteCompraFornecedorListagem {
  nome?: string;
  cnpj?: string;
  ie?: string;
  telefone?: string;

  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  ufId?: number;
  municipioId?: number;

  licencaMunicipal?: string;
  licencaMunicipalExpiracao?: Date;
  licencaAnvisa?: string;
  licencaAnvisaExpiracao?: Date;
};

export class OperacaoPendenteDispensacaoPrescritorListagem {
  nome?: string;
  especialidade?: string;
  conselho?: number;
  inscricao?: string;
  conselhoUFId?: number;

  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  municipioId?: number;
  uFId?: number;
};

export class OperacaoPendenteDispensacaoCompradorListagem {
  nome?: string;
  sexo?: number;
  telefone?: string;
  dataNascimento?: Date;
  tipoDocumento?: number;
  numeroDocumento?: string;
  orgaoExpedidor?: number;
  ufOrgaoExpedidorId?: number;

  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  ufId?: number;
  municipioId?: number;
};

export class OperacaoPendenteTransferenciaFilialListagem {
  cnpj?: string;
  nome?: string;
};

export class AutorizacaoOperacaoPendenteMedicamentoListagem {
  medicamentoId?: string;
  medicamentoNome?: string;
  codigoAnvisa?: string;
  vendaControlada?: boolean;
  vpmc?: number;
  motivoIsencao?: string;
  lote?: string;
  validade?: Date;
  fabricacao?: Date;
  codigoAgregador?: number;
  usoProlongado?: boolean;
  receita?: AutorizacaoOperacaoPendenteMedicamentoReceita;
}

export class AutorizacaoOperacaoPendenteMedicamentoReceita {
  numeroReceita?: number;
  receitaTipo?: number;
  dataHoraLancamento?: Date;
  dataReceita?: Date;
  uf?: string;
  cid?: string;
  compradorPacienteMesmaPessoa?: boolean;

  prescritor?: AutorizacaoOperacaoPendenteMedicamentoReceitaPrescritor;
  comprador?: AutorizacaoOperacaoPendenteMedicamentoReceitaComprador;
  paciente?: AutorizacaoOperacaoPendenteMedicamentoReceitaPaciente;
}

export class AutorizacaoOperacaoPendenteMedicamentoReceitaPrescritor {
  nome?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  municipio?: string;
  codMunicipio?: number;
  uf?: string;
  especialidade?: string;
  tipoDocumento?: number;
  documento?: string;
  orgaoExpedidor?: number;
  ufOrgaoExpedidor?: string;
}

export class AutorizacaoOperacaoPendenteMedicamentoReceitaComprador {
  nome?: string;
  sexo?: number;
  telefone?: string;
  dataNascimento?: Date;
  tipoDocumento?: number;
  documento?: number;
  orgaoExpedidor?: number;
  ufOrgaoExpedidor?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  uf?: string;
  municipio?: string;
  codMunicipio?: number;
}

export class AutorizacaoOperacaoPendenteMedicamentoReceitaPaciente {
  nome?: string;
  sexo?: number;
  telefone?: string;
  dataNascimento?: Date;
  tipoDocumento?: number;
  documento?: number;
  orgaoExpedidor?: number;
  ufOrgaoExpedidor?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  uf?: string;
  municipio?: string;
  codMunicipio?: number;
}

export enum OperacaoAutorizacaoEnum {
  Compra = 0,
  Venda = 1,
  Transferencia = 2,
  Inventario = 3,
  Perda = 4,
  DispensacaoPJ = 5,
  Outros = 9
}

export enum StatusAutorizacaoEnum {
  Pendente = 1,
  Falha = 2
}

export const OperacaoAutorizacaoEnumDescricao = new Map<OperacaoAutorizacaoEnum, string>([
  [OperacaoAutorizacaoEnum.Compra, 'Compras'],
  [OperacaoAutorizacaoEnum.Venda, 'Dispensação'],
  [OperacaoAutorizacaoEnum.Transferencia, 'Transferência'],
  [OperacaoAutorizacaoEnum.Inventario, 'Inventário'],
  [OperacaoAutorizacaoEnum.Perda, 'Perda'],
  [OperacaoAutorizacaoEnum.DispensacaoPJ, 'Dispensação - PJ'],
  [OperacaoAutorizacaoEnum.Outros, 'Outros'],
]);

export enum ETipoOperacaoTransferencia {
  Saida = 1,
  Entrada = 2,
};

export const ETipoOperacaoTransferenciaDescricao = new Map<ETipoOperacaoTransferencia, string>([
  [ETipoOperacaoTransferencia.Saida, "Saída"],
  [ETipoOperacaoTransferencia.Entrada, "Entrada"],
]);

export class RequestAutorizacaoAprovacaoReprovacao {
  constructor(
    operacaoPendenteId: string,
    autorizado: boolean,
  ) {
    this.operacaoPendenteId = operacaoPendenteId;
    this.autorizado = autorizado
  }
  operacaoPendenteId: string;
  autorizado: boolean;
}
