import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoginFormModel } from '../../../../../model/app/forms/master/login-form';
import { CircularLoading, makeUtilClasses, useThemeQueries } from '../../../../../views';
import { TextFieldSaurus } from '../../../controles/inputs/text-field-saurus';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useStyles } from './form-login-styles';
import { useFormLoginValidation } from './form-login-validation';

export const FormLogin = React.forwardRef<
  DefaultFormRefs<LoginFormModel>,
  DefaultFormProps<LoginFormModel>
>((props: DefaultFormProps<LoginFormModel>, refs) => {
  const txtLogin = useRef<HTMLInputElement>();
  const txtSenha = useRef<HTMLInputElement>();
  const { FormLoginYupValidation } = useFormLoginValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<LoginFormModel>({
    resolver: yupResolver(FormLoginYupValidation),
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const onSubmit = (form: LoginFormModel) => {
    props.onSubmit(form);
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        var login = "";
        var senha = "";
        if (txtLogin.current != undefined)
          login = txtLogin?.current.value;
        if (txtSenha.current != undefined)
          senha = txtSenha?.current?.value;

        onSubmit(new LoginFormModel(login, senha));
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      onSubmit(new LoginFormModel(txtLogin.current?.value, txtSenha.current?.value));
    },
    resetForm: () => {
      reset();
      if (!isMobile) txtLogin?.current?.focus();
    },
    fillForm: (model: LoginFormModel) => {
      reset();
      if (!isMobile) txtLogin?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container className={classes.containerMain} >

            <Grid item xs={12}>
              <Controller
                name="login"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    size='medium'
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtLogin}
                    allowSubmit={false}
                    autoComplete={'off'}
                    labelCustom="Login"
                    placeholder="Login"
                    variant='outlined'
                    error={Boolean(errors.login && errors.login.message)}
                    helperText={
                      touchedFields.login || errors.login
                        ? errors.login?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                mt={2}
                mb={Boolean(touchedFields.senha && errors.senha) ? 0 : 1.5}
              >
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit={true}
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      inputRef={txtSenha}
                      autoComplete={'off'}
                      labelCustom="Senha"
                      placeholder="Digite sua Senha"
                      variant='outlined'
                      error={Boolean(errors.senha && errors.senha.message)}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
});
