import { Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { EnumCadastroTipo } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { useStyles } from "../dispensacao-cadastro-style";
import { DispensacaoStepsProp } from "../models/dispensacao-props";

export const DispensacaoPrescritorForm = (({ visualizacao, dispensacao, setDispensacao, errorValidation }: DispensacaoStepsProp) => {
  const styles = useStyles();
  const [erroPrescritor, setErroPrescritor] = useState(false);

  const setPrescritor = useCallback((prescritor) => {

    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.prescritor = prescritor;
      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  return (
    <>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Prescritor</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados do Prescritor</Typography>
        </div>
        <div className={`flex-column h-100 ${styles.innerFormContainer}`}>
          <div className={`w-100 ${styles.inputContainer}`}>
            <AutocompletePessoas label="Prescritor"
              name="prescritor"
              tipo={EnumCadastroTipo.TODOS}
              value={dispensacao.medicamentos[0].receita?.prescritor?.nome ?? ""}
              disabled={visualizacao}
              size="medium"
              onBlur={() => {
                if (!dispensacao.medicamentos[0].receita?.prescritor?.nome)
                  setErroPrescritor(true);
              }}
              onChange={(a) => setPrescritor(a.value)}
              error={Boolean((errorValidation || erroPrescritor) && !dispensacao.medicamentos[0].receita?.prescritor?.nome)}
              helperText={
                (errorValidation || erroPrescritor) && !dispensacao.medicamentos[0].receita?.prescritor?.nome
                  ? "Prescritor será obrigatório na autorização!"
                  : undefined
              }
            />
          </div>
        </div>

      </div>
    </>
  );
});