import { Button, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { EnumCadastroTipo } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { AvancarIcon, VoltarIcon } from "../../../../components/icons";
import { useStyles } from "../dispensacao-avulsa-cadastro-style";
import { DispensacaoAvulsaFormPrescritor, EDispensacaoAvulsaPassos } from "../models/dispensacao-avulsa-models";
import { DispensacaoAvulsaStepsProp } from "../models/dispensacao-avulsa-props";

export const DispensacaoAvulsaPrescritorForm = (({ dispensacao, setDispensacao, avancarStep, errorValidation }: DispensacaoAvulsaStepsProp) => {
  const styles = useStyles();
  const [erroPrescritor, setErroPrescritor] = useState(false);

  const setPrescritor = useCallback((prescritor: DispensacaoAvulsaFormPrescritor) => {
    setDispensacao({ ...dispensacao, prescritor: { ...prescritor } });
  }, []);

  const proximoStep = () => {
    avancarStep(EDispensacaoAvulsaPassos.Comprador);
  }

  const voltarStep = () => {
    avancarStep(EDispensacaoAvulsaPassos.Receita);
  }

  return (
    <>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Prescritor</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados do Prescritor</Typography>
        </div>
        <div className={`flex-column h-100 ${styles.innerFormContainer}`}>
          <div className={`w-100`}>
            <AutocompletePessoas label="Prescritor"
              tipo={EnumCadastroTipo.TODOS}
              value={dispensacao.prescritor?.nome}
              size="medium"
              onBlur={() => {
                if (!dispensacao.prescritor?.nome)
                  setErroPrescritor(true);
              }}
              onChange={(a) =>
                setPrescritor(a.value)
              }
              error={Boolean((errorValidation || erroPrescritor) && !dispensacao.prescritor?.nome)}
              helperText={
                (errorValidation || erroPrescritor) && !dispensacao.prescritor?.nome
                  ? 'Prescritor é obrigatório!'
                  : undefined
              }
            />
          </div>
        </div>

        <div className={`flex-row w-100 ${styles.buttonContainer}`}>

          <div className={`${styles.buttonStyle} ${styles.whiteButton}`}>
            <Button variant="text" className="h-100 w-100" onClick={() => voltarStep()} >
              <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                <VoltarIcon class={styles.iconSize} />
                Voltar
              </div>
            </Button>
          </div>

          <div className={`${styles.buttonStyle} ${styles.darkButton}`}>
            <Button variant="text" className="h-100 w-100" onClick={() => proximoStep()} >
              <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                <AvancarIcon class={styles.iconSize} />
                Avançar
              </div>
            </Button>
          </div>
        </div>

      </div>
    </>
  );
});