import {
  Fade,
  Typography
} from "@material-ui/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAprovarAutorizacao } from "../../../../../data/api/gestao/autorizacao/autorizacao-aprovar";
import { useGetAutorizacaoById } from '../../../../../data/api/gestao/autorizacao/autorizacao-by-id';
import { useGetListAutorizacao } from "../../../../../data/api/gestao/autorizacao/autorizacao-get-list";
import { useReprovarAutorizacao } from "../../../../../data/api/gestao/autorizacao/autorizacao-reprovar";
import { useInventario } from "../../../../../data/api/gestao/inventario/inventario";
import { useToastSaurus } from "../../../../../services/app";
import { CircularLoading } from "../../../../components";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import { Paginacao } from '../../../../components/paginacao';
import { ControlePaginacao } from "../../../../components/paginacao/models/controle-paginacao.model";
import { AutorizacaoCard } from "../autorizacao-card/autorizacao-card";
import { LegendaStatus } from "../legenda-status/legenda-status";
import { AutorizacaoOperacaoPendenteListagem } from "../model/autorizacao.model";
import { useStyles } from './autorizacao-list-style';

export const AutorizacaoListPage = () => {

  const classes = useStyles();

  const [controlePaginacao, setControlePaginacao] = useState(new ControlePaginacao<AutorizacaoOperacaoPendenteListagem>(1, 10, 0, 0, []));

  const { getInventarioAndamento, carregando: carregandoInventarioAndamento } = useInventario();
  const { getListAutorizacao, carregando: carregandoListaAutorizacao } = useGetListAutorizacao();
  const { reprovarAutorizacao, carregando: carregandoReprovarAutorizacao } = useReprovarAutorizacao();
  const { aprovarAutorizacao, carregando: carregandoAprovarAutorizacao } = useAprovarAutorizacao();
  const { getAutorizacaoById, carregando: carregandoMedicamentoById } = useGetAutorizacaoById();
  const { getMedicamentoSemEstoque, carregando: carregandoMedicamentoSemEstoque } = useGetAutorizacaoById();
  const [inventarioAndamento, setInventarioAndamento] = useState(false);

  const [timer, setTimer] = useState({} as NodeJS.Timeout);

  const { showToast } = useToastSaurus();

  const listarAutorizacoesPaginado = useCallback(async (currentPage: number, filter: string = "") => {
    try {
      const res = await getListAutorizacao(currentPage, controlePaginacao.pageSize, filter);

      if (res.erro)
        throw res.erro;

      setControlePaginacao(
        prev => (
          {
            ...prev,
            totalPages: res.resultado?.data.totalPages,
            totalRegisters: res.resultado?.data.totalResults,
            data: res.resultado?.data.list,
            currentPage: currentPage
          }));

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      showToast('error', e.message);
    }
  }, [getListAutorizacao, showToast]);

  const verificaInventarioAndamento = async () => {

    try {

      const resultInventarioAndamento = await getInventarioAndamento();

      if (resultInventarioAndamento.erro)
        throw resultInventarioAndamento.erro;

      if (resultInventarioAndamento?.resultado?.data === false) {
        setInventarioAndamento(false);
      }
      else
        setInventarioAndamento(true);

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      toast.error(e.message);
    }

  }

  const aprovar = async (operacaoPendenteId: string, autorizado: boolean = true) => {
    const res = await getAutorizacaoById(operacaoPendenteId);

    if (res.erro) {
      showToast("error", `${res.erro}`, 5000, 'top-center');
      return;
    }

    if (res.resultado?.data.compra != null) {
      const hasMedicamentoSemLote = res.resultado?.data.compra.medicamentos.some((x: any) => x.lote);

      if (!hasMedicamentoSemLote) {
        showToast("error", "Não é possível autorizar uma compra com medicamentos sem lote preenchido!", 5000, 'top-center');
        return;
      }
    }

    if (res.resultado?.data.perda != null) {
      const hasMedicamentoSemLote = res.resultado?.data.perda.medicamentos.some((x: any) => x.lote);

      if (!hasMedicamentoSemLote) {
        showToast("error", "Não é possível autorizar uma perda com medicamentos sem estoque!", 5000, 'top-center');
        return;
      }
    }

    if (res.resultado?.data.dispensacao != null) {
      const hasMedicamentoSemLote = res.resultado?.data.dispensacao.medicamentos.some((x: any) => x.lote);

      if (!hasMedicamentoSemLote) {
        showToast("error", "Não é possível autorizar uma dispensação com medicamentos sem estoques!", 5000, 'top-center');
        return;
      }

      const resMedicamentoLoteSemEstoque = await getMedicamentoSemEstoque(operacaoPendenteId);

      if (resMedicamentoLoteSemEstoque.erro) {
        showToast("error", `${resMedicamentoLoteSemEstoque.erro}`, 5000, 'top-center');
        return;
      }

    }

    try {

      const resultAutorizar = await aprovarAutorizacao(operacaoPendenteId, autorizado);

      if (resultAutorizar.erro) {
        showToast("error", `${resultAutorizar.erro}`, 5000, 'top-center');
        return;
      }

      await verificaInventarioAndamento();

      listarAutorizacoesPaginado(controlePaginacao.currentPage);
      showToast('success', "Operação autorizada com sucesso!", 5000, 'top-center');

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      showToast("error", `Erro ao autorizar a operação: ${e.message}`, 5000, 'top-center');
    }

  }

  const reprovar = async (operacaoPendenteId: string, autorizado: boolean = false) => {

    try {

      await reprovarAutorizacao(operacaoPendenteId, autorizado);
      await verificaInventarioAndamento();
      listarAutorizacoesPaginado(controlePaginacao.currentPage);
      showToast('success', "Operação não autorizada com sucesso!", 5000, 'top-center');

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      showToast("error", `Erro ao não autorizar a operação: ${e.message}`, 5000, 'top-center');
    }

  }

  const atualizarLista = () => {
    listarAutorizacoesPaginado(controlePaginacao.currentPage);
  }

  const pageChanged = (currentPage: number) => {
    listarAutorizacoesPaginado(currentPage);
  }

  const isLoading = () =>
    carregandoAprovarAutorizacao ||
    carregandoReprovarAutorizacao ||
    carregandoListaAutorizacao ||
    carregandoInventarioAndamento ||
    carregandoMedicamentoSemEstoque ||
    carregandoMedicamentoById;

  useEffect(() => {
    verificaInventarioAndamento();
    listarAutorizacoesPaginado(1);
  }, [])

  const filtroListagem = (res: string) => {
    clearTimeout(timer!);
    const newTimer = setTimeout(() => {
      listarAutorizacoesPaginado(1, res);
    }, 500);
    setTimer(newTimer);
  }

  return (
    <>
      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      <Fade in mountOnEnter unmountOnExit>

        <div className={`flex-column w-100 h-100 ${classes.color}`}>
          <PageHeader
            showSearchableBar
            showBotaoFiltrar
            showMenu
            showBotaoReload
            titulo='Autorizações'
            filtroListagem={res => filtroListagem(res)}
            botaoReload={async () => await listarAutorizacoesPaginado(1)} />
          <>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={controlePaginacao.totalPages}
              totalRegisters={controlePaginacao.totalRegisters}
              currentPage={controlePaginacao.currentPage}
            />

            <div className={`flex-column w-100 h-100 mb-20 overflow-auto ${classes.listPadding}`}>

              <div className={`flex-column h-100 w-100 ${classes.listContainer}`}>

                {
                  !controlePaginacao.data.length && (
                    <div className={classes.containerNenhumaAutorizacaoEncontrada}>
                      <Typography className={classes.spanNenhumaAutorizacaoEncontrada}>
                        <strong>
                          Não foi encontrada nenhuma Operação Pendente de  Autorização!
                        </strong>
                      </Typography>
                    </div>
                  )
                }

                {controlePaginacao.data.map((autorizacaoOperacao) => (
                  <div key={autorizacaoOperacao.id} className='flex-column w-100'>
                    <AutorizacaoCard
                      inventarioAndamento={inventarioAndamento}
                      operacao={autorizacaoOperacao}
                      aprovar={aprovar}
                      reprovar={reprovar}
                      atualizarLista={atualizarLista}
                    />
                  </div>
                ))}

              </div>

            </div>

            <LegendaStatus />
          </>

        </div>

      </Fade >
    </>
  );
}
