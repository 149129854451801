import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useMenuPrincipal } from '../../../../services/app/providers/menu-principal-provider';
import { useMenuStorage } from '../../../../services/app/use-cases/menu-storage';
import { useThemeQueries } from '../../../theme';
import { TextFieldSaurus } from '../../controles/inputs';
import { MenuIcon } from '../../icons';
import { PrivatePageHeader } from '../header-private-page';
import { PageHeaderProps } from './header-page-props';
import { useStyles } from './header-page-style';
import { RecarregarIcon } from '../../icons/recarregar-icon';

export enum OperacaoMenuEnum {
    Compra = 0,
    Venda = 1,
    Transferencia = 2,
    Inventario = 3,
    Perda = 4,
    Relatorio = 5,
    SNGPCEnvioMovimentacao = 6,
    SNGPCEnvioInventario = 7,
    Configuracao = 8,
    SNGPCInventarioEnviado = 9,
    Autorizacao = 99
}

export const PageHeader = (props: PageHeaderProps) => {
    const { abrirMenu, alterarModelo, fecharMenu } = useMenuPrincipal();
    const [search, setSearch] = useState("");
    const { get } = useMenuStorage();
    const { isMobile } = useThemeQueries();
    const theme = useTheme();

    const classes = useStyles();
    const history = useHistory();

    const voltar = () => {
        props.customBotaoVoltar !== undefined
            ? props.customBotaoVoltar()
            : history.goBack();
    };

    const controlarMenu = () => {
        const { situacao } = get();

        if (situacao === 'Fechado') {
            alterarModelo("Completo");
            abrirMenu();
            return;
        }

        alterarModelo("Mini");
        fecharMenu();
    };

    const reload = () => {
        if (props.botaoReload !== undefined)
            props.botaoReload();
    };

    const left = () => {
        return (
            <div className={classes.containerHeaderPageSub}>
                {
                    isMobile && (
                        <>
                            <IconButton aria-label="more" aria-haspopup="true" onClick={() => controlarMenu()} style={{ paddingLeft: '0px' }}>
                                <MenuIcon tipo="BUTTON" fill={theme.palette.common.white} style={{ cursor: 'pointer' }} />
                            </IconButton>
                        </>
                    )
                }

                {
                    props.showBotaoVoltar && (
                        <ArrowBackIos className={classes.iconHeaderPage} onClick={() => voltar()}></ArrowBackIos>
                    )
                }
            </div>
        )
    }

    const middle = () => {
        if (props.showSearchableBar)
            return (
                <>
                    <div className={classes.pesquisaContainer}>
                        <div className={classes.pesquisaSize}>
                            <TextFieldSaurus
                                className={classes.inputStyle}
                                fullWidth
                                allowSubmit={false}
                                label={false}
                                searchable
                                size='small'
                                placeholder="Digite aqui alguma informação para procurar..."
                                adornmentColor={theme.palette.text.disabled}
                                value={search}
                                onChange={res => {
                                    setSearch(res.target.value);
                                    if (props.filtroListagem) {
                                        props.filtroListagem(res.target.value.toString());
                                    }
                                }}
                            />
                        </div>
                        {
                            props.showBotaoReload && (
                                <div className={classes.containerHeaderButtonReload}>
                                    <Tooltip title="Atualizar as autorizações">
                                        <IconButton aria-label="more" aria-haspopup="true"
                                            onClick={() => reload()} className={classes.iconButtonReload}>
                                            <RecarregarIcon tipo="BUTTON" fill={isMobile ? theme.palette.common.white : theme.palette.common.black}
                                                style={{ cursor: 'pointer' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )
                        }
                    </div>
                </>
            );
    }

    return (
        <div className={classes.containerHeaderPageMain}>
            <div className={classes.containerHeaderPageSub}>
                <PrivatePageHeader
                    title={props.titulo}
                    leftArea={left()}
                    middleArea={middle()}
                />
            </div>
        </div>
    )
}
