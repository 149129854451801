import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useState } from "react";
import { AutocompleteMedicamento } from "../../../../../components/autocompletes/autocomplete-medicamentos";
import { NovoIcon } from "../../../../../components/icons/novo-icon";
import { InventarioAdicionarMedicamentoProp, InventarioMedicamento } from "../../models/inventario.models";
import { useStyles } from "../inventario-listagem.styles";

export const InventarioMedicamentoSelecao = (({ adicionarMedicamento, dataInventario }: InventarioAdicionarMedicamentoProp) => {
  const [medicamento, setMedicamento] = useState<InventarioMedicamento>({} as InventarioMedicamento);
  const styles = useStyles();

  const handleMedicamentoAutocomplete = useCallback((medicamento: any) => {
    setMedicamento({
      medicamentoId: medicamento.id,
      medicamento: medicamento.descricao,
      codigoAnvisa: medicamento.ms
    } as InventarioMedicamento);
  }, []);

  const adicionar = () => {
    setMedicamento(new InventarioMedicamento());
    adicionarMedicamento(medicamento);
  }

  const handleMedicamentoLabel = (): string =>
    medicamento.codigoAnvisa ? `${medicamento.medicamento} - ${medicamento.codigoAnvisa}` : '';

  return (
    <div className='flex-column w-100'>
      {dataInventario &&
        <Typography variant='h6' >
          Data do Inventário: {dataInventario}
        </Typography>
      }

      <div className={`flex-row w-100 ${styles.buscaContainer}`}>
        <div className={styles.autocompleteStyle}>
          <AutocompleteMedicamento
            value={handleMedicamentoLabel()}
            label='Buscar Medicamento'
            onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
          />
        </div>

        <div className={styles.buttonAdicionarContainer}>
          <div className={classNames(styles.buttonAdicionarStyle, styles.darkButton, 'h-100 w-100')}>
            <Button onClick={() => adicionar()} variant='text' className='h-100 w-100'>
              <NovoIcon class={classNames(styles.iconSize, styles.whiteLabel)} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});