import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
 
  statusColor: {
    backgroundColor: theme.palette.info.main
  },

  inputStyle: {
    width: '15rem',
    [theme.breakpoints.down("md")]: {
      maxWidth: '28%',
    }
  },

}));
