import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Fade } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import NotaFiscalIcon from "../../../../../assets/img/icon-nota-fiscal.svg";
import { useGerarRelatorioRMNRA } from "../../../../../data/api/gestao/relatorios/gerar-relatorio-rmnra";
import { KeyValueModel } from "../../../../../model";
import { useToastSaurus } from "../../../../../services/app";
import { useEmpresaAtualHandler } from "../../../../../services/app/use-cases/empresa-atual-handler";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import { CircularLoading } from "../../../../components/utils/circular-loading";
import { EMeses, EMesesMap, RelatorioMensalReceitaAModel } from "../models/relatorio-models";
import { RelatorioRelacaoMensalReceitaAForm } from "./relatorio-relacao-mensal-receita-a-form";
import { relatorioDisplay, useStyles } from "./relatorio-relacao-mensal-receita-a-styles";

export const RelatorioRelacaoMensalReceitaA = () => {
    const [formRMNRA, setFormRMNRA] = useState({ ano: new Date().getFullYear() } as RelatorioMensalReceitaAModel);
    const [relatorioRMNRAGerado, setRelatorioRMNRAGerado] = useState("");
    const styles = useStyles();

    const [errorValidationfarmaceutico, setErrorValidationfarmaceutico] = useState(false);

    const { gerarRelatorioRMNRA, carregando } = useGerarRelatorioRMNRA();
    const { getEmpresaAtualEndereco } = useEmpresaAtualHandler();
    const { showToast } = useToastSaurus();

    const listaMeses = Object.values(EMeses)
        .filter(e => !isNaN(Number(e))).map(i => i as EMeses)
        .map((mes: EMeses) => new KeyValueModel(mes, EMesesMap.get(mes)));

    const schema = yup
        .object()
        .shape({
            farmaceutico: yup.string().required("O Farmacêutico é obrigatório!"),
            mes: yup.number().required("O Mês é obrigatório!"),
            ano: yup.number().required("O Ano é obrigatório!"),
        });

    const { register, formState, setError } = useForm<RelatorioMensalReceitaAModel>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const handleEmpresaEndereco = useCallback(async (form: RelatorioMensalReceitaAModel) => {
        let enderecoEmpresa = await getEmpresaAtualEndereco();

        if (enderecoEmpresa === undefined)
            return;

        setFormRMNRA({ ...form, enderecoEmpresa });
    }, [getEmpresaAtualEndereco]);

    useEffect(() => {
        if (
            formRMNRA === undefined ||
            formRMNRA?.enderecoEmpresa !== undefined ||
            getEmpresaAtualEndereco === undefined
        ) return;

        handleEmpresaEndereco(formRMNRA);
    }, [formRMNRA, getEmpresaAtualEndereco, handleEmpresaEndereco]);

    useEffect(() => {
        if (relatorioRMNRAGerado.length)
            baixarRelatorio();
    }, [relatorioRMNRAGerado]);

    const gerarRelatorio = async () => {
        validarCampos();

        if (!await schema.isValid(formRMNRA))
            return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

        try {
            let res = await gerarRelatorioRMNRA(formRMNRA);

            if (res.erro)
                throw res.erro;

            if (!res.resultado?.data)
                return;

            setRelatorioRMNRAGerado(res.resultado?.data);
        } catch (e: any) {
            if (axios.isCancel(e))
                return;

            showToast('error', e.message);
        }
    };

    const validarCampos = async () => {
        setErrorValidationfarmaceutico(true);
        try {
            await schema.validate({
                mes: formRMNRA.mes,
                ano: formRMNRA.ano
            }, { abortEarly: false });
        } catch (error) {

            const validationError = error as yup.ValidationError;

            validationError?.inner?.forEach(err => {
                const path = err.path?.toString();

                if (path && Object.prototype.hasOwnProperty.call(schema.fields, path))
                    setErrorField(path, err.message);

            });

            return false;
        }
    }

    function setErrorField(path: string, errorMensage: string) {

        switch (path) {

            case 'mes':
                setError('mes', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'ano':
                setError('ano', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            default:
                break;
        }

    }

    const baixarRelatorio = () => {
        let link = document.getElementById('relatorioLink');
        link?.click();
    };

    return (
        <>
            <Fade in mountOnEnter unmountOnExit>
                <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>
                    <PageHeader showBotaoVoltar titulo='RMNRA - Relação Mensal de Receitas A' />

                    <div className={`flex-column w-100 ${styles.formPadding}`}>

                        <div className={`flex-column ${styles.formContainer}`}>
                            <div className={`flex-column w-100 h-100`}>
                                <RelatorioRelacaoMensalReceitaAForm
                                    listaMeses={listaMeses}
                                    formState={formState}
                                    register={register}
                                    formRMNRA={formRMNRA}
                                    setFormRMNRA={setFormRMNRA}
                                    errorValidationfarmaceutico={errorValidationfarmaceutico}
                                    setErrorValidationfarmaceutico={res => setErrorValidationfarmaceutico(res)}
                                />
                            </div>
                        </div>

                        <a id="relatorioLink" href={relatorioRMNRAGerado} target="_blank" style={relatorioDisplay} />
                        <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                <Button onClick={() => !carregando ? gerarRelatorio() : {}} variant="text" className="h-100 w-100">
                                    {carregando ? <CircularLoading tipo="FULLSIZED" /> :
                                        <>
                                            <img src={NotaFiscalIcon} alt="" style={{ marginRight: '2%' }} />
                                            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                                Gerar Relatório
                                            </div>
                                        </>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade >
        </>

    );
} 