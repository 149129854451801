import { makeStyles } from "@material-ui/core";

export const useDefaultCardStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: 0,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "transparent",
    transition: "0.3s ease-in-out border",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    display: "inline-table",
    marginTop: "5px",
  },
  cardPadding: {
    padding: '0.25rem 0.5rem',
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
  },
  tagStatus: {
    width: "5px",
    borderRadius: "5px",
    margin: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardIcon: {
    width: "50px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
  },
  cardColor: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    borderColor: theme.palette.grey[700],
    borderStyle: "solid",
    marginBottom: 10,
    margin: 0,
    boxShadow: theme.shadows[6],
  },
  cardRightButton: {
    width: "50px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: `1px solid ${theme.palette.text.primary}`
    }
  },
  cardRightButtonProcesso: {
    width: "50px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
  },
  cardContent: {
    flex: "1 1 100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    // "& div:last-child": {
    //   marginRight: theme.spacing(1),
    // },
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  cardContentMobile: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: 'column',
    paddingRight: theme.spacing(1),
    paddingTop: "3px",
    // "& div:last-child": {
    //   marginRight: theme.spacing(1),
    // },
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  celulaGrid: {
    marginLeft: theme.spacing(2),
    minWidth: 0,
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  celulaGridFull: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },

  celulaGridFullDescriptionFull: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "& p": {
      maxWidth: '600px',
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: 'ellipsis',
      fontWeight: 600
    }
  },

  celulaGridFullMobile: {
    marginLeft: theme.spacing(1),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      maxWidth: '270px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: 600
    }
  },

  celulaGridFullMobileDialog: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    flexWrap: "wrap",

    overflow: "hidden",
    "& p": {
      overflow: 'hidden',
    }
  },

  cardImg: {
    display: "flex",
    objectFit: "cover",
    zIndex: 1,
    position: 'relative',
    background: theme.palette.common.white,
    width: "50px",
    height: "50px",
    borderRadius: "5px",
  },
  cardinativo: {
    opacity: 0.6,
    filter: 'grayscale(1)',
  },

  celulaGridDescricao: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    minWidth: 0,
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },

  infoContainer: {
    margin: '0.3rem 0.5rem',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexGrow: 1,
    gap: '7%',
    minWidth: '75%'
  },

  labelFont: {
    fontWeight: 800,
    fontSize: "0.7rem",
    paddingRight: '0.7rem',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("md")]: {
      fontSize: "0.65rem",
    }
  },

  contentFont: {
    fontWeight: 700,
    fontSize: "0.7rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "0.75rem",
    }
  },

  infoTagContainer: {
    margin: "0 0.5%",
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },

  infoTagStyle: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    width: '1.5rem',
    height: '1.5rem',
    padding: '0.3rem',
    paddingTop: '0.4rem',
    borderRadius: '3px',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
  },

  inputMinWidth: {
    width: '9rem',
    [theme.breakpoints.down("md")]: {
      maxWidth: '28%',
    }
  },

  mainInputMinWidth: {
    width: '9rem',
    [theme.breakpoints.down("md")]: {
      width: '100%',
    }
  },
}));

