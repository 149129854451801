import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Fade } from '@material-ui/core';
import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from "yup";
import { CircularLoading } from '../../../../..';
import MedicamentoIcon from "../../../../../../assets/img/icon-medicamento.svg";
import MovimentacaoIcon from "../../../../../../assets/img/icon-movimentacao.svg";
import { useGetAutorizacaoById } from '../../../../../../data/api/gestao/autorizacao/autorizacao-by-id';
import { useEditarOperacaoTransferencia, useGetTransferenciaById } from '../../../../../../data/api/gestao/transferencias/transferencias';
import { TokenSngpcEmpresaModel } from '../../../../../../model/api/gestao/master/token-sngpc-empresa';
import { useSessaoAtual, useToastSaurus } from '../../../../../../services/app';
import { formatarDataInput } from '../../../../../../utils/formatDateInput';
import { ButtonModalHeader } from '../../../../../components/controles/buttons/button-modal-header';
import { AvancarIcon, SalvarIcon, VoltarIcon } from '../../../../../components/icons';
import { ModalHeader } from '../../../../../components/modal-header';
import { Wizard } from "../../../../../components/wizard/wizard-component";
import { WizardStep } from '../../../../../components/wizard/wizard-model';
import { ETipoOperacaoMovimentacao } from '../../../envio-movimentacao/models/envio-movimentacao.model';
import { ControleMedicamentoLotes, ETipoOperacaoTransferencia, ETransferenciaPassos, MedicamentoTransferencia, OperacaoTransferenciaRequest, TransferenciaFilial, TransferenciaMedicamentoLote, TransferenciaModel } from '../../models/transferencias.model';
import { useStyles } from '../cadastro/transferencias-cadastro-styles';
import { TransferenciaLancamentoForm } from './transferencias-lancamento-form';
import { TransferenciaMedicamentosForm } from './transferencias-medicamentos-form';
import { TransferenciaMedicamentoLotes } from './transferencias-medicamentos-lote/transferencias-medicamentos-lote';

export const TransferenciaCadastroPage = (props: any) => {
  const [passoTransferencia, setPassoTransferencia] = useState(0);
  const [formTransferencia, setFormTransferencia] = useState({} as TransferenciaModel);
  const [controleMedicamentos, setControleMedicamentos] = useState<ControleMedicamentoLotes>(new ControleMedicamentoLotes());
  const [isVisualizacao, setIsVisualizacao] = useState(false);
  const [empresasFiliais, setEmpresasFiliais] = useState<TokenSngpcEmpresaModel[]>([]);
  const { getEmpresaSelecionada, getEmpresasVinculadas } = useSessaoAtual();
  const [isLoading, setIsLoading] = useState(true);

  const styles = useStyles();

  const { getAutorizacaoById } = useGetAutorizacaoById();
  const { getTransferenciaById } = useGetTransferenciaById();
  const { editarOperacaoTransferencia } = useEditarOperacaoTransferencia();
  const { showToast, showToastPersonalizado } = useToastSaurus();

  const operacaoId = props.operacaoId;
  const transferenciaId = props.transferenciaId;
  const dataCompetencia = props.dataCompetencia;

  const schema = yup
    .object()
    .shape({
      tipoOperacaoTransferencia: yup.number().required("Tipo de Operação é obrigatório!"),
      numeroNotaFiscal: yup.string().required("Nº da Nota Físcal é obrigatória!"),
      origemCNPJ: yup.string().when("tipoOperacaoTransferencia", (tipoOperacaoTransferencia) =>
        tipoOperacaoTransferencia === ETipoOperacaoTransferencia.entrada
          ? yup.string().required("Origem é obrigatória!")
          : yup.string()
      ),
      destinoCNPJ: yup.string().when("tipoOperacaoTransferencia", (tipoOperacaoTransferencia) =>
        tipoOperacaoTransferencia === ETipoOperacaoTransferencia.saida
          ? yup.string().required("Destino é obrigatório!")
          : yup.string()
      ),
      data: yup.string().required("Data é obrigatória!"),
      hora: yup.string().required("Hora é obrigatória!"),
    });

  const {
    register,
    formState,
    setError
  } = useForm<TransferenciaModel>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (
      getEmpresaSelecionada === undefined ||
      getEmpresasVinculadas === undefined ||
      empresasFiliais.length > 0
    ) return;

    let empresas = getEmpresasVinculadas();

    if (empresas !== undefined && empresas?.length > 0) {
      setEmpresasFiliais(empresas.filter(e =>
        e.Descricao !== getEmpresaSelecionada()?.Descricao
      ))
    }
  }, [getEmpresaSelecionada, getEmpresasVinculadas])

  const getTransferencia = useCallback(async () => {
    try {
      const res = await getAutorizacaoById(operacaoId);

      if (res.erro) {
        props.fecharModal(true);
        setIsLoading(false);
        return showToast("error", `${res.erro}`, 5000, 'top-center');
      }


      const transferencia = res.resultado?.data.transferencia;

      setFormTransferencia(new TransferenciaModel(
        res.resultado?.data.id,
        res.resultado?.data.operacaoAutorizacaoId,
        transferencia.tipoOperacaoTransferencia,
        transferencia.origem?.cnpj,
        transferencia.origem,
        transferencia.destino?.cnpj,
        transferencia.destino,
        transferencia.numeroNotaFiscal,
        formatarDataInput(transferencia.dataHoraLancamento).data,
        formatarDataInput(transferencia.dataHoraLancamento).hora,
        transferencia.medicamentos
      ));
      setIsLoading(false);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getAutorizacaoById, operacaoId]);

  useEffect(() => {
    if (!operacaoId)
      return;

    getTransferencia();
  }, [operacaoId]);

  const getTransferenciaVisualizacao = useCallback(async () => {
    try {
      setIsVisualizacao(true);
      const res = await getTransferenciaById(transferenciaId, dataCompetencia);

      if (res.erro) {
        props.fecharModal(true);
        setIsLoading(false);
        return showToast("error", `${res.erro}`, 5000, 'top-center');
      }

      const transferencia = res.resultado?.data;

      setFormTransferencia(new TransferenciaModel(
        res.resultado?.data.id,
        res.resultado?.data.operacaoAutorizacaoId,
        transferencia.tipoOperacaoTransferencia,
        transferencia.origem?.cnpj,
        transferencia.origem,
        transferencia.destino?.cnpj,
        transferencia.destino,
        transferencia.numeroNotaFiscal,
        formatarDataInput(transferencia.dataHoraLancamento).data,
        formatarDataInput(transferencia.dataHoraLancamento).hora,
        transferencia.medicamentos
      ));
      setIsLoading(false);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getTransferenciaById, transferenciaId]);

  useEffect(() => {
    if (!transferenciaId)
      return;

    getTransferenciaVisualizacao();
  }, [transferenciaId]);

  const editarMedicamentos = useCallback((medicamentos: MedicamentoTransferencia[]) => {
    setControleMedicamentos({
      exibir: true,
      medicamentoLotes: medicamentos,
    });
  }, []);

  const salvarLotes = (lotesSelecionados: TransferenciaMedicamentoLote[], medicamento: MedicamentoTransferencia) => {
    let medicamentos = new Array<MedicamentoTransferencia>();

    if (!lotesSelecionados?.length) {
      setControleMedicamentos(new ControleMedicamentoLotes());
      return showToast("error", "Nenhum lote foi salvo!");
    }

    while (lotesSelecionados?.length) {
      const loteAtual = lotesSelecionados[0];

      medicamentos.push({
        ...medicamento,
        lote: loteAtual.lote,
        validade: new Date(loteAtual.dataValidade),
        fabricacao: loteAtual.dataFabricacao
          ? new Date(loteAtual.dataFabricacao)
          : undefined
      } as MedicamentoTransferencia);

      lotesSelecionados[0].quantidadeTransferida -= 1;
      if (loteAtual.quantidadeTransferida === 0)
        lotesSelecionados = lotesSelecionados.slice(1);
    }

    setFormTransferencia({ ...formTransferencia, medicamentos });
    setControleMedicamentos(new ControleMedicamentoLotes());
  }

  const steps: WizardStep[] = [
    {
      label: 'Dados da Transferência',
      icon: <img src={MovimentacaoIcon} alt="" />
    },
    {
      label: 'Medicamentos',
      icon: <img src={MedicamentoIcon} alt="" />
    }
  ];

  const salvarTransferencia = async (deveAutorizar: boolean) => {
    var formIsValid = await schema.isValid(formTransferencia);

    if (!formIsValid)
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

    if (!formTransferencia?.medicamentos?.length)
      return showToast("error", "Nenhum medicamento adicionado!", 5000, 'top-center');

    try {

      setIsLoading(true);
      const empresaSelecionada = getEmpresaSelecionada();
      const empresas = getEmpresasVinculadas();

      let origemSngpc = isEntrada()
        ? empresas?.find(x => x.Documento === formTransferencia.origemCNPJ)
        : empresaSelecionada;

      let destinoSngpc = !isEntrada()
        ? empresas?.find(x => x.Documento === formTransferencia.destinoCNPJ)
        : empresaSelecionada;

      let transferenciaRequest = {
        ...formTransferencia,
        dataHoraLancamento: new Date(`${formTransferencia.data}T${formTransferencia.hora}:00.000Z`),
        origem: {
          nome: origemSngpc?.Descricao ?? "",
          cnpj: origemSngpc?.Documento ?? ""
        } as TransferenciaFilial,
        destino: {
          nome: destinoSngpc?.Descricao ?? "",
          cnpj: destinoSngpc?.Documento ?? ""
        } as TransferenciaFilial,
      }

      const transferencia = new OperacaoTransferenciaRequest(
        transferenciaRequest.id,
        transferenciaRequest.operacaoAutorizacaoId,
        ETipoOperacaoMovimentacao.Transferencia,
        transferenciaRequest.dataHoraLancamento,
        deveAutorizar,
        transferenciaRequest
      );

      const operacaoSalva = await editarOperacaoTransferencia(transferencia);

      if (operacaoSalva.erro) {
        setIsLoading(false);
        return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
      }

      showToast("success", deveAutorizar
        ? 'Transferência autorizada com sucesso!'
        : 'Transferência editada com sucesso!');

      setIsLoading(false);
      props.fecharModal();

    } catch (e: any) {

      showToast("error", e.message, 500, 'top-center');
      setIsLoading(false);

    }

  };

  const isEntrada = () =>
    formTransferencia.tipoOperacaoTransferencia === ETipoOperacaoTransferencia.entrada;

  const goBack = () =>
    controleMedicamentos.exibir
      ? setControleMedicamentos(new ControleMedicamentoLotes())
      : props.fecharModal();

  const avancarStep = async (passo: number) => {
    if (passoTransferencia === passo) return;

    return await validarTransferencia()
      ? setPassoTransferencia(passo)
      : showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
  }

  const validarTransferencia = async () => {

    try {
      await schema.validate({
        tipoOperacaoTransferencia: formTransferencia.tipoOperacaoTransferencia,
        numeroNotaFiscal: formTransferencia.numeroNotaFiscal,
        origemCNPJ: formTransferencia.origemCNPJ,
        destinoCNPJ: formTransferencia.destinoCNPJ,
        data: formTransferencia.data,
        hora: formTransferencia.hora
      }, { abortEarly: false });

      return true;

    } catch (error) {

      const validationError = error as yup.ValidationError;

      validationError?.inner?.forEach(err => {
        const path = err.path?.toString();

        if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
          setErrorField(path, err.message);
        }

      });

      return false;
    }

  }

  function setErrorField(path: string, errorMensage: string) {

    switch (path) {

      case 'numeroNotaFiscal':
        setError('numeroNotaFiscal', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'origemCNPJ':
        setError('origemCNPJ', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'destinoCNPJ':
        setError('destinoCNPJ', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'data':
        setError('data', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'hora':
        setError('hora', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      default:
        break;
    }

  }

  return (
    <Fade in mountOnEnter unmountOnExit>
      <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>

        <ModalHeader
          title={'Transferências'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={goBack}
            />
          }
        />

        {isLoading ? <CircularLoading tipo="FULLSIZED" /> :
          <>
            {controleMedicamentos.exibir
              ? <TransferenciaMedicamentoLotes
                medicamentos={controleMedicamentos.medicamentoLotes}
                salvarLotesTransferencia={salvarLotes}
                isEntrada={isEntrada()}
                visualizacao={isVisualizacao}
              />
              : (
                <div className={`flex-column w-100 ${styles.formPadding, styles.formHeight}`}>
                  <Wizard steps={steps} activeStep={passoTransferencia} currentStepEmmiter={avancarStep} />

                  <div className={`flex-column ${isVisualizacao ? styles.formContainerVisualizacao : styles.formContainer}`}>
                    {
                      passoTransferencia === ETransferenciaPassos.Movimentacao ? (
                        <TransferenciaLancamentoForm
                          register={register}
                          formState={formState}
                          visualizacao={isVisualizacao}
                          transferencia={formTransferencia}
                          empresasFiliais={empresasFiliais}
                          setTransferencia={setFormTransferencia}
                        />
                      ) : (
                        <TransferenciaMedicamentosForm
                          register={register}
                          formState={formState}
                          visualizacao={isVisualizacao}
                          transferencia={formTransferencia}
                          setTransferencia={setFormTransferencia}
                          editarMedicamentoLotes={editarMedicamentos}
                        />
                      )
                    }
                  </div>

                  <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                    {passoTransferencia === ETransferenciaPassos.Movimentacao ?
                      <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                        <Button onClick={() => avancarStep(passoTransferencia + 1)} variant="text" className="h-100 w-100">
                          <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                            <AvancarIcon class={styles.iconSize} />
                            Avançar
                          </div>
                        </Button>
                      </div> :
                      <>
                        {
                          !isVisualizacao && (
                            <>
                              <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                                <Button onClick={() => salvarTransferencia(false)} variant="text" className="h-100 w-100">
                                  <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                                    <SalvarIcon class={styles.iconSize} />
                                    Salvar
                                  </div>
                                </Button>
                              </div>
                              <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                <Button onClick={() => salvarTransferencia(true)} variant="text" className="h-100 w-100">
                                  <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                    <AvancarIcon class={styles.iconSize} />
                                    Autorizar
                                  </div>
                                </Button>
                              </div>
                            </>
                          )
                        }
                      </>
                    }
                  </div>
                </div>
              )
            }
          </>
        }
      </div>
    </Fade>
  );
};
