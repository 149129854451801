import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import ConfirmaIcon from "../../../../../assets/img/icon-confirma.svg";
import { AutocompleteMedicamento } from "../../../../components/autocompletes/autocomplete-medicamentos";
import { DefaultCard } from "../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { useStyles } from "./compras-styles";
import { CompraAvulsaMedicamentoLotesProp, CompraMedicamentoItem, EnumUnidadeComercial } from "./models/compras-cadastro.model";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useToastSaurus } from '../../../../../services/app';
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { KeyValueModel } from "../../../../../model";

export const NovaCompraAvulsaMedicamentosCard = (({ adicionarNovoMedicamento }: CompraAvulsaMedicamentoLotesProp) => {
  const styles = useStyles();
  const [novoMedicamento, setNovoMedicamento] = useState(new CompraMedicamentoItem());
  const { showToastPersonalizado } = useToastSaurus();

  const schema = yup
    .object()
    .shape({
      quantidade: yup.number()
        .required("Itens é obrigatório!"),
      unidadeComercialId: yup.number()
        .required("Unidade é obrigatória!")
    });

  const {
    formState,
    register,
    clearErrors
  } = useForm<CompraMedicamentoItem>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  const adicionar = async () => {

    var formIsValid = await schema.isValid(novoMedicamento);

    if (!formIsValid || !novoMedicamento.medicamentoNome)
      return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

    adicionarNovoMedicamento(novoMedicamento);

    clearErrors("quantidade");
    setNovoMedicamento(new CompraMedicamentoItem());
  }

  const handleMedicamentoAutocomplete = (medicamento: any) => {
    if (!medicamento.ms)
      return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

    clearErrors("quantidade");

    setNovoMedicamento({
      medicamentoId: medicamento.id,
      medicamentoNome: medicamento.descricao,
      codigoAnvisa: medicamento.ms,
      vendaControlada: medicamento.vendaControlada,
      codigoEan: medicamento?.value?.codigoEan,
      laboratorioId: medicamento?.value?.laboratorioId,
      unidadeComercialId: medicamento?.unidadeComercialId,
      quantidade: medicamento?.quantidade
    } as CompraMedicamentoItem);
  };

  const handleMedicamentoLabel = (): string =>
    novoMedicamento.codigoAnvisa ? `${novoMedicamento.medicamentoNome} - ${novoMedicamento.codigoAnvisa}` : '';

  const buscarValoresUnidadeComercial = (): KeyValueModel[] => {
    const valores: KeyValueModel[] = [];
    for (let key in EnumUnidadeComercial) {
      if (isNaN(Number(key))) {
        valores.push(new KeyValueModel(EnumUnidadeComercial[key], key));
      }
    }
    return valores;
  };

  return (
    <>
      <DefaultCard>
        <div className={`flex-row h-100 w-100 ${styles.infoContainerCompraAvulsa, styles.divCardMed}`}>

          <div className={`flex-column w-100 ${styles.infoContainer}`}>
            <div className="flex-row w-100">
              <AutocompleteMedicamento
                value={handleMedicamentoLabel()}
                label="Buscar Medicamento"
                className={`h-50 w-100 ${styles.inputMedStyle}`}
                onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
              />
            </div>

            <div className={`flex-row w-100 ${styles.itensDiv}`}>

              <div className={`flex-column ${styles.widthInput}`}>
                <Typography className={styles.labelFont}>
                  Código
                </Typography>
                <Typography className={styles.contentFont}>
                  {novoMedicamento.codigoAnvisa}
                </Typography>
              </div>

              <div className={`flex-column ${styles.widthInput}`}>
                <SelectSaurus
                  value={novoMedicamento.unidadeComercialId}
                  label="Undidade"
                  variant="outlined"
                  size="small"
                  conteudo={buscarValoresUnidadeComercial()}
                  className={`h-100 w-100 ${styles.inputMedStyle}`}
                  error={Boolean(errors.unidadeComercialId && !novoMedicamento.unidadeComercialId)}
                  helperText={
                    errors.unidadeComercialId && !novoMedicamento.unidadeComercialId
                      ? errors.unidadeComercialId?.message
                      : undefined
                  }
                  {...register('unidadeComercialId', {
                    onChange: (e: any) => setNovoMedicamento({ ...novoMedicamento, unidadeComercialId: e.target.value })
                  })}
                />
              </div>

              <div className={`flex-column ${styles.widthInputItens}`}>
                <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                  value={novoMedicamento.quantidade}
                  labelCustom="Itens"
                  variant="outlined"
                  size="small"
                  tipo='NUMERO'
                  error={Boolean(errors.quantidade && !novoMedicamento.quantidade)}
                  helperText={
                    errors.quantidade && !novoMedicamento.quantidade
                      ? errors.quantidade?.message
                      : undefined
                  }
                  {...register('quantidade', {
                    onChange: (e: any) => setNovoMedicamento({ ...novoMedicamento, quantidade: e.target.value })
                  })}
                  min={undefined}
                  max={undefined}
                />
              </div>

            </div>

            <div className={`flex-row w-100 ${styles.containerButtonAdd}`}>
              <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
                <Button onClick={adicionar} variant="text" className="h-100 w-100">
                  <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                    <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
                  </div>
                </Button>
              </div>
            </div>

          </div>


        </div>
      </DefaultCard>
    </>
  );
});