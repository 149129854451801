import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Fade } from '@material-ui/core';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from "yup";
import { CircularLoading } from '../../../..';
import MedicamentoIcon from "../../../../../assets/img/icon-medicamento.svg";
import NotaFiscalIcon from "../../../../../assets/img/icon-nota-fiscal.svg";
import { useGetAutorizacaoById } from '../../../../../data/api/gestao/autorizacao/autorizacao-by-id';
import { useEditarOperacaoCompra, useGetCompraById } from '../../../../../data/api/gestao/compras/compras';
import { useToastSaurus } from '../../../../../services/app';
import { formatarDataInput } from '../../../../../utils/formatDateInput';
import { ButtonModalHeader } from '../../../../components/controles/buttons/button-modal-header';
import { AvancarIcon, SalvarIcon, VoltarIcon } from '../../../../components/icons';
import { ModalHeader } from '../../../../components/modal-header';
import { Wizard } from '../../../../components/wizard/wizard-component';
import { WizardStep } from '../../../../components/wizard/wizard-model';
import { ETipoOperacaoMovimentacao } from '../../envio-movimentacao/models/envio-movimentacao.model';
import { CompraMedicamentosForm } from './compra-medicamentos-form';
import { CompraNotaFiscalForm } from './compra-nota-fiscal-form';
import { ComprasMedicamentoLotes } from './compras-medicamento-lote/compras-medicamento-lotes';
import { useStyles } from './compras-styles';
import {
    CompraMedicamentoResponse,
    ComprasCadastroModel,
    ComprasMedicamentoLote,
    ECompraPassos,
    OperacaoCompraRequest
} from './models/compras-cadastro.model';

export const CompraCadastroPage = (props: any) => {
    const [isVisualizacao, setIsVisualizacao] = useState(false);
    const [passoCompra, setPassoCompra] = useState(0);
    const [formCompra, setFormCompra] = useState({} as ComprasCadastroModel);
    const [requestCompra, setRequestCompra] = useState({} as OperacaoCompraRequest);
    const [medicamentosEdicao, setMedicamentosEdicao] = useState<CompraMedicamentoResponse[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const styles = useStyles();
    const { showToast, showToastPersonalizado } = useToastSaurus();

    const { getAutorizacaoById } = useGetAutorizacaoById();
    const { editarOperacaoCompra } = useEditarOperacaoCompra();
    const { getCompraById } = useGetCompraById();

    const [errorValidationFornecedor, setErrorValidationFornecedor] = useState(false);

    const operacaoId = props.operacaoId;
    const compraId = props.compraId;
    const dataHoraLancamento = props.dataHoraLancamento;

    const schema = yup
        .object()
        .shape({
            dataLancamento: yup.string().required("Data é obrigatória!"),
            horaLancamento: yup.string().required("Hora é obrigatória!"),
            diaNotaFiscal: yup.string().required("Data é obrigatória!"),
            numeroNotaFiscal: yup.number().required("Número da N.F é obrigatório!")
        });

    const {
        control,
        formState,
        register,
        setError
    } = useForm<ComprasCadastroModel>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const getCompra = useCallback(async () => {
        try {
            const res = await getAutorizacaoById(operacaoId);

            if (res.erro) {
                setIsLoading(false);
                props.fecharModal(true);
                return showToast("error", `${res.erro}`, 5000, 'top-center');
            }

            const operacao = res.resultado?.data;
            const compra = operacao.compra;

            setFormCompra(new ComprasCadastroModel(
                formatarDataInput(compra.dataHoraLancamento).data,
                formatarDataInput(compra.dataHoraLancamento).hora,
                compra.dataHoraLancamento,
                formatarDataInput(compra.dataNotaFiscal).data,
                compra.dataNotaFiscal,
                compra.numeroNotaFiscal,
                compra.fornecedor,
                compra.medicamentos
            ));

            setRequestCompra(new OperacaoCompraRequest(
                operacao.id,
                operacao.operacaoAutorizacaoId,
                operacao.operacaoTipo,
                operacao.dataHoraLancamento,
                false,
                formCompra
            ))
            setIsLoading(false);
        } catch (e: any) {
            if (axios.isCancel(e))
                return;

            toast.error(e.message);
            setIsLoading(false);
        }
    }, [formCompra, getAutorizacaoById, operacaoId, props, showToast]);

    useEffect(() => {
        if (!operacaoId)
            return;

        getCompra();
    }, [operacaoId]);

    const getCompraVisualizacao = useCallback(async () => {
        try {
            setIsVisualizacao(true);
            const res = await getCompraById(compraId, dataHoraLancamento);

            if (res.erro) {
                setIsLoading(false);
                props.fecharModal(true);
                return showToast("error", `${res.erro}`, 5000, 'top-center');
            }

            const compra = res.resultado?.data;

            setFormCompra(new ComprasCadastroModel(
                formatarDataInput(compra.dataHoraLancamento).data,
                formatarDataInput(compra.dataHoraLancamento).hora,
                compra.dataHoraLancamento,
                formatarDataInput(compra.dataNotaFiscal).data,
                compra.dataNotaFiscal,
                compra.numeroNotaFiscal,
                compra.fornecedor,
                compra.medicamentos
            ));
            setIsLoading(false);
        } catch (e: any) {
            if (axios.isCancel(e))
                return;

            console.error("error", e.message);
            toast.error('O processo resultou em um erro interno da API');
            setIsLoading(false);
        }
    }, [getCompraById, compraId, dataHoraLancamento, props, showToast]);

    useEffect(() => {
        if (!compraId)
            return;

        getCompraVisualizacao();
    }, [compraId]);

    const steps: WizardStep[] = [
        {
            label: 'Dados da Nota',
            icon: <img src={NotaFiscalIcon} alt="" />
        },
        {
            label: 'Medicamentos',
            icon: <img src={MedicamentoIcon} alt="" />
        }
    ];

    const editarMedicamentos = useCallback((medicamentos: CompraMedicamentoResponse[]) => {

        setMedicamentosEdicao(medicamentos);
    }, []);

    const salvarLotes = (lotesAdicionados: ComprasMedicamentoLote[], codigoAnvisa: string) => {
        let compra = { ...formCompra };

        for (let index = 0; index < compra.medicamentos?.length; index++) {

            if (compra.medicamentos[index].codigoAnvisa !== codigoAnvisa)
                continue;

            var hasLote = lotesAdicionados?.some(x =>
                x.lote === compra.medicamentos[index].lote);

            if (!hasLote)
                compra.medicamentos[index] = {
                    ...compra.medicamentos[index],
                    lote: undefined,
                    validade: undefined,
                    fabricacao: undefined,
                    codigoAgregador: undefined,
                };

            let loteAtual = lotesAdicionados.slice(-1).pop();

            if (!loteAtual || compra.medicamentos[index].codigoAnvisa !== codigoAnvisa)
                continue;

            let loteAtualIndice = lotesAdicionados.indexOf(loteAtual);

            const dataValidade = moment(loteAtual.dataValidade).toDate();
            const dataFabricacao = moment(loteAtual.dataFabricacao).toDate();

            compra.medicamentos[index] = {
                ...compra.medicamentos[index],
                lote: loteAtual.lote,
                validade: dataValidade,
                fabricacao: dataFabricacao,
                codigoAgregador: loteAtual.codigoAgregador,
            }

            lotesAdicionados[loteAtualIndice].quantidade -= 1;
            if (loteAtual.quantidade === 0)
                lotesAdicionados.pop();
        }

        setFormCompra(compra);
        setMedicamentosEdicao([]);

        showToast('success', "Lote adicionado com sucesso!", 5000, 'top-center');
    }

    const avancarStep = async (step: number) => {

        if (await validarNota()) {
            setPassoCompra(step);
            return;
        }

        return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
    }

    const validarNota = async () => {

        let formIsValid = true;

        try {

            await schema.validate({
                dataLancamento: formCompra.dataLancamento,
                horaLancamento: formCompra.horaLancamento,
                diaNotaFiscal: formCompra.diaNotaFiscal,
                numeroNotaFiscal: formCompra.numeroNotaFiscal
            }, { abortEarly: false });

            formIsValid = true;

        } catch (error) {

            const validationError = error as yup.ValidationError;

            validationError?.inner?.forEach(err => {
                const path = err.path?.toString();

                if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
                    setErrorField(path, err.message);
                }

            });

            formIsValid = false;
        }


        const resultErroValidationFornecedor = !formCompra.fornecedor.nome || formCompra.fornecedor.nome === undefined;
        setErrorValidationFornecedor(resultErroValidationFornecedor);
        return (!formIsValid || resultErroValidationFornecedor) ? false : true;

    }

    function setErrorField(path: string, errorMensage: string) {

        switch (path) {

            case 'dataAtual':
                setError('dataLancamento', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'horaAtual':
                setError('horaLancamento', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'dataNotaFiscal':
                setError('dataNotaFiscal', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'numeroNotaFiscal':
                setError('numeroNotaFiscal', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            default:
                break;
        }

    }

    const fecharMedicamentos = () => {
        medicamentosEdicao.length
            ? setMedicamentosEdicao([])
            : props.fecharModal();
    };

    const passoFormRender = () => {
        return (passoCompra === ECompraPassos.NotaFiscal && formCompra) ? (
            <CompraNotaFiscalForm
                control={control}
                formState={formState}
                visualizacao={isVisualizacao}
                compra={formCompra}
                setCompra={setFormCompra}
                register={register}
                errorValidation={errorValidationFornecedor}
            />
        ) : (
            <div className={styles.compraAvulsaMedicamentosForm}>
                <CompraMedicamentosForm
                    control={control}
                    formState={formState}
                    visualizacao={isVisualizacao}
                    compra={formCompra}
                    setCompra={setFormCompra}
                    editarMedicamentoLotes={editarMedicamentos}
                    register={register}
                />
            </div>
        )
    };

    const editarCompra = async (deveAutorizar: boolean) => {
        var formIsValid = await schema.isValid(formCompra);

        if (!formIsValid)
            return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

        if (deveAutorizar) {
            const hasMedicamentoSemLote = formCompra.medicamentos.some(x => !x.lote);

            if (hasMedicamentoSemLote)
                return showToast("error", "Não é possível autorizar uma compra com medicamentos sem lote preenchido!", 5000, 'top-center');
        }

        const dataHoraLancamento = new Date(`${formCompra.dataLancamento}T${formCompra.horaLancamento}:00.000Z`);
        formCompra.dataHoraLancamento = dataHoraLancamento;

        setIsLoading(true);
        const compra = new OperacaoCompraRequest(
            requestCompra.id,
            requestCompra.operacaoAutorizacaoId,
            ETipoOperacaoMovimentacao.Compra,
            formCompra.dataHoraLancamento,
            deveAutorizar,
            formCompra
        );

        const operacaoSalva = await editarOperacaoCompra(compra);

        if (operacaoSalva.erro) {
            setIsLoading(false);
            return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
        }

        if (!deveAutorizar) {
            showToast("success", 'Compra editada com sucesso!');
            setIsLoading(false);
            return props.fecharModal(true);
        }
        setIsLoading(false);
        showToast("success", 'Compra autorizada com sucesso!');
        props.fecharModal(true);
    };

    return (
        <>
            <Fade in mountOnEnter unmountOnExit>
                <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>

                    <ModalHeader
                        title={'Compras'}
                        leftArea={
                            <ButtonModalHeader
                                tooltip="Voltar"
                                icon={<VoltarIcon tipo="MODAL_HEADER" />}
                                onClick={() => { fecharMedicamentos() }}
                            />
                        }
                    />


                    {isLoading ? <CircularLoading tipo="FULLSIZED" /> :
                        <>
                            {medicamentosEdicao?.length
                                ? <ComprasMedicamentoLotes
                                    medicamentos={medicamentosEdicao}
                                    salvarLotesCompras={salvarLotes}
                                    visualizacao={isVisualizacao} />
                                : (
                                    <div className={`flex-column w-100 ${styles.formPadding, styles.formHeight}`}>
                                        <Wizard steps={steps} activeStep={passoCompra} setActiveStep={step => avancarStep(Number(step))} />

                                        <div className={`flex-column ${isVisualizacao ? styles.formContainerVisualizacao : styles.formContainer}`}>
                                            {passoFormRender()}
                                        </div>


                                        <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                                            {passoCompra === ECompraPassos.NotaFiscal ?
                                                <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                                    <Button onClick={() => avancarStep(passoCompra + 1)} variant="text" className="h-100 w-100">
                                                        <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                                            <AvancarIcon class={styles.iconSize} />
                                                            Avançar
                                                        </div>
                                                    </Button>
                                                </div> :
                                                <>
                                                    {
                                                        !isVisualizacao && (
                                                            <>
                                                                <div className={styles.buttonCadastroAlign}>
                                                                    <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                                                                        <Button onClick={async () => await editarCompra(false)} variant="text" className="h-100 w-100"
                                                                            disabled={isVisualizacao}>
                                                                            <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                                                                                <SalvarIcon class={styles.iconSize} />
                                                                                Salvar
                                                                            </div>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.buttonCadastroAlign}>
                                                                    <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                                                        <Button onClick={async () => await editarCompra(true)} variant="text" className="h-100 w-100"
                                                                            disabled={isVisualizacao}>
                                                                            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                                                                <AvancarIcon class={styles.iconSize} />
                                                                                Autorizar
                                                                            </div>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                </div>
            </Fade>
        </>
    );
};
