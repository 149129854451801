import { useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { GlobalStatesProvider } from '../services/app/providers/global-states-provider/global-states-provider';
import LoginPage from '../views/pages/public/login/login-page';
import LogoutPage from '../views/pages/public/logout/logout-page';

import { SessaoAtualProvider } from '../services/app';
import { ExpiredTokenRoute } from './components/expired-token-route';
import RouterMain from './components/router-main/router-main';
import { RenderModais } from './render-modais';

export const RouterApp = () => {
  const switchApp = useMemo(() => (

    <Switch>
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/login/:parametro?" component={LoginPage} />

      <ExpiredTokenRoute>
        <RouterMain></RouterMain>
      </ExpiredTokenRoute>

    </Switch>

  ), []);

  return (
    <GlobalStatesProvider>
      <RecoilRoot>
        <SessaoAtualProvider>
          <BrowserRouter>
            {switchApp}
          </BrowserRouter>
          <RenderModais />
        </SessaoAtualProvider>
      </RecoilRoot>
    </GlobalStatesProvider>
  );

};
