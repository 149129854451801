import { KeyValueModel } from "../model";

export const UFMock: KeyValueModel[] = [
   new KeyValueModel(undefined, ""),
   new KeyValueModel(12, "AC"),
   new KeyValueModel(27, "AL"),
   new KeyValueModel(16, "AP"),
   new KeyValueModel(13, "AM"),
   new KeyValueModel(29, "BA"),
   new KeyValueModel(23, "CE"),
   new KeyValueModel(53, "DF"),
   new KeyValueModel(32, "ES"),
   new KeyValueModel(52, "GO"),
   new KeyValueModel(21, "MA"),
   new KeyValueModel(51, "MT"),
   new KeyValueModel(50, "MS"),
   new KeyValueModel(31, "MG"),
   new KeyValueModel(15, "PA"),
   new KeyValueModel(25, "PB"),
   new KeyValueModel(41, "PR"),
   new KeyValueModel(26, "PE"),
   new KeyValueModel(22, "PI"),
   new KeyValueModel(33, "RJ"),
   new KeyValueModel(24, "RN"),
   new KeyValueModel(43, "RS"),
   new KeyValueModel(11, "RO"),
   new KeyValueModel(14, "RR"),
   new KeyValueModel(42, "SC"),
   new KeyValueModel(35, "SP"),
   new KeyValueModel(28, "SE"),
   new KeyValueModel(17, "TO"),
];