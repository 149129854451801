import { useCallback } from "react";
import { MenuGroup, MenuModel } from "../../../model/app";
import { useSngpcStorage } from "../../../services/app";
import { OperacaoMenuEnum } from "../../../views/components/headers/header-page/header-page";

export const useGetMenu = () => {
  const Modulos: MenuModel[] = [
    new MenuModel("Autorizações Pendentes", OperacaoMenuEnum.Autorizacao, "/autorizacao-listagem", 1, ""),
    new MenuModel("Dispensações", OperacaoMenuEnum.Venda, "/dispensacao-listagem", 2, "Operações"),
    new MenuModel("Compras", OperacaoMenuEnum.Compra, "/compra-listagem", 3, "Operações"),
    new MenuModel("Perdas", OperacaoMenuEnum.Perda, "/perda-listagem", 4, "Operações"),
    new MenuModel("Transferências", OperacaoMenuEnum.Transferencia, "/transferencia-listagem", 5, "Operações"),
    new MenuModel("Inventário", OperacaoMenuEnum.Inventario, "/inventario-listagem", 6, "Operações"),
    new MenuModel("Envio de Movimentações", OperacaoMenuEnum.SNGPCEnvioMovimentacao, "/envio-movimentacao-listagem", 8, "SNGPC"),
    new MenuModel("Envio de Inventário", OperacaoMenuEnum.SNGPCEnvioInventario, "/envio-inventario-listagem", 9, "SNGPC"),
    new MenuModel("Relatórios", OperacaoMenuEnum.Relatorio, "/relatorio-home", 7, "Relatório"),
    new MenuModel("Configurações", OperacaoMenuEnum.Configuracao, "/configuracoes", 10, "Geral"),
    new MenuModel("Inventários Enviados", OperacaoMenuEnum.SNGPCInventarioEnviado, "/inventarios-enviados", 11, "SNGPC")
  ];

  function groupMenuItems(menuItems: MenuModel[]): MenuGroup {
    const groupedMenu: MenuGroup = {};

    menuItems.forEach(item => {
      if (!groupedMenu[item.tituloPai]) {
        groupedMenu[item.tituloPai] = [];
      }
      groupedMenu[item.tituloPai].push(item);
    });

    return groupedMenu;
  }

  const { getRegistro } = useSngpcStorage()

  const getMenu = useCallback(() => {
    return groupMenuItems(Modulos);
  }, [getRegistro]);

  return { getMenu };
};
