import { Typography } from "@material-ui/core";
import { useState } from "react";
import { EnumCadastroTipo } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { useStyles } from "./compras-styles";
import { CompraAvulsaStepsNotaFiscalProp, CompraFornecedor } from "./models/compras-cadastro.model";

export const CompraAvulsaNotaFiscalForm = (({ formState, register, compra, setCompra, errorValidation }: CompraAvulsaStepsNotaFiscalProp) => {
  const styles = useStyles();
  const { errors } = formState;
  const [fornecedorNome, setFornecedorNome] = useState("");
  const [erroFornecedor, seterroFornecedor] = useState(false);

  const setFornecedor = (value: any) => {

    if (!value) {
      setFornecedorNome("");
      setCompra((compra) => ({
        ...compra,
        fornecedor: new CompraFornecedor()
      }))
      return;
    }

    setFornecedorNome(value.nome);

    value.cnpj = value?.cpfcnpj;
    value.cpfcnpj = null;
    setCompra((compra) => ({
      ...compra,
      fornecedor: value ? value : null
    }))
  }

  return (
    <div className={`flex-column ${styles.formContainer}`}>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Dados da Nota Avulsa</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados da Nota</Typography>
        </div>

        <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
          <div className={`w-100 ${styles.autocompleteContainerStyle}`}>

            <AutocompletePessoas
              label="Fornecedor"
              tipo={EnumCadastroTipo.TODOS}
              value={compra?.fornecedor?.nome}
              onChange={(a) => setFornecedor(a.value)}
              name='fornecedor'
              error={Boolean((errorValidation || erroFornecedor) && !fornecedorNome)}
              helperText={
                (errorValidation || erroFornecedor) && !fornecedorNome
                  ? 'Fornecedor é obrigatório!'
                  : undefined
              }
              onBlur={() => {
                if (!fornecedorNome)
                  seterroFornecedor(true);
              }
              }
            />
          </div>

          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className="w-50">
              <TextFieldSaurus
                required
                className={`w-100 ${styles.inputStyle}`}
                tipo='DATA'
                variant='outlined'
                labelCustom='Data de Lançamento'
                value={compra.dataAtual}
                showStartAdornment={true}
                allowSubmit={true}
                size="medium"
                fullWidth={true}
                error={Boolean(errors.dataAtual && !compra.dataAtual)}
                helperText={
                  errors.dataAtual && !compra.dataAtual
                    ? errors.dataAtual?.message
                    : undefined
                }
                {...register('dataAtual', {
                  onChange: (e: any) => setCompra({ ...compra, dataAtual: e.target.value })
                })}
              />

            </div>
            <div className="w-50">
              <TextFieldSaurus
                required
                className={`w-100 ${styles.inputStyle}`}
                tipo='HORA'
                variant='outlined'
                labelCustom='Hora de Lançamento'
                value={compra.horaAtual}
                size="medium"
                name='horaAtual'
                fullWidth={true}
                error={Boolean(errors.horaAtual && !compra.horaAtual)}
                helperText={
                  errors.horaAtual && !compra.horaAtual
                    ? errors.horaAtual?.message
                    : undefined
                }
                {...register('horaAtual', {
                  onChange: (e: any) => setCompra({ ...compra, horaAtual: e.target.value })
                })}
              />
            </div>
          </div>

          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className="w-50">
              <TextFieldSaurus
                required
                className={`w-100 ${styles.inputStyle}`}
                tipo='DATA'
                variant='outlined'
                labelCustom='Data da Nota Fiscal'
                value={compra.dataNotaFiscal}
                size="medium"
                name='dataNotaFiscal'
                fullWidth={true}
                error={Boolean(errors.dataNotaFiscal && !compra.dataNotaFiscal)}
                helperText={
                  errors.dataNotaFiscal && !compra.dataNotaFiscal
                    ? errors.dataNotaFiscal?.message
                    : undefined
                }
                {...register('dataNotaFiscal', {
                  onChange: (e: any) => setCompra({ ...compra, dataNotaFiscal: e.target.value })
                })}
              />
            </div>
            <div className="w-50">
              <TextFieldSaurus
                required
                className={`w-100 ${styles.inputStyle}`}
                tipo='NUMERO'
                variant="outlined"
                labelCustom='N. da Nota Fiscal'
                size="medium"
                name='numeroNotaFiscal'
                value={compra.numeroNotaFiscal}
                fullWidth={true}
                error={Boolean(errors.numeroNotaFiscal && !compra.numeroNotaFiscal)}
                helperText={
                  errors.numeroNotaFiscal && !compra.numeroNotaFiscal
                    ? errors.numeroNotaFiscal?.message
                    : undefined
                }
                {...register('numeroNotaFiscal', {
                  onChange: (e: any) => setCompra({ ...compra, numeroNotaFiscal: e.target.value })
                })}
              />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
});