import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";

import { Grid } from "@material-ui/core";
import axios from "axios";
import { SngpcStorageKeys, useSessaoAtual, useSngpcStorage, useToastSaurus } from "../../../../services/app";
import { CircularLoading } from "../../../components";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { makeUtilClasses } from '../../../theme/util-make-styles';


const LogoutPage = () => {
    const { replace: historyReplace } = useHistory();
    const { deslogar, usuario } = useSessaoAtual();
    const utilClasses = makeUtilClasses();
    const { showToast } = useToastSaurus();
    const { delRegistro } = useSngpcStorage();

    const efetuarLogout = useCallback(() => {
        try {
            deslogar(usuario?.usuarioId || '');
            delRegistro(SngpcStorageKeys.SelecaoModulo, false);
        } catch (e: any) {
            if (axios.isCancel(e))
                return;

            showToast("error", e.message);
        }
        historyReplace("/login");
    }, [delRegistro, deslogar, historyReplace, showToast, usuario?.usuarioId]);

    useEffect(() => {
        efetuarLogout();
    }, [efetuarLogout]);

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer}>
                    <CircularLoading tipo="FULLSIZED" />
                </Grid>
            </div>
        </>
    )
}

export default LogoutPage;


