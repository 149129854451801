
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    color: {
        backgroundColor: theme.palette.grey[100],
    },

    listPadding: {
        padding: '0 0.5rem',
        justifyContent: "space-between",
    },

    listContainer: {
        justifyContent: 'start',
        gap: '1%'
    },

    medicamentoListContainer: {
        height: 0,
        flexGrow: 1
    },

    medicamentoListStyle: {
        overflow: 'auto',
        paddingRight: '0.5rem'
    },

    cardMovimentacaoContainer: {
        justifyContent: 'flex-start',
        marginBottom: 10
    },

    buttonContainer: {
        margin: '0.5rem 0',
        gap: '1.2rem',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            alignSelf: 'center',
        }
    },

    buttonStyle: {
        flexGrow: 1,
        minWidth: '20%',
        borderRadius: '10px',
        height: '56px'
    },

    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },

    whiteButton: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`
    },

    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },

    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },

    darkLabel: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
    },

    iconSize: {
        width: '2rem',
    },

    semRegistroStyle: {
        textAlign: 'center',
        margin: '1rem 0',
        fontSize: '1rem'
    },

    containerModais: {
        alignItems: "center",
        maxHeight: "6vh",
        gap: '4%',
    },

    modal: {
        backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
    },

    statusColor: {
        backgroundColor: theme.palette.info.main
    },

    infoContainer: {
        margin: '0.3rem',
        justifyContent: 'space-between'
    },

    medicamentoInfoContainer: {
        width: '60%'
    },
    codAnvisaInfoContainer: {
        width: '20%'
    },
    quantidadeInfoContainer: {
        width: 'fit-content'
    },

    labelFont: {
        alignContent: 'center',
        fontWeight: 700,
        fontSize: "0.6rem",
        color: theme.palette.common.black,
        paddingRight: '0.25rem',
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },

    contentFont: {
        alignContent: 'center',
        fontSize: "0.6rem",
        color: theme.palette.secondary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },

    buscaContainer: { // select
        gap: '0.5rem',
        display: "flex",
        alignItems: "center",
        height: "fit-content",
        marginBottom: '0.5rem'
    },

    autocompleteStyle: { // select
        width: 0,
        justifyContent: 'center',
        borderRadius: '8px',
        flexGrow: 1,
    },

    buttonAdicionarContainer: { //select
        width: '56px',
        height: '56px',
    },

    buttonAdicionarStyle: { //select
        borderRadius: '50%',
    },
}));