import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetDispensacaoMedicamentoLote } from "../../../../../../data/api/gestao/dispensacao/dispensacao";
import { useGetEstoqueByCodigoAnvisa } from "../../../../../../data/api/gestao/estoque/get-estoque";
import { ConfirmarIcon } from "../../../../../components/icons/confirmar-icon";
import { DispensacaoMedicamentoLote } from "../../models/dispensacao-models";
import { DispensacaoMedicamentoLotesProps } from "../../models/dispensacao-props";
import { DispensacaoMedicamentoLotesCard } from "./dispensacao-medicamento-lotes-card";
import { useStyles } from "./dispensacao-medicamento-lotes-style";

export const DispensacaoMedicamentoLotes = (({ medicamentos, salvarLotesDispensacao, visualizacao, dispensacaoId, dataCompetencia }: DispensacaoMedicamentoLotesProps) => {
  const styles = useStyles();
  const { getEstoqueByCodigoAnvisa } = useGetEstoqueByCodigoAnvisa();
  const { getDispensacaoMedicamentoLote } = useGetDispensacaoMedicamentoLote();

  const [lotes, setLotes] = useState<DispensacaoMedicamentoLote[]>([]);
  const [quantidadeRestante, SetQuantidadeRestante] = useState<number>(0)

  const getLotes = useCallback(async () => {
    try {

      const res = visualizacao ?
        await getDispensacaoMedicamentoLote(dispensacaoId, dataCompetencia, medicamentos[0].codigoAnvisa)
        :
        await getEstoqueByCodigoAnvisa(medicamentos[0].codigoAnvisa)

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      const lotes = res.resultado?.data?.lotes?.map((lote: any) => ({
        lote: lote.lote,
        quantidade: lote.quantidade,
        quantidadeReservada: medicamentos.filter(m => m.lote === lote.lote).length,
        dataValidade: lote.dataValidade,
        dataFabricacao: lote.dataFabricacao,
        codigoAgregador: lote.codigoAgregador,
      } as DispensacaoMedicamentoLote));

      setLotes(lotes);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getEstoqueByCodigoAnvisa, medicamentos]);

  const alterarLotes = (loteNome: string, quantidade: number) => {
    if (!loteNome)
      return;

    let lotesAlteracao = [...lotes];
    let loteIndice = lotesAlteracao.findIndex(l => l.lote === loteNome);
    if (loteIndice === undefined)
      return;

    lotesAlteracao[loteIndice].quantidadeReservada = quantidade;
    setLotes(lotesAlteracao);
  };

  useEffect(() => {
    if (medicamentos !== undefined && !lotes?.length)
      getLotes();
  }, [medicamentos]);

  useEffect(() => {
    if (!lotes?.length) return;

    let reservados = lotes.map(l => l.quantidadeReservada ?? 0)
      .reduce((accumulator, currentValue) =>
        accumulator + currentValue, 0);

    SetQuantidadeRestante(medicamentos.length - reservados);
  }, [lotes]);

  const salvarLotes = () => {
    let lotesSelecionados = lotes?.filter(l => l.quantidadeReservada);
    salvarLotesDispensacao(lotesSelecionados, medicamentos[0]?.codigoAnvisa);
  };

  const quantidadeRestanteCor = () => !quantidadeRestante
    ? styles.quantidadeRestanteCompleto
    : styles.quantidadeRestantePendente;

  return (
    <>
      {medicamentos !== undefined &&
        <div className={`flex-row w-100 ${styles.medicamentoInfoContainer}`}>
          <div className={`flex-column ${styles.medicamentoInfoStyle}`}>
            <Typography className={styles.medicamentoCodigoStyle}>
              Cód: {medicamentos[0]?.codigoAnvisa}
            </Typography>
            <Typography className={styles.medicamentoNomeStyle}>
              {medicamentos[0]?.medicamentoNome}
            </Typography>
          </div>
          <div className={`flex-column ${styles.quantidadeTotalCores} ${styles.quantidadeContainer}`}>
            <Typography className={styles.quantidadeLabel}>
              Quantidade Total
            </Typography>
            <Typography className={styles.quantidadeValor}>
              {medicamentos.length}
            </Typography>
          </div>
          <div className={`flex-column ${styles.quantidadeContainer} ${quantidadeRestanteCor()}`}>
            <Typography className={styles.quantidadeLabel}>
              Quantidade Restante
            </Typography>
            <Typography className={styles.quantidadeValor}>
              {quantidadeRestante}
            </Typography>
          </div>
        </div>
      }

      <div className={`flex-column h-100 w-100 ${styles.formPadding}`}>

        <div className={`flex-column w-100 ${styles.titleContainer}`}>
          <Typography className={`w-100 ${styles.titleStyle}`}>
            Lote e Validade
          </Typography>
          <div className={`w-100 ${styles.breakLine}`} />
        </div>

        <div className={`flex-column h-100 w-100 ${styles.innerFormContainerMedicamento}`}>
          {
            lotes?.map((lote, index) => (
              <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                <DispensacaoMedicamentoLotesCard lote={lote}
                  quantidadeTotal={medicamentos.length}
                  quantidadeRestante={quantidadeRestante}
                  alteraLoteQtd={alterarLotes}
                  visualizacao={visualizacao} />
              </div>
            ))
          }
          {
            !lotes?.length && (
              <div className={`flex-column w-100 ${styles.titleMessage}`}>
                <Typography className={`w-100 ${styles.titleMessageStyle}`}>
                  Medicamento sem estoque!
                </Typography>
              </div>
            )
          }
        </div>

        {
          !visualizacao &&
          <div className={`flex-row w-100 ${styles.buttonContainer}`}>
            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
              <Button onClick={() => salvarLotes()} variant="text" className="h-100 w-100">
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  <ConfirmarIcon class={styles.iconSize} />
                  Adicionar
                </div>
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  );
});