import { VariaveisAmbiente } from "../config"

export const consoleDev = (arg: any, arg2: any = '', type: "log" | "table" = "log") => {
    if (VariaveisAmbiente.isDev) {
        if (type === "log") {
            return console.log(arg, arg2)
        }

        return console.table(arg, arg2)
    }
    return
}