import { Button, Typography } from '@material-ui/core';
import { useThemeQueries } from '../../..';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-dispensacao-style';
import { useGerenciarMedicamentos } from './use-dialog-gerenciar-medicamento-opcoes';

interface DialogGerenciarMedicamentoConteudoProps {
  aberto: boolean;
  editar: () => any;
  excluir: () => any;
}

export const DialogGerenciarMedicamentoConteudo = ({
  aberto,
  editar,
  excluir,
}: DialogGerenciarMedicamentoConteudoProps) => {
  const { fecharGerenciarMedicamentos } = useGerenciarMedicamentos();
  const { md } = useThemeQueries();
  const styles = useStyles();

  return (
    <DialogSaurus
      isPopUp
      tamanho="xs"
      titulo="Medicamento"
      cardHeight={30}
      cardWidth={md ? 71 : 20}
      isButtonTitleClose
      aberto={aberto || false}
      handleClickClose={fecharGerenciarMedicamentos}
    >
      <div className={`flex-column h-100 w-100 ${styles.dialogContainer}`}>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={() => {
            editar();
            fecharGerenciarMedicamentos();
          }} className='w-100'>
            <Typography className={styles.buttonFont}>
              Editar
            </Typography>
          </Button>
        </div>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={() => {
            excluir();
            fecharGerenciarMedicamentos();
          }} className='w-100'>
            <Typography className={styles.buttonFont} color='error'>
              Excluir
            </Typography>
          </Button>
        </div>
      </div>
    </DialogSaurus>
  );
};