import classNames from 'classnames'
import {  BackgroundWhiteTop } from './background/background-white'
import { PublicPageHeaderProps } from './header-public-page-props'
import { useStyles } from './header-public-page-style'

export const PublicPageHeader = (props: PublicPageHeaderProps) => {
    const classes = useStyles();

    return (
        <>
            <div className={
                classNames(
                    classes.publicPageHeaderContainer,
                    props.variant === 'white' ? classes.publicPageHeaderContainerWhite : classes.publicPageHeaderContainerBlue
                )
            }>
                <BackgroundWhiteTop />
            </div >
        </>
    )
}
