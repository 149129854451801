import { Typography } from "@material-ui/core";
import { useState } from "react";
import { CompraAvulsaMedicamentosCard } from "./compra-avulsa-medicamentos-card";
import { useStyles } from "./compras-styles";
import { CompraAvulsaStepsProp, CompraMedicamentoItem } from "./models/compras-cadastro.model";
import { NovaCompraAvulsaMedicamentosCard } from "./nova-compra-avulsa-medicamentos-card";

export const CompraAvulsaMedicamentosForm = (({ compra, formState, setCompra }: CompraAvulsaStepsProp) => {
  const styles = useStyles();
  const [editando, setEditando] = useState(false);

  const adicionarNovoMedicamento = (novoMedicamento: CompraMedicamentoItem) => {
    if (novoMedicamento.medicamentoId === "" || novoMedicamento.quantidade === undefined)
      return;

    var medicamentosItem = new CompraMedicamentoItem();
    medicamentosItem.medicamentoNome = novoMedicamento.medicamentoNome;
    medicamentosItem.medicamentoId = novoMedicamento.medicamentoId;
    medicamentosItem.codigoAnvisa = novoMedicamento.codigoAnvisa;
    medicamentosItem.quantidade = novoMedicamento.quantidade;
    medicamentosItem.codigoEan = novoMedicamento.codigoEan;
    medicamentosItem.laboratorioId = novoMedicamento.laboratorioId;
    medicamentosItem.unidadeComercialId = novoMedicamento.unidadeComercialId;

    if (novoMedicamento.vendaControlada !== 0)
      medicamentosItem.vendaControlada = 1;

    setCompra((compra) => ({
      ...compra,
      medicamentos: [...compra.medicamentos, medicamentosItem]
    }))
  }

  const excluir = (index: number) => {
    compra.medicamentos.splice(index, 1);
    setCompra((compra) => ({
      ...compra,
      medicamentos: compra.medicamentos
    }))
  }

  return (
    <div className={`flex-column w-100 h-100`}>
      <div className={`flex-column ${styles.titleContainer}`}>
        <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
        <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
      </div>
      <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento, styles.scrollMeds}`}>
        {compra.medicamentos?.map((medicamento, index) => (
          <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem, styles.cardMedicamentoAlign}`}>
            <CompraAvulsaMedicamentosCard medicamento={medicamento}
              excluirMedicamento={() => { excluir(index) }}
              setEditando={editando => setEditando(editando)}
              formState={formState}
            />
          </div>
        ))}

        {!editando &&
          <div className={`flex-column w-100 ${styles.cardMedicamentoItem, styles.cardMedicamentoAlign}`}>
            <NovaCompraAvulsaMedicamentosCard adicionarNovoMedicamento={novoMedicamento => adicionarNovoMedicamento(novoMedicamento)} />
          </div>
        }
      </div>
    </div>
  );
});