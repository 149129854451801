import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({

  formContainer: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 250px)'
  },

  formContainerVisualizacao: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 200px)'
  },

  formPadding: {
    flexGrow: 1,
    padding: '1rem 0.5rem'
  },

  defaultContainer: {
    position: "relative",
    overflowY: "hidden",
    overflowX: "hidden",
  },

  containerMedicamento: {
    display: 'flex',
    gap: "10px",
    flexDirection: 'row',
    alignSelf: "center",
    marginTop: '0.5rem',
    height: '100%'
  },

  addButtonMedicamentoStyle: {
    width: '100%',
    borderRadius: '10px',
    height: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center',
      width: '95%',
    },
    backgroundColor: theme.palette.grey[100],
    alignContent: 'center',
    marginBottom: '5%'
  },

  medicamentosCardsContainer: {
    gap: "2%",
    flexGrow: 1
  },

  containerAutocompleteMedicamento: {
    width: '100%',
    height: '100%'
  },

  containerAddMedicamento: {
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    borderRadius: "10px",
    padding: "0.4rem",
    justifyContent: "center"
  },

  iconeBotaoAdicionar: {
    color: theme.palette.common.white,
    height: '1.6rem',
    width: '1.6rem',
  },

  whiteButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`
  },

  darkButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  buttonLabel: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem'
    },
  },

  whiteLabel: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },

  darkLabel: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },

  iconSize: {
    width: '1.3rem',
    [theme.breakpoints.up('md')]: {
      width: '2rem'
    }
  },

  titleContainer: {
    color: theme.palette.common.black,
    alignItems: 'center'
  },

  titleStyle: {
    width: 'fit-content'
  },

  inputContainer: {
    gap: '3%',
  },

  inputStyle: {
    borderRadius: '10px',
  },

  innerFormContainer: {
    gap: '1rem',
    padding: '1rem 0',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
      alignSelf: 'center'
    }
  },

  buttonContainer: {
    gap: '5%',
    height: '4rem',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
      alignSelf: 'center',
      marginBottom: '2%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    }
  },

  buttonStyle: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    minWidth: '47%',
    borderRadius: '10px',

  },

  addButtonStyle: {
    width: '100%',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center'
    },
    backgroundColor: theme.palette.grey[100],
    alignContent: 'center'
  },

  innerFormContainerMedicamento: {
    overflowY: 'auto',
    gap: '2%',
    padding: '1rem 0',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
      alignSelf: 'center'
    }
  },

  cardMedicamentoItem: {
    justifyContent: 'center',
  },

  cardContainer: {
    padding: '0 0.25rem'
  },

  infoContainer: {
    margin: '0.3rem 0.5rem',
    justifyContent: 'space-between',
    flexGrow: 1
  },

  labelFont: {
    fontSize: '0.6rem',
    color: theme.palette.grey[400],
    marginBottom: '-0.25rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    }
  },

  contentFont: {
    fontSize: '0.75rem',
    color: theme.palette.common.black,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
    }
  },

  optionsButtonContainer: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },

  optionsButtonStyle: {
    padding: '0.15rem',
    color: theme.palette.common.black,
  },

  formHeight: {
    height: '92%',
    padding: '2%'
  },

}));