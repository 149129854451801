import {
  Box, Dialog,
  DialogContent,
  DialogTitle, Fade, Grid,
  IconButton, Slide, Typography,
  useMediaQuery
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { forwardRef } from 'react';
import { useThemeQueries } from '../../../theme';
import { CancelIcon } from '../../icons/cancel-icon';
import { CircularLoading } from '../../utils/circular-loading/circular-loading';
import { DialogSaurusProps } from './dialog-saurus-props';
import { useStyles } from './dialog-saurus-styles';

const SlideTransition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FadeTransition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

export const DialogSaurus = (props: DialogSaurusProps) => {
  const {
    aberto,
    children,
    icone,
    carregando,
    titulo,
    tamanho,
    zIndex,
    isButtonTitleClose,
    handleClickClose,
    fullScreen,
    isPopUp,
    bottomArea,
    subTitulo
  } = props

  const { theme } = useThemeQueries();
  const classes = useStyles(props);

  return (
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      fullScreen={fullScreen}
      disableScrollLock
      keepMounted
      disableEscapeKeyDown
      onClose={handleClickClose}
      className={`flex-column w-100 ${isPopUp ? classes.popDialogContainer : classes.dialogContainer}`}
      TransitionComponent={
        useMediaQuery(theme.breakpoints.up(600)) || isPopUp
          ? FadeTransition
          : SlideTransition
      }
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
    >
      <DialogTitle disableTypography>
        {isButtonTitleClose ? (
          <Grid className={classes.containerTitle}>
            <Box>
              <Typography variant="h6" className={classes.title}>
                {titulo}
              </Typography>
              {subTitulo && (
                <Typography variant="body1" className={classes.subTitle}>
                  {subTitulo}
                </Typography>
              )}
            </Box>
            <IconButton
              className={classes.closeButton}
              onClick={handleClickClose}
            >
              <CancelIcon tipo="BUTTON" fill={theme.palette.secondary.main} style={{ width: 30 }} />
            </IconButton>
          </Grid>
        ) : (
          <Grid className={classes.titleCentralizado}>
            {icone ? icone : null}
            <Box>
              <Typography variant="h6" className={classes.title}>
                {titulo}
              </Typography>
              {subTitulo && (
                <Typography variant="body1" className={classes.subTitle}>
                  {subTitulo}
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : children}
      </DialogContent>
      {bottomArea && <div>
        {bottomArea}
      </div>}
    </Dialog>
  );
};
