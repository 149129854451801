import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Fade } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { CircularLoading } from "../../..";
import CompradorIcon from "../../../../assets/img/icon-comprador.svg";
import MedicamentoIcon from "../../../../assets/img/icon-medicamento.svg";
import PrescritorIcon from "../../../../assets/img/icon-prescritor.svg";
import ReceitaIcon from "../../../../assets/img/icon-receita.svg";
import { useAprovarAutorizacao } from "../../../../data/api/gestao/autorizacao/autorizacao-aprovar";
import { useGetAutorizacaoById } from "../../../../data/api/gestao/autorizacao/autorizacao-by-id";
import { useReprovarAutorizacao } from "../../../../data/api/gestao/autorizacao/autorizacao-reprovar";
import { useEditarOperacaoDispensacao, useGetDispensacaoById } from "../../../../data/api/gestao/dispensacao/dispensacao";
import { useToastSaurus } from "../../../../services/app";
import { formatarDataInput } from "../../../../utils/formatDateInput";
import { ButtonModalHeader } from "../../../components/controles/buttons/button-modal-header";
import { useFinalizacaoOperacaoOpcoes } from "../../../components/dialog/dialog-finalizar-operacao/use-dialog-finalizacao-operacao-opcoes";
import { AvancarIcon, VoltarIcon } from "../../../components/icons";
import { ModalHeader } from "../../../components/modal-header";
import { Wizard } from "../../../components/wizard/wizard-component";
import { WizardStep } from "../../../components/wizard/wizard-model";
import { ETipoOperacaoMovimentacao } from "../envio-movimentacao/models/envio-movimentacao.model";
import { DispensacaoCompradorForm } from "./componentes/dispensacao-comprador-form";
import { DispensacaoMedicamentoLotes } from "./componentes/dispensacao-medicamento-lotes/dispensacao-medicamento-lotes";
import { DispensacaoMedicamentosForm } from "./componentes/dispensacao-medicamentos-form";
import { DispensacaoPrescritorForm } from "./componentes/dispensacao-prescritor-form";
import { DispensacaoReceitaForm } from "./componentes/dispensacao-receita-form";
import { useStyles } from "./dispensacao-cadastro-style";
import {
  Dispensacao,
  DispensacaoMedicamento,
  DispensacaoMedicamentoLote,
  DispensacaoMedicamentoReceita,
  EDispensacaoPassos,
  OperacaoDispensacaoRequest
} from "./models/dispensacao-models";

export const DispensacaoCadastroPage = ((props: any) => {
  const styles = useStyles();
  const { getAutorizacaoById } = useGetAutorizacaoById();

  const [isVisualizacao, setIsVisualizacao] = useState(false);
  const [passoDispensacao, setPassoDispensacao] = useState(0);
  const [formDispensacao, setFormDispensacao] = useState({} as Dispensacao);
  const [medicamentosEdicao, setMedicamentosEdicao] = useState<DispensacaoMedicamento[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const operacaoId = props.operacaoId;
  const dispensacaoId = props.dispensacaoId;
  const dataCompetencia = props.dataCompetencia;

  const { editarOperacaoDispensacao } = useEditarOperacaoDispensacao();
  const { getDispensacaoById } = useGetDispensacaoById();
  const { showToast, showToastPersonalizado } = useToastSaurus();

  const { getMedicamentoSemEstoque } = useGetAutorizacaoById();
  const { abrirFinalizacaoOperacaoOpcoes } = useFinalizacaoOperacaoOpcoes();

  const { reprovarAutorizacao } = useReprovarAutorizacao();
  const { aprovarAutorizacao } = useAprovarAutorizacao();

  const [errorValidationPrescritor, setErrorValidationPrescritor] = useState(false);
  const [errorValidationComprador, setErrorValidationComprador] = useState(false);
  const [errorValidationPaciente, setErrorValidationPaciente] = useState(false);

  const schema = yup
    .object()
    .shape({
      receitaTipo: yup.number().required('Tipo da Receita será obrigatório na autorização!'),
      dataLancamento: yup.string().required('Data será obrigatória na autorização!'),
      horaLancamento: yup.string().required('Hora será obrigatória na autorização!'),
      dataReceita: yup.date().required('Data será obrigatória na autorização!'),
      numeroReceita: yup.string().required('Número será obrigatório na autorização!'),
      uf: yup.string().required('UF será obrigatória na autorização!'),
      cid: yup.string().required('CID será obrigatório na autorização!')
    });

  const {
    formState,
    register,
    setError
  } = useForm<DispensacaoMedicamentoReceita>({
    criteriaMode: 'all',
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const getDispensacao = useCallback(async () => {
    try {
      const res = await getAutorizacaoById(operacaoId);

      if (res.erro) {
        props.fecharModal(true);
        setIsLoading(false);
        showToast("error", `${res.erro}`, 5000, 'top-center');
        return;
      }

      const dispensacao = res.resultado?.data.dispensacao;

      if (!dispensacao.medicamentos[0]?.receita)
        dispensacao.medicamentos[0].receita = new DispensacaoMedicamentoReceita();

      if (dispensacao.medicamentos[0].receita.dataHoraLancamento) {
        dispensacao.medicamentos[0].receita.dataLancamento = formatarDataInput(dispensacao.medicamentos[0].receita.dataHoraLancamento).data;
        dispensacao.medicamentos[0].receita.horaLancamento = formatarDataInput(dispensacao.medicamentos[0].receita.dataHoraLancamento).hora;
      }

      setFormDispensacao(new Dispensacao(
        res.resultado?.data.id,
        res.resultado?.data.operacaoAutorizacaoId,
        dispensacao.medicamentos[0].receita.dataHoraLancamento ?? "",
        dispensacao.medicamentos[0].receita.dataHoraLancamento ?
          formatarDataInput(dispensacao.medicamentos[0]?.receita.dataHoraLancamento).data : "",
        dispensacao.medicamentos[0].receita.dataHoraLancamento ?
          formatarDataInput(dispensacao.medicamentos[0]?.receita.dataHoraLancamento).hora : "",
        dispensacao.medicamentos,
        dispensacao.receitaArquivoNome,
        dispensacao.receitaArquivo,
      ));

      setIsLoading(false);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getAutorizacaoById, operacaoId]);

  useEffect(() => {
    if (!operacaoId)
      return;

    getDispensacao();
  }, [operacaoId]);

  const controlarStep = async (step: EDispensacaoPassos) => {

    if (await validarStep(step))
      return setPassoDispensacao(step);

    return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
  }

  const validarStep = async (step: EDispensacaoPassos) => {
    if (step == EDispensacaoPassos.Receita)
      return true;

    if (step == EDispensacaoPassos.Prescritor) {
      return await validarReceita();
    }

    if (step == EDispensacaoPassos.Comprador) {
      if (!validarReceita()) return false;
      if (!validarPrescritor()) return false;
      return true;
    }

    if (step == EDispensacaoPassos.Medicamento) {
      if (!validarReceita()) return false;
      if (!validarPrescritor()) return false;
      if (!validarComprador()) return false;
      return true;
    }
  }

  const validarReceita = async () => {

    try {
      const formIsValid = await schema.isValid(formDispensacao.medicamentos[0]?.receita);
      if (!formIsValid)
        return false;

      return true;

    } catch (error) {
      const validationError = error as yup.ValidationError;

      validationError?.inner?.forEach(err => {
        const path = err.path?.toString();

        if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
          setErrorField(path, err.message);
        }

      });

      return false;
    }

  }

  const validarPrescritor = () => {
    const prescritorValid = formDispensacao.medicamentos[0]?.receita.prescritor?.nome === undefined;
    setErrorValidationPrescritor(prescritorValid);
    return !prescritorValid;
  }

  const validarComprador = () => {
    var compradorValid = formDispensacao.medicamentos[0]?.receita.comprador?.nome !== undefined;
    setErrorValidationComprador(!compradorValid);
    if (!compradorValid)
      return compradorValid;

    const compradorPacienteMesmaPessoa = formDispensacao.medicamentos[0]?.receita.compradorPacienteMesmaPessoa;
    if (!compradorPacienteMesmaPessoa)
      compradorValid = formDispensacao.medicamentos[0]?.receita.paciente?.nome !== undefined;

    setErrorValidationPaciente(compradorPacienteMesmaPessoa);
    return compradorValid;

  }

  function setErrorField(path: string, errorMensage: string) {

    switch (path) {

      case 'dataLancamento':
        setError('dataLancamento', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'horaLancamento':
        setError('horaLancamento', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'dataReceita':
        setError('dataReceita', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'numeroReceita':
        setError('numeroReceita', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'uf':
        setError('uf', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      case 'cid':
        setError('cid', {
          type: 'manual',
          message: errorMensage,
        });
        break;

      default:
        break;
    }

  }

  const getDispensacaoVisualizacao = useCallback(async () => {
    try {
      setIsVisualizacao(true);
      const res = await getDispensacaoById(dispensacaoId, dataCompetencia);

      if (res.erro) {
        props.fecharModal(true);
        setIsLoading(false);
        showToast("error", `${res.erro}`, 5000, 'top-center');
        return;
      }

      const dispensacao = res.resultado?.data;

      if (!dispensacao.medicamentos[0]?.receita)
        dispensacao.medicamentos[0].receita = new DispensacaoMedicamentoReceita();

      setFormDispensacao(new Dispensacao(
        res.resultado?.data.id,
        res.resultado?.data.operacaoAutorizacaoId,
        dispensacao.medicamentos[0].receita.dataHoraLancamento,
        dispensacao.medicamentos[0].receita.dataHoraLancamento ?
          formatarDataInput(dispensacao.medicamentos[0].receita.dataHoraLancamento).data : "",
        dispensacao.medicamentos[0].receita.dataHoraLancamento ?
          formatarDataInput(dispensacao.medicamentos[0].receita.dataHoraLancamento).hora : "",
        dispensacao.medicamentos,
        dispensacao.receitaArquivoNome,
        dispensacao.receitaArquivo,
      ));

      setIsLoading(false);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getDispensacaoById, dispensacaoId, dataCompetencia]);

  useEffect(() => {
    if (!dispensacaoId)
      return;

    getDispensacaoVisualizacao();
  }, [dispensacaoId]);

  const steps: WizardStep[] = [
    { label: 'Receita', icon: <img src={ReceitaIcon} alt="" /> },
    { label: 'Prescritor', icon: <img src={PrescritorIcon} alt="" /> },
    { label: 'Comprador', icon: <img src={CompradorIcon} alt="" /> },
    { label: 'Medicamento', icon: <img src={MedicamentoIcon} alt="" /> }
  ];

  const passoFormRender = () => {
    switch (passoDispensacao) {
      case EDispensacaoPassos.Receita:
        return <DispensacaoReceitaForm
          visualizacao={isVisualizacao}
          formState={formState}
          register={register}
          dispensacao={formDispensacao}
          setDispensacao={setFormDispensacao} />
      case EDispensacaoPassos.Prescritor:
        return <DispensacaoPrescritorForm
          visualizacao={isVisualizacao}
          formState={formState}
          register={register}
          dispensacao={formDispensacao}
          setDispensacao={setFormDispensacao}
          errorValidation={errorValidationPrescritor} />
      case EDispensacaoPassos.Comprador:
        return <DispensacaoCompradorForm
          visualizacao={isVisualizacao}
          formState={formState}
          register={register}
          dispensacao={formDispensacao}
          setDispensacao={setFormDispensacao}
          errorValidation={errorValidationComprador}
          errorValidationCompradorPacienteMesmaPessoa={errorValidationPaciente} />
      case EDispensacaoPassos.Medicamento:
        return <DispensacaoMedicamentosForm
          visualizacao={isVisualizacao}
          formState={formState}
          register={register}
          dispensacao={formDispensacao}
          setDispensacao={setFormDispensacao}
          editarMedicamentos={editarMedicamentos}
        />
    }
  };

  const editarMedicamentos = useCallback((medicamentos: DispensacaoMedicamento[]) => {
    setMedicamentosEdicao(medicamentos);
  }, []);

  const salvarLotes = (lotesSelecionados: DispensacaoMedicamentoLote[], codigoAnvisa: string) => {
    let dispensacao = { ...formDispensacao as Dispensacao };

    for (let index = 0; index < dispensacao.medicamentos.length; index++) {
      if (dispensacao.medicamentos[index].codigoAnvisa !== codigoAnvisa)
        continue;

      var hasLote = lotesSelecionados?.some(x => x.lote === dispensacao.medicamentos[index].lote);

      if (!hasLote)
        dispensacao.medicamentos[index] = {
          ...dispensacao.medicamentos[index],
          lote: '',
          validade: undefined,
          fabricacao: undefined,
          codigoAgregador: undefined,
        };

      let loteAtual = lotesSelecionados.slice(-1).pop();

      if (!loteAtual || dispensacao.medicamentos[index].codigoAnvisa !== codigoAnvisa)
        continue;

      let loteAtualIndice = lotesSelecionados.indexOf(loteAtual);

      dispensacao.medicamentos[index] = {
        ...dispensacao.medicamentos[index],
        lote: loteAtual.lote,
        validade: loteAtual.dataValidade,
        fabricacao: loteAtual.dataFabricacao,
        codigoAgregador: loteAtual.codigoAgregador,
      }

      lotesSelecionados[loteAtualIndice].quantidadeReservada -= 1;
      if (loteAtual.quantidadeReservada === 0)
        lotesSelecionados.pop();
    }

    setFormDispensacao(dispensacao);
    setMedicamentosEdicao([]);
    showToast('success', "Lote adicionado com sucesso!", 5000, 'top-center');
  }

  const openModalFinalizar = () => {
    abrirFinalizacaoOperacaoOpcoes(operacaoId, autorizarDispensacao, reprovarDispensacao, salvarDispensacao)
  }

  const autorizarDispensacao = async () => {
    setIsLoading(true);

    const formIsValid = await schema.isValid(formDispensacao.medicamentos[0]?.receita);

    if (!formIsValid) {
      setIsLoading(false);
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');
    }

    formDispensacao.dataHoraLancamento =
      new Date(`${formDispensacao.medicamentos[0]?.receita.dataLancamento}T${formDispensacao.medicamentos[0]?.receita.horaLancamento}:00.000Z`);

    formDispensacao.medicamentos.forEach(medicamento => {
      medicamento.receita.dataHoraLancamento = formDispensacao.dataHoraLancamento;
    });

    let dispensacao = new OperacaoDispensacaoRequest(
      formDispensacao.id,
      formDispensacao.operacaoAutorizacaoId,
      ETipoOperacaoMovimentacao.Dispensacao,
      formDispensacao.dataHoraLancamento,
      false,
      formDispensacao
    );

    let operacaoSalva = await editarOperacaoDispensacao(dispensacao);

    if (operacaoSalva.erro) {
      setIsLoading(false);
      return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
    }

    const resMedicamentoLoteSemEstoque = await getMedicamentoSemEstoque(formDispensacao.id);

    if (resMedicamentoLoteSemEstoque.erro) {
      setIsLoading(false);
      return showToast("error", `${resMedicamentoLoteSemEstoque.erro}`, 10000, 'top-center');
    }

    const operacaoAutorizada = await aprovarAutorizacao(operacaoId, true);

    if (operacaoAutorizada.erro) {
      setIsLoading(false);
      return showToast("error", `${operacaoAutorizada.erro}`, 5000, 'top-center');
    }

    showToast('success', "Operação autorizada com sucesso!", 5000, 'top-center');

    setIsLoading(false);
    props.fecharModal(true);
    return;
  }

  const reprovarDispensacao = async () => {
    try {
      setIsLoading(true);
      await reprovarAutorizacao(operacaoId, false);

      showToast('success', "Operação reprovada com sucesso!", 5000, 'top-center');
      setIsLoading(false);
      props.fecharModal(true);

    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      showToast("error", `Erro ao reprovar a operação: ${e.message}`, 5000, 'top-center');
    }
  }

  const salvarDispensacao = async () => {
    setIsLoading(true);

    const formIsValid = await schema.isValid(formDispensacao.medicamentos[0]?.receita);

    if (!formIsValid) {
      setIsLoading(false);
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');
    }

    formDispensacao.dataHoraLancamento =
      new Date(`${formDispensacao.medicamentos[0]?.receita.dataLancamento}T${formDispensacao.medicamentos[0]?.receita.horaLancamento}:00.000Z`);

    formDispensacao.medicamentos.forEach(medicamento => {
      medicamento.receita.dataHoraLancamento = formDispensacao.dataHoraLancamento;
    });

    const dispensacao = new OperacaoDispensacaoRequest(
      formDispensacao.id,
      formDispensacao.operacaoAutorizacaoId,
      ETipoOperacaoMovimentacao.Dispensacao,
      formDispensacao.dataHoraLancamento,
      false,
      formDispensacao
    );

    const operacaoSalva = await editarOperacaoDispensacao(dispensacao);

    if (operacaoSalva.erro) {
      setIsLoading(false);
      return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
    }

    showToast("success", 'Dispensação editada com sucesso!');

    setIsLoading(false);
    props.fecharModal(true);
  };

  const showAvancar = () => passoDispensacao !== EDispensacaoPassos.Medicamento;
  const showVoltar = () => passoDispensacao === EDispensacaoPassos.Prescritor || passoDispensacao === EDispensacaoPassos.Comprador || passoDispensacao === EDispensacaoPassos.Medicamento;
  const showBotoesFinalizar = () => passoDispensacao === EDispensacaoPassos.Medicamento;

  const fecharMedicamentos = () => {
    medicamentosEdicao.length
      ? setMedicamentosEdicao([])
      : props.fecharModal();
  };

  return (
    <>
      <Fade in timeout={900} mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`}>

          <ModalHeader
            title={'Dispensação'}
            leftArea={
              <ButtonModalHeader
                tooltip="Voltar"
                icon={<VoltarIcon tipo="MODAL_HEADER" />}
                onClick={() => fecharMedicamentos()}
              />
            }
          />

          {isLoading ? <CircularLoading tipo="FULLSIZED" /> :
            <>
              {medicamentosEdicao.length
                ? <DispensacaoMedicamentoLotes
                  visualizacao={isVisualizacao}
                  medicamentos={medicamentosEdicao}
                  salvarLotesDispensacao={salvarLotes}
                  dispensacaoId={dispensacaoId}
                  dataCompetencia={dataCompetencia} />
                : (
                  <div className={`flex-column w-100 ${styles.formPadding, styles.formHeight}`}>
                    <Wizard steps={steps} activeStep={passoDispensacao} currentStepEmmiter={controlarStep} />

                    <div className={`flex-column ${isVisualizacao ? styles.formContainerVisualizacao : styles.formContainer}`}>
                      {!isLoading && passoFormRender()}
                    </div>

                    <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                      {showVoltar() &&
                        <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                          <Button onClick={() => controlarStep(passoDispensacao - 1)} variant="text" className="h-100 w-100">
                            <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                              <VoltarIcon class={styles.iconSize} />
                              Voltar
                            </div>
                          </Button>
                        </div>
                      }
                      {showAvancar() &&
                        <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                          <Button onClick={() => controlarStep(passoDispensacao + 1)} variant="text" className="h-100 w-100">
                            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                              <AvancarIcon class={styles.iconSize} />
                              Avançar
                            </div>
                          </Button>
                        </div>
                      }
                      {
                        !isVisualizacao && (
                          <>
                            {showBotoesFinalizar() &&
                              <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                <Button onClick={() => openModalFinalizar()} variant="text" className="h-100 w-100">
                                  <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                    <AvancarIcon class={styles.iconSize} />
                                    Finalizar
                                  </div>
                                </Button>
                              </div>
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                )
              }
            </>
          }
        </div>
      </Fade>
    </>
  );
});
