import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    overflowY: "hidden",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[100],
  },
  color: {
    backgroundColor: theme.palette.grey[100],
  },

  listContainer: {
    marginTop: '1rem',
    justifyContent: 'start',
    gap: '1%',
    height: 0,
    flexGrow: 1
  },
  inventarioListStyle: {
    overflow: 'auto',
    paddingRight: '0.5rem'
  },
  contentContainer: {
    padding: '0 4%',
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: '0 1%'
    }
  },
  darkLabel: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },
  whiteButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`
  },
  semRegistroStyle: {
    fontSize: "1rem",
    fontWeight: 600,
    textAlign: 'center',
    margin: '1rem 0'
  },
  titleStyle: {
    paddingLeft: '1rem',
    fontSize: '1.3rem',
    fontWeight: 500,
  },
  breakLine: {
    height: '2px',
    marginBottom: '1vh',
    backgroundColor: theme.palette.divider,
  },
  buttonStyle: {
    flexGrow: 1,
    minWidth: '30%',
    borderRadius: '10px',
  },
  darkButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  containerButtonMaxHeight: {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    marginTop: 10,
    marginBotton: 10,
    alignItems: 'center',
    maxHeight: "5vh"
  },
  whiteLabel: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },
  buttonLabel: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem'
    },
  },
  buttonContainer: {
    height: '4rem',
    gap: '1.0rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      alignSelf: 'center',
    }
  },
  bw45: {
    width: '45%',
  }
}));