import classNames from 'classnames';
import { DefaultCardProps } from './default-card-props';
import { useDefaultCardStyles } from './default-card-styles';

export const DefaultCard = ({
  onClick,
  onCheck,
  isSelected,
  disabled,
  children,
  className,
}: DefaultCardProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <div
        onClick={onClick}
        className={classNames(
          classes.cardContainer,
          'card',
          isSelected ? classes.cardSelected : undefined,
          disabled ? classes.cardinativo : undefined,
          className
        )}
      >
        {children}
      </div>
    </>
  );
};
