import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from '../../../../config/types';

export function useGetConfiguracoes() {
    const { invocarApi,  carregando, ...outrasPropriedades } = useApiBase();

    const getConfiguracoes = useCallback(
        () => {
            return invocarApi({
                url: `Configuracoes`,
                method: "GET",
                baseURL: VariaveisAmbiente.apiUrl,
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.TouchPharma,
                apiVersion: 'v1'
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        carregando,
        getConfiguracoes
    };
}
