import { Button, Fade, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { isUndefined } from "lodash";
import moment from 'moment';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useGetListTransferencias } from '../../../../../../data/api/gestao/transferencias/transferencias';
import { useSessaoAtual, useToastSaurus } from '../../../../../../services/app';
import { formatarCPFCNPJ } from "../../../../../../utils/cpfcnpj-format";
import { CircularLoading } from "../../../../../components";
import { DefaultCard, useDefaultCardStyles } from '../../../../../components/cards/components/default-card';
import { PageHeader } from '../../../../../components/headers/header-page/header-page';
import { ProcurarIcon } from "../../../../../components/icons";
import { NovoIcon } from "../../../../../components/icons/novo-icon";
import { Paginacao } from '../../../../../components/paginacao';
import { TransferenciaModal } from "../../modals/modal-transferencia/transferencia-modal";
import { TransferenciasResponse } from '../../models/transferencias.model';
import { useStyles } from './transferencias-listagem-styles';

export const TransferenciaListPage = () => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();
  const pageItems = 10;
  const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<TransferenciasResponse>() });
  const { getListTransferencias, carregando } = useGetListTransferencias();

  const { showToast } = useToastSaurus();
  const { getEmpresasVinculadas } = useSessaoAtual();

  const [modalOpen, setModalOpen] = useState(false);
  const [transferenciaModal, setTransferenciaModal] = useState(null as ReactNode);

  const [timer, setTimer] = useState({} as NodeJS.Timeout);

  const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<TransferenciasResponse>) => {
    setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
  }, []);

  const search = useCallback(
    async (newPage: number, filter: string = "") => {
      try {
        const res = await getListTransferencias(newPage, pageItems, filter);

        if (res.erro)
          throw res.erro;

        const list = res.resultado?.data.list;
        const pageIndex = res.resultado?.data.pageIndex;
        const totalPages = res.resultado?.data.totalPages;
        const totalResults = res.resultado?.data.totalResults;

        fillResult(
          pageIndex,
          totalPages,
          totalResults,
          list
        );

      } catch (e: any) {
        if (axios.isCancel(e))
          return;
        
        showToast('error', e.message);
      }
    }, [fillResult, showToast]
  );

  const pageChanged = useCallback(
    (newPage: number) => {
      search(newPage);
    },
    [search]
  );

  useEffect(() => {
    search(1);
  }, [search])

  const visualizarTransferencia = (transferenciaId: string, dataCompetencia?: Date) => {
    transferenciaRender(transferenciaId, dataCompetencia);
  }

  const transferenciaRender = (id?: string, data?: Date) => {
    if (getEmpresasVinculadas().length <= 1)
      return showToast("error", "É necessário duas ou mais empresas vinculadas para fazer uma Transferência!");

    setModalOpen(true);

    return (
      setTransferenciaModal(
        <TransferenciaModal
          openned={true}
          setModalOpen={setModalOpen}
          transferenciaId={id}
          dataCompetencia={data}
        />
      )
    );
  }

  function origemDestino(cnpj?: string, nome?: string): string {

    let cnpjResult = isUndefined(cnpj) ? "" : formatarCPFCNPJ(cnpj);
    let nomeResult = isUndefined(nome) ? "" : nome;

    let origemDestinoResult = (cnpjResult !== "" && nomeResult !== "") ? `${cnpjResult} - ${nomeResult}` :
      `${cnpjResult} ${nomeResult}`


    return origemDestinoResult;
  }

  const filtroListagem = (res: string) => {
    clearTimeout(timer!);
    const newTimer = setTimeout(() => {
      search(1, res);
    }, 500);
    setTimer(newTimer);
  }

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <Fade in mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${classes.color}`}>
          <PageHeader
            showSearchableBar
            showBotaoFiltrar
            showMenu
            titulo='Transferências'
            filtroListagem={res => filtroListagem(res)} />

          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />

          <div className={`flex-column h-100 mb-20 overflow-auto ${classes.listPadding}`}>
            {
              !queryStatus.list.length && (
                <Typography variant="h3" className={classes.semRegistroStyle}>
                  Nenhuma tranferência foi encontrada.
                </Typography>
              )
            }
            <div className={`flex-column h-100 w-100 ${classes.listContainer}`}>
              {queryStatus.list.map((row, index) => (
                <DefaultCard key={index} className={classesCard.cardContainer}
                  onClick={() => visualizarTransferencia(row.id, row.dataHoraLancamento)}>
                  <div className={`flex-row h-100 w-100`}>
                    <div className={classNames(classesCard.tagStatus, classes.statusColor)} />

                    <div className={classesCard.infoTagContainer}>
                      <Typography className={classesCard.infoTagStyle}>
                        T
                      </Typography>
                    </div>

                    <div className={`flex-row w-100 ${classesCard.infoContainer}`}>
                      <div className={`flex-column ${classesCard.inputMinWidth}`}>
                        <Typography className={classesCard.labelFont}>
                          Origem:
                        </Typography>
                        <Typography className={classesCard.contentFont}>
                          {origemDestino(row.origem?.cnpj, row.origem?.nome)}
                        </Typography>
                      </div>
                      <div className={`flex-column ${classesCard.inputMinWidth}`}>
                        <Typography className={classesCard.labelFont}>
                          Destino
                        </Typography>
                        <Typography className={classesCard.contentFont}>
                          {origemDestino(row.destino?.cnpj, row.destino?.nome)}
                        </Typography>
                      </div>
                      <div className={`flex-column ${classesCard.inputMinWidth}`}>
                        <Typography className={classesCard.labelFont}>
                          Operação:
                        </Typography>
                        <Typography className={classesCard.contentFont}>
                          {row.tipoOperacaoTransferencia === 1 ? "Saída" : "Entrada"}
                        </Typography>
                      </div>
                      <div className={`flex-column ${classesCard.inputMinWidth}`}>
                        <Typography className={classesCard.labelFont}>
                          Data:
                        </Typography>
                        <Typography className={classesCard.contentFont}>
                          {moment(row.dataHoraLancamento).format("DD/MM/YYYY")}
                        </Typography>
                      </div>

                      <div className={`flex-column ${classesCard.inputMinWidth}`}>
                        <Typography className={classesCard.labelFont}>
                          Nº da NF
                        </Typography>
                        <Typography className={classesCard.contentFont}>
                          {row.numeroNotaFiscal}
                        </Typography>
                      </div>
                    </div>

                    <Tooltip arrow title="Vizualizar Dispensação">
                      <div className={classesCard.cardRightButton}>
                        <ProcurarIcon tipo="BUTTON" onClick={() => visualizarTransferencia(row.id, row.dataHoraLancamento)} />
                      </div>
                    </Tooltip>
                  </div>
                </DefaultCard>
              ))}

            </div>

            {modalOpen && (transferenciaModal)}

            <div className={`flex-row w-100 ${classNames(classes.buttonContainer, classes.buttonPosition)}`}>
              <div className={classNames(classes.buttonStyle, classes.darkButton, classes.buttonAlign)}>
                <Button onClick={() => transferenciaRender()} variant="text" className="h-100 w-100">
                  <div className={`flex-row ${classes.buttonLabel} ${classes.whiteLabel}`}>
                    <NovoIcon class={classes.iconSize} />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}
