import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";

export function useGetConfigNfceExists() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConfigNfceExists = useCallback(
        () =>
            invocarApi({
                url: `configuracoes/emissor/tem-configuracao`,
                method: "GET",
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.Pedidos,
                apiVersion: 'v1'
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getConfigNfceExists,
    };
}
