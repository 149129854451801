import { OperacaoMenuEnum } from "../../../../../../components/headers/header-page/header-page";
import { ListModuloFilho } from "../list-modulo-filho/list-modulo-filho";
import { ListModuloPaiProps } from "./list-modulo-pai-props";

export const ListModuloPai = (props: ListModuloPaiProps) => {

  return (
    <>
      <ListModuloFilho
        modeloAtual={props.modeloAtual}
        key={props.codigoModulo}
        codigoModulo={props.codigoModulo}
        nomeModulo={props.nomeModulo}
        isFavorite={props.codigoModulo === OperacaoMenuEnum.Autorizacao ? true : false}
        rota={props.rota}
        isMobile={props.isMobile}
      />
    </>
  );
};
