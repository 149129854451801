import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const AvancarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M19.8011 9.80103L16.2293 13.3728L27.8312 25L16.2293 36.6272L19.8011 40.1989L35 25L19.8011 9.80103Z" />
            </DefaultIcon>
        </>
    );
};
