import moment from "moment";
import { useState } from "react";
import { FormState } from "react-hook-form";
import { EnumCadastroTipo, KeyValueModel } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { DefaultCard } from '../../../../components/cards/components';
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { EPeriodoTipo, RelatorioBalancoMedicamentoModel } from "../models/relatorio-models";
import { useStyles } from "./relatorio-balanco-medicamento-styles";

interface BMPOFormProps {
  listaDataTipo: KeyValueModel[];
  listaTrimestre: KeyValueModel[];
  formState: FormState<RelatorioBalancoMedicamentoModel>;
  register: any;
  formBMPO: RelatorioBalancoMedicamentoModel;
  errorValidationfarmaceutico: boolean;
  setErrorValidationfarmaceutico: React.Dispatch<React.SetStateAction<boolean>>;
  setFormBMPO: React.Dispatch<React.SetStateAction<RelatorioBalancoMedicamentoModel>>;
}

export const RelatorioBalancoMedicamentoForm = (({
  listaDataTipo,
  listaTrimestre,
  formState,
  register,
  formBMPO,
  errorValidationfarmaceutico,
  setErrorValidationfarmaceutico,
  setFormBMPO
}: BMPOFormProps) => {
  const styles = useStyles();
  const { errors } = formState;

  const alterarTipoData = (periodoTipoId: EPeriodoTipo) =>
    setFormBMPO({
      ...formBMPO,
      periodoTipoId,
      periodoTrimestralId: undefined,
      ano: new Date().getFullYear(),
      dataInicial: undefined,
      dataFinal: undefined,
    });

  const [dataMaximaInicial, setDataMaximaInicial] = useState(moment(new Date()).format("YYYY-MM-DD"));

  return (
    <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
      <DefaultCard className={styles.card}>
        <div className={`w-100 ${styles.inputContainer}`}>
          <AutocompletePessoas label="Farmacêutico"
            name="farmaceutico"
            size="medium"
            tipo={EnumCadastroTipo.TODOS}
            onChange={(e: any) => setFormBMPO({ ...formBMPO, farmaceutico: e.value.id })}
            error={Boolean(errorValidationfarmaceutico && !formBMPO.farmaceutico)}
            helperText={
              errorValidationfarmaceutico && !formBMPO.farmaceutico
                ? "O Farmacêutico é obrigatório!"
                : undefined
            }
            onBlur={() => {
              if (!formBMPO.farmaceutico)
                setErrorValidationfarmaceutico(true);
            }}
          />
        </div>

        <div className={`w-100 ${styles.inputContainer}`}>
          <SelectSaurus label="Tipo de Data"
            conteudo={listaDataTipo}
            className={`h-100 w-100 ${styles.inputStyle}`}
            variant="outlined"
            allowSubmit={true}
            size="medium"
            required
            error={Boolean(errors.periodoTipoId && !formBMPO.periodoTipoId)}
            helperText={
              errors.periodoTipoId && !formBMPO.periodoTipoId
                ? errors.periodoTipoId?.message
                : undefined
            }
            {...register('periodoTipoId', {
              onChange: (e: any) => e.target.value ? alterarTipoData(e.target.value) : ''
            })}
          />
        </div>

        {
          formBMPO.periodoTipoId &&
          <div className={`flex-row w-100 ${styles.inputContainer}`}>
            <div className={formBMPO.periodoTipoId !== EPeriodoTipo.Personalizado ? styles.dataPeriodoTrimestreContainer : ``}>
              {
                formBMPO.periodoTipoId === EPeriodoTipo.Trimestral &&
                <div className="w-50">
                  <SelectSaurus label="Trimestre"
                    conteudo={listaTrimestre}
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    variant="outlined"
                    allowSubmit={true}
                    size="medium"
                    required
                    error={Boolean(errors.periodoTrimestralId && !formBMPO.periodoTrimestralId)}
                    helperText={
                      errors.periodoTrimestralId && !formBMPO.periodoTrimestralId
                        ? errors.periodoTrimestralId?.message
                        : undefined
                    }
                    {...register('periodoTrimestralId', {
                      onChange: (e: any) => setFormBMPO({ ...formBMPO, periodoTrimestralId: e.target.value })
                    })}
                  />
                </div>
              }

              {
                formBMPO.periodoTipoId !== EPeriodoTipo.Personalizado &&
                <div className={
                  formBMPO.periodoTipoId === EPeriodoTipo.Trimestral ?
                    `w-50` :
                    `w-100`
                }>
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    labelCustom="Ano"
                    value={formBMPO.ano ?? undefined}
                    name="ano"
                    required
                    variant="outlined"
                    tipo="NUMERO"
                    size="medium"
                    inputProps={{ maxLength: 4 }}
                    showStartAdornment
                    error={Boolean(errors.ano && !formBMPO.ano)}
                    helperText={
                      errors.ano && !formBMPO.ano
                        ? errors.ano?.message
                        : undefined
                    }
                    {...register('ano', {
                      onChange: (e: any) => setFormBMPO({ ...formBMPO, ano: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 })
                    })}
                  />
                </div>
              }
            </div>

            {
              formBMPO.periodoTipoId === EPeriodoTipo.Personalizado &&
              <div className={styles.dataPeriodoContainer}>
                <div className="w-50">
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    labelCustom="Data Inicial"
                    value={formBMPO.dataInicial ?? new Date()}
                    tipo="DATA"
                    size="medium"
                    maxDate={dataMaximaInicial}
                    variant="outlined"
                    error={Boolean(errors.dataInicial && !formBMPO.dataInicial)}
                    helperText={
                      errors.dataInicial && !formBMPO.dataInicial
                        ? errors.dataInicial?.message
                        : undefined
                    }
                    {...register('dataInicial', {
                      onChange: (e: any) => setFormBMPO({ ...formBMPO, dataInicial: e.target.value })
                    })}
                  />
                </div>
                <div className="w-50">
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    labelCustom="Data Final"
                    value={formBMPO.dataFinal ?? ''}
                    tipo="DATA"
                    size="medium"
                    maxDate={moment(new Date()).format("YYYY-MM-DD")}
                    minDate={formBMPO.dataInicial}
                    disabled={formBMPO.dataInicial == null}
                    variant="outlined"
                    error={Boolean(errors.dataFinal && !formBMPO.dataFinal)}
                    helperText={
                      errors.dataFinal && !formBMPO.dataFinal
                        ? errors.dataFinal?.message
                        : undefined
                    }
                    {...register('dataFinal', {
                      onChange: (e: any) => {
                        setDataMaximaInicial(moment(e.target.value).format("YYYY-MM-DD"));
                        setFormBMPO({ ...formBMPO, dataFinal: e.target.value });
                      }
                    })}
                  />
                </div>
              </div>
            }
          </div>
        }

      </DefaultCard>
    </div>
  );
});