import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetEstoqueByCodigoAnvisa } from "../../../../../../../data/api/gestao/estoque/get-estoque";
import { useToastSaurus } from "../../../../../../../services/app";
import { AutocompleteMedicamento } from "../../../../../../components/autocompletes/autocomplete-medicamentos";
import { ConfirmarIcon } from "../../../../../../components/icons/confirmar-icon";
import { MedicamentoPerda, PerdaMedicamentoLote, PerdaMedicamentoLoteProp } from "../../../models/perdas-models";
import { PerdaMedicamentoLotesCard } from "./perda-medicamento-lotes-card";
import { useStyles } from "./perda-medicamento-lotes-styles";

export const PerdaMedicamentoLotes = (({ medicamentos, salvarLotesPerda, visualizacao }: PerdaMedicamentoLoteProp) => {
  const [medicamento, setMedicamento] = useState<MedicamentoPerda>({} as MedicamentoPerda);
  const [lotes, setLotes] = useState<PerdaMedicamentoLote[]>([]);
  const [totalPerdido, SetTotalPerdido] = useState<number>(0);

  const { getEstoqueByCodigoAnvisa } = useGetEstoqueByCodigoAnvisa();
  const styles = useStyles();
  const { showToast } = useToastSaurus();

  useEffect(() => {
    if (medicamentos?.length)
      setMedicamento({ ...medicamentos[0] } as MedicamentoPerda);
  }, [medicamentos]);

  useEffect(() => {

    if (medicamento.codigoAnvisa)
      getLotes(medicamento.codigoAnvisa);

  }, [medicamento]);

  useEffect(() => {
    if (!lotes?.length) return;

    let perdidos = lotes.map(l => l.quantidadePerdida ?? 0)
      .reduce((accumulator, currentValue) =>
        accumulator + currentValue, 0);

    SetTotalPerdido(perdidos);
  }, [lotes]);

  const getLotes = useCallback(async (codigoAnvisa: string) => {
    try {

      const res = await getEstoqueByCodigoAnvisa(codigoAnvisa);

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data) {
        showToast('error', 'Este medicamento não possui estoque!', 3000, 'top-center');
        setLotes([]);
        return;
      }

      const lotes = res.resultado?.data?.lotes?.map((lote: any) => ({
        lote: lote.lote,
        quantidadeDisponivel: lote.quantidade,
        quantidadePerdida: medicamentos.filter(m => m.lote === lote.lote).length,
        dataValidade: lote.dataValidade,
        dataFabricacao: lote.dataFabricacao,
        codigoAgregador: lote.codigoAgregador,
      } as PerdaMedicamentoLote));

      setLotes(lotes);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getEstoqueByCodigoAnvisa, medicamentos]);

  const alterarLotes = (loteNome: string, quantidade: number) => {
    if (!loteNome || isNaN(quantidade))
      return;

    let lotesAlteracao = [...lotes];
    let loteIndice = lotesAlteracao.findIndex(l => l.lote === loteNome);
    if (loteIndice === undefined)
      return;

    lotesAlteracao[loteIndice].quantidadePerdida = quantidade;

    setLotes(lotesAlteracao);
  };

  const salvarLotes = () => {
    let lotesSelecionados = lotes?.filter(l => l.quantidadePerdida);
    salvarLotesPerda(lotesSelecionados, medicamento);
  };

  const handleMedicamentoAutocomplete = (medicamento: any) => {

    if (medicamento && !medicamento.ms) {
      showToast('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');
      setLotes([]);
      setMedicamento({} as MedicamentoPerda);
    }


    setMedicamento({
      medicamentoId: medicamento.id,
      medicamentoNome: medicamento.descricao,
      codigoAnvisa: medicamento.ms
    } as MedicamentoPerda);


  };

  const handleMedicamentoLabel = (): string =>
    medicamento.codigoAnvisa ? `${medicamento.medicamentoNome} - ${medicamento.codigoAnvisa}` : '';

  return (
    <>
      {medicamentos !== undefined &&
        <div className={`flex-row w-100 ${styles.medicamentoInfoContainer}`}>
          <div className={`flex-column h-100 ${styles.medicamentoInfoStyle}`}>
            <AutocompleteMedicamento
              value={handleMedicamentoLabel()}
              label="Buscar Medicamento"
              className={styles.inputStyle}
              onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
              disabled={visualizacao}
            />
          </div>
          <div className={`flex-column ${styles.quantidadeContainer}`}>
            <Typography className={styles.quantidadeLabel}>
              Saída por Perdas
            </Typography>
            <Typography className={styles.quantidadeValor}>
              {totalPerdido}
            </Typography>
          </div>
        </div>
      }

      <div className={`flex-column w-100 ${styles.formPadding}`}>

        {
          lotes?.length ?
            <>
              <div className={`flex-column w-100 ${styles.titleContainer}`}>
                <Typography className={`w-100 ${styles.titleStyle}`}>
                  Lote e Validade
                </Typography>
                <div className={`w-100 ${styles.breakLine}`} />
              </div>

              <div className={`flex-column ${styles.innerFormContainerMedicamento}`}>
                {
                  lotes?.map((lote, index) => (
                    <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                      <PerdaMedicamentoLotesCard lote={lote}
                        alteraLoteQtd={alterarLotes}
                        visualizacao={visualizacao} />
                    </div>
                  ))
                }
              </div>

            </>
            :
            <div className='flex-column w-100 h-100'>
              <Typography className={styles.noInfoStyle}>BUSQUE O PRODUTO QUE DESEJA INFORMAR A PERDA!</Typography>
              {(medicamento?.codigoAnvisa && lotes?.length === 0) &&
                <Typography className={`w-100 ${styles.noInfoStyle}`}>
                  Medicamento sem estoque!
                </Typography>
              }

            </div>
        }
        {
          !visualizacao &&
          <div className={`flex-row w-100 ${styles.buttonContainer}`}>
            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
              <Button onClick={() => salvarLotes()} variant="text" className="h-100 w-100">
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  <ConfirmarIcon class={styles.iconSize} />
                  Adicionar
                </div>
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  );
});