import axios from "axios";
import { useCallback } from "react";
import { SngpcStorageKeys, useSngpcStorage, useToastSaurus } from ".";
import { useGetEmpresaById } from "../../../data/api/gestao/empresa/get-empresa-by-id";
import { EmpresaAtualEndereco } from "../providers/empresa-atual-provider/empresa-atual-model";

export function useEmpresaAtualHandler() {
  const { getRegistro } = useSngpcStorage();
  const { getEmpresaById } = useGetEmpresaById();
  const { showToast } = useToastSaurus();

  const getEmpresaAtual = useCallback(() => {
    return getEmpresaById(
      getRegistro(SngpcStorageKeys.EmpresaAtual, false).toString()
    );
  }, [getRegistro, getEmpresaById]);

  const getEmpresaAtualEndereco = useCallback(async () => {
    try {
      let res = await getEmpresaAtual();

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      let empresaAtual = res?.resultado.data;
      let enderecoEmpresa = {
        cep: empresaAtual.cep,
        logradouro: empresaAtual.logradouro,
        numero: empresaAtual.numero,
        complemento: empresaAtual.complemento,
        bairro: empresaAtual.bairro,
        municipio: empresaAtual.municipio,
        uf: empresaAtual.uf
      } as EmpresaAtualEndereco;

      return enderecoEmpresa;
    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      showToast('error', e.message);
    }
  }, [getEmpresaAtual, showToast]);

  return { getEmpresaAtual, getEmpresaAtualEndereco };
}