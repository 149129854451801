import { useCallback, useEffect, useState } from 'react';
import { AppEventEnum } from '../../../../model/enums/enum-app-event';
import { useEventTools } from '../../../../services/app/use-cases/events/event-tools';
import { DialogFinalizacaoOperacaoOpcoesConteudo } from './dialog-finalizacao-operacao-opcoes-conteudo';

class DialogFinalizacaoOperacaoOpcoesControleProps {
  constructor(
    public aberto: boolean = false,
    public operacaoId: string = "",
    public aprovar = () => { },
    public reprovar = () => { },
    public salvar = () => { },
  ) { }
}

export const DialogFinalizacaoOperacaoOpcoesControle = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogFinalizacaoOperacaoOpcoesControleProps>(
    new DialogFinalizacaoOperacaoOpcoesControleProps()
  );

  const modalAlterado = useCallback(({ aberto, operacaoId, aprovar, reprovar, salvar }: any) => {
    setModalState({ aberto, operacaoId, aprovar, reprovar, salvar });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.FinalizarOperacao, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.FinalizarOperacao, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogFinalizacaoOperacaoOpcoesConteudo
          aberto={modalState.aberto}
          operacaoId={modalState.operacaoId}
          reprovar={modalState.reprovar}
          aprovar={modalState.aprovar}
          salvar={modalState.salvar}
        />
      )}
    </>
  );
};