import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '0.5rem',
    marginTop: theme.spacing(2),
    paddingRight: '1%',
    paddingLeft: '1%',
    backgroundColor: theme.palette.grey[100],
    overflow: 'hidden'
  },
  navContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderSpacing: 10,
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    }
  },
  navContainerInterno: {
    minHeight: '0.55rem',
    borderRadius: 5,
    boxShadow: theme.shadows[1],
    padding: 5,
    paddingLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    background: theme.palette.common.white,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1.5),

  },
  paginaContainer: {
    minHeight: '0.55rem',
    boxShadow: theme.shadows[1],

    borderRadius: 5,
    padding: 3,
    marginBottom: theme.spacing(1),

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    background: theme.palette.common.white,
  },
  ul: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "nowrap",
    listStyle: "none",
    alignItems: "center",
  },
  btnNavPagina: {
    cursor: "pointer",
    height: "1.85rem",
    minWidth: "1.9rem",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    textAlign: "center",
    lineHeight: '0.55rem',
    letterSpacing: "0.01071em",
  },
  btnNavPaginaSelected: {
    borderRadius: '100%',
    pointerEvents: "none",
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  gridBtnTxtPagina: {
    float: "right",
    display: "flex",
    flexDirection: "row",
  },
  btnTxtPagina: {
    width: '0.55rem',
    height: '0.55rem',
    borderRadius: "100%",
    padding: 0,
    "& svg": {
      width: '0.36rem',
      height: '0.36rem',
      fill: theme.palette.text.primary
    }
  },
  txtFieldPagina: {
    width: 150,
    marginTop: -5,
    marginRight: theme.spacing(1)
  },
  btnNavAction: {
    cursor: "pointer",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    borderRadius: '100%',
    height: '0.55rem',
    width: '0.55rem',
    marginRight: theme.spacing(1),
    "& svg": {
      width: '032rem',
      height: '032rem',
      fill: theme.palette.text.primary
    }
  },
  svgIconActionDisabled: {
    "pointer-events": "none",
    "& svg": {
      opacity: 0.4,
    }
  },
  containerPaginacao: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.41rem',
    paddingBottom: '0.41rem'
  },
  containerTotalRegistros: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    width: '30%',
    fontSize: '0.55rem',
  },
  spanTotalRegistros: {
    fontSize: '0.6rem',
    color: theme.palette.grey[400],
    fontWeight: 600
  },
  spanNumeroPagina: {
    color: theme.palette.grey[400]
  },
  overflowVisible: {
    overflow: 'visible'
  },
  sumaryContainer: {
    minHeight: '0.55rem',
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    padding: 3,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.common.white,
    "& strong": {
      paddingLeft: theme.spacing(1)
    }
  },
}));
