import { FocusEvent, useCallback, useState } from "react";
import { useAutocompletePessoas } from "../../../../data/api/gestao/pessoas/autocomplete-pessoas";
import { EnumCadastroTipo } from '../../../../model';
import {
  AutocompleteSaurus, retornoAutoComplete
} from "../autocomplete-saurus";


export enum EnumNomeCnpj {
  Nome,
  Cnpj,
  Todos
}


export interface AutocompletePessoaProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  tipo: EnumCadastroTipo;
  nomeCnpj?: EnumNomeCnpj;
  size?: "medium" | "small";
}

interface PessoaDadoAdicional {
  estendido: string
}

export const AutocompletePessoas = ({ onChange, ...props }: AutocompletePessoaProps) => {
  const { getPessoas, carregando } = useAutocompletePessoas()
  const [pessoas, setPessoas] = useState<Array<PessoaDadoAdicional>>([]);

  const getPessoasWrapper = useCallback(
    async (termo: string) => {
      try {
        const ret = await getPessoas(props.tipo, termo);
        if (ret.erro) throw ret.erro;
        let pessoasArray: Array<PessoaDadoAdicional> = [];
        if (ret.resultado?.data?.list?.length > 0) {
          pessoasArray = (ret.resultado?.data?.list)
            .filter((x: any) =>
              props.nomeCnpj === EnumNomeCnpj.Cnpj
                ? x.cpfcnpj
                : props.nomeCnpj === EnumNomeCnpj.Nome ?
                  x.nome
                  : x
            )
            .map((pessoa: any) => ({
              ...pessoa,
              estendido: (`${pessoa.cpfcnpj ? (pessoa.cpfcnpj + ' - ') : ''}${pessoa.nome}`)
            }));
        }
        setPessoas(pessoasArray);
      } catch (e: any) { }
    }, [getPessoas, props.nomeCnpj, props.tipo]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );
  const onPesquisa = useCallback((termo: string) => {
    getPessoasWrapper(termo)
  }, [getPessoasWrapper]);

  const optionKey = (value: EnumNomeCnpj | undefined) => ({
    [EnumNomeCnpj.Nome]: 'nome',
    [EnumNomeCnpj.Cnpj]: 'cpfcnpj',
    [EnumNomeCnpj.Todos]: 'estendido',
  })[value || EnumNomeCnpj.Nome]

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      loading={(carregando)}
      onPesquisa={onPesquisa}
      fullWidth
      onChange={onChangeWrapper}
      opcoes={pessoas}
      optionLabelKey={optionKey(props.nomeCnpj)}
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      noOptionsText={"Pessoa não Localizada"}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      size={props.size}
    />
  );
};