import { Button, Fade, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { useGetListEnvioInventario } from "../../../../../../data/api/gestao/envio-inventario/envio-inventario";
import { useToastSaurus } from "../../../../../../services/app";
import { CircularLoading } from "../../../../../components";
import { PageHeader } from "../../../../../components/headers/header-page/header-page";
import { Paginacao } from "../../../../../components/paginacao";
import { EnvioInventarioCard } from "../envio-inventario-card/envio-inventario-card";
import { EnvioInventarioModal } from "../envio-inventario-modal/envio-inventario-modal";
import { useStyles } from "./envio-inventario-list-styles";
import { EnvioInventarioItem, EnvioInventarioLote } from "./envio-inventario-models";

export const EnvioInventarioListPage = (() => {

  const styles = useStyles();
  const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<EnvioInventarioItem>() });
  const { getListInventarioEnvioPendenteSNGPC, carregando } = useGetListEnvioInventario();
  const { getDataInventario, carregando: buscandoDataInventario } = useGetListEnvioInventario();
  const { enviarXMLInventarioSNGPCANVISA, carregando: enviandoXMLInventario } = useGetListEnvioInventario();
  const { downloadXMLInventarioSNGPCANVISA, carregando: downloadXMLInventario } = useGetListEnvioInventario();
  const { enviarXMLInventarioSNGPCANVISAManual, carregando: envioXMLInventarioManual } = useGetListEnvioInventario();
  const { showToast } = useToastSaurus();

  const [modalOpen, setModalOpen] = useState(false);
  const [loteModal, setLoteModal] = useState(null as ReactNode);
  const [timer, setTimer] = useState({} as NodeJS.Timeout);
  const pageItems = 10;
  const [dataInventario, setDataInventario] = useState<string | undefined>('');
  const history = useHistory();

  const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<EnvioInventarioItem>) => {
    setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
  }, []);


  const listarPaginado = useCallback(
    async (newPage: number, filter: string = "") => {
      try {
        const res = await getListInventarioEnvioPendenteSNGPC(newPage, pageItems, filter);

        if (res.erro)
          throw res.erro;

        const list = res.resultado?.data.list;
        const pageIndex = res.resultado?.data.pageIndex;
        const totalPages = res.resultado?.data.totalPages;
        const totalResults = res.resultado?.data.totalResults;

        fillResult(
          pageIndex,
          totalPages,
          totalResults,
          list
        );

      } catch (e: any) {
        if (axios.isCancel(e))
          return;

        showToast('error', e.message);
      }
    }, [fillResult, showToast]
  );

  const buscarDataInventario = useCallback(
    async () => {
      try {
        const res = await getDataInventario();

        if (res.erro)
          throw res.erro;

        setDataInventario(res.resultado?.data);

      } catch (e: any) {
        if (axios.isCancel(e))
          return;

        showToast('error', e.message);
      }
    }, [fillResult, showToast]
  );

  const filtroListagem = (res: string) => {
    clearTimeout(timer!);
    const newTimer = setTimeout(() => {
      listarPaginado(1, res);
    }, 500);
    setTimer(newTimer);
  }

  const enviarXMLSNGPCAnvisa = useCallback(async () => {

    try {

      const res = await enviarXMLInventarioSNGPCANVISA();

      if (res.erro) {
        showToast("error", `${res.erro}`, 5000, 'top-center');
        return
      }

      if (res.resultado?.data) {
        showToast("success", res.resultado?.data, 5000, 'top-center');
        history.push({
          pathname: '/autorizacao-listagem',
        })
      }


    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      showToast("info", e.message, 5000, 'top-center');
      console.error("error", e.message);
    }

  }, [enviarXMLInventarioSNGPCANVISA]);

  const enviarXMLManual = useCallback(async () => {

    try {

      const res = await enviarXMLInventarioSNGPCANVISAManual();

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      const blob = new Blob([res.resultado?.data], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);

      const dataAtual = moment().format('YYYYMMDDHHmmss');

      const link = document.createElement('a');
      link.href = url;
      link.download = `Inventário_${dataAtual}`;
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);

      showToast("success", "Download para o envio manual realizado com sucesso!", 5000, 'top-center');
      history.push({
        pathname: '/autorizacao-listagem',
      })

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }, [downloadXMLInventarioSNGPCANVISA]);

  const downloadXML = useCallback(async () => {

    try {

      const res = await downloadXMLInventarioSNGPCANVISA();

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      const blob = new Blob([res.resultado?.data], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);

      const dataAtual = moment().format('YYYYMMDDHHmmss');

      const link = document.createElement('a');
      link.href = url;
      link.download = `Inventário_${dataAtual}`;
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);

      showToast("success", "Download realizado com sucesso!", 5000, 'top-center');


    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }, [downloadXMLInventarioSNGPCANVISA]);

  const pageChanged = useCallback(
    (newPage: number) => {
      listarPaginado(newPage);
    },
    [listarPaginado]
  );

  useEffect(() => {
    listarPaginado(1);
  }, [listarPaginado]);

  useEffect(() => {
    buscarDataInventario();
  }, []);

  const isLoading = () =>
    carregando || enviandoXMLInventario || downloadXMLInventario || envioXMLInventarioManual;

  const openModalLotes = (lotes: EnvioInventarioLote[], medicamentoNome: string) => loteRender(lotes, medicamentoNome);

  function enviarXMLInventarioSNGPC() {
    enviarXMLSNGPCAnvisa();
  }

  function downloadXMLInventarioSNGPC() {
    downloadXML();
  }

  function enviarXMLInventarioSNGPCManual() {
    enviarXMLManual();
  }

  function existeInventario(): boolean {
    return queryStatus?.list?.length ? true : false;
  }

  const loteRender = (lotes: Array<EnvioInventarioLote>, medicamentoNome: string) => {
    setModalOpen(true);

    return (
      setLoteModal(
        <EnvioInventarioModal
          openned={true}
          setModalOpen={setModalOpen}
          lotes={lotes}
          medicamento={medicamentoNome}
        />
      )
    );
  }

  return (
    <>
      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      {modalOpen && loteModal}

      <Fade in timeout={900} mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${styles.color}`}>
          <PageHeader
            showBotaoFiltrar
            showMenu
            showSearchableBar
            titulo='Envio de Inventário'
            filtroListagem={res => filtroListagem(res)} />
          <>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
            <div className={`flex-column h-100 ${styles.contentContainer}`}>

              {dataInventario &&
                <Typography variant="h6" className="dataInventario">
                  Data do Inventário: {dataInventario}
                </Typography>
              }

              <div className={`flex-column h-100 w-100 ${styles.listContainer}`}>
                <div className={styles.inventarioListStyle}>
                  {queryStatus?.list?.length > 0 && queryStatus?.list?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => openModalLotes(item.lotes, item.medicamento)}>
                        <EnvioInventarioCard item={item} />
                      </div>
                    );
                  })}
                </div>
                {!queryStatus?.list?.length &&
                  <Typography className={styles.semRegistroStyle}>
                    Nenhum registro foi encontrado.
                  </Typography>
                }


                {existeInventario() &&

                  <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                    <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                      <Button onClick={() => enviarXMLInventarioSNGPC()} variant="text" className="h-100 w-100">
                        <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                          Enviar XML
                        </div>
                      </Button>
                    </div>
                    <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                      <Button onClick={() => enviarXMLInventarioSNGPCManual()} variant="text" className="h-100 w-100">
                        <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                          Envio Manual
                        </div>
                      </Button>
                    </div>
                    <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                      <Button onClick={() => downloadXMLInventarioSNGPC()} variant="text" className="h-100 w-100">
                        <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                          Download XML
                        </div>
                      </Button>
                    </div>
                  </div>
                }

              </div>

            </div>
          </>
        </div>
      </Fade>
    </>
  );
});