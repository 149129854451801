import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Edit from '../../../../../assets/img/edit.svg';
import ConfirmaIcon from "../../../../../assets/img/icon-confirma.svg";
import { useToastSaurus } from '../../../../../services/app';
import { AutocompleteMedicamento } from "../../../../components/autocompletes/autocomplete-medicamentos";
import { DefaultCard } from "../../../../components/cards/components";
import { TextFieldSaurus } from '../../../../components/controles/inputs';
import { useGerenciarMedicamentos } from "../../../../components/dialog/dialog-dispensacao/use-dialog-gerenciar-medicamento-opcoes";
import { MedicamentoPerda, PerdaAvulsaMedicamentoStepsProp } from "../models/perda-avulsa-models";
import { useStyles } from "../perda-avulsa-cadastro-style";

export const PerdaAvulsaMedicamentoAdicionadoCard = (({ medicamento, excluirMedicamento, setEditando }: PerdaAvulsaMedicamentoStepsProp) => {
    const styles = useStyles();
    const [medicamentoEdicao, setMedicamentoEdicao] = useState(new MedicamentoPerda());
    const [isEdit, setIsEdit] = useState(false);
    const { showToastPersonalizado } = useToastSaurus();

    const schema = yup
        .object()
        .shape({
            quantidade: yup.number()
                .required("Item é obrigatório!")
        });

    const {
        formState,
        register
    } = useForm<MedicamentoPerda>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    const handleMedicamentoAutocomplete = (medicamentoSelecionado: any) => {
        if (!medicamentoSelecionado.ms && medicamentoSelecionado)
            return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');
        
        medicamento.medicamentoId = medicamentoSelecionado.id;
        medicamento.medicamentoNome = medicamentoSelecionado.descricao;
        medicamento.codigoAnvisa = medicamentoSelecionado.ms;
        medicamento.vendaControlada = medicamentoSelecionado.vendaControlada;

        setMedicamentoEdicao((medicamento) => ({
            ...medicamento,
            medicamentoId: medicamentoSelecionado.id,
            medicamentoNome: medicamentoSelecionado.descricao,
            codigoAnvisa: medicamentoSelecionado.ms,
            vendaControlada: medicamentoSelecionado.vendaControlada
        }) as MedicamentoPerda);
    };

    const handleMedicamentoLabel = (): string =>
        medicamentoEdicao.codigoAnvisa ? `${medicamentoEdicao.medicamentoNome} - ${medicamentoEdicao.codigoAnvisa}` : '';

    const editMedicamento = async () => {

        var formIsValid = await schema.isValid(medicamentoEdicao);

        if (!formIsValid || !medicamentoEdicao.medicamentoNome)
            return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

        medicamento.quantidade = medicamentoEdicao.quantidade;
        setIsEdit(false);
        setEditando(false);
    }

    const { abrirGerenciarMedicamentos } = useGerenciarMedicamentos();

    const openModal = () => {
        abrirGerenciarMedicamentos(excluir, editar);
    };

    const excluir = () => {
        excluirMedicamento();
    }

    const editar = () => {
        setIsEdit(true);
        setMedicamentoEdicao(medicamento);
        setEditando(true);
    }

    return (
        <>
            <DefaultCard>
                {isEdit ?
                    <div className={`flex-row h-100 w-100 ${styles.infoContainerPerdaAvulsa, styles.divCardMed}`}>

                        <div className={`flex-column w-100 ${styles.infoContainer}`}>
                            <div className="flex-column w-100">
                                <AutocompleteMedicamento
                                    value={handleMedicamentoLabel()}
                                    label="Buscar Medicamento"
                                    className={`h-50 w-100 ${styles.inputStyle}`}
                                    onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
                                />
                            </div>
                            <div className={`flex-row w-100 ${styles.itensDiv}`}>
                                <div className="flex-column w-50">
                                    <Typography className={styles.labelFont}>
                                        Código
                                    </Typography>
                                    <Typography className={styles.contentFont}>
                                        {medicamentoEdicao.codigoAnvisa}
                                    </Typography>
                                </div>
                                <div className="flex-column w-50">
                                    <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                                        required
                                        value={medicamentoEdicao.quantidade}
                                        labelCustom="Itens"
                                        variant="outlined"
                                        size="small"
                                        tipo='NUMERO'
                                        error={Boolean(errors.quantidade && !medicamentoEdicao.quantidade)}
                                        helperText={
                                            errors.quantidade && !medicamentoEdicao.quantidade
                                                ? errors.quantidade?.message
                                                : undefined
                                        }
                                        {...register('quantidade', {
                                            onChange: (e: any) => setMedicamentoEdicao({ ...medicamentoEdicao, quantidade: e.target.value })
                                        })}
                                        min={undefined}
                                        max={undefined}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
                            <Button onClick={() => editMedicamento()} variant="text" className="h-100 w-100">
                                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                    <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
                                </div>
                            </Button>
                        </div>
                    </div>
                    :
                    <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

                        <div className={`flex-column w-100 ${styles.infoContainer}`}>
                            <div className="flex-column w-100">
                                <Typography className={styles.labelFont}>
                                    Medicamento
                                </Typography>
                                <Typography className={styles.contentFont}>
                                    {medicamento.medicamentoNome}
                                </Typography>
                            </div>
                            <div className="flex-row w-100">
                                <div className="flex-column w-50">
                                    <Typography className={styles.labelFont}>
                                        Código
                                    </Typography>
                                    <Typography className={styles.contentFont}>
                                        {medicamento.codigoAnvisa}
                                    </Typography>
                                </div>
                                <div className="flex-column w-50">
                                    <Typography className={styles.labelFont}>
                                        Itens
                                    </Typography>
                                    <Typography className={styles.contentFont}>
                                        {medicamento.quantidade}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={`h-100 ${styles.optionsButtonContainer}`}>
                            <Button className={styles.optionsButtonStyle} onClick={() => openModal()}>
                                <img src={Edit} alt="" />
                            </Button>
                        </div>
                    </div>
                }
            </DefaultCard>
        </>
    );
});