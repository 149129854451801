import { Button } from "@material-ui/core"

interface ButtonIconProps {
    icon: JSX.Element;
    onClick: () => any;
}

export const ButtonIcon= ({ icon, onClick }: ButtonIconProps) => {

    return (
        <>
            <Button onClick={onClick}>
                {icon}
            </Button>
        </>
    )

}

