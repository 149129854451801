import { useCallback } from "react";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools";

export const useGerenciarMedicamentos = () => {
  const { callEvent } = useEventTools();

  const abrirGerenciarMedicamentos = useCallback((
    excluir: () => any,
    editar: () => any
  ) => {
    callEvent(AppEventEnum.GerenciarMedicamentos, { aberto: true, excluir, editar });
  },
    [callEvent]
  );

  const fecharGerenciarMedicamentos = useCallback(() => {
    callEvent(AppEventEnum.GerenciarMedicamentos, { aberto: false });
  },
    [callEvent]
  );

  return {
    abrirGerenciarMedicamentos,
    fecharGerenciarMedicamentos,
  }
};