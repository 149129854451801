import { Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CircularLoading, useThemeQueries } from '../../..';
import { useToastSaurus } from '../../../../services/app';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-autorizacao-opcoes-style';
import { useAutorizacaoOpcoes } from './use-dialog-autorizacao-opcoes';

interface DialoAutorizacaoOpcoesConteudoProps {
  carregando?: boolean;
  aberto: boolean;
  operacaoId: string;
  operacaoAptaAutorizar: boolean;
  aprovar: (operacaoId: string) => any;
  reprovar: (operacaoId: string) => any;
  navegar: () => void;
}

export const DialogAutorizacaoOpcoesConteudo = ({
  aberto,
  carregando,
  operacaoId,
  operacaoAptaAutorizar,
  reprovar,
  aprovar,
  navegar,
}: DialoAutorizacaoOpcoesConteudoProps) => {
  const { fecharAutorizacaoOpcoes } = useAutorizacaoOpcoes();
  const theme = useThemeQueries();
  const styles = useStyles();
  const [cardWidth, setCardWidth] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const { showToastPersonalizado } = useToastSaurus();

  useEffect(() => {
    setarTamanhoModal();
  }, [theme])

  const setarTamanhoModal = () => {
    if (theme.isMobile && theme.lg && !theme.xs) {
      setCardWidth(40);
      setCardHeight(35);
      return;
    };
    if (theme.isMobile && theme.xs) {
      setCardWidth(65);
      setCardHeight(35);
      return;
    };
    if (theme.isMobile && theme.lg) {
      setCardWidth(40);
      setCardHeight(28);
      return;
    };
    if (theme.md) {
      setCardWidth(35);
      setCardHeight(35);
      return;
    };
    if (theme.lg) {
      setCardWidth(20);
      setCardHeight(38);
      return;
    };
    if (theme.sm) {
      setCardWidth(20);
      setCardHeight(38);
      return;
    };
    if (theme.xl) {
      setCardWidth(15);
      setCardHeight(30);
      return;
    };
  }

  const navegarEdicao = () => {
    navegar();
    fecharAutorizacaoOpcoes();
  }

  const aprovarOperacao = () => {
    if (!operacaoAptaAutorizar)
      return showToastPersonalizado('error', 'Operação possui informações obrigatórias não preenchidas!', 5000, 'top-center');

    aprovar(operacaoId);
    fecharAutorizacaoOpcoes();
  }

  const reprovarOperacao = () => {
    reprovar(operacaoId);
    fecharAutorizacaoOpcoes();
  }

  return (
    <DialogSaurus
      isPopUp
      tamanho="xs"
      titulo="Operações"
      cardHeight={cardHeight}
      cardWidth={cardWidth}
      isButtonTitleClose
      aberto={aberto || false}
      handleClickClose={fecharAutorizacaoOpcoes}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <div className={`flex-column h-85 w-100 ${styles.dialogContainer}`}>
        <Button onClick={navegarEdicao} className={`w-100 ${styles.buttonContainer}`}>
          <Typography className={styles.buttonFont}>
            Editar
          </Typography>
        </Button>
        <Button onClick={aprovarOperacao} className={`w-100 ${styles.buttonContainer}`}>
          <Typography className={styles.buttonFont}>
            Autorizar
          </Typography>
        </Button>
        <Button onClick={reprovarOperacao} className={`w-100 ${styles.buttonContainer}`}>
          <Typography className={styles.buttonFont} color='error'>
            Não Autorizar
          </Typography>
        </Button>
      </div>
    </DialogSaurus>
  );
};