import { useCallback } from "react";
import { ETypeDomain } from "../../../../config/types";
import { useApiBase } from "../../base/api-base";

export function useGetEstoqueByCodigoAnvisa() {
  
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getEstoqueByCodigoAnvisa = useCallback(
    (codigoAnvisa: string) => {
      return invocarApi(
        {
          url: `Estoque/buscar-lote-codigo-anvisa?codigoAnvisa=${codigoAnvisa}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        }
      );
    },
    [invocarApi]
  );

  const getEstoqueDisponivelByCodigoAnvisa = useCallback(
    (codigoAnvisa: string) => {
      return invocarApi(
        {
          url: `Estoque/buscar-lote-codigo-anvisa-quantidade-disponivel?codigoAnvisa=${codigoAnvisa}`,
          method: "GET",
          enviarTokenUsuario: true,
          typeDomain: ETypeDomain.TouchPharma,
          apiVersion: 'v1'
        }
      );
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    carregando,
    getEstoqueByCodigoAnvisa,
    getEstoqueDisponivelByCodigoAnvisa
  };
}