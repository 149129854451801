import { Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { EnumCadastroTipo } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { CheckboxDefault } from "../../../../components/checkboxes";
import { useStyles } from "../dispensacao-cadastro-style";
import { DispensacaoCompradorPaciente } from "../models/dispensacao-models";
import { DispensacaoStepsProp } from "../models/dispensacao-props";

export const DispensacaoCompradorForm = (({ visualizacao, dispensacao, setDispensacao, errorValidation, errorValidationCompradorPacienteMesmaPessoa }: DispensacaoStepsProp) => {
  const styles = useStyles();
  const [erroPaciente, setErroPaciente] = useState(false);
  const [erroComprador, setErroComprador] = useState(false);

  const setPaciente = useCallback((paciente) => {
    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      //TODO: Solicitar o genêno com a Saurus.
      if (paciente)
        paciente.sexo = 1;

      medicamento.receita.paciente = paciente;

      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, []);

  const setComprador = useCallback((comprador: DispensacaoCompradorPaciente) => {
    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      //TODO: Solicitar o genêno com a Saurus.
      if (comprador)
        comprador.sexo = 1;

      medicamento.receita.comprador = comprador;

      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, []);

  const setCompradorMesmaPessoa = useCallback(() => {
    let medicamentos = dispensacao.medicamentos?.map(medicamento => {
      medicamento.receita.compradorPacienteMesmaPessoa =
        !dispensacao.medicamentos[0]?.receita?.compradorPacienteMesmaPessoa;

      return medicamento;
    });

    setDispensacao({ ...dispensacao, medicamentos });
  }, [dispensacao, setDispensacao]);

  return (
    <>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Comprador/Paciente</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados do comprador/paciente.</Typography>
        </div>

        <div className={`flex-column h-100 ${styles.innerFormContainer}`}>
          <div className={`w-100`}>
            <AutocompletePessoas label="Comprador"
              tipo={EnumCadastroTipo.TODOS}
              value={dispensacao.medicamentos[0].receita?.comprador?.nome}
              disabled={visualizacao}
              size="medium"
              onChange={(a) => setComprador(a.value)}
              onBlur={() => {
                if (!dispensacao.medicamentos[0].receita?.comprador?.nome)
                  setErroComprador(true);
              }}
              error={Boolean((errorValidation || erroComprador) && !dispensacao.medicamentos[0].receita?.comprador?.nome)}
              helperText={
                (errorValidation || erroComprador) && !dispensacao.medicamentos[0].receita?.comprador?.nome
                  ? 'Comprador será obrigatório na autorização!'
                  : undefined
              }
            />
          </div>
          <div className={`flex-row w-100 ${styles.checkboxContainer}`}>
            <CheckboxDefault
              className={styles.checkboxStyle}
              disabled={visualizacao}
              checked={dispensacao.medicamentos[0]?.receita?.compradorPacienteMesmaPessoa}
              onClick={() => setCompradorMesmaPessoa()}
            />
            <Typography variant="body2">
              Paciente e comprador são os mesmos?
            </Typography>
          </div>
          {!dispensacao.medicamentos[0]?.receita?.compradorPacienteMesmaPessoa &&
            <div className={`w-100`}>
              <AutocompletePessoas label="Paciente"
                tipo={EnumCadastroTipo.TODOS}
                value={dispensacao.medicamentos[0]?.receita?.paciente?.nome}
                disabled={visualizacao}
                size="medium"
                onBlur={() => {
                  if (!dispensacao.medicamentos[0].receita?.paciente?.nome)
                    setErroPaciente(true);
                }}
                onChange={(a) => setPaciente(a.value)}
                error={Boolean((errorValidationCompradorPacienteMesmaPessoa || erroPaciente) && !dispensacao.medicamentos[0].receita?.paciente?.nome)}
                helperText={
                  (errorValidationCompradorPacienteMesmaPessoa || erroPaciente) && !dispensacao.medicamentos[0].receita?.paciente?.nome
                    ? 'Paciente será obrigatório na autorização!'
                    : undefined
                }
              />
            </div>}
        </div>

      </div>
    </>
  );
});