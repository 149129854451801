import { useStyles } from './legenda-status-styles';

export const LegendaStatus = () => {
  const classes = useStyles();

  return (
    <div className={`w-100 ${classes.legendContainer}`}>

      <div className={classes.legendaLabelContainer}>
        <span className={classes.title}>Legenda</span>

        <div className={classes.labelContainer}>
          <span className={classes.greenBall}></span>
          <span className={classes.label}>
            Permitido Autorizar
          </span>
        </div>

        <div className={classes.labelContainer}>
          <span className={classes.redBall}></span>
          <span className={classes.label}>
            Não Permitido Autorizar
          </span>
        </div>
      </div>

    </div>
  );
}