
import { Control, FormState } from "react-hook-form";

export enum ETipoOperacaoMovimentacao {
  Compra = 0,
  Dispensacao = 1,
  Transferencia = 2,
  Perda = 4
}

export const ETipoOperacaoMovimentacaoMap = new Map<ETipoOperacaoMovimentacao, string>([
  [ETipoOperacaoMovimentacao.Compra, 'Compras'],
  [ETipoOperacaoMovimentacao.Dispensacao, 'Dispensação'],
  [ETipoOperacaoMovimentacao.Transferencia, 'Transferência'],
  [ETipoOperacaoMovimentacao.Perda, 'Perda']
]);

export class MovimentacaoModel {
  constructor(
    public tipoOperacao: ETipoOperacaoMovimentacao = ETipoOperacaoMovimentacao.Dispensacao,
    public dataInicial: Date,
    public dataInicialFormatada: string,
    public dataFinal: Date | null
  ) { }
}

export interface MovimentacaoFiltroProp {
  control: Control<MovimentacaoModel, any>;
  formState: FormState<MovimentacaoModel>;
  buscarMovimentacoes: (movimentacaoFiltro: MovimentacaoModel) => void;
  limparCardMovimentacoes: () => void;
  validarConflitoPeriodo: (conflitoPeriodo: boolean) => void;
  resetarFiltro: boolean;
  setResetarFiltro: (resetarFiltro: boolean) => void;
}

export enum StatusAutorizacaoEnum {
  Pendente = 1,
  Falha = 2
}

export interface MovimentacaoCompraResponse {
  dataLancamento: string;
  dataNotaFiscal: string;
  numeroNotaFiscal: number;
  cnpjFornecedor: string;
  nomeFornecedor: string;
};

export interface MovimentacaoDispensacaoResponse {
  dataVenda: string;
  dataReceita: string;
  numeroReceita: string;
  pacienteComprador: string;
  prescritor: string;
};

export interface MovimentacaoPerdaResponse {
  data: string;
  medicamento: string;
  quantidade: number;
  motivo: string;
};

export interface MovimentacaoTransferenciaResponse {
  data: string;
  operacao: string;
  numeroNotaFiscal: number;
  origem: string;
  destino: string;
};