export class MenuModel {
  constructor(
    public nome: string = "",
    public codigo: number = 0,
    public rota: string = "",
    public posicao: number,
    public tituloPai: string,
  ) { }
}

export interface MenuGroup {
  [title: string]: MenuModel[];
}
