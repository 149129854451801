import { useStyles } from './background-styles'

export const BackgroundWhiteTop = () => {

    const classes = useStyles()

    return (
        <>
            <div className={classes.background} />
        </>
    )
}
