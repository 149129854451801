import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";

export function useGetWhitelabel() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getWhitelabel = useCallback(() =>
    invocarApi({
      url: `Whitelabel`,
      method: "GET",
      enviarTokenUsuario: true,
      typeDomain: ETypeDomain.Login,
      apiVersion: 'v4'
    }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getWhitelabel,
  };
}