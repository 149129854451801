import { Tooltip, Typography } from "@material-ui/core";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components";
import classNames from "classnames";
import { InventarioEnviadoItem } from "../../models/inventario-enviado-models";
import { useStyles } from "./inventario-enviado-card-style";
import { DownloadIcon } from "../../../../../components/icons/download-icon";

interface InventarioEnviadoCardProps {
  item: InventarioEnviadoItem,
}

export const InventarioEnviadoCard = ({ item }: InventarioEnviadoCardProps) => {
  const classesCard = useDefaultCardStyles();
  const styles = useStyles();

  return (

    <DefaultCard className={`w-100 ${classesCard.cardContainer}`}>
      <div className={`flex-row h-100 w-100`}>
        <div className={classNames(classesCard.tagStatus, styles.statusColor)} />

        <div className={`flex-row w-100 ${classesCard.infoContainer}`}>
          <div className={`flex-column ${styles.inputStyle}`}>
            <Typography className={classesCard.labelFont}>
              Data do Inventário:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.dataEnvio ?? '--'}
            </Typography>
          </div>

          <div className={`flex-column ${styles.inputStyle}`}>
            <Typography className={classesCard.labelFont}>
              Quantidade de Medicamentos:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.quantidadeMedicamentos ?? 0}
            </Typography>
          </div>          

        </div>

        <Tooltip arrow title="Download XML">
          <div className={classesCard.cardRightButton}>
            <DownloadIcon tipo="BUTTON"  />
          </div>
        </Tooltip>

      </div>
    </DefaultCard>
  );
};