import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardMovimentacaoItem: {
    justifyContent: 'flex-start',
    marginBottom: 10
  },
  cardContainer: {
    justifyContent: 'center',
  },
  labelFont: {
    fontWeight: 700,
    fontSize: "0.6rem",
    color: theme.palette.common.black,
    paddingRight: '0.25rem',
    [theme.breakpoints.up("md")]: {
      fontSize: "0.75rem",
    }
  },
  contentFont: {
    fontSize: "0.6rem",
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "0.75rem",
    }
  },
  infoContainer: {
    margin: '0.3rem 0.5rem',
    justifyContent: 'space-between',
    width: '100%',
  },
  containerLabelMedicamento: {
    marginBottom: 10
  },
  cardPadding: {
    padding: '0.25rem 0.5rem',
  },
  statusColor: {
    backgroundColor: theme.palette.info.main
  },

  medicamentoInfoContainer: {
    height: "12vh",
    boxShadow: "0 0 0.5rem 0"
  },

  medicamentoInfoStyle: {
    flexGrow: 1,
    padding: "0.5rem 0.8rem",
    justifyContent: 'center',
  },

  medicamentoCodigoStyle: {
    fontSize: '0.85rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },

  inputStyle: {
    width: '15vw',
    fontSize: '0.9rem',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      width: '6vw',
    },
    [theme.breakpoints.between('lg', 'sm')]: {
      width: '10vw',
    }
  },
}));
