import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ConfiguracoesModel } from '../../../../model/api/configuracoes/configuracoes-models';
import { ETypeDomain } from '../../../../config/types';

export function usePostConfiguracoes() {
    const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

    const postConfiguracoes = useCallback(
        (model: ConfiguracoesModel) => {
            return invocarApi({
                url: `Configuracoes`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(model),
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.TouchPharma,
                apiVersion: 'v1'
            });
        }, [invocarApi]);

    return {
        ...outrasPropriedades,
        carregando,
        postConfiguracoes,
    };
}
