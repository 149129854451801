import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ETypeDomain } from "../../../../config/types";
import { VariaveisAmbiente } from "../../../../config";

export function useGetEmpresaById() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getEmpresaById = useCallback(
    (empresaId: string) => {
      return invocarApi({
        url: `Empresa/${empresaId}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiLoginUrl,
        typeDomain: ETypeDomain.Login,
        apiVersion: 'v4'
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    carregando,
    getEmpresaById,
  };
}