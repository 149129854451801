export enum EnumCadastroTipo {
    TODOS = "",
    FUNCIONARIO = 0,
    REPRESENTANTE = 1,
    CLIENTE = 2,
    FORNECEDOR = 3,
    TRANSPORTADORA = 4,
    RESPONSAVELTECNICO = 5,
    PRESCRITOR = 6,
    FARMACEUTICO = 7 
}