import { Fade, Typography } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetListEnvioInventario } from "../../../../../data/api/gestao/envio-inventario/envio-inventario";
import { useToastSaurus } from "../../../../../services/app";
import { CircularLoading } from "../../../../components";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import { Paginacao } from "../../../../components/paginacao";
import { InventarioEnviadoItem } from "../models/inventario-enviado-models";
import { InventarioEnviadoCard } from "./componentes/inventario-enviado-card";
import { useStyles } from "./inventario-enviado-list-style";

export const InventarioEnviadoList = (() => {

  const styles = useStyles();
  const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<InventarioEnviadoItem>() });
  const { getListEnvioInventario, carregando } = useGetListEnvioInventario();
  const { downloadXMLInventarioSNGPCANVISAEnviado, carregando: downloadXMLInventario } = useGetListEnvioInventario();
  const { showToast } = useToastSaurus();

  const [timer, setTimer] = useState({} as NodeJS.Timeout);
  const pageItems = 10;

  const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<InventarioEnviadoItem>) => {
    setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
  }, []);


  const listarPaginado = useCallback(
    async (newPage: number, filter: string = "") => {
      try {
        const res = await getListEnvioInventario(newPage, pageItems, filter);

        if (res.erro)
          throw res.erro;

        const list = res.resultado?.data.list;
        const pageIndex = res.resultado?.data.pageIndex;
        const totalPages = res.resultado?.data.totalPages;
        const totalResults = res.resultado?.data.totalResults;

        fillResult(
          pageIndex,
          totalPages,
          totalResults,
          list
        );

      } catch (e: any) {
        if (axios.isCancel(e))
          return;

        showToast('error', e.message);
      }
    }, [fillResult, showToast]
  );


  const filtroListagem = (res: string) => {
    clearTimeout(timer!);
    const newTimer = setTimeout(() => {
      listarPaginado(1, res);
    }, 500);
    setTimer(newTimer);
  }

  const downloadXML = useCallback(async (id: string) => {

    try {

      const res = await downloadXMLInventarioSNGPCANVISAEnviado(id);

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      const blob = new Blob([res.resultado?.data], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);

      const dataAtual = moment().format('YYYYMMDDHHmmss');

      const link = document.createElement('a');
      link.href = url;
      link.download = `Inventário_${dataAtual}`;
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);

      showToast("success", "Download realizado com sucesso!", 5000, 'top-center');

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }, [downloadXMLInventarioSNGPCANVISAEnviado]);

  const pageChanged = useCallback(
    (newPage: number) => {
      listarPaginado(newPage);
    },
    [listarPaginado]
  );

  useEffect(() => {
    listarPaginado(1);
  }, [listarPaginado]);


  const isLoading = () =>
    carregando || downloadXMLInventario;


  return (
    <>
      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      <Fade in timeout={900} mountOnEnter unmountOnExit>
        <div className={`flex-column w-100 h-100 ${styles.color}`}>
          <PageHeader
            showBotaoFiltrar
            showMenu
            showSearchableBar
            titulo='Inventários Enviados'
            filtroListagem={res => filtroListagem(res)} />
          <>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
            <div className={`flex-column h-100 ${styles.contentContainer}`}>

              <div className={`flex-column h-100 w-100 ${styles.listContainer}`}>
                <div className={styles.inventarioListStyle}>
                  {queryStatus?.list?.length > 0 && queryStatus?.list?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => downloadXML(item.id)}>
                        <InventarioEnviadoCard item={item} />
                      </div>
                    );
                  })}
                </div>
                {!queryStatus?.list?.length &&
                  <Typography className={styles.semRegistroStyle}>
                    Nenhum registro foi encontrado.
                  </Typography>
                }
              </div>

            </div>
          </>
        </div>
      </Fade>
    </>
  );
});