import { Tooltip, Typography } from "@material-ui/core";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components";
import { ProcurarIcon } from "../../../../../components/icons";
import { EnvioInventarioItem } from "../envio-inventario-list/envio-inventario-models";
import classNames from "classnames";
import { useStyles } from "./envio-inventario-card-styles";

interface EnvioInventarioCardProps {
  item: EnvioInventarioItem,
}

export const EnvioInventarioCard = ({ item }: EnvioInventarioCardProps) => {
  const classesCard = useDefaultCardStyles();
  const styles = useStyles();

  return (

    <DefaultCard className={`w-100 ${classesCard.cardContainer}`}>
      <div className={`flex-row h-100 w-100`}>
        <div className={classNames(classesCard.tagStatus, styles.statusColor)} />

        <div className={`flex-row w-100 ${classesCard.infoContainer}`}>
          <div className={`flex-column ${classesCard.inputMinWidth}`}>
            <Typography className={classesCard.labelFont}>
              Medicamento:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.medicamento ?? '--'}
            </Typography>
          </div>

          <div className={`flex-column ${classesCard.inputMinWidth}`}>
            <Typography className={classesCard.labelFont}>
              Registro MS:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.codigoAnvisa ?? 0}
            </Typography>
          </div>

          <div className={`flex-column ${classesCard.inputMinWidth}`}>
            <Typography className={classesCard.labelFont}>
              Cod. Barras:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.codigoEan ?? 0}
            </Typography>
          </div>

          <div className={`flex-column ${classesCard.inputMinWidth}`}>
            <Typography className={classesCard.labelFont}>
              Laboratorio:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.laboratorio ?? '--'}
            </Typography>
          </div>

          <div className={`flex-column ${classesCard.inputMinWidth}`}>
            <Typography className={classesCard.labelFont}>
              Quantidade:
            </Typography>
            <Typography className={classesCard.contentFont}>
              {item.quantidade ?? 0}
            </Typography>
          </div>

        </div>

        <Tooltip arrow title="Vizualizar Lotes">
          <div className={classesCard.cardRightButton}>
            <ProcurarIcon tipo="BUTTON" />
          </div>
        </Tooltip>

      </div>
    </DefaultCard>
  );
};