import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const EnvioMovimentacaoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M7 2V48H34C34.0117 47.9996 34.0235 47.9989 34.0352 47.998C35.7045 49.248 37.7664 50 40 50C45.5 50 50 45.5 50 40C50 35.5445 47.0456 31.7472 43 30.4648V15.5859L29.4141 2H7ZM9 4H28V17H41V30.0508C40.6712 30.0178 40.3371 30 40 30C34.5 30 30 34.5 30 40C30 42.2491 30.7617 44.3247 32.0273 46H9V4ZM30 5.41406L39.5859 15H30V5.41406ZM14 21V23H36V21H14ZM14 27V29H36V27H14ZM40 32C44.4 32 48 35.6 48 40C48 44.4 44.4 48 40 48C35.6 48 32 44.4 32 40C32 35.6 35.6 32 40 32ZM14 33V35H25V33H14Z" />
                <path d="M36.6663 38.6438C36.687 38.8614 36.8336 39.0474 37.0403 39.1184C37.2481 39.1906 37.4778 39.1349 37.6288 38.9763L39.44 37.165V44.2C39.4389 44.2175 39.4389 44.235 39.44 44.2525C39.4542 44.562 39.7167 44.8005 40.0263 44.7863C40.3358 44.772 40.5742 44.5095 40.56 44.2V37.165L42.3713 38.985C42.5911 39.2049 42.9477 39.2049 43.1675 38.985C43.3873 38.7652 43.3873 38.4086 43.1675 38.1888L40.3938 35.4238L40 35.0213L39.6063 35.4238L36.8325 38.1888C36.7111 38.3069 36.6498 38.4742 36.6663 38.6438Z" />
            </DefaultIcon>
        </>
    );
};
