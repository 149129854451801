import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  Button,
  Fade,
  FormControlLabel, useTheme
} from '@material-ui/core';

import { useStyles } from './login-page-styles';

import axios from 'axios';
import { useConfirm } from 'material-ui-confirm';
import Profile from '../../../../assets/img/profile.svg';
import { AutenticacaoPendenciaContratoModel } from '../../../../model/api/autenticacao-pendencia/autenticacao-pendencia';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { LoginFormModel } from '../../../../model/app/forms/master/login-form';
import { SngpcStorageKeys, useSessaoAtual, useSngpcStorage, useToastSaurus } from '../../../../services/app';
import { CheckboxDefault } from '../../../../views/components/checkboxes';
import { FormLogin } from '../../../components/form/master/login/form-login';
import { DefaultFormRefs } from '../../../components/form/utils/form-default-props';
import { LoginIcon } from '../../../components/icons';
import { CircularLoading } from '../../../components/utils';
import { useThemeQueries } from '../../../theme';
import { makeUtilClasses } from '../../../theme/util-make-styles';

const LoginPage = () => {
  const utilClasses = makeUtilClasses();
  const { isMobile } = useThemeQueries();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const theme = useTheme();
  const { logar, carregando, usuario } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const chkManterConectado = useRef<HTMLInputElement>();
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
  const { setRegistro } = useSngpcStorage()
  const confirm = useConfirm()

  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, [usuario]);

  //logar funçao
  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      const manterConectado =
        chkManterConectado.current?.querySelector('input')?.checked === true;
      const res: any = await logar(model.login, model.senha, '', manterConectado);

      if (res?.status) {
        if (res.status === 402) {
          setRegistro(SngpcStorageKeys.PendenciaContrato, res?.data as AutenticacaoPendenciaContratoModel)
          return history.push('/pendencias')
        }
      }

      if (typeof res !== 'string') {
        setRegistro(SngpcStorageKeys.TokenTouchPharma, res?.data, false);
        return history.push({
          pathname: '/selecionar-contrato',
          state: {
            email: model.login,
            senha: btoa(model.senha),
            contratos: res
          }
        })
      }

      if (typeof res == 'string') {
        return history.push({
          pathname: '/autorizacao-listagem',
        })
      }

      setBloqueado(true);
      return history.push('/');
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      if (e.constructor.name === ApiError.name) {
        history.push(`/ativar-email?email=${model.login}`);
        return;
      } else {
        showToast('error', e.message);
        loginRefs.current?.resetForm();
      }
    }
  };

  const redirectToLanding = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (usuario) {
      if (usuario.confirmado !== 'True') {
        confirm({
          title: 'E-mail não confirmado.',
          description: `Atenção, o e-mail "${usuario.email}" ainda não foi confirmado. Pedimos para que seja confirmado antes que o acesso seja bloqueado.`,
          confirmationButtonProps: { variant: 'contained' },
          confirmationText: 'OK',
        }).then(() => {
          redirectToLanding();
        });
        return;
      }
    }
  }, [usuario, confirm, redirectToLanding]);

  return (
    <>

      {
        carregando && <CircularLoading tipo="FULLSIZED" />
      }

      <Fade in mountOnEnter unmountOnExit>

        <div className={`flex-column ${classes.containerDesktopMain}`}>

          <div className={classes.containerDesktopSub}>

            <div className={classes.containerLoginMain}>

              <div className={classes.containerTitle}>
                <img src={Profile} className={classes.imgTitulo} />
                <span className={classes.fontLogin}>
                  Login
                </span>
              </div>

              <div className={classes.containerLoginSub}>
                <span className={classes.fontLoginSubtitle}>
                  Olá, bem vindo de volta!
                </span>
                <span className={classes.fontLoginSubtitle}>
                  Informe o seu e-mail para ingressar.
                </span>
              </div>
            </div>

            <div className={classes.containerForm}>

              <FormLogin
                showLoading={false}
                ref={loginRefs}
                loading={carregando}
                onSubmit={(model) => {
                  efetuarLogin(model);
                }}
              />

              <div className={classes.containerCheckbox}>

                <FormControlLabel
                  disabled={carregando || bloqueado}
                  ref={chkManterConectado}
                  control={<CheckboxDefault />}
                  label={
                    <span className={classes.spanCheckbox}>
                      Mantenha-me conectado
                    </span>
                  }
                />

              </div>

              <div className={classes.containerBotoes}>
                <Button className={classes.botaoAcessar}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  disabled={carregando || bloqueado}
                  onClick={() => loginRefs.current?.submitForm()}
                >
                  <LoginIcon tipo="GERAL"
                    class={classes.botaoAcessarIcone}
                  />

                  <span className={classes.botaoAcessarSpan}>
                    Acessar
                  </span>

                </Button>
              </div>
            </div>

          </div>

        </div>

      </Fade>

    </>
  );
};

export default LoginPage;
