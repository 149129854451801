import { Button, Fade, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { useInventario } from "../../../../../data/api/gestao/inventario/inventario";
import { useToastSaurus } from "../../../../../services/app";
import { CircularLoading } from "../../../../components";
import { DialogInventarioPendenteEnvioSNGPC } from "../../../../components/dialog/dialog-inventario-pendente-envio/dialog-inventario-pendente-envio";
import { DialogNovoInventario } from "../../../../components/dialog/dialog-novo-inventario/dialog-novo-inventario";
import { DialogOperacaoPendenteAutorizacao } from "../../../../components/dialog/dialog-operacao-pendente-autorizacao/dialog-operacao-pendente-autorizacao";
import { PageHeader } from "../../../../components/headers/header-page/header-page";
import { AvancarIcon, SalvarIcon } from "../../../../components/icons";
import { Paginacao } from "../../../../components/paginacao";
import { InventarioLoteModal } from "../lote/modals/inventario-lote-modal";
import { Inventario, InventarioLote, InventarioMedicamento, MedicamentoInfo } from "../models/inventario.models";
import { InventarioMedicamentoCard } from "./componentes/inventario-medicamento-card";
import { InventarioMedicamentoSelecao } from "./componentes/inventario-medicamento-selecao";
import { useStyles } from "./inventario-listagem.styles";


export const InventarioListPage = ((props: any) => {

  const inventarioMedicamentosAtual = props.location.state?.inventarioMedicamentosAtual;

  const { getOperacaoPendenteAprovacao, carregando: carregandoOperacaoPendentAprovacao } = useInventario();
  const { getInventarioAndamento, carregando: carregandoInventarioAndamento } = useInventario();
  const { getInventarioPendenteEnvioSNGPC, carregando: carregandoInventarioPendenteEnvioSNGPC } = useInventario();
  const { getInventario, carregando: carregandoListaInventario } = useInventario();
  const { saveInventario, carregando: salvandoInventario } = useInventario();
  const { authorizeInventario, carregando: autorizandoInventario } = useInventario();
  const { showToast } = useToastSaurus();

  const [inventario, setInventario] = useState<Inventario>({});
  const [inventarioMedicamentos, setInventarioMedicamentos] = useState<Array<InventarioMedicamento>>([]);
  const [currentInventarioMedicamentos, setCurrentInventarioMedicamentos] = useState<Array<InventarioMedicamento>>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [modalLoteComponent, setModalLoteComponent] = useState(null as ReactNode);
  const [medicamentoSelecionado, setMedicamentoSelecionado] = useState({} as MedicamentoInfo);
  const [openModalLote, setOpenModalLote] = useState(false);
  const [openModalOperacaoPendenteAprovacao, setOpenModalOperacaoPendenteAprovacao] = useState(false);
  const [openModalNovoInventario, setOpenModalNovoInventario] = useState(false);
  const [openModalInventarioPendenteEnvio, setOpenModalInventarioPendenteEnvio] = useState(false);

  const history = useHistory();
  const styles = useStyles();
  const pageItems = 10;

  useEffect(() => {
    if (inventarioMedicamentosAtual) {
      setInventarioMedicamentos(inventarioMedicamentosAtual);
      setInventario({ medicamentos: inventarioMedicamentosAtual });
      setCurrentInventarioMedicamentos(inventarioMedicamentosAtual?.slice(0, pageItems));
      return;
    }

    buscarInventario();
  }, []);

  const buscarInventario = async () => {
    try {
      const resultInventarioPendenteEnvioSNGPC = await getInventarioPendenteEnvioSNGPC();

      if (resultInventarioPendenteEnvioSNGPC.erro)
        throw resultInventarioPendenteEnvioSNGPC.erro;

      if (resultInventarioPendenteEnvioSNGPC?.resultado?.data === true) {
        setOpenModalInventarioPendenteEnvio(true);
        return;
      }

      const resultInventarioAndamento = await getInventarioAndamento();

      if (resultInventarioAndamento.erro)
        throw resultInventarioAndamento.erro;

      if (resultInventarioAndamento?.resultado?.data === false) {
        setOpenModalNovoInventario(true);
        return;
      }

      const res = await getInventario();

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data || !res.resultado?.data?.medicamentos?.length) {
        return;
      }

      setInventario({
        ...inventario,
        id: res.resultado?.data.id,
        inicial: res.resultado?.data.inicial,
        data: res.resultado?.data.data,
        medicamentos: res.resultado?.data?.medicamentos
      })

      setInventarioMedicamentos(res.resultado?.data?.medicamentos);
      setCurrentInventarioMedicamentos(res.resultado?.data?.medicamentos.slice(0, pageItems));

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageItems;
    const endIndex = startIndex + pageItems;
    setCurrentInventarioMedicamentos(inventarioMedicamentos.slice(startIndex, endIndex));
  }, [inventarioMedicamentos, currentPage, pageItems]);

  const handlePageChange = (newPage: number) => {
    const startIndex = (newPage - 1) * pageItems;
    const endIndex = startIndex + pageItems;
    setCurrentInventarioMedicamentos(inventarioMedicamentos.slice(startIndex, endIndex));
    setCurrentPage(newPage);
  };

  const iniciarNovoInventario = async () => {
    setOpenModalNovoInventario(false);

    try {

      const resultOperacaoPendenteAprovacao = await getOperacaoPendenteAprovacao();

      if (resultOperacaoPendenteAprovacao.erro)
        throw resultOperacaoPendenteAprovacao.erro;

      if (resultOperacaoPendenteAprovacao?.resultado?.data === true) {
        setOpenModalOperacaoPendenteAprovacao(true);
        return;
      }

      const res = await getInventario();

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data || !res.resultado?.data?.medicamentos?.length) {
        return;
      }

      setInventario({
        ...inventario,
        id: res.resultado?.data.id,
        inicial: res.resultado?.data.inicial,
        data: res.resultado?.data.data,
        medicamentos: res.resultado?.data?.medicamentos
      })

      setInventarioMedicamentos(res.resultado?.data?.medicamentos);
      setCurrentInventarioMedicamentos(res.resultado?.data?.medicamentos.slice(0, pageItems));

    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }

  const iniciarNovoInventarioOperacaoPendente = async () => {
    setOpenModalOperacaoPendenteAprovacao(false);

    try {

      const res = await getInventario();

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data || !res.resultado?.data?.medicamentos?.length) {
        return;
      }

      setInventario({
        ...inventario,
        id: res.resultado?.data.id,
        inicial: res.resultado?.data.inicial,
        data: res.resultado?.data.data,
        medicamentos: res.resultado?.data?.medicamentos
      })

      setInventarioMedicamentos(res.resultado?.data?.medicamentos);
      setCurrentInventarioMedicamentos(res.resultado?.data?.medicamentos.slice(0, pageItems));

    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }

  const naoIniciarNovoInventario = useCallback(() => {
    setOpenModalNovoInventario(false);
    setOpenModalOperacaoPendenteAprovacao(false);
    history.push({
      pathname: '/autorizacao-listagem',
    })
  }, [history]);

  const acessarEnvioInventarioSNGPC = async () => {

    setOpenModalNovoInventario(false);
    history.push({
      pathname: '/envio-inventario-listagem',
    })

  }

  const salvar = async () => {

    try {

      await atualizarInventario();

      const res = await saveInventario(inventario);

      if (res.erro)
        throw res.erro;

      showToast('success', 'Inventário salvo com Sucesso!', 5000, 'top-center');

    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }

  const autorizar = async () => {

    try {

      await atualizarInventario();

      const res = await authorizeInventario(inventario);

      if (res.erro)
        throw res.erro;

      showToast('success', 'Inventário Autorizado com Sucesso!', 5000, 'top-center');

      history.push({
        pathname: '/autorizacao-listagem',
      })


    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }

  const adicionarMedicamento = (medicamento: InventarioMedicamento) => {

    const medicamentoJaExiste = inventarioMedicamentos.some(x => x.medicamentoId === medicamento.medicamentoId);

    if (!medicamento.medicamentoId || !medicamento.medicamento) {
      showToast('info', 'Selecione um medicamento!', 5000, 'top-center');
      return;
    }

    if (medicamentoJaExiste === true) {
      showToast('info', 'Este medicamento já foi adicionado!', 5000, 'top-center');
      return;
    }

    setInventarioMedicamentos(prevInventarioMedicamentos => [...prevInventarioMedicamentos, medicamento]);

    const inventarioMedicamento = {
      ...inventario,
      medicamentos: [...(inventario.medicamentos || []), medicamento],
    };

    setInventario(inventarioMedicamento);

    showToast('success', 'Medicamento adicionado com sucesso!', 5000, 'top-center');

  }

  const salvarLote = (medicamentoId?: string, lotes?: InventarioLote[]) => {

    const medicamentoIndex = inventarioMedicamentos.findIndex(x => x.medicamentoId === medicamentoId);

    if (medicamentoIndex !== -1) {

      const medicamentoAtualizado = { ...inventarioMedicamentos[medicamentoIndex] };

      medicamentoAtualizado.lotes = lotes;

      const novoInventarioMedicamentos = [...inventarioMedicamentos];
      novoInventarioMedicamentos[medicamentoIndex] = medicamentoAtualizado;

      setInventarioMedicamentos(novoInventarioMedicamentos);

      const novoInventario = { ...inventario, medicamentos: novoInventarioMedicamentos };
      setInventario(novoInventario);

    }

  }

  const atualizarInventario = async () => {

    inventario?.medicamentos?.forEach(medicamento => {

      const medicamentoIndex = inventarioMedicamentos?.findIndex(x => x.codigoAnvisa === medicamento.codigoAnvisa);

      if (medicamentoIndex !== -1) {
        const medicamentoAtualizado = { ...inventarioMedicamentos[medicamentoIndex] };
        medicamento.lotes = medicamentoAtualizado.lotes;
      }

    })

  }

  const excluirRegistro = (id?: string) => {
    const medicamentosInventario = inventarioMedicamentos.filter(x => x.medicamentoId !== id);
    setInventarioMedicamentos(medicamentosInventario);
    setInventario({ medicamentos: medicamentosInventario });

    showToast('success', 'Medicamento excluído com Sucesso!', 5000, 'top-center');
  }

  const navegarLotes = useCallback((medicamento: InventarioMedicamento) => {
    setOpenModalLote(true);
    setMedicamentoSelecionado(medicamento);
  }, []);

  useEffect(() => {
    if (openModalLote && medicamentoSelecionado !== undefined)
      setModalLoteComponent(
        <InventarioLoteModal
          openned={true}
          setModalOpen={setOpenModalLote}
          medicamentoInfo={medicamentoSelecionado}
          inventarioMedicamentosAtual={currentInventarioMedicamentos}
          setMedicamentoInfo={(res: any) => setMedicamentoSelecionado(res)}
          setInventarioAtual={(res: any) => setInventarioMedicamentos(res)}
        />
      );
  }, [openModalLote, medicamentoSelecionado]);

  const {
    control,
    formState
  } = useForm<InventarioMedicamento>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const isLoading = () =>
    carregandoListaInventario || salvandoInventario || carregandoInventarioAndamento || autorizandoInventario || carregandoInventarioPendenteEnvioSNGPC || carregandoOperacaoPendentAprovacao;

  return (
    <>

      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      <Fade in mountOnEnter unmountOnExit >
        <div className={`flex-column w-100 h-100 ${styles.color}`}>
          <PageHeader
            showBotaoFiltrar
            showMenu
            titulo='Inventário' />
          <Paginacao
            pageChanged={handlePageChange}
            totalPages={Math.ceil(inventarioMedicamentos?.length / pageItems)}
            totalRegisters={inventarioMedicamentos?.length}
            currentPage={currentPage}
          />

          <div className={`flex-column h-100 ${styles.listPadding}`}>

            <div className={`flex-column h-100 w-100 ${styles.listContainer}`}>

              <div className='flex-column w-100'>
                <InventarioMedicamentoSelecao
                  control={control}
                  formState={formState}
                  adicionarMedicamento={adicionarMedicamento}
                  dataInventario={inventario.data ? moment(inventario.data).format("DD/MM/YYYY") : ""}
                />
              </div>

              {inventario.medicamentos !== undefined && (
                <>
                  {
                    inventario.medicamentos.length > 0
                      ? (
                        <div className={`flex-column ${styles.medicamentoListContainer}`}>
                          <div className={styles.medicamentoListStyle}>
                            {currentInventarioMedicamentos?.map((medicamento, index) => (
                              <div className={`w-100 ${styles.cardMovimentacaoContainer}`} key={index}>
                                <InventarioMedicamentoCard
                                  inventarioMedicamento={medicamento}
                                  excluirRegistro={excluirRegistro}
                                  navegarLotes={navegarLotes}
                                />
                              </div>
                            ))}
                          </div>
                          <div className={`flex-row w-100 ${styles.buttonContainer}`}>
                            <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                              <Button onClick={() => salvar()} variant="text" className="h-100 w-100">
                                <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                                  <SalvarIcon class={styles.iconSize} />
                                  Salvar
                                </div>
                              </Button>
                            </div>
                            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                              <Button onClick={() => autorizar()} variant="text" className="h-100 w-100">
                                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                  <AvancarIcon class={styles.iconSize} />
                                  Autorizar
                                </div>
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <Typography variant="h3" className={styles.semRegistroStyle}>
                          Nenhum registro foi encontrado.
                        </Typography>
                      )
                  }
                </>
              )}

            </div>

            <div className={`flex-row w-100 ${styles.containerModais}`}>
              {openModalLote && (modalLoteComponent)}

              {openModalOperacaoPendenteAprovacao && (
                <div className={`h-100 w-100 ${styles.modal}`}>
                  <DialogOperacaoPendenteAutorizacao
                    fecharModal={() => naoIniciarNovoInventario()}
                    sim={() => iniciarNovoInventarioOperacaoPendente()}
                    nao={() => naoIniciarNovoInventario()}
                  />
                </div>
              )}

              {openModalNovoInventario && (
                <div className={`h-100 w-100 ${styles.modal}`}>
                  <DialogNovoInventario
                    fecharModal={() => naoIniciarNovoInventario()}
                    sim={() => iniciarNovoInventario()}
                    nao={() => naoIniciarNovoInventario()}
                  />
                </div>
              )}

              {openModalInventarioPendenteEnvio && (
                <div className={`h-100 w-100 ${styles.modal}`}>
                  <DialogInventarioPendenteEnvioSNGPC
                    fecharModal={() => naoIniciarNovoInventario()}
                    acessarEnvioInventarioSNGPC={() => acessarEnvioInventarioSNGPC()}
                  />
                </div>
              )}
            </div>

          </div >
        </div >

      </Fade >


    </>
  );

});