import { FormState } from "react-hook-form";
import { Medicamento } from "../../../../components/autocompletes/autocomplete-medicamentos/autocomplete-medicamento-model";

export enum EPerdaPassos {
    Movimentacao = 0,
    Medicamentos = 1
}

export class OperacaoPerdaRequest {
    constructor(
        public id: string,
        public operacaoAutorizacaoId: string,
        public operacaoTipo: number,
        public dataHoraLancamento: Date,
        public deveAutorizar: boolean,
        public perda: PerdaModel
    ) { }
}

export class PerdaModel {
    constructor(
        public id: string,
        public operacaoAutorizacaoId: string,
        public dataHoraLancamento: Date = new Date(),
        public data: string,
        public hora: string,
        public motivoPerda: EMotivoPerda,
        public medicamentos: MedicamentoPerda[] = []
    ) { }
}

export interface PerdaRequest {
    id?: string;
    dataHoraLancamento: Date;
    motivoPerda: EMotivoPerda;
    medicamentos: Array<MedicamentoPerdaRequest>;
}

export interface PerdaForm {
    data: string;
    hora: string;
    motivoPerda: EMotivoPerda;
    medicamentos: Array<MedicamentoPerdaRequest>;
}

export class PerdaModalForm {
    constructor() {
        this.loteSelecionadoId = "";
        this.quantidade = 0;
    }

    loteSelecionadoId: string;
    quantidade: number;
}

export interface MedicamentoPerdaRequest {
    id: string;
    medicamentoId: string;
    medicamentoNome: string;
    registroMS: string;
    lote: string;
    loteDescricao: string;
    validade: Date;
    quantidade: number;
}

export interface PerdaResponse {
    id: string;
    dataLancamento: string;
    dataHoraLancamento: string;
    motivoPerda: number;
    quantidade: number;
    medicamentos: Array<MedicamentoPerda>
    status: string;
}

export interface MedicamentoPerdaResponse {
    medicamentoNome: string;
    medicamentoId: string;
    codigoAnvisa: string;
    validade: string;
    lote: string;
}

export interface MedicamentoPerda {
    medicamentoNome: string;
    medicamentoId: string;
    codigoAnvisa: string;
    validade?: Date;
    fabricacao?: Date;
    lote?: string;
    vendaControlada: boolean;
    codigoAgregador?: number;
    motivoIsencao?: string;
    vpmc: 0;
}

export interface PerdaMedicamentoLote {
    lote: string;
    quantidadeDisponivel: number;
    quantidadePerdida: number;
    dataValidade: Date;
    dataFabricacao?: Date;
    codigoAgregador?: number;
};

export enum EMotivoPerda {
    FurtoRoubo = 1,
    Avaria = 2,
    Vencimento = 3,
    Apreensao = 4,
    PerdaNoProcesso = 5,
    ColetaParaControleQualidade = 6,
    PerdaExclusaoPortaria344 = 7,
    PorDesvioQualidade = 8,
    RecolhimentoFabricante = 9,
    DevolucaoFornecedorDistribuidora = 10
}

export const EMotivoPerdaMap = new Map<number, string>([
    [EMotivoPerda.Apreensao, 'Apreensão/Recolhimento pela Visa'],
    [EMotivoPerda.Avaria, 'Avaria'],
    [EMotivoPerda.ColetaParaControleQualidade, 'Coleta para controle de qualidade'],
    [EMotivoPerda.DevolucaoFornecedorDistribuidora, 'Devolução ao fornecedor/distribuidora'],
    [EMotivoPerda.FurtoRoubo, 'Furto/Roubo'],
    [EMotivoPerda.PerdaExclusaoPortaria344, 'Perda de exclusão da portaria 344'],
    [EMotivoPerda.PerdaNoProcesso, 'Perda no processo'],
    [EMotivoPerda.PorDesvioQualidade, 'Por desvio de qualidade'],
    [EMotivoPerda.RecolhimentoFabricante, 'Recolhimento do Fabricante'],
    [EMotivoPerda.Vencimento, 'Vencimento']
]);

export interface MedicamentoLotes {
    id: string;
    validade: Date;
    descricao: string;
    quantidade: number;
}

export class MedicamentoInfo {
    medicamento!: Medicamento;
    medicamentoLotes: Array<MedicamentoLotes> = [];
}

export class ControleMedicamentoLotes {
    constructor(
        public exibir: boolean = false,
        public medicamentoLotes: Array<MedicamentoPerda> = [],
    ) { }
}

export interface PerdaStepsProp {
    visualizacao: boolean;
    perda: PerdaModel;
    formState: FormState<PerdaModel>;
    register: any;
    setPerda: React.Dispatch<React.SetStateAction<PerdaModel>>;
    editarMedicamentoLotes?: (medicamentos: MedicamentoPerda[]) => void;
}
export interface PerdaMedicamentoProp {
    visualizacao: boolean;
    medicamentos: MedicamentoPerda[];
    editar: (medicamentos: MedicamentoPerda[]) => void;
}
export interface PerdaMedicamentoLoteProp {
    visualizacao: boolean;
    medicamentos: MedicamentoPerda[];
    salvarLotesPerda: (lotesSelecionados: PerdaMedicamentoLote[], medicamentos: MedicamentoPerda) => void;
}
export interface PerdasMedicamentoLoteCardProp {
    visualizacao: boolean;
    lote: PerdaMedicamentoLote;
    alteraLoteQtd: (lote: string, quantidade: number) => void;
}