import { Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { EnumCadastroTipo } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { useStyles } from "./compras-styles";
import { CompraFornecedor, CompraStepsProp } from "./models/compras-cadastro.model";


export const CompraNotaFiscalForm = (({ visualizacao, register, control, formState, compra, setCompra, errorValidation }: CompraStepsProp) => {
  const styles = useStyles();
  const { errors } = formState;
  const [fornecedorNome, setFornecedorNome] = useState("");
  const [erroFornecedor, setErroFornecedor] = useState(false);

  const setDataHoraLancamento = useCallback((data: string, hora: string) => {
    setCompra({
      ...compra,
      dataLancamento: data,
      horaLancamento: hora,
      dataHoraLancamento: new Date(`${data}T${hora}:00.000Z`)
    })
  }, [compra, setCompra]);

  const setDataNotaFiscal = useCallback((data: string) => {
    setCompra({
      ...compra,
      diaNotaFiscal: data,
      dataNotaFiscal: new Date(`${data}T12:00:00.000Z`)
    })
  }, [compra, setCompra]);

  const setFornecedor = (value: any) => {

    if (!value) {
      setFornecedorNome("");
      setCompra((compra) => ({
        ...compra,
        fornecedor: new CompraFornecedor()
      }))
      return;
    }

    setFornecedorNome(value.nome);

    value.cnpj = value?.cpfcnpj;
    value.cpfcnpj = null;
    setCompra((compra) => ({
      ...compra,
      fornecedor: value ? value : null
    }))
  }

  return (
    <>
      {compra &&
        <div className={`flex-column ${styles.formContainer}`}>
          <div className={`flex-column w-100 h-100`}>
            <div className={`flex-column ${styles.titleContainer}`}>
              <Typography className={styles.titleStyle} variant="h6">Dados da Nota</Typography>
              <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados da Nota</Typography>
            </div>

            <div className={`flex-column h-100 w-100 ${styles.innerFormContainer}`}>
              <div className={`w-100 ${styles.inputContainer}`}>
                <AutocompletePessoas label="Fornecedor"
                  tipo={EnumCadastroTipo.TODOS}
                  value={compra?.fornecedor ? compra?.fornecedor?.nome : ''}
                  disabled={visualizacao}
                  name='fornecedor'
                  onChange={(a) => setFornecedor(a.value)}
                  error={Boolean((errorValidation || erroFornecedor) && !fornecedorNome)}
                  helperText={
                    (errorValidation || erroFornecedor) && !fornecedorNome
                      ? 'Fornecedor é obrigatório!'
                      : undefined
                  }
                  onBlur={() => {
                    if (!fornecedorNome)
                      setErroFornecedor(true);
                  }}
                />
              </div>

              <div className={`flex-row w-100 ${styles.inputContainer}`}>
                <div className="w-50">
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    tipo='DATA'
                    variant='outlined'
                    labelCustom='Data de Lançamento'
                    showStartAdornment={true}
                    allowSubmit={true}
                    size="medium"
                    fullWidth={true}
                    value={compra.dataLancamento}
                    disabled={visualizacao}
                    error={Boolean(errors.dataLancamento && !compra.dataLancamento)}
                    helperText={
                      errors.dataLancamento && !compra.dataLancamento
                        ? errors.dataLancamento?.message
                        : undefined
                    }
                    {...register('dataLancamento', {
                      onChange: (e: any) => setDataHoraLancamento(e.target.value, compra.horaLancamento)
                    })}
                  />
                </div>
                <div className="w-50">
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    tipo='HORA'
                    variant='outlined'
                    labelCustom='Hora de Lançamento'
                    size="medium"
                    name='horaAtual'
                    value={compra.horaLancamento}
                    fullWidth
                    disabled={visualizacao}
                    error={Boolean(errors.horaLancamento && !compra.horaLancamento)}
                    helperText={
                      errors.horaLancamento && !compra.horaLancamento
                        ? errors.horaLancamento?.message
                        : undefined
                    }
                    {...register('horaLancamento', {
                      onChange: (e: any) => setDataHoraLancamento(compra.dataLancamento, e.target.value)
                    })}
                  />
                </div>
              </div>

              <div className={`flex-row w-100 ${styles.inputContainer}`}>
                <div className="w-50">
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    tipo='DATA'
                    variant='outlined'
                    labelCustom='Data da Nota Fiscal'
                    size="medium"
                    name='dataNotaFiscal'
                    value={compra.diaNotaFiscal}
                    fullWidth
                    disabled={visualizacao}
                    error={Boolean(errors.diaNotaFiscal && !compra.diaNotaFiscal)}
                    helperText={
                      errors.diaNotaFiscal && !compra.diaNotaFiscal
                        ? errors.diaNotaFiscal?.message
                        : undefined
                    }
                    {...register('diaNotaFiscal', {
                      onChange: (e: any) => setDataNotaFiscal(e.target.value)
                    })}
                  />
                </div>
                <div className="w-50">
                  <TextFieldSaurus
                    className={`h-100 w-100 ${styles.inputStyle}`}
                    tipo='NUMERO'
                    variant="outlined"
                    labelCustom='N. da Nota Fiscal'
                    size="medium"
                    name='numeroNotaFiscal'
                    value={compra.numeroNotaFiscal}
                    fullWidth
                    required
                    disabled={visualizacao}
                    error={Boolean(errors.numeroNotaFiscal && !compra.numeroNotaFiscal)}
                    helperText={
                      errors.numeroNotaFiscal && !compra.numeroNotaFiscal
                        ? errors.numeroNotaFiscal?.message
                        : undefined
                    }
                    {...register('numeroNotaFiscal', {
                      onChange: (e: any) => setCompra({ ...compra, numeroNotaFiscal: e.target.value ? e.target.value : undefined })
                    })}
                  />
                </div>

              </div>

            </div>
          </div>
        </div>
      }
    </>
  );
});