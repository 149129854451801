import { Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CircularLoading, useThemeQueries } from '../../..';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-inventario-medicamento-opcoes-style';
import { useInventarioMedicamentoOpcoes } from './use-dialog-inventario-medicamento-opcoes';

interface DialoInventarioMedicamentoOpcoesProps {
  carregando?: boolean;
  aberto: boolean;
  navegar: () => void;
  excluir: () => void;
}

export const DialogInventarioMedicamentoOpcoesConteudo = ({
  carregando,
  aberto,
  navegar,
  excluir
}: DialoInventarioMedicamentoOpcoesProps) => {
  const { fecharInventarioMedicamentoOpcoes } = useInventarioMedicamentoOpcoes();
  const theme = useThemeQueries();
  const styles = useStyles();
  const [cardWidth, setCardWidth] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    setarTamanhoModal();
  }, [theme])

  const setarTamanhoModal = () => {
    if (theme.isMobile && theme.lg && !theme.xs) {
      setCardWidth(65);
      setCardHeight(35);
      return;
    };
    if (theme.isMobile && theme.xs) {
      setCardWidth(80);
      setCardHeight(35);
      return;
    };
    if (theme.isMobile && theme.lg) {
      setCardWidth(65);
      setCardHeight(28);
      return;
    };
    if (theme.md) {
      setCardWidth(45);
      setCardHeight(35);
      return;
    };
    if (theme.lg) {
      setCardWidth(30);
      setCardHeight(38);
      return;
    };
    if (theme.sm) {
      setCardWidth(30);
      setCardHeight(38);
      return;
    };
    if (theme.xl) {
      setCardWidth(15);
      setCardHeight(30);
      return;
    };
  }

  const navegarEdicao = () => {
    navegar();
    fecharInventarioMedicamentoOpcoes();
  }

  const excluirMedicamento = () => {
    excluir();
    fecharInventarioMedicamentoOpcoes();
  }

  return (
    <DialogSaurus
      isPopUp
      tamanho="xs"
      titulo="Lotes e Movimentações"
      cardHeight={cardHeight}
      cardWidth={cardWidth}
      isButtonTitleClose
      aberto={aberto || false}
      handleClickClose={fecharInventarioMedicamentoOpcoes}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <div className={`flex-column h-100 w-100 ${styles.dialogContainer}`}>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={navegarEdicao} className='w-100'>
            <Typography className={styles.buttonFont}>
              Lotes
            </Typography>
          </Button>
        </div>
        <div className={`w-100 ${styles.buttonContainer}`}>
          <Button onClick={excluirMedicamento} className='w-100'>
            <Typography className={styles.buttonFont} color='error'>
              Excluir
            </Typography>
          </Button>
        </div>
      </div>
    </DialogSaurus>
  );
};