import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.grey[100],
        overflow: 'hidden'
    },

    contentContainer: {
        flexGrow: 1,
        alignItems: "center",
        marginBottom: "1.2rem",
    },

    listPadding: {
        width: "95%",
        justifyContent: "space-between",
    },

    titleStyle: {
        paddingLeft: '1rem',
        fontSize: '1.3rem',
        fontWeight: 500,
    },

    breakLine: {
        height: '2px',
        marginBottom: '1vh',
        backgroundColor: theme.palette.primary.main,
    },

    loteContainer: {
        display: 'flex'
    },

    containerLoteAdicionar: {
        height: "fit-content",
    },

    semRegistroStyle: {
        textAlign: 'center',
        margin: '1rem 0',
        fontSize: '1rem'
    },

    listContainer: {
        height: 0,
        flexGrow: 1,
        overflow: 'auto',
        margin: '0.5rem 0',
        paddingRight: '0.5rem'
    },

    containerButton: {
        display: "flex",
        flexDirection: "row",
        width: '100%',
        maxHeight: "6vh",
        alignItems: "center",
        gap: '4%',
    },

    buttonStyle: {
        flexGrow: 1,
        minWidth: '47%',
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem'
        }
    },

    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },

    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },

    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },

    containerMedicamento: {
        display: "flex",
        flexDirection: "row",
        width: '100%',
        padding: '0 0.5rem'
    },

    labelMedicamentoFont: {
        fontWeight: 700,
        fontSize: "1rem",
        color: theme.palette.secondary.main,
        paddingRight: '0.25rem',
    },

    contentFontMedicamento: {
        fontSize: "1",
        color: theme.palette.secondary.main,
    },

    containerInput: {
        flexGrow: 1
    },

    loteFormContainer: {
        gap: '1.3rem',
        paddingTop: '.5rem',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            alignSelf: 'center'
        }
    },

    formContainer: {
        gap: '3%',
    },

    inputStyle: {
        borderRadius: '10px',
        height: '3.5rem',
        backgroundColor: theme.palette.grey[100],
    },

    containerAdicionar: {
        gap: '5%',
        [theme.breakpoints.up('sm')]: {
            alignSelf: 'center',
        }
    },

    cardLoteItem: {
        justifyContent: 'flex-start',
        marginBottom: 10
    },

    cardPadding: {
        padding: '0.25rem 0.5rem',
    },

    infoContainer: {
        margin: '0.3rem 0.5rem',
        justifyContent: 'space-between',
        width: '100%',
    },

    containerLabel: {
        marginBottom: 10
    },

    labelFont: {
        fontWeight: 700,
        fontSize: "0.7rem",
        color: theme.palette.common.black,
        paddingRight: '0.25rem',
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "0.7rem",
        }
    },

    contentFont: {
        fontSize: "0.7rem",
        color: theme.palette.secondary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },

    containerW45: {
        width: '45%'
    },

    containerButtonDelete: {
        display: "flex",
        alignItems: "center",
    },

    editButtonIcon: {
        height: '3vh',
        width: '3vh',
        margin: '0.3vh 0'
    },

    deleteButtonStyle: {
        display: "flex",
        alignItems: "center",
        height: 'fit-content',
        width: 'fit-content',
        borderRadius: '10px',
    },

    deleteButtonLabel: {
        display: "flex",
        alignItems: "center",
        gap: '0.4rem',
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },

    deleteButtonIcon: {
        height: '3vh',
        width: '3vh',
        margin: '0.3vh 0'
    },

    redButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
    },
}));