import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { SngpcStorageKeys, useSngpcStorage, useSngpcToken, useToastSaurus } from '../../../services/app';
import { useSessaoAtual } from '../../../services/app/providers';


export const ExpiredTokenRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { usuario, termosDeUsoAtivo, carregando: carregandoSessaoAtual, validaUsuarioConectado, refreshUser } = useSessaoAtual();
  const { getEmpresaAtual, isTokenExpired } = useSngpcToken();
  const { showToast } = useToastSaurus();
  const { delRegistro } = useSngpcStorage();
  const { getRegistro } = useSngpcStorage();

  const modulo = getRegistro(SngpcStorageKeys.SelecaoModulo, false)

  const carregando = carregandoSessaoAtual

  useEffect(() => {
    if (isTokenExpired(usuario)) {
      if (validaUsuarioConectado()) {
        refreshUser(usuario!.originalToken)
      }
    }
  }, [isTokenExpired, refreshUser, usuario, validaUsuarioConectado])

  const retRoute = useCallback(() => {
    if (isTokenExpired(usuario)) {
      showToast(
        'error',
        `Sua sessão expirou. Realize o login novamente para continuar. (401) `,
      );
      delRegistro(SngpcStorageKeys.TokenTouchPharma, false);
      return <Redirect to="/login/expirou" />;
    }

    return carregando ? (
      children
    ) : isEmpty(usuario) ? (
      <Redirect to="/login/expirou" />
    ) : !termosDeUsoAtivo() ? (
      <Redirect to="/termos-de-uso" />
    ) : !getEmpresaAtual() ? (
      <Redirect to="/selecionar-empresa" />
    ) : (
      children
    );
  }, [isTokenExpired, usuario, carregando, children, termosDeUsoAtivo, getEmpresaAtual, modulo, showToast, delRegistro]);
  return <Route {...otherProps}>{retRoute()}</Route>;
};
