import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fullSized: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.8)',
        height: '100%',
        width: '100%',
        zIndex: 1,
    },
    onAutoComplete: {
        position: 'relative',
        float: 'unset',
        width: '70px',
        height: '38px',
        zIndex: 1,
        background: theme.palette.common.white,
        objectFit: 'cover',
        marginRight: -30,
        "& .loader": {
            width: '40px',
            height: '40px',
            float: 'right',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            top: 'unset',
            bottom: 'unset',
            left: 'unset',
            right: 'unset',
            margin: 'unset',
            "& div": {
                top: 'unset',
                left: 'unset',
            }
        }
    },
    normal: {
        position: 'relative',
    }
}));

