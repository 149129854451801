import { FormState } from 'react-hook-form';
import { TokenSngpcEmpresaModel } from '../../../../../model/api/gestao/master/token-sngpc-empresa';
import { OperacaoAutorizacaoEnum, StatusAutorizacaoEnum } from '../../autorizacao-page/model/autorizacao.model';

export interface TransferenciasResponse {
    id: string;
    numeroNotaFiscal: string;
    dataHoraLancamento: Date;
    origem: TransferenciaFilial;
    destino: TransferenciaFilial;
    tipoOperacaoTransferencia: ETipoOperacaoTransferencia;
    status: StatusAutorizacaoEnum;
}

export class OperacaoTransferenciaRequest {
    constructor(
        public id: string,
        public operacaoAutorizacaoId: string,
        public operacaoTipo: number,
        public dataHoraLancamento: Date,
        public deveAutorizar: boolean,
        public transferencia: TransferenciaModel
    ) { }
}

export class TransferenciaModel {
    constructor(
        public id: string,
        public operacaoAutorizacaoId: string,
        public tipoOperacaoTransferencia: ETipoOperacaoTransferencia = ETipoOperacaoTransferencia.saida,
        public origemCNPJ: string,
        public origem: TransferenciaFilial,
        public destinoCNPJ: string,
        public destino: TransferenciaFilial,
        public numeroNotaFiscal: string,
        public data: string,
        public hora: string,
        public medicamentos: MedicamentoTransferencia[] = [],
        public dataHoraLancamento: Date = new Date(),
    ) { }
}

export class OperacaoPendenteAvulsa {
    constructor(
        dataHoraLancamento: Date,
        transferencia: TransferenciaModel
    ) {
        this.dataHoraLancamento = dataHoraLancamento;
        this.transferencia = transferencia;
    }
    dataHoraLancamento: Date;
    operacaoTipo: OperacaoAutorizacaoEnum = OperacaoAutorizacaoEnum.Transferencia;
    transferencia: TransferenciaModel;
}

export class TransferenciaFilial {
    constructor(
        public cnpj: string = "",
        public nome: string = ""
    ) { }
}

export enum ETipoOperacaoTransferencia {
    saida = 1,
    entrada = 2
}

export const ETipoOperacaoTransferenciaMap = new Map<number, string>([
    [ETipoOperacaoTransferencia.saida, 'Saída'],
    [ETipoOperacaoTransferencia.entrada, 'Entrada'],
]);

export enum ETransferenciaPassos {
    Movimentacao = 0,
    Medicamentos = 1
}

export interface MedicamentoTransferencia {
    medicamentoNome: string;
    medicamentoComposicao: string;
    medicamentoDescritivo: string;
    medicamentoId: string;
    codigoAnvisa: string;
    validade?: Date;
    fabricacao?: Date;
    lote?: string;
    vendaControlada: boolean;
    codigoAgregador: number;
    motivoIsencao?: string;
    vpmc: 0;
}

export interface TransferenciaMedicamentoLote {
    lote: string;
    quantidadeDisponivel: number;
    quantidadeTransferida: number;
    dataValidade: Date;
    dataFabricacao?: Date;
    codigoAgregador?: number;
    novoLote: boolean;
};

export class ControleMedicamentoLotes {
    constructor(
        public exibir: boolean = false,
        public medicamentoLotes: Array<MedicamentoTransferencia> = [],
    ) { }
}

export class NovoLote {
    constructor(
        public lote: string = "",
        public dataValidade: Date | undefined = undefined,
        public quantidade: number = 0
    ) { }
}

export interface TransferenciaStepsProp {
    visualizacao: boolean;
    transferencia: TransferenciaModel;
    formState: FormState<TransferenciaModel>;
    register?: any;
    setTransferencia: React.Dispatch<React.SetStateAction<TransferenciaModel>>;
    empresasFiliais?: Array<TokenSngpcEmpresaModel>;
    editarMedicamentoLotes?: (medicamentos: MedicamentoTransferencia[]) => void;
}

export interface TransferenciaMedicamentoLotesProp {
    visualizacao: boolean;
    medicamentos: MedicamentoTransferencia[];
    editar: (medicamentos: MedicamentoTransferencia[]) => void;
}

export interface TransferenciaMedicamentoLoteProp {
    visualizacao: boolean;
    isEntrada: boolean;
    medicamentos: MedicamentoTransferencia[];
    salvarLotesTransferencia: (lotesSelecionados: TransferenciaMedicamentoLote[], medicamentos: MedicamentoTransferencia) => void;
}

export interface TransferenciaEntradaMedicamentoLoteProp {
    visualizacao: boolean;
    lotes: TransferenciaMedicamentoLote[];
    medicamento: MedicamentoTransferencia;
    adicionarLote: (novoLote: NovoLote) => void;
    alterarLotes: (loteNome: string, quantidade: number) => void;
    removerLote: (loteNome: string) => void;
}

export interface TransferenciaSaidaMedicamentoLoteProp {
    visualizacao: boolean;
    lotes: TransferenciaMedicamentoLote[];
    medicamento: MedicamentoTransferencia;
    alterarLotes: (loteNome: string, quantidade: number) => void;
}

export interface TransferenciasMedicamentoLoteCardProp {
    visualizacao: boolean;
    isEntrada: boolean;
    lote: TransferenciaMedicamentoLote;
    alteraLoteQtd: (lote: string, quantidade: number) => void;
    remover?: (lote: string) => void;
}

export interface TransferenciasMedicamentoNovoLoteCardProp {
    adicionar: () => Promise<void>;
    novoLote: NovoLote;
    setNovoLote: React.Dispatch<React.SetStateAction<NovoLote>>;
    formState: FormState<NovoLote>;
    register?: any;
}