import { useCallback } from 'react';
import { ETypeDomain } from '../../../../config/types';
import { useApiBase } from '../../base';

export class IdentidadeAutenticarUsernameProps {
    public constructor(
        public apelido: string = '',
        public senha: string = '',
        public contratoId: string = ''
    ) { }
}

export function usePostIdentidadeAutenticarUsername() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarUsername = useCallback(
        (autenticacaoProps: IdentidadeAutenticarUsernameProps) =>
            invocarApi({
                method: 'POST',
                url: `Auth/token-apelido`,
                data: autenticacaoProps,
                enviarTokenUsuario: true,
                typeDomain: ETypeDomain.Login,
                apiVersion: 'v4'
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarUsername,
    };
}
