import { Control, FormState } from "react-hook-form";
import { EnumClasseTerapeutica, EnumUnidadeComercial } from "../../compras/componentes/models/compras-cadastro.model";

export class Inventario {
  id?: string;
  inicial?: boolean;
  data?: Date;
  medicamentos?: InventarioMedicamento[];
};

export class InventarioMedicamento {
  medicamentoId?: string;
  medicamento?: string;
  codigoAnvisa?: string;
  vendaControlada?: boolean;
  vmpc?: number;
  motivoIsencao?: string;
  quantidadeBloqueada?: number;
  quantidade?: number;
  codigoEan?: string;
  laboratorio?: string;
  unidadeComercial?: EnumClasseTerapeutica;
  classeTerapeutica?: EnumUnidadeComercial
  laboratorioId?: string;
  medicamentoComposicao?: string;
  medicamentoDescritivo?: string;
  lotes?: InventarioLote[];
};

export class InventarioLote {
  loteEditado?: string;
  lote?: string;
  quantidade?: number;
  codigoAgregador?: number;
  dataValidade?: Date;
  dataFabricacao?: Date;
  dataValidadeFormatada?: string;
  dataFabricacaoFormatada?: string;
};

export interface InventarioAdicionarMedicamentoProp {
  control: Control<InventarioMedicamento, any>;
  formState: FormState<InventarioMedicamento>;
  adicionarMedicamento: (movimentacaoFiltro: InventarioMedicamento) => void;
  dataInventario?: string;
}

export interface InventarioAdicionarLoteMedicamentoProp {
  control: Control<InventarioLote, any>;
  formState: FormState<InventarioLote>;
  medicamentonome: string;
  adicionarLote: (movimentacaoFiltro: InventarioLote) => void;
}

export interface InventarioEditarLoteMedicamentoProp {
  control: Control<InventarioLote, any>;
  formState: FormState<InventarioLote>;
  salvarEdicaoLote: (movimentacaoFiltro: InventarioLote, loteEditado?: string,) => void;
  loteEdicao: InventarioLote
}

export interface MedicamentoInfo {
  medicamentoId?: string;
  medicamentoNome?: string;
  lotes?: InventarioLote[];
}