import { Dispatch, SetStateAction } from "react";
import { DefaultModal } from "../../../../../../components/default-modal";
import { CompraCadastroPage } from "../../compras-cadastro";
import { CompraCadastroAvulsaPage } from "../../compras-cadastro-avulso";

interface CompraModalProps {
    openned?: boolean;
    operacaoId?: string;
    compraId?: string;
    dataHoraLancamento?: Date;

    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const CompraModal = (props: CompraModalProps) => {

    const fecharModal = () => props.setModalOpen(false);

    return (
        <DefaultModal
            minWidth="45vw"
            open={props.openned}
            variant={"temporary"}
            anchor="right"
        >
            {
                (props.operacaoId || props.compraId) ?
                    <CompraCadastroPage
                        compraId={props.compraId}
                        operacaoId={props.operacaoId}
                        dataHoraLancamento={props.dataHoraLancamento}
                        fecharModal={() => fecharModal()}
                    /> : <CompraCadastroAvulsaPage fecharModal={() => fecharModal()} />
            }
        </DefaultModal>
    );
};
