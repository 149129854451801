import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    legendContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.grey[100],
        justifyContent: 'center'
    },
    legendaLabelContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    title: {
        paddingLeft: '3%',
        fontSize: '0.7rem',
        color: theme.palette.grey[400],
        width: '100%',
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    },
    greenBall: {
        display: 'flex',
        alignSelf: 'center',
        width: '10px',
        height: '10px',
        backgroundColor: theme.palette.info.main,
        marginRight: '5px',
    },
    redBall: {
        display: 'flex',
        alignSelf: 'center',
        width: '10px',
        height: '10px',
        backgroundColor: theme.palette.error.main,
        marginRight: '5px',
    },
    labelContainer: {
        display: 'flex',
        padding: '1%',
        width: '45%',
        justifyContent: 'center',
    },
    label: {
        fontSize: '0.7rem',
        alignSelf: 'center',
        fontWeight: 600
    },
}));
