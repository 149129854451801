import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "../../../../config";
import { ETypeDomain } from "../../../../config/types";

export function useGetListEnvioInventario() {
  const { invocarApi, carregando, ...outrasPropriedades } = useApiBase();

  const getListEnvioInventario = useCallback(
    (pageIndex: number, pageSize: number, filter: string) => {
      return invocarApi({
        url: `EnvioInventario/inventario-enviado-paginado?page=${pageIndex}&pageSize=${pageSize}&filter=${filter}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );

  const getListInventarioEnvioPendenteSNGPC = useCallback(
    (pageIndex: number, pageSize: number, filter:string) => {
      return invocarApi({
        url: `EnvioInventario/inventario-paginado-envio-sngpc?page=${pageIndex}&pageSize=${pageSize}&filter=${filter}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrl,
        typeDomain: ETypeDomain.TouchPharma,
        apiVersion: 'v1'
      });
    },
    [invocarApi]
  );

  const enviarXMLInventarioSNGPCANVISA = useCallback(() => {
    return invocarApi({
      url: `EnvioInventario/enviar-xml-inventario-sngpc-anvisa`,
      method: "POST",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const enviarXMLInventarioSNGPCANVISAManual = useCallback(() => {
    return invocarApi({
      url: `EnvioInventario/enviar-xml-manual-inventario-sngpc-anvisa`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const downloadXMLInventarioSNGPCANVISA = useCallback(() => {
    return invocarApi({
      url: `EnvioInventario/download-xml-inventario-sngpc-anvisa`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const getDataInventario = useCallback(() => {
    return invocarApi({
      url: `EnvioInventario/buscar-data-inventario`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  const downloadXMLInventarioSNGPCANVISAEnviado = useCallback((inventarioId: string) => {
    return invocarApi({
      url: `EnvioInventario/download-xml-inventario-sngpc-anvisa-enviado/${inventarioId}`,
      method: "GET",
      enviarTokenUsuario: true,
      baseURL: VariaveisAmbiente.apiUrl,
      typeDomain: ETypeDomain.TouchPharma,
      apiVersion: 'v1'
    });
  },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    carregando,
    getListEnvioInventario,
    getListInventarioEnvioPendenteSNGPC,
    enviarXMLInventarioSNGPCANVISA,
    enviarXMLInventarioSNGPCANVISAManual,
    downloadXMLInventarioSNGPCANVISA,
    getDataInventario,
    downloadXMLInventarioSNGPCANVISAEnviado
  };
}