import { Button, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { EnumCadastroTipo } from "../../../../../model";
import { AutocompletePessoas } from "../../../../components/autocompletes/autocomplete-pessoas";
import { CheckboxDefault } from "../../../../components/checkboxes";
import { AvancarIcon, VoltarIcon } from "../../../../components/icons";
import { useStyles } from "../dispensacao-avulsa-cadastro-style";
import { DispensacaoAvulsaFormCompradorPaciente, EDispensacaoAvulsaPassos } from "../models/dispensacao-avulsa-models";
import { DispensacaoAvulsaStepsProp } from "../models/dispensacao-avulsa-props";

export const DispensacaoAvulsaCompradorForm = (({ dispensacao, setDispensacao, avancarStep, errorValidation, errorValidationCompradorPacienteMesmaPessoa }: DispensacaoAvulsaStepsProp) => {
  const styles = useStyles();
  const [erroPaciente, setErroPaciente] = useState(false);
  const [erroComprador, setErroComprador] = useState(false);

  const setPaciente = useCallback((paciente) => {
    //TODO: Solicitar o genêno com a Saurus.
    if (paciente)
      paciente.sexo = 1;

    setDispensacao(prevState => ({
      ...prevState,
      paciente: paciente
    }));
  }, [setDispensacao]);

  const setComprador = useCallback((comprador: DispensacaoAvulsaFormCompradorPaciente) => {
    //TODO: Solicitar o genêno com a Saurus.
    if (comprador)
      comprador.sexo = 1;

    setDispensacao(prevState => ({
      ...prevState,
      comprador: comprador
    }));
  }, [setDispensacao]);

  const setCompradorPacienteMesmaPessoa = useCallback(() => {
    setDispensacao(prevState => ({
      ...prevState,
      compradorPacienteMesmaPessoa: !prevState.compradorPacienteMesmaPessoa
    }));
  }, [setDispensacao]);

  const proximoStep = () => {
    avancarStep(EDispensacaoAvulsaPassos.Medicamento);
  }

  const voltarStep = () => {
    avancarStep(EDispensacaoAvulsaPassos.Prescritor);
  }

  return (
    <>
      <div className={`flex-column w-100 h-100`}>
        <div className={`flex-column ${styles.titleContainer}`}>
          <Typography className={styles.titleStyle} variant="h6">Comprador/Paciente</Typography>
          <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados do comprador/paciente.</Typography>
        </div>

        <div className={`flex-column h-100 ${styles.innerFormContainer}`}>
          <div className={`w-100`}>
            <AutocompletePessoas label="Comprador"
              tipo={EnumCadastroTipo.TODOS}
              value={dispensacao.comprador?.nome}
              size="medium"
              onChange={(a) => setComprador(a.value)}
              onBlur={() => {
                if (!dispensacao.comprador?.nome)
                  setErroComprador(true);
              }}
              error={Boolean((errorValidation || erroComprador) && !dispensacao.comprador?.nome)}
              helperText={
                (errorValidation || erroComprador) && !dispensacao.comprador?.nome
                  ? 'Comprador é obrigatório!'
                  : undefined
              }
            />
          </div>

          <div className={`flex-row w-100 ${styles.checkboxContainer}`}>
            <CheckboxDefault
              className={styles.checkboxStyle}
              checked={dispensacao.compradorPacienteMesmaPessoa}
              onClick={setCompradorPacienteMesmaPessoa}
            />
            <Typography variant="body2">
              Paciente e comprador são os mesmos?
            </Typography>
          </div>
          {!dispensacao.compradorPacienteMesmaPessoa &&
            <div className={`w-100`}>
              <AutocompletePessoas label="Paciente"
                tipo={EnumCadastroTipo.TODOS}
                value={dispensacao.paciente?.nome}
                size="medium"
                onBlur={() => {
                  if (!dispensacao.paciente?.nome)
                    setErroPaciente(true);
                }}
                onChange={(a) =>
                  setPaciente(a.value)
                }
                error={Boolean((errorValidationCompradorPacienteMesmaPessoa || erroComprador) && !dispensacao.paciente?.nome)}
                helperText={
                  (errorValidationCompradorPacienteMesmaPessoa || erroComprador) && !dispensacao.paciente?.nome
                    ? 'Paciente é obrigatório!'
                    : undefined
                }
              />
            </div>
          }
        </div>
        <div className={`flex-row w-100 ${styles.buttonContainer}`}>

          <div className={`${styles.buttonStyle} ${styles.whiteButton}`}>
            <Button variant="text" className="h-100 w-100" onClick={() => voltarStep()} >
              <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                <VoltarIcon class={styles.iconSize} />
                Voltar
              </div>
            </Button>
          </div>

          <div className={`${styles.buttonStyle} ${styles.darkButton}`}>
            <Button variant="text" className="h-100 w-100" onClick={() => proximoStep()} >
              <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                <AvancarIcon class={styles.iconSize} />
                Avançar
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});