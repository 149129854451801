import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const EnvioInventarioIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M7 2V48H34C34.0117 47.9996 34.0235 47.9989 34.0352 47.998C35.7045 49.248 37.7664 50 40 50C45.5 50 50 45.5 50 40C50 35.5445 47.0456 31.7472 43 30.4648V15.5859L29.4141 2H7ZM9 4H28V17H41V30.0508C40.6712 30.0178 40.3371 30 40 30C34.5 30 30 34.5 30 40C30 42.2491 30.7617 44.3247 32.0273 46H9V4ZM30 5.41406L39.5859 15H30V5.41406ZM14 21V23H36V21H14ZM14 27V29H36V27H14ZM40 32C44.4 32 48 35.6 48 40C48 44.4 44.4 48 40 48C35.6 48 32 44.4 32 40C32 35.6 35.6 32 40 32ZM14 33V35H25V33H14Z" />
                <path d="M33.0026 38.6225C33.0234 38.8402 33.17 39.0261 33.3767 39.0972C33.5845 39.1694 33.8142 39.1136 33.9651 38.955L35.7764 37.1438V44.1788C35.7753 44.1963 35.7753 44.2138 35.7764 44.2313C35.7906 44.5408 36.0531 44.7792 36.3626 44.765C36.6722 44.7508 36.9106 44.4883 36.8964 44.1788V37.1438L38.7076 38.9638C38.9275 39.1836 39.284 39.1836 39.5039 38.9638C39.7237 38.7439 39.7237 38.3874 39.5039 38.1675L36.7301 35.4025L36.3364 35L35.9426 35.4025L33.1689 38.1675C33.0475 38.2856 32.9862 38.453 33.0026 38.6225Z" />
                <path d="M46.6661 41.1431C46.6454 40.9254 46.4988 40.7395 46.2921 40.6684C46.0843 40.5962 45.8546 40.652 45.7036 40.8106L43.8924 42.6219V35.5869C43.8935 35.5694 43.8935 35.5519 43.8924 35.5344C43.8782 35.2248 43.6157 34.9864 43.3061 35.0006C42.9966 35.0148 42.7582 35.2773 42.7724 35.5869V42.6219L40.9611 40.8019C40.7413 40.582 40.3847 40.582 40.1649 40.8019C39.945 41.0217 39.945 41.3783 40.1649 41.5981L42.9386 44.3631L43.3324 44.7656L43.7261 44.3631L46.4999 41.5981C46.6213 41.48 46.6825 41.3126 46.6661 41.1431Z" />
            </DefaultIcon>
        </>
    );
};
