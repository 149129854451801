import { Dispatch, SetStateAction } from "react";
import { DefaultModal } from "../../../../../components/default-modal";
import { PerdaCadastroPage } from "../../../perdas/componentes/cadastro/perdas-cadastro";
import { PerdaAvulsaCadastroPage } from "../../perda-avulsa-cadastro";

interface PerdaModalProps {
    openned?: boolean;
    operacaoId?: string;
    perdaId?: string;
    dataHoraLancamento?: string;

    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const PerdaModal = (props: PerdaModalProps) => {

    const fecharModal = () => props.setModalOpen(false);

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned}
            variant={"temporary"}
            anchor="right"
        >
            {
                (props.operacaoId || props.perdaId) ?
                    <PerdaCadastroPage
                        perdaId={props.perdaId}
                        operacaoId={props.operacaoId}
                        dataHoraLancamento={props.dataHoraLancamento}
                        fecharModal={() => fecharModal()}
                    /> : <PerdaAvulsaCadastroPage fecharModal={() => fecharModal()} />
            }
        </DefaultModal>
    );
};
