import { useCallback, useEffect, useState } from 'react';
import { AppEventEnum } from '../../../../model/enums/enum-app-event';
import { useEventTools } from '../../../../services/app/use-cases/events/event-tools';
import { MedicamentoPerda } from '../../../pages/private/perdas/models/perdas-models';
import { DialogPerdaLoteViewConteudo } from './dialog-perda-lote-view-conteudo';

class DialogPerdaLoteViewControleProps {
  constructor(
    public aberto: boolean = false,
    public lotes: Array<MedicamentoPerda> = []
  ) { }
}

export const DialogPerdaLoteViewControle = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogPerdaLoteViewControleProps>(
    new DialogPerdaLoteViewControleProps()
  );

  const modalAlterado = useCallback(({ aberto, lotes }: any) => {
    setModalState({ aberto, lotes });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.PerdaLoteView, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.PerdaLoteView, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogPerdaLoteViewConteudo
          aberto={modalState.aberto}
          lotes={modalState.lotes}
        />
      )}
    </>
  );
};