import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
    return ({
        color: {
            backgroundColor: theme.palette.grey[100],
        },
        titleStyle: {
            paddingLeft: '1rem',
            fontSize: '1.3rem',
            fontWeight: 500,
        },

        breakLine: {
            height: '2px',
            marginBottom: '1vh',
            backgroundColor: theme.palette.divider,
        },

        cardAutorizacaoItem: {
            height: '17%',
            justifyContent: 'center',
        },

        cardPadding: {
            padding: '0.25rem 0.5rem',
        },

        iconSize: {
            width: '3rem',
            [theme.breakpoints.up('md')]: {
                width: '2rem'
            }
        },

        buttonLabel: {
            fontSize: '1rem',
            [theme.breakpoints.up('md')]: {
                fontSize: '3rem'
            },
        },

        buttonContainer: {
            display: 'flex',
            padding: '0.2rem',
            justifyContent: 'center',
            width: '4rem',
            height: '3.8rem',
            alignSelf: 'self-end'
        },

        buttonPosition: {
            alignSelf: 'start',
            marginLeft: '1%'
        },

        buttonAlign: {
            display: 'flex',
            placeContent: 'center'
        },

        whiteLabel: {
            color: theme.palette.common.white,
            fill: theme.palette.common.white,
        },

        buttonStyle: {
            flexGrow: 1,
            minWidth: '47%',
            borderRadius: '50%',
        },

        darkButton: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },

        containerNenhumaAutorizacaoEncontrada: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },

        spanNenhumaAutorizacaoEncontrada: {
            textAlign: 'center'
        },

        listPadding: {
            padding: '0 4%',
            justifyContent: "space-between",
            [theme.breakpoints.up("md")]: {
                padding: '0 1%'
            }
        },

        listContainer: {
            justifyContent: 'start',
            gap: '0.5%'
        },
    })
});
