import { useCallback } from "react";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools";
import { MedicamentoPerda } from "../../../pages/private/perdas/models/perdas-models";

export const usePerdaLoteView = () => {
  const { callEvent } = useEventTools();

  const abrirPerdaLoteView = useCallback(
    (lotes: Array<MedicamentoPerda>) => {
      callEvent(AppEventEnum.PerdaLoteView, { aberto: true, lotes });
    },
    [callEvent]
  );

  const fecharPerdaLoteView = useCallback(
    () => {
      callEvent(AppEventEnum.PerdaLoteView, { aberto: false });
    },
    [callEvent]
  );

  return {
    abrirPerdaLoteView,
    fecharPerdaLoteView,
  }
};