import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
    return ({
        "@keyframes fadein": {
            "0%": {
                opacity: 0
            },
            "100%": {
                opacity: 1
            }
        },
        containerHeaderPageMain: {
            height: '10%',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.grey[100],
        },
        containerHeaderPageSub: {
            height: "100%",
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
        },
        containerHeaderButtonReload: {
            height: "40px",
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'end',
            marginLeft: '2%',
            [theme.breakpoints.down("sm")]: {
                marginLeft: '3%',
            },
        },
        iconButtonReload: {
            display: 'flex', 
            padding: '10px 0px 10px 14px', 
            fontSize: '2.5rem'
        },
        spanTitleHeaderPage: {
            color: theme.palette.common.white,
            fontSize: '1.09rem',
            fontWeight: 500,
            fontFamily: "'Montserrat', sans-serif !important"
        },
        iconHeaderPage: {
            height: '1rem',
            width: '1rem',
            cursor: 'pointer',
            marginRight: '0.72rem',
        },
        iconMoreOptions: {
            height: '1.63rem',
            width: '1.63rem',
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
        containerIconHeaderPage: {
            width: '10%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'end',
        },
        operacaoTipoCard: {
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 700,
            width: '1.5rem',
            height: '1.5rem',
            padding: '0.3rem',
            paddingTop: '0.4rem',
            borderRadius: '3px',
            alignItems: 'center',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.warning.main,
            marginRight: '16px'
        },
        inputStyle: {
            borderRadius: '10px',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down("sm")]: {
                backgroundColor: theme.palette.primary.contrastText,
            },
        },

        pesquisaContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
        },

        pesquisaSize: {
            width: '40%',
            [theme.breakpoints.down("sm")]: {
                width: '100%'
            },
        },
    })
});