import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetEstoqueByCodigoAnvisa } from "../../../../../../data/api/gestao/estoque/get-estoque";
import { useToastSaurus } from "../../../../../../services/app";
import { AutocompleteMedicamento } from "../../../../../components/autocompletes/autocomplete-medicamentos";
import { ConfirmarIcon } from "../../../../../components/icons/confirmar-icon";
import { DispensacaoAvulsaFormMedicamento, DispensacaoMedicamentoLote } from "../../models/dispensacao-avulsa-models";
import { DispensacaoMedicamentoLotesProps } from "../../models/dispensacao-avulsa-props";
import { DispensacaoMedicamentoLotesCard } from "./dispensacao-avulsa-medicamento-lotes-card";
import { useStyles } from "./dispensacao-avulsa-medicamento-lotes-style";

export const DispensacaoAvulsaMedicamentoLotes = (({ medicamentos, salvarLotesDispensacao }: DispensacaoMedicamentoLotesProps) => {
  const styles = useStyles();
  const { getEstoqueDisponivelByCodigoAnvisa } = useGetEstoqueByCodigoAnvisa();
  const { showToastPersonalizado } = useToastSaurus();

  const [medicamento, setMedicamento] = useState<DispensacaoAvulsaFormMedicamento>({} as DispensacaoAvulsaFormMedicamento);
  const [lotes, setLotes] = useState<DispensacaoMedicamentoLote[]>([]);
  const [quantidade, setQuantidade] = useState<number>(0);

  useEffect(() => {
    if (medicamentos?.length)
      setMedicamento(medicamentos[0]);
  }, [medicamentos]);

  const getLotes = useCallback(async (codigoAnvisa: string) => {
    try {
      const res = await getEstoqueDisponivelByCodigoAnvisa(codigoAnvisa);

      if (res.erro)
        throw res.erro;

      let lotes = res.resultado?.data?.lotes;

      if (!lotes?.length)
        return showToastPersonalizado('error', 'O Produto selecionado não existe no Estoque!', 3000, 'top-center');

      const lotesFormatados = res.resultado?.data?.lotes?.map((lote: any) => ({
        lote: lote.lote,
        quantidade: lote.quantidade,
        quantidadeReservada: medicamentos.filter(m => m.lote === lote.lote).length,
        dataValidade: lote.dataValidade,
        dataFabricacao: lote.dataFabricacao,
        codigoAgregador: lote.codigoAgregador,
      } as DispensacaoMedicamentoLote));

      setLotes(lotesFormatados);
    } catch (e: any) {
      if (axios.isCancel(e))
        return;
      
      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }
  }, [getEstoqueDisponivelByCodigoAnvisa, medicamentos, showToastPersonalizado]);

  const alterarLotes = (loteNome: string, quantidade: number) => {
    
    let lotesAlteracao = [...lotes];
    let loteIndice = lotesAlteracao.findIndex(l => l.lote === loteNome);
    if (loteIndice === undefined)
      return;

    lotesAlteracao[loteIndice].quantidadeReservada = quantidade;

    setLotes(lotesAlteracao);
  };

  useEffect(() => {
    if (medicamento.codigoAnvisa && !lotes?.length)
      getLotes(medicamento.codigoAnvisa);
  }, [medicamento]);

  useEffect(() => {
    if (!lotes?.length) return;

    let reservados = lotes.map(l => l.quantidadeReservada ?? 0)
      .reduce((accumulator, currentValue) =>
        accumulator + currentValue, 0);

    setQuantidade(reservados);
  }, [lotes]);

  const salvarLotes = () => {
    if (!medicamento?.medicamentoId)
      return showToastPersonalizado("error", "O Medicamento não foi preenchido!", 5000, 'top-center');

    let lotesSelecionados = lotes?.filter(l => l.quantidadeReservada);
    salvarLotesDispensacao(lotesSelecionados, medicamento);
  };

  const handleMedicamentoAutocomplete = useCallback((medicamento: any) => {
    if (!medicamento.ms && medicamento)
      return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

    setLotes([]);

    setMedicamento({
      medicamentoId: medicamento.id,
      medicamentoNome: medicamento.descricao,
      medicamentoDescritivo: medicamento.descritivo,
      medicamentoComposicao: medicamento.composicao,
      codigoAnvisa: medicamento.ms
    } as DispensacaoAvulsaFormMedicamento);
  }, [showToastPersonalizado]);

  const handleMedicamentoLabel = (): string =>
    medicamento.codigoAnvisa ? `${medicamento.medicamentoNome} - ${medicamento.codigoAnvisa}` : '';

  return (
    <>
      <div className={`flex-row w-100 ${styles.medicamentoInfoContainer}`}>
        <div className={`flex-column h-100 ${styles.medicamentoInfoStyle}`}>
          <AutocompleteMedicamento
            value={handleMedicamentoLabel()}
            label="Buscar Medicamento"
            className={styles.inputStyle}
            onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
          />
        </div>
        <div className={`flex-column ${styles.quantidadeTotalCores} ${styles.quantidadeContainer}`}>
          <Typography className={styles.quantidadeLabel}>
            Quantidade Vendida
          </Typography>
          <Typography className={styles.quantidadeValor}>
            {quantidade}
          </Typography>
        </div>
      </div>

      <div className={`flex-column h-100 w-100 ${styles.formPadding}`}>

        {!lotes.length &&
          <div className='flex-column w-100 h-100'>
            <Typography className={styles.noInfoStyle}>BUSQUE O PRODUTO DISPENSADO!</Typography>
          </div>
        }

        {medicamento?.codigoAnvisa && lotes?.length > 0 &&
          <>
            <div className={`flex-column w-100 ${styles.titleContainer}`}>
              <Typography className={`w-100 ${styles.titleStyle}`}>
                Lote e Validade
              </Typography>
              <div className={`w-100 ${styles.breakLine}`} />
            </div>


            <div className={`flex-column h-100 w-100 ${styles.innerFormContainerMedicamento}`}>
              {
                lotes?.map((lote, index) => (
                  <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                    <DispensacaoMedicamentoLotesCard lote={lote}
                      alteraLoteQtd={alterarLotes} />
                  </div>
                ))
              }
            </div>
          </>
        }

        <div className={`flex-row w-100 ${styles.buttonContainer}`}>
          <div className={classNames(styles.buttonStyle, styles.darkButton)}>
            <Button onClick={salvarLotes} variant="text" className="w-100">
              <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                <ConfirmarIcon class={styles.iconSize} />
                Salvar
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});