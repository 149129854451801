import { Button, MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
import { useCallback } from "react";
import { KeyValueModel } from "../../../../../model";
import { ETipoReceituario, ETipoReceituarioMap } from "../../../../../model/enums/enum-tipo-receiturario";
import { UFMock } from "../../../../../utils/uf-mock";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { AvancarIcon } from "../../../../components/icons";
import { useStyles } from "../dispensacao-avulsa-cadastro-style";
import { EDispensacaoAvulsaPassos } from "../models/dispensacao-avulsa-models";
import { DispensacaoAvulsaStepsProp } from "../models/dispensacao-avulsa-props";

export const DispensacaoAvulsaReceitaForm = (({ dispensacao, setDispensacao, formState, avancarStep, register }: DispensacaoAvulsaStepsProp) => {
  const styles = useStyles();
  const { errors } = formState;
  const fileTypes = ".pdf,.png,.jpg";
  const maxBytes = 1000000;

  const listaTipoReceituario = Object.values(ETipoReceituario).filter(e => !isNaN(Number(e)))
    .map((tipo: any) => new KeyValueModel(tipo, ETipoReceituarioMap.get(tipo)));

  const proximoStep = () => {
    avancarStep(EDispensacaoAvulsaPassos.Prescritor);
  }

  const setReceitaArquivo = useCallback((receitaArquivo, receitaArquivoNome) => {
    setDispensacao({ ...dispensacao, receitaArquivoNome, receitaArquivo });
  }, [dispensacao, setDispensacao]);

  const handleFile = async (event: any) => {
    if (!event.target.files.length) return;

    var fr = new FileReader();
    var receitaSelecionada = event.target.files[0];

    fr.onload = (fileLoadedEvent) =>
      setReceitaArquivo(fileLoadedEvent?.target?.result, receitaSelecionada.name)

    fr.readAsDataURL(receitaSelecionada);
  };

  return (
    <div className={`flex-column w-100 h-100`}>
      <div className={`flex-column ${styles.titleContainer}`}>
        <Typography className={styles.titleStyle} variant="h6">Receita</Typography>
        <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados da receita</Typography>
      </div>

      <div className={`flex-column ${styles.innerFormContainer}`}>
        <SelectSaurus label="Tipo de Receita"
          value={dispensacao.receitaTipo}
          className={`w-100 ${styles.inputStyle}`}
          conteudo={listaTipoReceituario}
          variant="outlined"
          size="small"
          allowSubmit
          required
          error={Boolean(errors.receitaTipo && !dispensacao?.receitaTipo)}
          helperText={
            errors.receitaTipo && !dispensacao?.receitaTipo
              ? errors.receitaTipo?.message
              : undefined
          }
          {...register('receitaTipo', {
            onChange: (e: any) => setDispensacao({ ...dispensacao, receitaTipo: Number(e.target.value) })
          })}
        />
        <div className={`flex-row w-100 ${styles.inputContainer}`}>
          <div className="w-50">
            <TextFieldSaurus className={`w-100 ${styles.inputStyle}`}
              labelCustom="Data de Lançamento"
              value={dispensacao.dataLancamento}
              tipo="DATA"
              size="small"
              variant="outlined"
              error={Boolean(errors.dataLancamento && !dispensacao?.dataLancamento)}
              helperText={
                errors.dataLancamento && !dispensacao?.dataLancamento
                  ? errors.dataLancamento?.message
                  : undefined
              }
              {...register('dataLancamento', {
                onChange: (e: any) => setDispensacao({ ...dispensacao, dataLancamento: e.target.value })
              })}
            />
          </div>
          <div className="w-50">
            <TextFieldSaurus className={`w-100 ${styles.inputStyle}`}
              labelCustom="Hora de Lançamento"
              value={dispensacao.horaLancamento}
              tipo="HORA"
              size="small"
              variant="outlined"
              error={Boolean(errors.horaLancamento && !dispensacao?.horaLancamento)}
              helperText={
                errors.horaLancamento && !dispensacao?.horaLancamento
                  ? errors.horaLancamento?.message
                  : undefined
              }
              {...register('horaLancamento', {
                onChange: (e: any) => setDispensacao({ ...dispensacao, horaLancamento: e.target.value })
              })}
            />
          </div>
        </div>
        <div className={`flex-row w-100 ${styles.inputContainer}`}>
          <div className="w-50">
            <TextFieldSaurus className={`w-100 ${styles.inputStyle}`}
              labelCustom="Data da Receita"
              value={dispensacao.dataReceita}
              tipo="DATA"
              size="small"
              variant="outlined"
              maxDate={moment(new Date().setDate(new Date().getDate())).format("YYYY-MM-DD")}
              error={Boolean(errors.dataReceita && !dispensacao?.dataReceita)}
              helperText={
                errors.dataReceita && !dispensacao?.dataReceita
                  ? errors.dataReceita?.message
                  : undefined
              }
              {...register('dataReceita', {
                onChange: (e: any) => setDispensacao({ ...dispensacao, dataReceita: e.target.value })
              })}
            />
          </div>
          <div className="w-50">
            <TextFieldSaurus className={`w-100 ${styles.inputStyle}`}
              labelCustom="Número da Receita"
              value={dispensacao.numeroReceita}
              tipo="NUMERO"
              size="small"
              variant="outlined"
              error={Boolean(errors.numeroReceita && !dispensacao?.numeroReceita)}
              helperText={
                errors.numeroReceita && !dispensacao?.numeroReceita
                  ? errors.numeroReceita?.message
                  : undefined
              }
              {...register('numeroReceita', {
                onChange: (e: any) => setDispensacao({ ...dispensacao, numeroReceita: e.target.value })
              })}
            />
          </div>
        </div>
        <div className={`flex-row w-100 ${styles.inputContainer}`}>
          <div className="w-50">
            <TextFieldSaurus className={`w-100 ${styles.inputStyle}`}
              labelCustom="UF"
              value={dispensacao.uf}
              variant="outlined"
              size="small"
              select
              defaultValue={dispensacao.uf}
              error={Boolean(errors.uf && !dispensacao?.uf)}
              helperText={
                errors.uf && !dispensacao?.uf
                  ? errors.uf?.message
                  : undefined
              }
              {...register('uf', {
                onChange: (e: any) => setDispensacao({ ...dispensacao, uf: e.target.value })
              })}
            >
              {
                UFMock.map(uf => (
                  <MenuItem className={styles.menuItemStyle} key={uf.Key} value={uf.Value}>
                    {uf.Value}
                  </MenuItem>
                ))
              }
            </TextFieldSaurus>
          </div>
          <div className="w-50">
            <TextFieldSaurus className={`w-100 ${styles.inputStyle}`}
              labelCustom="CID"
              value={dispensacao.cid}
              size="small"
              variant="outlined"
              error={Boolean(errors.cid && !dispensacao?.cid)}
              helperText={
                errors.cid && !dispensacao?.cid
                  ? errors.cid?.message
                  : undefined
              }
              {...register('cid', {
                onChange: (e: any) => setDispensacao({ ...dispensacao, cid: e.target.value })
              })}
            />
          </div>
        </div>
        <input id="receitaFile" type="file" size={maxBytes} accept={fileTypes} name="files" className={styles.fileInput} onChange={(e: any) => handleFile(e)} />
        <div className={`flex-column w-100 ${styles.uploadReceitaContainer}`}>
          <div className={`w-100 ${styles.uploadButtonContainer}`}>
            <Button className="w-100 h-100" variant="text" onClick={() =>
              document.getElementById('receitaFile')?.click()
            }>
              <div className={styles.uploadButtonLabel}>{dispensacao.receitaArquivoNome ?? "Selecione sua Receita"}</div>
            </Button>
          </div>
        </div>
      </div>

      <div className={`flex-row w-100 ${styles.buttonContainer}`}>
        <div className={`${styles.buttonStyle} ${styles.darkButton}`}>
          <Button onClick={() => proximoStep()} variant="text" className="h-100 w-100">
            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
              <AvancarIcon class={styles.iconSize} />
              Avançar
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
});