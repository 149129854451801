import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: 'relative',
    overflowY: 'hidden',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },

  formPadding: {
    flexGrow: 1,
    padding: '1rem 0.5rem',
  },

  infoContainerDispensacao: {
    padding: '0 0.25rem',
    height: '30%',
  },

  formContainer: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 250px)'
  },

  formContainerVisualizacao: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 200px)'
  },

  innerFormContainer: {
    gap: '1rem',
    padding: '1rem 0.2rem',
    height: 0,
    flexGrow: 1,
    overflow: 'auto',
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
      alignSelf: 'center'
    }
  },

  innerFormContainerMedicamento: {
    overflowY: 'auto',
    gap: '2%',
    padding: '1rem 0',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      alignSelf: 'center'
    }
  },

  buttonContainer: {
    height: '4rem',
    gap: '5%',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
      alignSelf: 'center',
      marginBottom: '2%'
    }
  },

  containerStepsForm: {
    height: '88%'
  },

  buttonStyle: {
    flexGrow: 1,
    minWidth: '47%',
    borderRadius: '10px',
    alignContent: 'center'
  },

  whiteButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`
  },

  darkButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  buttonLabel: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem'
    },
  },

  uploadButtonLabel: {
    color: theme.palette.common.black,
    fontSize: '0.8rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem'
    },
  },

  cardRightButton: {
    width: "50px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: `1px solid ${theme.palette.text.primary}`
    }
  },

  whiteLabel: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },

  darkLabel: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },

  iconSize: {
    width: '1.3rem',
    [theme.breakpoints.up('md')]: {
      width: '2rem'
    }
  },

  formHeight: {
    height: '92%',
    padding: '2%'
  },

  titleContainer: {
    color: theme.palette.common.black,
    alignItems: 'center'
  },

  titleStyle: {
    width: 'fit-content'
  },

  inputContainer: {
    gap: '3%'
  },

  inputStyle: {
    borderRadius: '10px',
    height: '3.5rem'
  },

  menuItemStyle: {
    fontSize: '0.8rem',
  },

  uploadReceitaContainer: {
    maxHeight: '2rem',
    flexGrow: 1
  },

  uploadButtonContainer: {
    flexGrow: 1,
    borderRadius: '10px',
    border: '1px dashed black',
    backgroundColor: theme.palette.common.white
  },

  checkboxContainer: {
    alignItems: 'center'
  },

  checkboxStyle: {
    color: theme.palette.primary.main
  },

  cardMedicamentoItem: {
    justifyContent: 'center',
  },

  cardContainer: {
    padding: '0 0.25rem'
  },

  infoContainer: {
    margin: '0.3rem 0.5rem',
    justifyContent: 'space-between',
    flexGrow: 1
  },

  labelFont: {
    fontSize: '0.6rem',
    color: theme.palette.grey[400],
    marginBottom: '-0.25rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    }
  },

  contentFont: {
    fontSize: '0.75rem',
    color: theme.palette.common.black,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
    }
  },

  optionsButtonContainer: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    alignSelf: 'center'
  },

  optionsButtonStyle: {
    padding: '0.15rem',
    color: theme.palette.common.black,
  },

  fileInput: {
    display: 'none'
  }
}));