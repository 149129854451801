import { isEmpty } from "lodash";
import { useCallback } from "react";
import { useApiBase } from '../../base/api-base';
import { ETypeDomain } from "../../../../config/types";

export function useGetPlanoAtual() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPlanoAtual = useCallback(
    (tokenUsuario?: string) =>
      invocarApi({
        url: `planos/plano-atual`,
        method: "GET",
        enviarTokenUsuario: tokenUsuario && !isEmpty(tokenUsuario) ? false : true,
        tokenUsuario: !isEmpty(tokenUsuario) ? `Bearer ${tokenUsuario}` : null,
        typeDomain: ETypeDomain.Login,
        apiVersion: 'v3'
      })
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPlanoAtual,
  };
}
