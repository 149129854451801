import { EmpresaAtualEndereco } from "../../../../../services/app/providers/empresa-atual-provider/empresa-atual-model";

export enum ETipoRelatorio {
    BalancoMedicamento = 1,
    RelacaoReceitaA = 2,
    RelacaoReceitaB2 = 3,
    RelacaoMensalVendas = 4,
    LivroRegistroEspecifico = 5
}

export class RelatorioMensalReceitaAModel {
    constructor(
        public farmaceutico: string,
        public mes: string,
        public ano: number,
        public incluirAdendos: string,
        public tipoProduto: string,
        public enderecoEmpresa: EmpresaAtualEndereco,
    ) { }
}

export class RelatorioMensalReceitaB2Model {
    constructor(
        public farmaceutico: string,
        public mes: string,
        public ano: number,
        public tipoProduto: string,
        public enderecoEmpresa: EmpresaAtualEndereco,
    ) { }
}

export class RelatorioMensalVendaModel {
    constructor(
        public farmaceutico: string,
        public mes: string,
        public ano: number,
        public tipoProduto: string,
        public enderecoEmpresa: EmpresaAtualEndereco,
    ) { }
}

export class RelatorioLivroRegistroEspecificoModel {
    constructor(
        public dataInicial: Date,
        public dataFinal: Date,
        public tipoRegistro: EGrupoLista,
        public enderecoEmpresa: EmpresaAtualEndereco,
    ) { }
}

export class RelatorioBalancoMedicamentoModel {
    constructor(
        public farmaceutico: string = '',
        public periodoTipoId: EPeriodoTipo = 1,
        public enderecoEmpresa: EmpresaAtualEndereco,
        public periodoTrimestralId?: ETrimestre,
        public ano?: number,
        public dataInicial?: Date,
        public dataFinal?: Date,
    ) { }
};

export enum EMeses {
    Janeiro = 1,
    Fevereiro = 2,
    Marco = 3,
    Abril = 4,
    Maio = 5,
    Junho = 6,
    Julho = 7,
    Agosto = 8,
    Setembro = 9,
    Outubro = 10,
    Novembro = 11,
    Dezembro = 12
}

export const EMesesMap = new Map<number, string>([
    [EMeses.Janeiro, 'Janeiro'],
    [EMeses.Fevereiro, 'Fevereiro'],
    [EMeses.Marco, 'Março'],
    [EMeses.Abril, 'Abril'],
    [EMeses.Maio, 'Maio'],
    [EMeses.Junho, 'Junho'],
    [EMeses.Julho, 'Julho'],
    [EMeses.Agosto, 'Agosto'],
    [EMeses.Setembro, 'Setembro'],
    [EMeses.Outubro, 'Outubro'],
    [EMeses.Novembro, 'Novembro'],
    [EMeses.Dezembro, 'Dezembro'],
]);

export enum EGrupoLista {
    Anabolizantes = 1,
    Antiretrovirais = 2,
    Antimicrobianos = 3,
    Entorpecentes = 4,
    Imunossupressores = 5,
    Psicotropicos = 6,
    Retinoicas = 7,
    ControleEspecial = 8
}

export const EGrupoListaMap = new Map<number, string>([
    [EGrupoLista.Anabolizantes, 'Anabolizantes'],
    [EGrupoLista.Antiretrovirais, 'Anti-retrovirais'],
    [EGrupoLista.Antimicrobianos, 'Antimicrobianos'],
    [EGrupoLista.Entorpecentes, 'Entorpecentes'],
    [EGrupoLista.Imunossupressores, 'Imunossupressores'],
    [EGrupoLista.Psicotropicos, 'Psicotrópicos'],
    [EGrupoLista.Retinoicas, 'Retinoicas'],
    [EGrupoLista.ControleEspecial, 'Sujeito a controle especial'],
]);

export enum EPeriodoTipo {
    Trimestral = 1,
    Anual = 2,
    Personalizado = 3,
};

export const EPeriodoTipoMap = new Map<number, string>([
    [EPeriodoTipo.Trimestral, "Trimestral"],
    [EPeriodoTipo.Anual, "Anual"],
    [EPeriodoTipo.Personalizado, "Personalizado"],
]);

export enum ETrimestre {
    PrimeiroTrimestre = 1,
    SegundoTrimestre = 2,
    TerceiroTrimestre = 3,
    QuartoTrimestre = 4,
};

export const ETrimestreMap = new Map<number, string>([
    [ETrimestre.PrimeiroTrimestre, "1º Trimestre"],
    [ETrimestre.SegundoTrimestre, "2º Trimestre"],
    [ETrimestre.TerceiroTrimestre, "3º Trimestre"],
    [ETrimestre.QuartoTrimestre, "4º Trimestre"],
]);