import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import EditIcon from "../../../../../../assets/img/edit.svg";
import LixeiraIcon from "../../../../../../assets/img/icon-lixeira.svg";
import { DefaultCard } from "../../../../../components/cards/components";
import { InventarioLote } from "../../models/inventario.models";
import { useStyles } from "../inventario-lote.styles";

interface InventarioLoteListaProps {
  inventarioLote: InventarioLote;
  removerLote: (lote?: string) => void;
  editarLote: (lote?: string) => void;
}

export const InventarioLoteCard = ({ inventarioLote, removerLote, editarLote }: InventarioLoteListaProps) => {
  const styles = useStyles();

  return (
    <DefaultCard>
      <div className={`flex-row w-100 h-100 ${styles.cardPadding}`}>

        <div className={`flex-column ${styles.infoContainer}`}
          onClick={() => editarLote(inventarioLote.lote)}>

          <div className={`flex-row w-100} ${styles.containerLabel}`} >

            <div className="flex-row w-50">
              <Typography className={styles.labelFont}>
                Lote:
              </Typography>
              <Typography className={styles.contentFont}>
                {inventarioLote.lote}
              </Typography>
            </div>

            <div className={styles.containerW45}>
              <Typography className={styles.labelFont}>
                Validade:
              </Typography>
              <Typography className={styles.contentFont}>
                {inventarioLote.dataValidadeFormatada}
              </Typography>
            </div>


          </div>


          <div className="flex-row w-100">

            <div className="flex-row w-50">
              <Typography className={styles.labelFont}>
                Quantidade:
              </Typography>
              <Typography className={styles.contentFont}>
                {inventarioLote.quantidade}
              </Typography>
            </div>

            <div className={styles.containerW45}>
              <Typography className={styles.labelFont}>
                Fabricação:
              </Typography>
              <Typography className={styles.contentFont}>
                {inventarioLote.dataFabricacaoFormatada}
              </Typography>
            </div>

          </div>

        </div>

        <div className={styles.containerButtonDelete} >
          <div>
            <Button onClick={() => editarLote(inventarioLote.lote)} variant="text" className="h-100 w-100">
              <div className={`flex-column ${styles.whiteLabel}`} >
                <img className={styles.editButtonIcon} src={EditIcon} alt="Editar" />
              </div>
            </Button>
          </div>
          <div className={classNames(styles.deleteButtonStyle, styles.redButton)}>
            <Button onClick={() => removerLote(inventarioLote.lote)} variant="text" className="h-100 w-100">
              <div className={`flex-column ${styles.deleteButtonLabel} ${styles.whiteLabel}`} >
                <img className={styles.deleteButtonIcon} src={LixeiraIcon} alt="Excluir" />
              </div>
            </Button>

          </div>
        </div>

      </div>

    </DefaultCard>
  )
}