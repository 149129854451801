import { Typography } from "@material-ui/core";
import { DefaultCard } from "../../../../components/cards/components";
import { useGerenciarMedicamentos } from "../../../../components/dialog/dialog-dispensacao/use-dialog-gerenciar-medicamento-opcoes";
import { EditarIcon } from "../../../../components/icons";
import { useStyles } from "../dispensacao-avulsa-cadastro-style";
import { DispensacaoAvulsaMedicamentoCardProp } from "../models/dispensacao-avulsa-props";


export const DispensacaoAvulsaMedicamentosCard = (({ medicamentos, excluir, editar }: DispensacaoAvulsaMedicamentoCardProp) => {
  const styles = useStyles();
  const { abrirGerenciarMedicamentos } = useGerenciarMedicamentos();

  const openModal = () => abrirGerenciarMedicamentos(excluir, editar);

  
  return (
    <DefaultCard>
      <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

        <div className={`flex-column w-100 ${styles.infoContainer}`}>
          <div className="flex-column w-100">
            <Typography className={styles.labelFont}>
              Medicamento
            </Typography>
            <Typography className={styles.contentFont}>
              {medicamentos[0]?.medicamentoNome}
            </Typography>
          </div>
          <div className="flex-row w-100">
            <div className="flex-column w-50">
              <Typography className={styles.labelFont}>
                Código
              </Typography>
              <Typography className={styles.contentFont}>
                {medicamentos[0]?.codigoAnvisa}
              </Typography>
            </div>
            <div className="flex-column w-50">
              <Typography className={styles.labelFont}>
                Itens
              </Typography>
              <Typography className={styles.contentFont}>
                {medicamentos.length}
              </Typography>
            </div>
          </div>
        </div>

        <div className={styles.optionsButtonContainer}>
          <EditarIcon tipo="BUTTON" onClick={openModal} />
        </div>
      </div>
    </DefaultCard>
  );
});