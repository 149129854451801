import { useCallback, useEffect, useState } from 'react';
import { AppEventEnum } from '../../../../model/enums/enum-app-event';
import { useEventTools } from '../../../../services/app/use-cases/events/event-tools';
import { DialogAutorizacaoOpcoesConteudo } from './dialog-autorizacao-opcoes-conteudo';

class DialogAutorizacaoOpcoesControleProps {
  constructor(
    public aberto: boolean = false,
    public operacaoId: string = "",
    public operacaoAptaAutorizar: boolean = false,
    public aprovar = () => { },
    public reprovar = () => { },
    public navegar = () => { },
  ) { }
}

export const DialogAutorizacaoOpcoesControle = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogAutorizacaoOpcoesControleProps>(
    new DialogAutorizacaoOpcoesControleProps()
  );

  const modalAlterado = useCallback(({ aberto, operacaoId, operacaoAptaAutorizar, aprovar, reprovar, navegar }: any) => {
    setModalState({ aberto, operacaoId, operacaoAptaAutorizar, aprovar, reprovar, navegar });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.AutorizacaoOpcoes, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.AutorizacaoOpcoes, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogAutorizacaoOpcoesConteudo
          aberto={modalState.aberto}
          operacaoId={modalState.operacaoId}
          operacaoAptaAutorizar={modalState.operacaoAptaAutorizar}
          reprovar={modalState.reprovar}
          aprovar={modalState.aprovar}
          navegar={modalState.navegar}
        />
      )}
    </>
  );
};