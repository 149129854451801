import { ETipoReceituario } from "../../../../../model/enums/enum-tipo-receiturario";
import { OperacaoAutorizacaoEnum } from "../../autorizacao-page/model/autorizacao.model";

export enum EDispensacaoAvulsaPassos {
  Receita = 0,
  Prescritor = 1,
  Comprador = 2,
  Medicamento = 3,
}

export const EDispensacaoAvulsaPassosMap = new Map<number, string>([
  [EDispensacaoAvulsaPassos.Receita, 'Receita'],
  [EDispensacaoAvulsaPassos.Prescritor, 'Prescritor'],
  [EDispensacaoAvulsaPassos.Comprador, 'Comprador'],
  [EDispensacaoAvulsaPassos.Medicamento, 'Medicamento'],
]);

export class DispensacaoAvulsaForm {
  medicamentos: DispensacaoAvulsaFormMedicamento[] = [];
  receitaTipo: ETipoReceituario = ETipoReceituario.ReceitaControleEspecial2Vias;
  numeroReceita: string = "";
  dataLancamento: string = "";
  horaLancamento: string = "";
  dataReceita: string = "";
  uf: string = "";
  cid: string = "";
  public receitaArquivoNome?: string;
  public receitaArquivo?: string;
  compradorPacienteMesmaPessoa: boolean = true;
  prescritor: DispensacaoAvulsaFormPrescritor = {};
  comprador: DispensacaoAvulsaFormCompradorPaciente = {};
  paciente: DispensacaoAvulsaFormCompradorPaciente = {};

  receitaIsInvalid = () => (
    !this.receitaTipo ||
    !this.dataLancamento ||
    !this.horaLancamento ||
    !this.dataReceita ||
    !this.numeroReceita ||
    !this.uf ||
    !this.cid
  )
};

export class DispensacaoAvulsaFormMedicamento {
  medicamentoId: string = "";
  medicamentoNome: string = "";
  medicamentoDescritivo: string = "";
  medicamentoComposicao: string = "";
  codigoAnvisa: string = "";
  vendaControlada: boolean = false;
  vpmc?: number | null;
  motivoIsencao: string = "";
  codigoAgregador: number | null = null;
  usoProlongado: boolean = false;
  lote?: string;
  validade?: Date;
  fabricacao?: Date;
}

export class DispensacaoAvulsaFormPrescritor {
  nome?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  municipio?: string;
  codMunicipio?: number;
  uf?: string;
  id?: string;
  especialidade?: string;
  tipoDocumento?: number;
  documento?: string;
  orgaoExpedidor?: number;
  ufOrgaoExpedidor?: string;
};

export class DispensacaoAvulsaFormCompradorPaciente {
  nome?: string;
  sexo?: number = 1;
  telefone?: string;
  dataNascimento?: Date;
  tipoDocumento?: number;
  documento?: string;
  orgaoExpedidor?: number;
  ufOrgaoExpedidorId?: number;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  uf?: string;
  id?: string;
  codMunicipio?: number;
  municipio?: string;
};

export type DispensacaoMedicamentoLote = {
  lote: string;
  quantidade: number;
  quantidadeReservada: number;
  dataValidade: Date;
  dataFabricacao: Date;
  codigoAgregador: number;
}

export class OperacaoPendenteAvulsa {
  constructor(
    dataHoraLancamento: Date,
    dispensacao: OperacaoPendenteDispensacaoAvulsa
  ) {
    this.dataHoraLancamento = dataHoraLancamento;
    this.dispensacao = dispensacao;
  }
  dataHoraLancamento: Date;
  operacaoTipo: OperacaoAutorizacaoEnum = OperacaoAutorizacaoEnum.Venda;
  dispensacao: OperacaoPendenteDispensacaoAvulsa;
}

export class OperacaoPendenteDispensacaoAvulsa {
  constructor(
    medicamentos: Array<OperacaoPendenteDispensacaoMedicamento>,
    dataHoraLancamento: Date,
    receitaArquivoNome?: string,
    receitaArquivo?: string
  ) {
    this.medicamentos = medicamentos;
    this.dataHoraLancamento = dataHoraLancamento;
    this.receitaArquivoNome = receitaArquivoNome;
    this.receitaArquivo = receitaArquivo;
  }
  medicamentos: OperacaoPendenteDispensacaoMedicamento[] = [];
  dataHoraLancamento?: Date | null;
  receitaArquivoNome?: string | null;
  receitaArquivo?: string | null;
}

export class OperacaoPendenteDispensacaoMedicamento {
  constructor(
    medicamentoId: string,
    medicamentoNome: string,
    medicamentoDescritivo: string,
    medicamentoComposicao: string,
    codigoAnvisa: string,
    vendaControlada: boolean,
    vpmc: number | null | undefined,
    motivoIsencao: string,
    lote: string | null | undefined,
    validade: Date | null | undefined,
    fabricacao: Date | null | undefined,
    codigoAgregador: number | null | undefined,
    usoProlongado: boolean,
    receita: OperacaoPendenteDispensacaoReceita
  ) {
    this.medicamentoId = medicamentoId;
    this.medicamentoNome = medicamentoNome;
    this.medicamentoDescritivo = medicamentoDescritivo;
    this.medicamentoComposicao = medicamentoComposicao;
    this.codigoAnvisa = codigoAnvisa;
    this.vendaControlada = vendaControlada;
    this.vpmc = vpmc;
    this.motivoIsencao = motivoIsencao;
    this.lote = lote;
    this.validade = validade;
    this.fabricacao = fabricacao;
    this.codigoAgregador = codigoAgregador;
    this.usoProlongado = usoProlongado;
    this.receita = receita;
  }
  medicamentoId: string = "";
  medicamentoNome: string = "";
  medicamentoDescritivo: string = "";
  medicamentoComposicao: string = "";
  codigoAnvisa: string = "";
  vendaControlada: boolean = false;
  vpmc?: number | null;
  motivoIsencao?: string | null;
  lote?: string | null;
  validade?: Date | null;
  fabricacao?: Date | null;
  codigoAgregador?: number | null;
  usoProlongado: boolean = false;
  receita: OperacaoPendenteDispensacaoReceita;
}

export class OperacaoPendenteDispensacaoReceita {

  constructor(
    numeroReceita: string,
    receitaTipo: ETipoReceituario,
    dataHoraLancamento: Date,
    dataReceita: Date,
    uf: string,
    cid: string,
    compradorPacienteMesmaPessoa: boolean,
    prescritor: OperacaoPendenteDispensacaoPrescritor,
    comprador: OperacaoPendenteDispensacaoCompradorPaciente,
    paciente: OperacaoPendenteDispensacaoCompradorPaciente,
  ) {
    this.numeroReceita = numeroReceita;
    this.receitaTipo = receitaTipo;
    this.dataHoraLancamento = dataHoraLancamento;
    this.dataReceita = dataReceita;
    this.uf = uf;
    this.cid = cid;
    this.compradorPacienteMesmaPessoa = compradorPacienteMesmaPessoa;
    this.prescritor = prescritor;
    this.comprador = comprador;
    this.paciente = paciente;
  }

  numeroReceita: string = "";
  receitaTipo: ETipoReceituario = ETipoReceituario.ReceitaControleEspecial2Vias;
  dataHoraLancamento: Date = new Date;
  dataReceita: Date = new Date;
  uf: string = "";
  cid: string = "";
  compradorPacienteMesmaPessoa: boolean = false;
  usoProlongado: boolean = false;
  prescritor: OperacaoPendenteDispensacaoPrescritor = {};
  comprador: OperacaoPendenteDispensacaoCompradorPaciente = {};
  paciente: OperacaoPendenteDispensacaoCompradorPaciente = {};
}

export class OperacaoPendenteDispensacaoPrescritor {
  constructor(
    nome: string | null | undefined,
    cep: string | null | undefined,
    logradouro: string | null | undefined,
    numero: string | null | undefined,
    complemento: string | null | undefined,
    municipio: string | null | undefined,
    codMunicipio: number | null | undefined,
    uf: string | null | undefined,
    especialidade: string | null | undefined,
    tipoDocumento: number | null | undefined,
    documento: string | null | undefined,
    orgaoExpedidor: number | null | undefined,
    ufOrgaoExpedidor: string | null | undefined,
  ) {
    this.nome = nome;
    this.cep = cep;
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.municipio = municipio;
    this.codMunicipio = codMunicipio;
    this.uf = uf;
    this.especialidade = especialidade;
    this.tipoDocumento = tipoDocumento;
    this.documento = documento;
    this.orgaoExpedidor = orgaoExpedidor;
    this.ufOrgaoExpedidor = ufOrgaoExpedidor;
  }
  nome?: string | null | undefined;
  cep?: string | null | undefined;
  logradouro?: string | null | undefined;
  numero?: string | null | undefined;
  complemento?: string | null | undefined;
  municipio?: string | null | undefined;
  codMunicipio?: number | null | undefined;
  uf?: string | null | undefined;
  especialidade?: string | null | undefined;
  tipoDocumento?: number | null | undefined;
  documento?: string | null | undefined;
  orgaoExpedidor?: number | null | undefined;
  ufOrgaoExpedidor?: string | null | undefined;
}

export class OperacaoPendenteDispensacaoCompradorPaciente {

  constructor(
    nome: string | null | undefined,
    sexo: number | null | undefined,
    telefone: string | null | undefined,
    dataNascimento: Date | null | undefined,
    tipoDocumento: number | null | undefined,
    documento: string | null | undefined,
    orgaoExpedidor: number | null | undefined,
    ufOrgaoExpedidorId: number | null | undefined,

    cep: string | null | undefined,
    logradouro: string | null | undefined,
    numero: string | null | undefined,
    complemento: string | null | undefined,
    uf: string | null | undefined,
    codMunicipio: number | null | undefined,
    municipio: string | null | undefined,
  ) {
    this.nome = nome;
    this.sexo = sexo;
    this.telefone = telefone;
    this.dataNascimento = dataNascimento;
    this.tipoDocumento = tipoDocumento;
    this.documento = documento;
    this.orgaoExpedidor = orgaoExpedidor;
    this.ufOrgaoExpedidorId = ufOrgaoExpedidorId;

    this.cep = cep;
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.uf = uf;
    this.codMunicipio = codMunicipio;
    this.municipio = municipio;
  }

  nome?: string | null | undefined;
  sexo?: number | null | undefined = 1;
  telefone?: string | null | undefined;
  dataNascimento?: Date | null | undefined;
  tipoDocumento?: number | null | undefined;
  documento?: string | null | undefined;
  orgaoExpedidor?: number | null | undefined;
  ufOrgaoExpedidorId?: number | null | undefined;

  cep?: string | null | undefined;
  logradouro?: string | null | undefined;
  numero?: string | null | undefined;
  complemento?: string | null | undefined;
  uf?: string | null | undefined;
  codMunicipio?: number | null | undefined;
  municipio?: string | null | undefined;
}

export class DispensacaoAvulsaCadastroFormErro {
  receita: DispensacaoAvulsaCadastroFormReceitaErro = new DispensacaoAvulsaCadastroFormReceitaErro();
  prescritor: boolean = false;
  comprador: boolean = false;
  paciente: boolean = false;
  medicamentos: DispensacaoAvulsaCadastroFormMedicamentoErro = new DispensacaoAvulsaCadastroFormMedicamentoErro();

  isInvalid = () => (
    this.prescritor ||
    this.comprador ||
    this.paciente ||
    this.receita.isInvalid() ||
    this.medicamentos.isInvalid()
  )
}

export class DispensacaoAvulsaCadastroFormReceitaErro {
  tipoReceita: boolean = false;
  dataLancamento: boolean = false;
  horaLancamento: boolean = false;
  dataReceita: boolean = false;
  numeroReceita: boolean = false;
  uf: boolean = false;
  cid: boolean = false;
  isInvalid = () => (
    this.tipoReceita ||
    this.dataLancamento ||
    this.horaLancamento ||
    this.dataReceita ||
    this.numeroReceita ||
    this.uf ||
    this.cid
  );
}

export class DispensacaoAvulsaCadastroFormMedicamentoErro {
  erroQuantidade: boolean = false;
  erroMedicamento: boolean = false;
  isInvalid = () => (
    this.erroQuantidade ||
    this.erroMedicamento
  )
}

export class NovoLote {
  constructor(
    public lote: string = "",
    public dataValidade: Date | undefined = undefined,
    public quantidade: number = 0
  ) { }
}

export class ControleMedicamentoLotes {
  constructor(
    public exibir: boolean = false,
    public medicamentoLotes: Array<DispensacaoAvulsaFormMedicamento> = [],
  ) { }
}