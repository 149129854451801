import { useCallback } from "react";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools";

export const useFinalizacaoOperacaoOpcoes = () => {
  const { callEvent } = useEventTools();

  const abrirFinalizacaoOperacaoOpcoes = useCallback((
    operacaoId: string,
    aprovar: (operacaoId: string) => any,
    reprovar: (operacaoId: string) => any,
    salvar: () => void
  ) => {
    callEvent(AppEventEnum.FinalizarOperacao, { aberto: true, operacaoId, aprovar, reprovar, salvar });
  },
    [callEvent]
  );

  const fecharFinalizacaoOperacaoOpcoes = useCallback(
    () => {
      callEvent(AppEventEnum.FinalizarOperacao, { aberto: false });
    },
    [callEvent]
  );

  return {
    abrirFinalizacaoOperacaoOpcoes,
    fecharFinalizacaoOperacaoOpcoes,
  }
};