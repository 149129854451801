import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  containerGrid: {
    alignContent: 'center',
    overflow: 'auto'
  },

  estiloGridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  containerTxtGridColuna: {
    paddingLeft: '0.2rem',
    paddingRight: '0.2rem',
  },

  estiloTxtGridColuna: {
    fontWeight: 'bold',
    fontSize: '0.63rem',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '10px',
  },

  tableItemsContainer: {
    overflowY: 'auto',
  },

  containerRow: {
    marginBottom: '0.3rem'
  },

  containerTxtGrid: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  estiloTxtGrid: {
    fontSize: '0.63rem',
    textAlign: 'center',
  },
}));