import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useToastSaurus } from "../../../../../../../services/app";
import { NovoLote, TransferenciaEntradaMedicamentoLoteProp } from "../../../models/transferencias.model";
import { TransferenciaMedicamentoLotesCard } from "./transferencias-medicamentos-lote-card";
import { TransferenciaMedicamentoNovoLoteCard } from "./transferencias-medicamentos-lote-novo-card";
import { useStyles } from "./transferencias-medicamentos-lote-styles";

export const TransferenciaEntradaMedicamentoLotes = (({
  lotes,
  medicamento,
  adicionarLote,
  removerLote,
  alterarLotes,
  visualizacao
}: TransferenciaEntradaMedicamentoLoteProp) => {
  const styles = useStyles();
  const { showToast } = useToastSaurus();
  const [novoLote, setNovoLote] = useState<NovoLote>(new NovoLote());

  const schema = yup
    .object()
    .shape({
      lote: yup.string().required("Lote é obrigatório!"),
      dataValidade: yup.date().required("Data de Validade é obrigatória!"),
      quantidade: yup.number().required("Quantidade é obrigatória!"),
    });

  const {
    register,
    formState,
    clearErrors
  } = useForm<NovoLote>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  const adicionar = async () => {
    var formIsValid = await schema.isValid(novoLote);

    if (!formIsValid)
      return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

    adicionarLote(novoLote);
    setNovoLote(new NovoLote());
    clearErrors();
  }

  return (
    <div className={`flex-column w-100 h-100`}>
      {medicamento?.medicamentoId?.length &&
        <div className={`flex-column w-100 ${styles.titleContainer}`}>
          <Typography className={`w-100 ${styles.titleStyle}`}>
            Lote e Validade
          </Typography>
          <div className={`w-100 ${styles.breakLine}`} />
        </div>
      }
      <div className={`flex-column h-100 ${styles.innerFormContainerMedicamento}`}>
        {
          lotes?.map((lote, index) => (
            <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
              <TransferenciaMedicamentoLotesCard
                alteraLoteQtd={alterarLotes}
                remover={removerLote}
                lote={lote}
                isEntrada={true}
                visualizacao={visualizacao}
              />
            </div>
          ))
        }
        {(medicamento?.medicamentoId?.length && !visualizacao) &&
          <div className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
            <TransferenciaMedicamentoNovoLoteCard
              novoLote={novoLote}
              setNovoLote={setNovoLote}
              adicionar={adicionar}
              formState={formState}
              register={register}
            />
          </div>
        }
      </div>
    </div>
  );
});