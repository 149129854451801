import { makeStyles } from "@material-ui/core";
import { DialogSaurusProps } from "./dialog-saurus-props";

export const useStyles = makeStyles((theme) => ({
    closeButton: {
        color: theme.palette.grey[500],
        padding: 4,
        margin: 0,
        '& svg': {
            padding: 0,
            margin: 0
        }
    },
    dialogContainer: ({ cardHeight, cardWidth }: DialogSaurusProps) => ({
        '& div.MuiPaper-root': {
            margin: 0,
            height: cardHeight ? `${cardHeight}%` : '80%',
            maxWidth: 'none'
        },
        [theme.breakpoints.down(600)]: {
            '& div.MuiDialog-container': {
                flexDirection: 'column',
                justifyContent: 'end'
            },
            '& div.MuiPaper-root': {
                width: '100%',
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
            },
        },
        [theme.breakpoints.up(600)]: {
            '& div.MuiPaper-root': {
                width: cardWidth ? `${cardWidth}%` : '80%',
                borderRadius: 30
            }
        }
    }),
    popDialogContainer: ({ cardHeight, cardWidth }: DialogSaurusProps) => ({
        '& div.MuiPaper-root': {
            margin: 0,
            width: cardWidth ? `${cardWidth}%` : '80%',
            height: cardHeight ? `${cardHeight}%` : '80%',
            borderRadius: 30,
            maxWidth: 'none'
        }
    }),
    containerTitle: ({ titulo }: DialogSaurusProps) => ({
        display: 'flex',
        justifyContent: titulo ? 'space-between' : 'flex-end',
    }),
    title: ({ colorTitulo }: DialogSaurusProps) => ({
        color: colorTitulo ? colorTitulo : '',
        fontWeight: 700
    }),
    subTitle: ({ colorSubTitulo }: DialogSaurusProps) => ({
        color: colorSubTitulo ? colorSubTitulo : '',
        fontWeight: 500
    }),
    titleCentralizado: ({ centralizarTitulo }: DialogSaurusProps) => ({
        display: 'flex',
        justifyContent: centralizarTitulo ? 'center' : 'flex-start',
        alignItems: centralizarTitulo ? 'center' : 'flex-start',
    }),
    dialogContent: ({
        removerPaddingContent = false,
        fullHeightContent = false
    }: DialogSaurusProps) => ({
        padding: removerPaddingContent ? 0 : '',
        height: fullHeightContent ? '100%' : ''
    }),
}));
