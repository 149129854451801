import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    formContainer: {
        flexGrow: 1
    },

    formPadding: {
        flexGrow: 1,
        padding: '1rem 0.5rem'
    },

    defaultContainer: {
        position: "relative",
        overflowY: "hidden",
        overflowX: "hidden",
    },

    containerMedicamento: {
        display: 'flex',
        gap: "10px",
        flexDirection: 'row',
        alignSelf: "center",
        marginTop: '0.5rem',
        height: '100%'
    },

    containerAutocompleteMedicamento: {
        width: '100%',
        height: '100%'
    },

    containerAddMedicamento: {
        display: "flex",
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        borderRadius: "8px",
        padding: "0.4rem",
        justifyContent: "center"
    },

    iconeBotaoAdicionar: {
        color: theme.palette.common.white,
        height: '1.6rem',
        width: '1.6rem',
    },

    whiteButton: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`
    },

    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },

    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },

    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },

    darkLabel: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
    },

    iconSize: {
        width: '1.3rem',
        [theme.breakpoints.up('md')]: {
            width: '2rem'
        }
    },

    titleContainer: {
        color: theme.palette.common.black,
        alignItems: 'center'
    },

    titleStyle: {
        width: 'fit-content'
    },

    inputContainer: {
        gap: '3%',
    },

    inputStyle: {
        borderRadius: '10px',
        height: '3.5rem'
    },

    innerFormContainer: {
        gap: '1.7rem',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            width: '95%',
            alignSelf: 'center'
        }
    },

    buttonContainer: {
        height: '4rem',
        gap: '5%',
        [theme.breakpoints.up('sm')]: {
            width: '95%',
            alignSelf: 'center',
            marginBottom: '2%'
        }
    },

    buttonCadastroAvulsoContainer: {
        height: '15%',
        alignItems: 'end',
        gap: '5%',
        [theme.breakpoints.up('sm')]: {
            width: '95%',
            alignSelf: 'center',
            marginBottom: '1rem'
        }
    },

    buttonStyle: {
        flexGrow: 1,
        minWidth: '47%',
        borderRadius: '10px',
        height: '4rem',
        alignContent: 'center',
    },

    addButtonStyle: {
        marginRight: '0.5rem',
        height: 'fit-content',
        width: '4rem',
        borderRadius: '10px',
        alignContent: 'center',
    },

    innerFormContainerMedicamento: {
        gap: '2%',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            alignSelf: 'center'
        }
    },

    cardMedicamentoItem: {
        justifyContent: 'center',
    },

    cardContainer: {
        padding: '0 0.25rem'
    },

    infoContainer: {
        margin: '0.3rem 0.5rem',
        justifyContent: 'space-between',
        flexGrow: 1
    },

    labelFont: {
        fontSize: '0.6rem',
        color: theme.palette.grey[400],
        marginBottom: '-0.25rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.75rem',
        }
    },

    contentFont: {
        fontSize: '0.75rem',
        color: theme.palette.common.black,
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        }
    },

    optionsButtonContainer: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingLeft: '1rem'
    },

    optionsButtonStyle: {
        padding: '0.15rem',
        color: theme.palette.common.black,
    },

    formHeight: {
        height: '92%',
        paddingTop: '2%',
        [theme.breakpoints.down('sm')]: {
            padding: '4%'
        }
    },

    wizardHeight: {
        marginTop: '0.5%',
        height: '15%'
    },

    medsHeight: {
        height: '60vh',
        [theme.breakpoints.down('sm')]: {
            height: '65vh',
        }
    },

    containerSteps: {
        height: '12%'
    },

    containerStepsForm: {
        height: '88%'
    },

    containerMedInfoTitle: {
        display: 'flex',
        flexDirection: 'column',
        height: '15%',
        color: theme.palette.common.black,
        alignItems: 'center'
    },

    containerScrollMed: {
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: '80%',
            alignSelf: 'center'
        }
    },

    cardMedicamentoAlign: {
        width: '95%',
        alignSelf: 'center'
    },

    infoContainerPerdaAvulsa: {
        padding: '0 0.25rem',
        height: '30%',
    },

    itensDiv: {
        marginTop: "5%"
    },

    adicionadoButtonStyle: {
        marginRight: '0.5rem',
        height: 'fit-content',
        width: 'fit-content',
        borderRadius: '10px'
    },

    addButtonIcon: {
        height: '3vh',
        width: '3vh',
        margin: '0.3vh 0'
    },

    divCardMed: {
        height: '100%',
        alignItems: 'center',
    },

    scrollMeds: {
        overflowY: 'auto',
        scrollbarWidth: 'none',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            alignSelf: 'center'
        }
    },

    buttonCadastroAvulsoAlign: {
        width: '100%',
        height: '4rem',
    },

    perdaAvulsaMedicamentosForm: {
        height: '60vh'
    },
}));