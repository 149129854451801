import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Fade } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import MedicamentoIcon from "../../../../assets/img/icon-medicamento.svg";
import MovimentacaoIcon from "../../../../assets/img/icon-movimentacao.svg";
import { useCadastrarOperacaoPendentePerda } from "../../../../data/api/gestao/perdas/perdas";
import { useToastSaurus } from "../../../../services/app";
import { CircularLoading } from "../../../components";
import { ButtonModalHeader } from "../../../components/controles/buttons/button-modal-header";
import { AvancarIcon, SalvarIcon, VoltarIcon } from "../../../components/icons";
import { ModalHeader } from "../../../components/modal-header";
import { Wizard } from "../../../components/wizard/wizard-component";
import { WizardStep } from "../../../components/wizard/wizard-model";
import { PerdaAvulsaMovimentacaoForm } from "./componentes/perda-avulsa-movimentacao-form";
import { PerdaAvulsaNovoMedicamentoForm } from "./componentes/perda-avulsa-novo-medicamento-form";
import { EPerdaPassos, MedicamentoPerda, PerdaAvulsaCadastroModel } from "./models/perda-avulsa-models";
import { useStyles } from "./perda-avulsa-cadastro-style";

export const PerdaAvulsaCadastroPage = ((props: any) => {
    const styles = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const [passoPerda, setPassoPerda] = useState(0);
    const [perda, setPerda] = useState(new PerdaAvulsaCadastroModel());
    const { showToast, showToastPersonalizado } = useToastSaurus();
    const { cadastrarOperacaoPendentePerda } = useCadastrarOperacaoPendentePerda();

    const schema = yup
        .object()
        .shape({
            data: yup.string().required("Data é obrigatória!"),
            hora: yup.string().required("Hora é obrigatória!"),
        });

    const {
        formState,
        register,
        setError
    } = useForm<PerdaAvulsaCadastroModel>({
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
        resolver: yupResolver(schema)
    });

    const steps: WizardStep[] = [
        {
            label: 'Dados da Movimentação',
            icon: <img src={MovimentacaoIcon} alt="" />
        },
        {
            label: 'Medicamentos',
            icon: <img src={MedicamentoIcon} alt="" />
        }
    ];

    const passoFormRender = () => {
        return (passoPerda === EPerdaPassos.Movimentacao && perda) ? (
            <PerdaAvulsaMovimentacaoForm
                formState={formState}
                register={register}
                perda={perda}
                setPerda={setPerda}
            />
        ) : (
            <div className={styles.perdaAvulsaMedicamentosForm}>
                <PerdaAvulsaNovoMedicamentoForm
                    formState={formState}
                    register={register}
                    perda={perda}
                    setPerda={setPerda}
                />
            </div>
        )
    };

    const avancarStep = async (step: number) => {

        if (await validarPerda()) {
            setPassoPerda(step);
            return;
        }

        return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

    }

    const validarPerda = async () => {

        try {

            await schema.validate({
                data: perda.data,
                hora: perda.hora
            }, { abortEarly: false });

            return true;

        } catch (error) {

            const validationError = error as yup.ValidationError;

            validationError?.inner?.forEach(err => {
                const path = err.path?.toString();

                if (path && Object.prototype.hasOwnProperty.call(schema.fields, path)) {
                    setErrorField(path, err.message);
                }

            });

            return false;
        }
    }

    function setErrorField(path: string, errorMensage: string) {

        switch (path) {

            case 'data':
                setError('data', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            case 'hora':
                setError('hora', {
                    type: 'manual',
                    message: errorMensage,
                });
                break;

            default:
                break;
        }

    }

    const salvar = async () => {
        var formIsValid = await schema.isValid(perda);

        if (!formIsValid || !perda.medicamentos?.length)
            return showToast("error", "Campos obrigatórios não preenchidos!", 5000, 'top-center');

        var novaListaMedicamentos = Array<MedicamentoPerda>();
        perda.medicamentos.forEach(medicamento => {
            for (let index = 0; index < medicamento.quantidade; index++) {
                novaListaMedicamentos.push(medicamento);
            }
        });

        setIsLoading(true);
        perda.dataHoraLancamento = new Date(`${perda.data}T${perda.hora}:00.000Z`);

        const operacaoSalva = await cadastrarOperacaoPendentePerda(perda);

        if (operacaoSalva.erro) {
            setIsLoading(false);
            return showToast("error", `${operacaoSalva.erro}`, 5000, 'top-center');
        }

        showToastPersonalizado('success', 'Perda cadastrada com sucesso, dado salvo na listagem de Operação Pendente', 3000, 'top-center');

        setIsLoading(false);
        props.fecharModal();
    }

    return (
        <>
            <Fade in mountOnEnter unmountOnExit>
                <div className={`flex-column w-100 h-100 ${styles.defaultContainer}`} >

                    <ModalHeader
                        title={'Perdas'}
                        leftArea={
                            <ButtonModalHeader
                                tooltip="Voltar"
                                icon={<VoltarIcon tipo="MODAL_HEADER" />}
                                onClick={() => { props.fecharModal() }}
                            />
                        }
                    />

                    {isLoading ? <CircularLoading tipo="FULLSIZED" /> :
                        <>
                            <div className={`flex-column w-100 ${styles.formPadding, styles.formHeight}`}>
                                <div className={`${styles.wizardHeight}`}>
                                    <Wizard steps={steps} activeStep={passoPerda} setActiveStep={step => avancarStep(Number(step))} />
                                </div>

                                <div className={`flex-column ${styles.formContainer, styles.medsHeight}`}>
                                    {passoFormRender()}
                                </div>

                                <div className={`flex-row w-100 ${styles.buttonCadastroAvulsoContainer}`}>
                                    {passoPerda === EPerdaPassos.Movimentacao ?
                                        <div className={styles.buttonCadastroAvulsoAlign}>
                                            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
                                                <Button onClick={() => avancarStep(passoPerda + 1)} variant="text" className="h-100 w-100">
                                                    <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                                        <AvancarIcon class={styles.iconSize} />
                                                        Avançar
                                                    </div>
                                                </Button>
                                            </div>
                                        </div> :
                                        <div className={styles.buttonCadastroAvulsoAlign}>
                                            <div className={classNames(styles.buttonStyle, styles.whiteButton)}>
                                                <Button onClick={() => salvar()} variant="text" className="h-100 w-100">
                                                    <div className={`flex-row ${styles.buttonLabel} ${styles.darkLabel}`}>
                                                        <SalvarIcon class={styles.iconSize} />
                                                        Salvar
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Fade>
        </>
    );
});