import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useGetConfigNfceExists } from '../../../../data/api/gestao/empresa/get-config-emissor-exists';
import { IdentidadeAutenticarEmailProps, usePostIdentidadeAutenticarEmail } from '../../../../data/api/gestao/identidade/post-autenticar-email';
import { IdentidadeAutenticarTelefoneProps, usePostIdentidadeAutenticarTelefone } from '../../../../data/api/gestao/identidade/post-autenticar-telefone';
import { IdentidadeAutenticarUsernameProps, usePostIdentidadeAutenticarUsername } from '../../../../data/api/gestao/identidade/post-autenticar-username';
import { usePostIdentidadeRefreshToken } from '../../../../data/api/gestao/identidade/post-refresh-token';
import { useGetPerfilById } from '../../../../data/api/gestao/perfil/get-perfil-by-id';
import { useGetPlanoAtual } from '../../../../data/api/gestao/plano/get-plano-atual';
import { useGetWhitelabel } from '../../../../data/api/gestao/white-label/get-white-label';
import { RetornoApiModel } from '../../../../model';
import { PerfilModel } from '../../../../model/api/gestao/master/perfil';
import { TokenSngpcModel } from '../../../../model/api/gestao/master/token-sngpc';
import { TokenSngpcEmpresaModel } from '../../../../model/api/gestao/master/token-sngpc-empresa';
import { PlanoAtualModel } from '../../../../model/api/gestao/plano/plano-atual-model';
import { MultiContratoModel } from '../../../../model/api/multi-contrato/multi-contrato';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { SNGPC } from '../../../../utils/plano-utils';
import { useSngpcToken } from "../../../app";
import { SngpcStorageKeys, useSngpcStorage } from '../../use-cases';

interface SessaoAtualContextValue {
  usuario?: TokenSngpcModel;
  plano?: PlanoAtualModel | undefined;
  getEmpresaSelecionada: () => TokenSngpcEmpresaModel | undefined;
  getEmpresasVinculadas: () => Array<TokenSngpcEmpresaModel>;
  getPerfil: () => Array<PerfilModel>;
  carregando: boolean;
  logar: (
    usuario: string,
    senha: string,
    contratoId: string,
    manterConectado: boolean,
  ) => Promise<string>;
  deslogar: (usuarioId: string) => void;
  setarUser: (token: string, manterConectado: boolean) => Promise<void>;
  carregarDadosUsuario: (user: TokenSngpcModel) => Promise<void>;
  refreshUser: (refreshToken: string) => Promise<boolean>;
  refreshWhitelabel: (user: any) => Promise<void>;
  sessaoExpirada: () => boolean;
  converterToken: (token: string) => TokenSngpcModel | undefined;
  validaUsuarioConectado: () => boolean;
  termosDeUsoAtivo: () => boolean;
  selecionarEmpresa: (empresaId: string) => Promise<void>;
  getIsEmpresaFiscal: () => boolean;
  setIsEmpresaFiscal: (fiscalEnabled: boolean) => void;
  verificarSeEmpresaTemNFCe: (empresaId: string) => Promise<void>;
  refreshPlanoUsuario: () => Promise<void>;
  getUsuarioAtual: () => TokenSngpcModel | undefined;
}

const SessaoAtualContext = React.createContext<SessaoAtualContextValue>({
  usuario: undefined,
  carregando: false,
  getEmpresaSelecionada: () => undefined,
  getEmpresasVinculadas: () => new Array<TokenSngpcEmpresaModel>(),
  getPerfil: () => new Array<PerfilModel>(),
  sessaoExpirada: () => false,
  logar: (usuario: string, senha: string, contratoId: string, manterConectado: boolean) => {
    return new Promise<string>(() => true);
  },
  converterToken: (token: string) => {
    return undefined;
  },
  setarUser: async () => { },
  deslogar: (usuarioId: string) => { },
  carregarDadosUsuario: (user: TokenSngpcModel) => {
    return new Promise<void>(() => true);
  },
  refreshUser: (refreshToken: string) => {
    return new Promise<boolean>(() => true);
  },
  refreshWhitelabel: (user: any) => {
    return new Promise<void>(() => true);
  },
  termosDeUsoAtivo: () => false,

  selecionarEmpresa: (empresaId: string) => new Promise<void>(() => { }),
  validaUsuarioConectado: () => false,
  refreshPlanoUsuario: () => {
    return new Promise<void>(() => true);
  },
  getIsEmpresaFiscal: () => {
    return false;
  },
  setIsEmpresaFiscal: (fiscalEnabled: boolean) => {
    return;
  },
  verificarSeEmpresaTemNFCe: (empresaId: string) => {
    return new Promise<void>(() => true);
  },
  getUsuarioAtual: () => {
    return undefined;
  },
});

export interface SessaoAtualProviderProps {
  children: React.ReactNode;
}

export const useSessaoAtual = () => React.useContext(SessaoAtualContext);

export const SessaoAtualProvider = ({ children }: SessaoAtualProviderProps) => {
  // PROVIDERS
  const {
    getTokenFromStorage,
    convertToken,
    getConnectedUsers,
    persistTermosDeUso,
    getEmpresaAtual,
    persistEmpresaSelecionada,
    getTermosDeUso,
    getPerfilPermissoes,
    persistPerfilPermissoes,
    isTokenValid,
    persistToken,
    addConnectedUser,
    removeConnectedUser,
    getPlanoUsuario,
    persistPlanoUsuario,
  } = useSngpcToken();
  const { delRegistro, setRegistro, getRegistro } = useSngpcStorage();
  // STATES E REFS
  const [loadingManual, setLoadingManual] = useState(false);
  const [usuario, setarUsuario] = React.useState(getTokenFromStorage());
  const [plano, setPlano] = React.useState(getPlanoUsuario());

  // CHAMADAS API
  const { getPerfilById, carregando: carregarPerfilById } = useGetPerfilById();
  const { postIdentidadeRefreshToken, carregando: carregandoRefreshToken } =
    usePostIdentidadeRefreshToken();
  const { postIdentidadeAutenticarTelefone, carregando: carregandoApiAutenticarTelefone } =
    usePostIdentidadeAutenticarTelefone();
  const { postIdentidadeAutenticarEmail, carregando: carregandoApiAutenticarEmail } =
    usePostIdentidadeAutenticarEmail();
  const { postIdentidadeAutenticarUsername, carregando: carregandoApiAutenticarUsername } =
    usePostIdentidadeAutenticarUsername();
  const { getPlanoAtual, carregando: carregandoPlanoAtual } =
    useGetPlanoAtual();
  const { getConfigNfceExists, carregando: carregandoEmissorExists } =
    useGetConfigNfceExists();
  const { getWhitelabel, carregando: carregandoWhitelabel } =
    useGetWhitelabel();
  // AUX
  const loading =
    carregandoRefreshToken ||
    carregandoApiAutenticarTelefone ||
    carregandoApiAutenticarEmail ||
    carregandoApiAutenticarUsername ||
    carregarPerfilById ||
    loadingManual ||
    carregandoEmissorExists ||
    carregandoWhitelabel ||
    carregandoPlanoAtual;

  const sessaoExpirada = React.useCallback((): boolean => {
    return !isTokenValid(usuario);
  }, [isTokenValid, usuario]
  );

  const converterToken = React.useCallback(
    (token: string): TokenSngpcModel | undefined => {
      return convertToken(token);
    },
    [convertToken],
  );

  const termosDeUsoAtivo = React.useCallback((): boolean => {
    return getTermosDeUso();
  }, [getTermosDeUso]
  );

  const limparVariaveis = useCallback(() => {
    persistPlanoUsuario(undefined);
    persistTermosDeUso(undefined, undefined);
    setPlano(undefined);
    persistPerfilPermissoes(undefined);
    delRegistro(SngpcStorageKeys.Whitelabel, false);
    delRegistro(SngpcStorageKeys.IsEmpresaFiscal, false);
    delRegistro(SngpcStorageKeys.Plano, false);

    sessionStorage.clear();
  }, [delRegistro, persistPerfilPermissoes, persistPlanoUsuario, persistTermosDeUso]
  );

  const deslogar = React.useCallback(
    (usuarioId: string) => {
      removeConnectedUser(usuarioId || '');
      persistToken(undefined);
      setarUsuario(undefined);
      limparVariaveis();
    },
    [removeConnectedUser, persistToken, limparVariaveis],
  );

  const refreshPlanoUsuario = useCallback(async (): Promise<void> => {
    //Pega o plano atual
    try {
      const retPlano = await getPlanoAtual();
      if (retPlano.erro) {
        throw retPlano.erro;
      }

      if (retPlano.statusCode === 204) {
        persistPlanoUsuario(new PlanoAtualModel());
        setPlano(new PlanoAtualModel());
      } else {
        persistPlanoUsuario(retPlano.resultado?.data as PlanoAtualModel);
        setPlano(retPlano.resultado?.data as PlanoAtualModel);
      }
    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      throw e;
    }
  }, [getPlanoAtual, persistPlanoUsuario, setPlano]
  );

  const validaUsuarioConectado = useCallback(() => {
    const users = getConnectedUsers();
    return users.filter((x) => x.usuarioId === usuario?.usuarioId).length > 0;
  }, [getConnectedUsers, usuario?.usuarioId]
  );

  const carregarDadosUsuario = React.useCallback(
    async (user: TokenSngpcModel) => {
      try {
        setLoadingManual(true);
        const perfilIds = Array<string>();
        //PREENCHO OS PERFIS DELE (DISTINCT)
        if (user?.empresa) {
          for (let i = 0; i < user?.empresa.length; i++) {
            if (perfilIds.indexOf(user?.empresa[i]?.Perfil?.Id) === -1) {
              perfilIds.push(user?.empresa[i]?.Perfil?.Id);
            }
          }
        }

        const perfis = new Array<PerfilModel>();
        //FACO A BUSCA NA API PELOS PERFIS DO USUARIO
        for (let i = 0; i < perfilIds.length; i++) {
          const retApiPerfil = await getPerfilById(perfilIds[i]);
          if (retApiPerfil.erro) {
            throw retApiPerfil.erro;
          }
          perfis.push(retApiPerfil.resultado?.data);
        }

        //SALVA OS PERFIS!
        persistPerfilPermissoes(perfis);

        setLoadingManual(false);
      } catch (e: any) {
        if (axios.isCancel(e))
          return;
        
        throw e;
      } finally {
      }
    },
    [persistPerfilPermissoes, getPerfilById],
  );

  const refreshWhitelabel = React.useCallback(async (user: any) => {
    const storageWhitelabel = getRegistro(SngpcStorageKeys.Whitelabel, false)
    if (isEmpty(storageWhitelabel) || storageWhitelabel.contratoId !== user.empresa[0].ContratoId) {
      const res = await getWhitelabel();

      if (res.erro) throw res.erro

      if (res.statusCode === 200) {
        const wl = res.resultado?.data;

        setRegistro(SngpcStorageKeys.Whitelabel, {
          ...wl,
          contratoId: user.empresa[0].ContratoId || ''
        }, false)
      }
    }

  }, [getRegistro, getWhitelabel, setRegistro]);

  const refreshUser = React.useCallback(
    async (refreshToken: string): Promise<boolean> => {
      try {
        const retRefresh = await postIdentidadeRefreshToken(refreshToken);

        if (retRefresh?.resultado?.data?.accessToken) {
          const newuser = convertToken(
            retRefresh?.resultado?.data?.accessToken,
          );
          if (newuser) {
            if (validaUsuarioConectado()) {
              addConnectedUser(newuser);
            }

            persistToken(newuser);
            await carregarDadosUsuario(newuser);
            await refreshWhitelabel(newuser);
            setarUsuario(newuser);
            return true;
          }
        }
      } catch (e: any) {
        persistToken(undefined);
        persistPerfilPermissoes(undefined);
        persistPlanoUsuario(undefined);
        persistTermosDeUso(undefined, undefined);
        setPlano(undefined);
        setarUsuario(undefined);
        throw e;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setIsEmpresaFiscal = React.useCallback(
    (fiscalEnabled: boolean) => {
      setRegistro(SngpcStorageKeys.IsEmpresaFiscal, fiscalEnabled, false);
    },
    [setRegistro],
  );

  const vendaNFCE = React.useCallback(
    async (): Promise<boolean> => {
      const emissor = await getConfigNfceExists();
      if (emissor.erro) {
        new Error('Erro ao consultar a situação de NFC-e.');
        return false;
      }

      if ((emissor.resultado?.data || false) === true) {
        return true;
      }

      return false;
    },
    [getConfigNfceExists],
  );

  const getEmpresaSelecionada = useCallback(():
    | TokenSngpcEmpresaModel
    | undefined => {
    const emp = usuario?.empresa.filter((x) => x.Id === getEmpresaAtual());
    if (emp === null || emp?.length === 0) return undefined;
    if (emp) return emp[0];

    return undefined;
  },
    [getEmpresaAtual, usuario?.empresa]
  );

  const getEmpresasVinculadas = useCallback((): Array<TokenSngpcEmpresaModel> =>
    usuario?.empresa ?? [],
    [usuario?.empresa]);

  const verificarSeEmpresaTemNFCe = useCallback(
    async () => {
      const nfce = await vendaNFCE();
      setIsEmpresaFiscal(nfce);
    },
    [setIsEmpresaFiscal, vendaNFCE],
  );

  const selecionarEmpresa = useCallback(
    async (empresaId: string): Promise<void> => {
      persistEmpresaSelecionada(empresaId);
    },
    [persistEmpresaSelecionada],
  );

  const getIsEmpresaFiscal = React.useCallback(() => {
    const isNFCE = getRegistro(SngpcStorageKeys.IsEmpresaFiscal, false);
    return isNFCE;
  }, [getRegistro]
  );

  const setarUser = useCallback(async (token: string, manterConectado: boolean) => {
    const user = convertToken(token);

    const empresaAtual = getRegistro(SngpcStorageKeys.EmpresaAtual, false)
    const documentoContrato = getRegistro(SngpcStorageKeys.DocumentoContrato) as string | undefined

    let mesmaEmpresa = user?.empresa.find(empresas => empresas.Id === empresaAtual)?.Id

    if (mesmaEmpresa !== empresaAtual) {
      delRegistro(SngpcStorageKeys.EmpresaAtual, false)
      delRegistro(SngpcStorageKeys.ProcessosSetores, false)
    }

    if (!isTokenValid(user))
      throw new Error(
        'O usuário informado não é válido ou expirou seu acesso. (InvalidToken)',
      );

    //PERSISTE NO STORAGE
    persistToken(user);

    if (manterConectado) {
      if (user !== undefined) addConnectedUser(user);
    }

    if (user) {
      await carregarDadosUsuario(user);
      await refreshWhitelabel(user);
    }

    //SETAMOS O USUARIO
    setarUsuario(user);

    if (user?.empresa) {
      await selecionarEmpresa(user?.empresa[0].Id);
      let empresasContratadas = user.empresa.filter(x => x.Documento === documentoContrato);
      if (empresasContratadas?.length > 0)
        await selecionarEmpresa(empresasContratadas[0].Id);
      delRegistro(SngpcStorageKeys.DocumentoContrato);
    }
  },
    [convertToken, getRegistro, isTokenValid, persistToken, delRegistro, carregarDadosUsuario, addConnectedUser, selecionarEmpresa],
  );

  function removerMascaraTelefone(telefone: string) {
    var telefoneSemMascara = telefone.replace(/[^\w\s]/gi, '');
    return telefoneSemMascara.replace(" ", "");
  }

  function validarTelefone(telefone: string) {
    var telefoneSemMascara = removerMascaraTelefone(telefone);
    var telefoneNumber = Number(telefoneSemMascara);
    return !isNaN(telefoneNumber);
  }

  function getUsuarioAtual() {
    return usuario;
  }

  const logar = React.useCallback(
    async (
      usuario: string,
      senha: string,
      contratoId: string,
      manterConectado: boolean,
    ): Promise<string | any> => {
      try {
        setLoadingManual(true);
        var retApi: RetornoApiModel;
        var loginValidado = false;

        if (usuario.match(/@/)) {
          retApi = await postIdentidadeAutenticarEmail(
            new IdentidadeAutenticarEmailProps(usuario, senha, contratoId),
          );
          loginValidado = true;
        }

        if (validarTelefone(usuario)) {
          retApi = await postIdentidadeAutenticarTelefone(
            new IdentidadeAutenticarTelefoneProps(usuario, senha, contratoId),
          );
          loginValidado = true;
        }

        if (!loginValidado) {
          retApi = await postIdentidadeAutenticarUsername(
            new IdentidadeAutenticarUsernameProps(usuario, senha, contratoId),
          );
        }

        //Usuario não confirmado está sem autorização
        if (retApi!.statusCode === 401) {
          throw new ApiError(retApi!.statusCode, retApi!.erro.message);
        }

        if (retApi!.statusCode === 300) {
          return retApi!.resultado?.data as MultiContratoModel
        }

        if (retApi!.statusCode === 402) {
          return {
            status: retApi!.statusCode,
            data: retApi!.resultado?.data
          }
        }

        if (retApi!.erro) {
          throw retApi!.erro;
        }

        await setarUser(retApi!?.resultado?.data?.accessToken, manterConectado);
        const retPlano = await getPlanoAtual(retApi!.resultado?.data.accessToken)

        if (retPlano.erro) {
          throw retPlano.erro
        }

        const planoModel = retPlano.resultado?.data as PlanoAtualModel

        if (!SNGPC(planoModel.plano)) {
          const token = undefined;
          persistToken(token);
          throw new Error('Seu plano não tem acesso a este sistema.')
        }

        return retApi!?.resultado?.data?.accessToken;
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [getPlanoAtual, postIdentidadeAutenticarEmail, postIdentidadeAutenticarTelefone, postIdentidadeAutenticarUsername, setarUser]
  );

  return (
    <SessaoAtualContext.Provider
      value={{
        usuario,
        plano,
        getEmpresaSelecionada,
        getEmpresasVinculadas,
        getPerfil: getPerfilPermissoes,
        carregando: loading,
        deslogar,
        setarUser,
        carregarDadosUsuario,
        refreshUser,
        refreshWhitelabel,
        sessaoExpirada,
        logar,
        converterToken,
        validaUsuarioConectado,
        termosDeUsoAtivo,
        selecionarEmpresa,
        getIsEmpresaFiscal,
        setIsEmpresaFiscal,
        verificarSeEmpresaTemNFCe,
        refreshPlanoUsuario,
        getUsuarioAtual
      }}
    >
      {children}
    </SessaoAtualContext.Provider>
  );
};