import { useCallback } from "react";

export enum SngpcStorageKeys {
  TokenTouchPharma,
  TermosDeUso,
  Plano,
  EmpresaAtual,
  EmpresaAnterior,
  PerfisDoUsuario,
  UsuariosConectados,
  ConfiguracoesMenu,
  IsEmpresaFiscal,
  UltimoLogin,
  ProcessosSetores,
  Setores,
  Produto,
  Pedido,
  PendenciaContrato,
  DocumentoContrato,
  SelecaoModulo,
  PedidoConfirmarEntrega,
  ConfiguracaoMesaEComanda,
  Whitelabel,
  PaletaWhitelabel,
}

export function useSngpcStorage() {

  const getRegistro = useCallback(
    (key: SngpcStorageKeys, session: boolean = true) => {
      const token = (session
        ? sessionStorage.getItem(SngpcStorageKeys[key].toString())
        : localStorage.getItem(SngpcStorageKeys[key].toString())) || "";

      if (token)
        return JSON.parse(token)

      return "";
    },
    []
  );

  const exists = useCallback((key: SngpcStorageKeys, session: boolean = true) => {
    if (session) return Boolean(sessionStorage.getItem(SngpcStorageKeys[key].toString()));
    else return Boolean(localStorage.getItem(SngpcStorageKeys[key].toString()));
  }, []);

  const setRegistro = useCallback((key: SngpcStorageKeys, obj: any, session: boolean = true) => {
    if (session) {
      sessionStorage.setItem(SngpcStorageKeys[key].toString(), JSON.stringify(obj));
    } else {
      localStorage.setItem(SngpcStorageKeys[key].toString(), JSON.stringify(obj));
    }
  }, []);

  const delRegistro = useCallback((key: SngpcStorageKeys, session: boolean = true) => {
    if (session) {
      sessionStorage.removeItem(SngpcStorageKeys[key].toString());
    } else {
      localStorage.removeItem(SngpcStorageKeys[key].toString());
    }
  }, []);
  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists,
  };
}
