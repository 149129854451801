import { Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { DefaultCard, useDefaultCardStyles } from "../../../../../components/cards/components";
import { MovimentacaoPerdaResponse } from "../../models/envio-movimentacao.model";
import { useStyles } from "../envio-movimentacao-listagem-styles";

interface MovimentacaoPerdaListaProps {
  listaPerda: MovimentacaoPerdaResponse[];
}

export const MovimentacaoPerdaLista: React.FC<MovimentacaoPerdaListaProps> = ({ listaPerda }) => {

  const styles = useStyles();
  const classesCard = useDefaultCardStyles();

  return (
    <div>
      {
        listaPerda?.map((row, index) => (

          <div className={`flex-column w-100 ${styles.cardMovimentacaoItem}`} key={index}>

            <DefaultCard className={styles.cardContainer}>

              <div className={`flex-row w-100 h-100`}>
                <div className={classNames(classesCard.tagStatus, styles.statusColor)} />

                <div className={`flex-row w-100 ${classesCard.infoContainer}`}>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Data:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {moment(row.data).format("DD/MM/YYYY")}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Quantidade:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {row.quantidade}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Medicamento:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {row.medicamento}
                    </Typography>
                  </div>

                  <div className={`flex-column ${classesCard.inputMinWidth}`}>
                    <Typography className={classesCard.labelFont}>
                      Motivo:
                    </Typography>
                    <Typography className={classesCard.contentFont}>
                      {row.motivo}
                    </Typography>
                  </div>

                </div>
              </div>

            </DefaultCard>

          </div>
        ))
      }
    </div>
  )
}