import { useCallback } from "react";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools";

export const useInventarioMedicamentoOpcoes = () => {
  const { callEvent } = useEventTools();

  const abrirInventarioMedicamentoOpcoes = useCallback((
    navegar: () => void,
    excluir: () => void
  ) => {
    callEvent(AppEventEnum.InventarioMedicamentoOpcoes, { aberto: true, navegar, excluir });
  },
    [callEvent]
  );

  const fecharInventarioMedicamentoOpcoes = useCallback(
    () => {
      callEvent(AppEventEnum.InventarioMedicamentoOpcoes, { aberto: false });
    },
    [callEvent]
  );

  return {
    abrirInventarioMedicamentoOpcoes,
    fecharInventarioMedicamentoOpcoes,
  }
};