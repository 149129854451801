import { Button, Fade, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useGetComprasPaginado } from "../../../../../data/api/gestao/compras/compras";
import { useToastSaurus } from "../../../../../services/app";
import { CircularLoading } from "../../../../components";
import { DefaultCard, useDefaultCardStyles } from "../../../../components/cards/components";
import { PageHeader } from '../../../../components/headers/header-page/header-page';
import { ProcurarIcon } from "../../../../components/icons";
import { NovoIcon } from "../../../../components/icons/novo-icon";
import { Paginacao } from '../../../../components/paginacao';
import { useStyles } from './compras-listagem-styles';
import { CompraModal } from "./modals/modal-compra/compra-modal";
import { CompraResponse } from "./models/compras-cadastro.model";

export const CompraListPage = () => {
	const classes = useStyles();
	const classesCard = useDefaultCardStyles();
	const pageItems = 10;
	const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<CompraResponse>() });
	const { getComprasPaginado, carregando: carregandoListaCompra } = useGetComprasPaginado();
	const { showToast } = useToastSaurus();

	const [timer, setTimer] = useState({} as NodeJS.Timeout);

	const [modalOpen, setModalOpen] = useState(false);
	const [compraModal, setCompraModal] = useState(null as ReactNode);

	const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<CompraResponse>) => {
		setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
	}, []);

	const search = useCallback(
		async (newPage: number, filter: string = "") => {
			try {
				const res = await getComprasPaginado(filter, newPage, pageItems);

				if (res.erro)
					throw res.erro;

				const list = res.resultado?.data.list;
				const pageIndex = res.resultado?.data.pageIndex;
				const totalPages = res.resultado?.data.totalPages;
				const totalResults = res.resultado?.data.totalResults;

				fillResult(
					pageIndex,
					totalPages,
					totalResults,
					list
				);

			} catch (e: any) {
				if (axios.isCancel(e))
					return;
				
				console.error("error", e.message);
				showToast("error", 'O processo resultou em um erro interno da API');
			}
		},
		[fillResult]
	);

	const pageChanged = useCallback(
		(newPage: number) => {
			search(newPage);
		},
		[search]
	);

	useEffect(() => {
		search(1);
	}, [search]);

	const visualizarCompra = (compraId: string, dataHoraLancamento: Date) => {
		compraRender(compraId, dataHoraLancamento);
	}

	const compraRender = (id?: string, data?: Date) => {
		setModalOpen(true);

		return (
			setCompraModal(
				<CompraModal
					openned={true}
					setModalOpen={setModalOpen}
					compraId={id}
					dataHoraLancamento={data}
				/>
			)
		);
	}

	const isLoading = () => carregandoListaCompra;

	const filtroListagem = (res: string) => {
		clearTimeout(timer!);
		const newTimer = setTimeout(() => {
			search(1, res);
		}, 500);
		setTimer(newTimer);
	}

	return (
		<>

			{isLoading() && <CircularLoading tipo="FULLSIZED" />}

			<Fade in mountOnEnter unmountOnExit>
				<div className={`flex-column w-100 h-100 ${classes.color}`}>
					<PageHeader
						showSearchableBar
						showBotaoFiltrar
						showMenu
						titulo='Compras'
						filtroListagem={res => filtroListagem(res)} />

					<Paginacao
						pageChanged={pageChanged}
						totalPages={queryStatus.totalPages}
						totalRegisters={queryStatus.totalResults}
						currentPage={queryStatus.page}
					/>

					<div className={`flex-column h-100 mb-20 overflow-auto ${classes.listPadding}`}>

						{!queryStatus.list.length &&
							<Typography variant="h3" className={classes.semRegistroStyle}>
								Nenhuma compra foi encontrada.
							</Typography>
						}

						<div className={`flex-column h-100 w-100 ${classes.listContainer}`}>

							{queryStatus.list.map((row, index) => (

								<DefaultCard key={index} className={classesCard.cardContainer}
									onClick={() => visualizarCompra(row.id, row.dataHoraLancamento)}>
									<div className={`flex-row w-100 h-100`}>
										<div className={classNames(classesCard.tagStatus, classes.statusColor)} />

										<div className={classesCard.infoTagContainer}>
											<Typography className={classesCard.infoTagStyle}>
												C
											</Typography>
										</div>

										<div className={`flex-row w-100 ${classesCard.infoContainer}`}>
											<div className={`flex-column ${classesCard.inputMinWidth}`}>
												<Typography className={classesCard.labelFont}>
													Fornecedor:
												</Typography>
												<Typography className={classesCard.contentFont}>
													{row.fornecedor?.nome}
												</Typography>
											</div>
											<div className={`flex-column ${classesCard.inputMinWidth}`}>
												<Typography className={classesCard.labelFont}>
													Nº NF:
												</Typography>
												<Typography className={classesCard.contentFont}>
													{row.numeroNotaFiscal}
												</Typography>
											</div>
											<div className={`flex-column ${classesCard.inputMinWidth}`}>
												<Typography className={classesCard.labelFont}>
													Data:
												</Typography>
												<Typography className={classesCard.contentFont}>
													{moment(row.dataNotaFiscal).format("DD/MM/YYYY")}
												</Typography>
											</div>
											<div className={`flex-column ${classesCard.inputMinWidth}`}>
												<Typography className={classesCard.labelFont}>
													Data Lançamento:
												</Typography>
												<Typography className={classesCard.contentFont}>
													{moment(row.dataHoraLancamento).format("DD/MM/YYYY")}
												</Typography>
											</div>
										</div>

										<Tooltip arrow title="Vizualizar Compra">
											<div className={classesCard.cardRightButton}>
												<ProcurarIcon tipo="BUTTON" onClick={() => visualizarCompra(row.id, row.dataHoraLancamento)} />
											</div>
										</Tooltip>
									</div>
								</DefaultCard>

							))}
						</div>

						{modalOpen && (compraModal)}

						<div className={`flex-row w-100 ${classNames(classes.buttonContainer, classes.buttonPosition)}`}>
							<div className={classNames(classes.buttonStyle, classes.darkButton, classes.buttonAlign)}>
								<Button onClick={() => compraRender()} variant="text" className="h-100 w-100">
									<div className={`flex-row ${classes.buttonLabel} ${classes.whiteLabel}`}>
										<NovoIcon class={classes.iconSize} />
									</div>
								</Button>
							</div>
						</div>

					</div>
				</div>
			</Fade>
		</>
	);
}
